import { getClient } from './base.service'
import { exposeErrors } from '../utils/errors'

export const getAllMonthsReport = async () => {
    const data = await getClient().usageReports.summaryForAllMonths()
    const usage = []
    data.usage.forEach(element => {
        const date = new Date(element.month.year, element.month.month - 1, 1)
        usage.push({
            month: {
                month: element.month.month,
                year: element.month.year,
                label: `${date.toLocaleString('en-us', { month: 'short' })}, ${date.toLocaleString('en-us', { year: '2-digit' })}`,
                fullLabel: `${date.toLocaleString('en-us', { month: 'long' })}, ${element.month.year}`
            },
            numUsedObjects: element.numUsedObjects
        })
    })

    return {
        usageCutoffDate: data.usageCutoffDate,
        usage
    }
}

const toYearAndMonth = (month, year) => `${year}-${('0' + month).slice(-2)}`

export const getMonthlyReport = (month, year) => {
    return getClient().usageReports.detailsForMonth(toYearAndMonth(month, year))
}

export const getEventInMonthReport = (eventId, month, year) => {
    return exposeErrors(getClient().usageReports.detailsForEventInMonth(eventId, toYearAndMonth(month, year)))
}

export const getSubscriptionReport = async () => {
    return getClient().usageReports.subscription()
}
