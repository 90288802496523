import { useEffect } from 'react'
import { persistencyService } from '../../services/persistency.service'
import { history } from '../../store'
import { useDispatch } from 'react-redux'
import { logout } from '../../actions/user'

const Logout = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(logout())
        persistencyService.remove('charts-scroll')
        history.push('/login')
    }, [dispatch])

    return null
}

export default Logout
