import React from 'react'
import * as PropTypes from 'prop-types'

const DropDown = props => {
    const label = props.list.length > 0 ? props.list[props.selectedIndex].label : ''
    const value = props.list.length > 0 ? props.list[props.selectedIndex].value : ''
    return <div className="DropDown">
        <div className="container">
            <div className="value">{label}</div>
            <select onChange={props.onChange} value={value} name={props.name}>
                {props.list.map(item => (
                    <option
                        value={item.value}
                        key={`dropdown-${item.label}`}
                    >
                        {item.label}
                    </option>
                ))}
            </select>
        </div>
    </div>
}

DropDown.propTypes = {
    onChange: PropTypes.func,
    list: PropTypes.array,
    label: PropTypes.string,
    selectedIndex: PropTypes.number,
    name: PropTypes.string
}

export default DropDown
