import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hideHeader } from '../actions/header'
import SplashScreenNotFound from '../components/GUI/SplashScreenNotFound'

class PageNotFound extends React.Component {
    componentDidMount () {
        this.props.hideHeader()
    }

    render () {
        return <SplashScreenNotFound/>
    }
}

PageNotFound.propTypes = {
    hideHeader: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
    hideHeader: payload => dispatch(hideHeader(payload))
})

export default connect(null, mapDispatchToProps)(PageNotFound)
