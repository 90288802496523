import { slowRequestDetectionService } from './slowRequestDetection.service'
import store from '../store'
import { backendUrlOfActiveCompany } from '../domain/regions'
import { WebappSeatsioClient } from '../utils/webappSeatsioClient'

export const getClient = () => {
    const user = store.getState().user
    const context = store.getState().context
    const client = new WebappSeatsioClient({ url: backendUrlOfActiveCompany() }, user.secretKey, context.workspace.key, context.company.id)
    client.setRequestListener(slowRequestDetectionService.slowRequestDetector)
    return client
}
