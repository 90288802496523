import React, {Component} from "react";
import {connect} from "react-redux";
import {resetHeader} from "../../../actions/header";
import lang from "../../../utils/lang";
import {isCompanyOrSuperAdmin} from "../../../reducers/user";
import * as PropTypes from "prop-types";
import Invoices from './Invoices'
import UpdateBillingDetails from './UpdateBillingDetails'
import { notificationService } from '../../../services/notification.service'
import ManagePaymentMethods from './ManagePaymentMethods'

class Billing extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            activeSection: 'invoices'
        }
    }

    async componentDidMount() {
        this.props.resetHeader()
        this.selectActiveSectionFromHash()
        this.showMessage()
        this.setState({loading: false})
    }

    selectActiveSectionFromHash() {
        const hash = window.location.hash.substring(1);
        if (hash) {
            this.setState({ activeSection: hash })
        } else if (this.props.location && this.props.location.state && this.props.location.state.tab) {
            this.setState({ activeSection: this.props.location.state.tab })
        }
    }

    showMessage() {
        if (this.props.location && this.props.location.state && this.props.location.state.messageKey) {
            notificationService.info(lang.d(this.props.location.state.messageKey), lang.d(`${this.props.location.state.messageKey}_message`))
        }
    }

    render() {
        if (this.state.loading) {
            return <div className="Billing">
                <div className="simple-page">
                    { this.renderLoadingPlaceholder() }
                    { this.renderLoadingPlaceholder() }
                    { this.renderLoadingPlaceholder() }
                </div>
            </div>
        }
        return (
            <div className="Billing">
                <div className="sidebar">
                    { this.renderSidebar() }
                </div>
                <div className="simple-page">
                    <div className="page-title">{lang.d('billing')}</div>
                    { isCompanyOrSuperAdmin(this.props.user) && this.renderContentSection() }
                </div>
            </div>
        )
    }

    renderSidebar() {
        return <>
            { this.renderSidebarItem('invoices') }
            { this.renderSidebarItem('billing_details') }
            { this.renderSidebarItem('payment_methods') }
        </>
    }

    renderSidebarItem(itemName) {
        return <a
            href={'#' + itemName}
            className={`tab ${this.state.activeSection === itemName ? 'active' : ''}`}
            onClick={() => this.setState({activeSection: itemName})}
        >
            { lang.d(itemName) }
        </a>
    }

    renderContentSection() {
        switch (this.state.activeSection) {
        case 'invoices':
            return <Invoices {...this.props} />
        case 'billing_details':
            return <UpdateBillingDetails {...this.props} />
        case 'payment_methods':
            return <ManagePaymentMethods {...this.props} />
        default:
            return <Invoices {...this.props} />
        }
    }

    renderLoadingPlaceholder() {
        return <div className="loading-placeholder">
            <div className="fake-item"></div>
            <div className="fake-item"></div>
            <div className="fake-item"></div>
        </div>
    }

}

Billing.propTypes = {
    user: PropTypes.object,
    company: PropTypes.object,
    resetHeader: PropTypes.func.isRequired,
    location: PropTypes.object
}

const mapStateToProps = (state) => ({
    setting: state.context.company.settings,
    user: state.user,
    company: state.context.company
})

const mapDispatchToProps = dispatch => ({
    resetHeader: () => dispatch(resetHeader())
})

export default connect(mapStateToProps, mapDispatchToProps)(Billing)
