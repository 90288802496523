import React from 'react'
import lang from '../../../utils/lang'
import { setHeader } from '../../../actions/header'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '../../../components/GUI/Button'
import { contextualMenuService } from '../../../services/contextualMenu.service'
import { notificationService } from '../../../services/notification.service'
import { exposeErrors } from '../../../utils/errors'
import { upperFirst } from 'lodash'
import { getPaymentMethods, removePaymentMethod, updateDefaultPaymentMethod } from '../../../services/companies.service'
import Badge from '../../../components/GUI/Badge'
import { Link } from 'react-router-dom'

class ManagePaymentMethods extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            paymentMethods: null,
            contextualMenuOpened: false
        }
    }

    async componentDidMount () {
        this.props.setHeader({
            leftItems: [{
                to: '/company/add-payment-method',
                icon: 'icon-plus',
                string_key: 'add_payment_method',
                highlighted: true
            }]
        })
        await this.loadPaymentMethods()
    }

    async loadPaymentMethods () {
        getPaymentMethods(this.props.companyId)
            .then(paymentMethods => this.setState({paymentMethods}))
    }

    onContextMenu (e, paymentMethod) {
        this.setState({ contextualMenuOpened: true })
        const now = new Date()
        const options = [
            {
                type: 'setAsDefault',
                caption: lang.d('set_as_default'),
                uiEvent: {
                    type: 'setAsDefault',
                    id: paymentMethod.id
                },
                disabled: paymentMethod.isDefault || (((now.getMonth() + 1) < paymentMethod.expiryMonth) && now.getYear() <= paymentMethod.expiryYear)
            },
            {
                type: 'remove',
                caption: lang.d('remove'),
                uiEvent: {
                    type: 'remove',
                    id: paymentMethod.id
                },
                disabled: paymentMethod.isDefault
            }
        ]
        contextualMenuService.show(options, {
            onSelect: uiEvent => this.onAction(uiEvent, paymentMethod.id),
            onClose: () => this.setState({ contextualMenuOpened: false }),
            position: {
                left: e.clientX,
                top: e.clientY
            }
        })
    }

    async onAction (uiEvent, paymentMethodId) {
        switch (uiEvent.type) {
        case 'setAsDefault':
            await this.setAsDefault(paymentMethodId)
            break
        case 'remove':
            await this.remove(paymentMethodId)
            break
        default:
            break
        }
    }

    async setAsDefault (paymentMethodId) {
        await exposeErrors(updateDefaultPaymentMethod(this.props.companyId, paymentMethodId))
        await this.loadPaymentMethods()
    }

    async remove (paymentMethodId) {
        await exposeErrors(removePaymentMethod(this.props.companyId, paymentMethodId))
        await this.loadPaymentMethods()
    }

    render () {
        return <div className="ManagePaymentMethods" key={`payment-methods-page-${this.state.requestedPage}`}>
            <div className="container">
                {this.state.paymentMethods
                    ? this.renderTable()
                    : this.renderPlaceholder()
                }
            </div>
        </div>
    }

    renderTable () {
        return <div className="DataTable">
            <table>
                <thead>
                    <tr>{ this.renderHeaders() }</tr>
                </thead>
                <tbody>
                    { this.renderRows() }
                </tbody>
            </table>
        </div>
    }

    renderHeaders () {
        return <>
            <th style={{width: '24%'}}>{lang.d('brand')}</th>
            <th style={{width: '24%'}}>{lang.d('last4')}</th>
            <th style={{width: '24%'}}>{lang.d('expiry_date')}</th>
            <th style={{width: '24%'}}></th>
            <th style={{width: '4%'}}></th>
        </>
    }

    renderRows () {
        return this.state.paymentMethods
            .map((paymentMethod) => {
                return <tr key={paymentMethod.id}>
                    <td>{upperFirst(lang.d(paymentMethod.brand))}</td>
                    <td>{paymentMethod.lastFour}</td>
                    <td>{paymentMethod.expiryMonth}/{paymentMethod.expiryYear}</td>
                    <td>{paymentMethod.isDefault &&
                        <Badge key="default" type="default">{lang.d('default')}</Badge>}</td>
                    <td>
                        <Button preset="round" type="more"
                            onClick={e => this.onContextMenu(e, paymentMethod)}/>
                    </td>
                </tr>
            })
    }

    renderPlaceholder () {
        return <div className="DataTable">
            <div className="loading-placeholder">
                <div className="fake-item double"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
            </div>
        </div>
    }
}

ManagePaymentMethods.propTypes = {
    setHeader: PropTypes.func,
    location: PropTypes.object,
    companyId: PropTypes.number
}

const mapStateToProps = (state) => ({
    companyId: state.context.company.id
})

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ManagePaymentMethods)
