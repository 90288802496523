import React from 'react'
import lang from '../utils/lang'
import { Formik } from 'formik'
import { userService } from '../services/user.service'
import { history } from '../store'
import LoadingIndicator from '../components/GUI/LoadingIndicator'
import * as PropTypes from 'prop-types'
import { parseBackendErrors } from '../utils/backendErrors'
import { useDispatch } from 'react-redux'
import { setUser } from '../actions/user'
import { hasErrorCode } from '../utils/errors'

export default function AcceptInvitationForNewUserForm (props) {
    const dispatch = useDispatch()
    return (
        <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            validate={values => {
                const errors = {}

                if (!values.password) {
                    errors.password = lang.d('required')
                }

                if (!values.confirmPassword) {
                    errors.confirmPassword = lang.d('required')
                }

                if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
                    errors.confirmPassword = lang.d('password_mismatch')
                }

                return errors
            }}
            onSubmit={async (values, { setErrors }) => {
                try {
                    const user = await userService.acceptInvitationForNewUser(props.code, values.password, props.region)
                    dispatch(setUser(user))
                    history.push('/')
                } catch (err) {
                    if (hasErrorCode(err, 'INVITATION_CANCELLED')) {
                        setErrors({ global: lang.d('invitation_cancelled') })
                    } else {
                        const { messages } = err
                        setErrors(parseBackendErrors(messages))
                    }
                }
            }}
            render={Form}
        />
    )
}

const Form = (props) => {
    const {
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        isSubmitting
    } = props

    return (
        <form onSubmit={handleSubmit} className="AccountForm dialog">
            <div className="title">{ lang.d('choose_password') }</div>

            <div className="form">
                <div className="form-field">
                    <input id="password"
                        type="password"
                        placeholder={lang.d('password')}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    { errors.password && touched.password && <div className="errors">
                        <div className="error" style={{ margin: '4px' }}>{errors.password}</div>
                    </div> }
                </div>
                <div className="form-field">
                    <input id="confirmPassword"
                        type="password"
                        placeholder={lang.d('confirm_password')}
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    { errors.confirmPassword && touched.confirmPassword && <div className="errors">
                        <div className="error" style={{ margin: '4px' }}>{errors.confirmPassword}</div>
                    </div> }
                </div>
                { errors.global && <div className="errors">
                    <div className="error" key="global">{errors.global}</div>
                </div> }
                <div className="actions">
                    { isSubmitting
                        ? <LoadingIndicator />
                        : <input type="submit" value={lang.d('confirm_password')} disabled={isSubmitting} className="action highlighted"/>
                    }
                </div>
            </div>
        </form>
    )
}

AcceptInvitationForNewUserForm.propTypes = {
    code: PropTypes.string,
    region: PropTypes.string
}

Form.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.bool,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleBlur: PropTypes.func,
    values: PropTypes.object,
    isSubmitting: PropTypes.bool
}
