import React from 'react'
import GeneralErrorModalDialogBody from './GeneralErrorModalDialogBody'
import LoadingIndicator from './LoadingIndicator'
import * as PropTypes from 'prop-types'

class SplashScreenModalDialog extends React.Component {
    render () {
        return (
            <div className="ModalDialog SplashScreen">
                <div className="logo"></div>
                <div className="backdrop"></div>
                {this.props.body ||
                <div className="dialog">
                    {this.renderOverlay()}
                    {this.props.title && <div className="title">{this.props.title}</div>}
                    <div className="message">{this.props.message}</div>
                    {this.renderActions()}
                </div>
                }
            </div>
        )
    }

    renderOverlay () {
        if (this.props.errorOverlayShown) {
            return this.renderErrorOverlay()
        } else if (this.props.overlayShown) {
            return this.renderRegularOverlay()
        }
        return null
    }

    renderErrorOverlay () {
        return (
            <div className="overlay-message">
                <GeneralErrorModalDialogBody />
            </div>
        )
    }

    renderRegularOverlay () {
        return (
            <div className="overlay-message">
                {this.props.overlayTitle && <div className="title">{this.props.overlayTitle}</div>}
                <div className="message">{this.props.overlayMessage}</div>
            </div>
        )
    }

    renderActions () {
        if (!this.props.actions && !this.props.isLoading) {
            return null
        }

        return (
            <div className="actions">
                {this.props.isLoading ? <LoadingIndicator /> : this.props.actions}
            </div>
        )
    }
}

SplashScreenModalDialog.propTypes = {
    body: PropTypes.node,
    title: PropTypes.string,
    message: PropTypes.string,
    errorOverlayShown: PropTypes.bool,
    overlayShown: PropTypes.bool,
    overlayTitle: PropTypes.string,
    overlayMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    actions: PropTypes.node
}

export default SplashScreenModalDialog
