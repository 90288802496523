import React from 'react'
import * as PropTypes from 'prop-types'
import { contextualMenuService } from '../../services/contextualMenu.service'
import Button from './Button'
import lang from '../../utils/lang'
import { formatWorkspaceName } from '../../utils/helperFunctions'
import { connect } from 'react-redux'
import { workspacesService } from '../../services/workspaces.service'
import { exposeErrors } from '../../utils/errors'
import { DEFAULT_NOTIFICATION_TIMEOUT, notificationService } from '../../services/notification.service'
import Badge from './Badge'

class InactiveWorkspaceListItem extends React.Component {
    state = {
        contextualMenuOpened: false
    }

    getContextMenuOptions () {
        const options = []
        if (!this.props.readOnly) {
            options.push({
                type: 'power',
                caption: lang.d('activate_workspace'),
                uiEvent: 'activate-workspace'
            })
        }
        return options
    }

    onContextMenu (e) {
        e.preventDefault()
        window.getSelection().removeAllRanges()
        this.setState({ contextualMenuOpened: true })
        contextualMenuService.show(this.getContextMenuOptions(), {
            onSelect: uiEvent => this.onAction(uiEvent),
            onClose: () => this.setState({ contextualMenuOpened: false }),
            position: {
                left: e.clientX,
                top: e.clientY
            }
        })
    }

    onAction (uiEvent) {
        this.setState({ contextualMenuOpened: false })
        switch (uiEvent) {
        case 'activate-workspace':
            this.activate()
            break
        default:
            throw new Error('Unknown uiEvent: ' + uiEvent)
        }
    }

    async activate () {
        await exposeErrors(workspacesService.activateWorkspace(this.props.workspace.key))
        notificationService.info(lang.d('workspace_activated'), this.props.workspace.name, DEFAULT_NOTIFICATION_TIMEOUT)
        this.props.onWorkspaceActivated(this.props.workspace)
    }

    workspaceNameColumn (workspace) {
        return <td>{ formatWorkspaceName(workspace) } { this.getWorkspaceBadges(workspace) }</td>
    }

    getWorkspaceBadges (workspace) {
        const badges = []
        if (workspace.isTest) {
            badges.push(<Badge key="test" type="test">{ lang.d('test') }</Badge>)
        } else {
            badges.push(<Badge key="production" type="production">{ lang.d('production-short') }</Badge>)
        }
        return badges
    }

    render () {
        const workspace = this.props.workspace
        return (
            <tr className={`${this.state.contextualMenuOpened && 'contextual-menu-opened'}`} onContextMenu={e => this.onContextMenu(e)}>
                { this.workspaceNameColumn(workspace) }
                <td className="fixed-width">{workspace.key}</td>
                <td className="has-button"><Button preset="round" type="more" onClick={e => this.onContextMenu(e)} /></td>
            </tr>
        )
    }
}

InactiveWorkspaceListItem.propTypes = {
    workspace: PropTypes.object.isRequired,
    context: PropTypes.object,
    readOnly: PropTypes.bool,
    doSearch: PropTypes.func,
    searchValue: PropTypes.string,
    onWorkspaceActivated: PropTypes.func
}

const mapStateToProps = state => ({
    context: state.context,
    searchValue: state.search.workspaces.value
})

export default connect(mapStateToProps, null)(InactiveWorkspaceListItem)
