import React from 'react'
import { chartService } from '../../services/chart.service'
import { seasonService } from '../../services/season.service'
import { PrivateRoute } from '../../components/CustomRoutes'
import NavigationList from '../../components/GUI/NavigationList'
import ChartDetailSubpage, { loadingPlaceholder as subpageLoadingPlaceholder } from './ChartDetailSubpage'
import { EventDetail } from '../Events'
import lang from '../../utils/lang'
import { notificationService, DEFAULT_NOTIFICATION_TIMEOUT } from '../../services/notification.service'
import { history } from '../../store'
import { connect } from 'react-redux'
import { exposeErrors, hasErrorCode, showFailedToContactServerError } from '../../utils/errors'
import {
    cloneObject,
    generateChartValidationList
} from '../../utils/helperFunctions'
import { setHeader } from '../../actions/header'
import { createChart } from '../../actions/charts'
import { addEvent, createEvent, setNewestAddedEventKey, playNewestAddedEventKey } from '../../actions/events'
import * as PropTypes from 'prop-types'
import {openModalDialog, setSubmitEnabled} from '../../actions/modalDialog'
import { Switch } from 'react-router'
import { isNonAdmin, isSuperAdminImpersonating } from '../../reducers/user'
import {workspacesService} from "../../services/workspaces.service";
import CopyToForm from "../../components/CopyToForm";
import { lowerCase } from 'lodash'

const copyToWorkspaceForm = props => {
    const {
        values,
        handleChange,
        errors,
        setFieldValue
    } = props

    return <CopyToForm values={values} handleChange={handleChange} errors={errors} setFieldValue={setFieldValue} />
}

class ChartDetail extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            chart: {},
            eventSearchValue: '',
            creatingEvent: false,
            creatingSeason: false,
            standaloneEventsListSorting: 'createdOnAsc'
        }
        this.handleCopyChart = this.handleCopyChart.bind(this)
        this.archiveChart = this.archiveChart.bind(this)
        this.copyToWorkspace = this.copyToWorkspace.bind(this)
    }

    componentDidMount () {
        this.props.setHeader({
            showSearch: true,
            caption: 'search_events',
            leftItems: [{
                to: '/',
                icon: 'icon-arrow-light-left',
                string_key: 'all_seating_charts',
                className: 'soft-button'
            }],
            centerItems: this.getCenterHeaderOptions()
        })

        this.loadData()
    }

    getCenterHeaderOptions () {
        const defaultCenterItems = [
            {
                to: {
                    pathname: `/charts/${this.props.match.params.chartKey}/edit`,
                    state: { fromSeatsio: true }
                },
                icon: 'icon-edit',
                string_key: 'edit_seating_chart'
            },
            {
                button: true,
                icon: 'icon-duplicate',
                string_key: 'duplicate_chart',
                onClick: this.handleCopyChart
            },
            {
                button: true,
                icon: 'icon-archive',
                string_key: 'archive_chart',
                onClick: this.archiveChart
            }
        ];
        const copyToWorkspaceOption = {
            button: true,
            icon: 'icon-copy',
            string_key: 'copy_to_workspace',
            onClick: this.copyToWorkspace
        };

        if (isSuperAdminImpersonating(this.props.user, this.props.context)) {
            return [...defaultCenterItems, copyToWorkspaceOption]
        } else if (isNonAdmin(this.props.user) && this.props.context.isDefaultContextOfUser) {
            return defaultCenterItems
        } else {
            return [...defaultCenterItems, copyToWorkspaceOption]
        }
    }

    async loadData () {
        await chartService.retrieveChartWithEvents(this.props.match.params.chartKey)
            .then(c => this.setState({ chart: c }))
            .catch(err => {
                if (hasErrorCode(err, 'CHART_NOT_FOUND')) {
                    history.replace('/')
                    notificationService.error(lang.d('error'), lang.d('chart_not_found'))
                } else showFailedToContactServerError()
            })
    }

    async createEvent (chartKey) {
        this.props.clearSearch()
        this.setState({ creatingEvent: true })
        await this.props.createEvent(chartKey)
        await exposeErrors(this.loadData())
        this.setState({ creatingEvent: false })
        setTimeout(() => this.props.clearEventAddedAnimation(), 1500)
    }

    async createSeason (chartKey) {
        this.props.clearSearch()
        this.setState({ creatingSeason: true })
        const season = await exposeErrors(seasonService.createSeason(chartKey))
        this.props.playNewestAddedEventKey(season.key)
        this.setState({ creatingSeason: false })
        await exposeErrors(this.loadData())
    }

    sortEvents (sortingOption) {
        this.setState({standaloneEventsListSorting: sortingOption})
    }

    getEventsList () {
        const subpage = this.props.match.params.subpage
        const filterBySearch = (event) => {
            const searchTerm = this.props.search.value.toUpperCase()
            return event.key.toUpperCase().includes(searchTerm)
                || (event.name && event.name.toUpperCase().includes(searchTerm))
        }
        const filterSeasonBySearch = (season) => {
            return filterBySearch(season) || season.eventsInSeason.some(event => filterBySearch(event))
        }
        const sortByNameOrKey = (sortOption) => {
            return (event1, event2) => {
                if (sortOption === 'createdOnAsc') {
                    return event1.createdOn - event2.createdOn;
                } else if (sortOption === 'nameOrKeyAsc') {
                    return this.getEventNameOrKey(event1).localeCompare(this.getEventNameOrKey(event2));
                } else if (sortOption === 'nameOrKeyDesc') {
                    return this.getEventNameOrKey(event2).localeCompare(this.getEventNameOrKey(event1));
                }
            }
        }
        const getEventIcon = (event) => {
            if (event.isTopLevelSeason) return 'season'
            if (event.isPartialSeason) return 'partial-season'
            if (event.isEventInSeason) {
                return event.isInThePast ? 'event-done' : 'season-event'
            }
            return undefined
        }
        const eventToNavLink = (event) => ({
            type: 'link',
            caption: this.getEventNameOrKey(event),
            subCaption: this.props.search.value ? (event.name ? event.key : undefined) : undefined,
            captionStyle: !event.name ? 'fixed-width' : '',
            subCaptionStyle: event.name ? 'fixed-width' : '',
            codename: event.key,
            highlighted: this.props.newestAddedEvent === event.key,
            icon: getEventIcon(event),
            to: `/charts/${this.state.chart.key}/events/${event.key}` + (subpage ? `/${subpage}` : ''),
            className: `${event.isPartialSeason || event.isEventInSeason ? 'sub' : ''} ${event.isInThePast ? 'indicate-done' : ''}`
        })
        const noMatch = (matchType) => ([{
            type: 'info',
            caption: lang.d('no_match', {type: lowerCase(matchType), searchTerm: this.props.search.value}),
            highlighted: false,
            className: 'no-match'
        }])
        const handleEmptyResults = (events, type) => {
            return (this.props.search.value && this.props.search.value.length > 0 && events.length === 0) ? noMatch(type) : events.map(event => eventToNavLink(event))
        }
        const topLevelSeasons = this.state.chart.events.filter(event => event.isTopLevelSeason).map(topLevelSeason => {
            topLevelSeason.eventsInSeason = []
            return topLevelSeason
        })
        this.state.chart.events
            .filter(event => event.isPartialSeason || event.isEventInSeason)
            .forEach(seasonalEvent => topLevelSeasons
                .filter(topLevelSeason => topLevelSeason.key === seasonalEvent.topLevelSeasonKey)
                .forEach(topLevelSeason => topLevelSeason.eventsInSeason.push(seasonalEvent))
            )
        const standaloneEventsList = this.state.chart.events
            .filter(event => !event.isTopLevelSeason && !event.isEventInSeason && !event.isPartialSeason)
            .filter(filterBySearch)
            .sort(sortByNameOrKey(this.state.standaloneEventsListSorting))
        const seasonalEventsList = topLevelSeasons.filter(filterSeasonBySearch).map(topLevelSeason => {
            const matchingEventsInSeason = topLevelSeason.eventsInSeason.filter(filterBySearch)
            const eventsToInclude = (matchingEventsInSeason.length === 0) ? [].concat(topLevelSeason.eventsInSeason) : matchingEventsInSeason
            eventsToInclude.unshift(topLevelSeason)
            return eventsToInclude
        }).flat()
        return {
            seasonalEvents: handleEmptyResults(seasonalEventsList, lang.d('seasonal_events')),
            standaloneEvents: handleEmptyResults(standaloneEventsList, lang.d('events'))
        }
    }

    getEventNameOrKey(event) {
        return event.name ? event.name : event.key;
    }

    deleteEventFromState (key) {
        const chart = cloneObject(this.state.chart)
        chart.events = chart.events.filter(event => event.key !== key)
        this.setState({ chart })
    }

    async refreshAfterEventKeyUpdate () {
        await exposeErrors(this.loadData())
        history.replace(`/charts/${this.props.match.params.chartKey}/events/${this.props.updatedEventKey}`)
    }

    async archiveChart (e) {
        e.preventDefault()
        await exposeErrors(chartService.archiveChart(this.props.match.params.chartKey))
        notificationService.info(lang.d('seating_chart_archived'), this.state.chart.name, DEFAULT_NOTIFICATION_TIMEOUT)
        history.replace('/')
    }

    async copyToWorkspace () {
        const { user, context } = this.props
        const workspaceList = await workspacesService.listActiveWorkspaces()
        this.props.setSubmitEnabled(false)
        this.props.openModalDialog({
            title: this.state.chart.name,
            prompt: workspaceList.length !== 0,
            onSubmit: values => chartService.copyToWorkspace(this.state.chart.key, values.selectedWorkspace),
            successMessage: lang.d('copied'),
            settings: {
                acceptCaption: lang.d('copy'),
                formik: {
                    initialValues: { user, context, workspaceList },
                    validate: (values) => {
                        const errors = {}
                        this.props.setSubmitEnabled(values.selectedWorkspace !== '')
                        return errors
                    },
                    render: copyToWorkspaceForm
                }
            }
        })
    }

    async handleCopyChart () {
        const validationResult = await this.validateChart(this.props.match.params.chartKey)
        if (validationResult.hasErrors || validationResult.hasWarnings) {
            const dialogServiceDescription = <div>{lang.d('copy_chart_with_validation_issues')} <div className="draft-validation-warning">{validationResult.list}</div></div>
            this.props.openModalDialog({
                title: lang.d('duplicate_chart'),
                message: dialogServiceDescription,
                successMessage: lang.d('duplicated'),
                settings: {
                    onAccept: () => this.duplicateChart(),
                    acceptCaption: lang.d('duplicate_chart'),
                    waitOnAccept: true,
                    dangerousAction: true
                }
            })
        } else await this.duplicateChart()
    }

    async validateChart (key) {
        const res = await exposeErrors(chartService.getPublishedChartValidation(key))
        return { list: generateChartValidationList(res), hasErrors: res.errors.length > 0, hasWarnings: res.warnings.length > 0 }
    }

    async duplicateChart () {
        const chart = await exposeErrors(chartService.copyChart(this.props.match.params.chartKey))
        this.props.createChart(chart)
        notificationService.info(lang.d('seating_chart_duplicated'), this.state.chart.name, DEFAULT_NOTIFICATION_TIMEOUT)
        history.replace('/')
    }

    onAction (uiEvent, args) {
        switch (uiEvent) {
        case 'editChart':
            history.push(`/charts/${this.state.chart.key}/edit`, { fromSeatsio: true })
            break
        case 'createEvent':
            this.createEvent(this.state.chart.key)
            break
        case 'createSeason':
            this.createSeason(this.state.chart.key)
            break
        case 'sortEvents':
            this.sortEvents(args)
            break
        default:
            break
        }
    }

    render () {
        if (Object.keys(this.state.chart).length === 0) {
            return <div className="ChartDetail">
                <div className="page">
                    <NavigationList
                        options={[{
                            type: 'title',
                            caption: lang.d('seating_chart')
                        },
                        {
                            type: 'link',
                            caption: lang.d('loading') + '...',
                            to: `/charts/${this.props.match.params.chartKey}`,
                            exact: true
                        },
                        {
                            type: 'title',
                            caption: lang.d('events')
                        }]}
                    />
                    <div className="contents">
                        { subpageLoadingPlaceholder }
                    </div>
                </div>
            </div>
        }
        const { chart: { key, name } } = this.state
        const { seasonalEvents, standaloneEvents } = this.getEventsList()

        return (
            <div className="ChartDetail">
                <div className="page">
                    <NavigationList
                        options={
                            [{
                                type: 'title',
                                caption: lang.d('seating_chart'),
                                buttonIcon: 'edit',
                                buttonCaption: lang.d('edit_seating_chart'),
                                buttonCodename: 'editChart'
                            },
                            {
                                type: 'link',
                                caption: name,
                                to: `/charts/${key}`,
                                exact: true
                            },
                            {
                                type: 'title',
                                caption: lang.d('events'),
                                buttonIcon: 'plus-bold',
                                buttonCaption: lang.d('create_event'),
                                buttonCodename: 'createEvent',
                                buttonClassName: this.state.creatingEvent && 'loading',
                                sortOptions: [
                                    {
                                        icon: 'sort-time-asc',
                                        value: 'createdOnAsc'
                                    },
                                    {
                                        icon: 'sort-az-asc',
                                        value: 'nameOrKeyAsc'
                                    },
                                    {
                                        icon: 'sort-az-desc',
                                        value: 'nameOrKeyDesc'
                                    }
                                ],
                                sortButtonCodename: 'sortEvents',
                                sortValue: this.state.standaloneEventsListSorting
                            },
                            ...standaloneEvents,
                            {
                                type: 'title',
                                caption: lang.d('seasons'),
                                buttonIcon: 'plus-bold',
                                buttonCaption: lang.d('create_season'),
                                buttonCodename: 'createSeason',
                                buttonClassName: this.state.creatingSeason && 'loading'
                            },
                            ...seasonalEvents
                            ]
                        }
                        onSelect={(uiEvent, args) => this.onAction(uiEvent, args)}
                    />
                    <div className="contents">
                        <Switch>
                            <PrivateRoute exact path="/charts/:chartKey" render={() => <ChartDetailSubpage {...this.state} />} />
                        </Switch>
                        { this.props.match.params.eventKey &&
                            <PrivateRoute
                                exact
                                path="/charts/:chartKey/events/:eventKey"
                                component={EventDetail}
                                key={this.props.match.params.eventKey}
                                removeEvent={this.deleteEventFromState.bind(this)}
                                refresh={this.refreshAfterEventKeyUpdate.bind(this)}
                                chartEvents={this.state.chart.events}
                                onEventChanges={() => this.loadData()}
                                context={this.props.context}
                                clearSearch={this.props.clearSearch}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

ChartDetail.propTypes = {
    match: PropTypes.object.isRequired,
    playNewestAddedEventKey: PropTypes.func.isRequired,
    clearEventAddedAnimation: PropTypes.func.isRequired,
    newestAddedEvent: PropTypes.string,
    updatedEventKey: PropTypes.string,
    search: PropTypes.object.isRequired,
    setHeader: PropTypes.func.isRequired,
    createChart: PropTypes.func.isRequired,
    openModalDialog: PropTypes.func.isRequired,
    addEvent: PropTypes.func.isRequired,
    createEvent: PropTypes.func.isRequired,
    context: PropTypes.object,
    user: PropTypes.object,
    setSubmitEnabled: PropTypes.func,
    clearSearch: PropTypes.func
}

copyToWorkspaceForm.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func
}

const mapStateToProps = state => ({
    search: state.search.chartDetail,
    updatedEventKey: state.charts.updatedEventKey,
    newestAddedEvent: state.charts.newestAddedEvent,
    context: state.context,
    user: state.user,
})

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload)),
    createChart: payload => dispatch(createChart(payload)),
    addEvent: payload => dispatch(addEvent(payload)),
    playNewestAddedEventKey: key => playNewestAddedEventKey(dispatch, key),
    clearEventAddedAnimation: () => dispatch(setNewestAddedEventKey(null)),
    openModalDialog: payload => dispatch(openModalDialog(payload)),
    createEvent: (chartKey) => dispatch(createEvent(chartKey)),
    setSubmitEnabled: enabled => dispatch(setSubmitEnabled(enabled)),
    clearSearch: () => dispatch({
        type: 'CHARTDETAIL/CLEAR_SEARCH'
    })
})

export default connect(mapStateToProps, mapDispatchToProps)(ChartDetail)
