/* eslint-disable react/prop-types */
import React from 'react'
import { Formik } from 'formik'
import { copySingleChart } from '../../services/admin.service'
import { parseBackendErrors } from '../../utils/backendErrors'
import lang from '../../utils/lang'
import Input from '../Input'
import LoadingIndicator from '../GUI/LoadingIndicator'
import { validateCopySingleChart } from '../../utils/validators'
import store from '../../store'

class CopySingleChartForm extends React.Component {
    renderWaiting () {
        const { status, setStatus } = this.props

        if (status === 'submitted') {
            setTimeout(() => {
                setStatus(null)
            }, 2000)
        }

        return (
            <div className={`waiting-indicator ${status === 'submitted' && 'done'}`}>
                <div className="done-indicator">
                    <div className="stamp">
                        <div className="stroke"/>
                        <div className={'icon icon-check'}/>
                    </div>
                    <div className="message">DONE</div>
                </div>
                <LoadingIndicator/>
            </div>
        )
    }

    copyToAdminWorkspace (e) {
        if (this.props.values.copyToMyWorkspace) {
            this.props.setFieldValue('workspaceKey', '')
        } else {
            this.props.setFieldValue('workspaceKey', store.getState().user.defaultWorkspace.key)
        }
        this.props.setFieldTouched('workspaceKey', true, false)
        this.props.setFieldTouched('copyToMyWorkspace', true, false)
        this.props.handleChange(e)
    }

    handleWorkspaceKeyChange (e) {
        this.props.handleChange(e)
        if (e.target.value === store.getState().user.defaultWorkspace.key) {
            this.props.setFieldValue('copyToMyWorkspace', true)
        } else {
            this.props.setFieldValue('copyToMyWorkspace', false)
        }
    }

    render () {
        const {
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            values,
            status
        } = this.props

        return (
            <form name="copyChartForm" onSubmit={handleSubmit} className="AdminForm">
                <div className="form-field">
                    <label htmlFor="copy_chartKey">{ lang.d('chart_key') }</label>
                    <Input
                        id="copy_chartKey"
                        name="chartKey"
                        type="text"
                        value={values.chartKey}
                        onChange={handleChange}
                        className={errors.chartKey && touched.chartKey && 'error'}
                        autoFocus
                    />
                </div>
                <div className="form-field">
                    <label htmlFor="copy_workspaceKey">{ lang.d('copy_to_workspace') }</label>
                    <Input
                        id="copy_workspaceKey"
                        name="workspaceKey"
                        type="text"
                        value={values.workspaceKey}
                        onChange={this.handleWorkspaceKeyChange.bind(this)}
                        className={errors.workspaceKey && touched.workspaceKey && 'error'}
                    />
                </div>
                <div className="form-field single-field">
                    <Input
                        id="copy-to-my-workspace"
                        name="copyToMyWorkspace"
                        type="checkbox"
                        onChange={this.copyToAdminWorkspace.bind(this)}
                        value={true}
                        checked={values.workspaceKey === store.getState().user.defaultWorkspace.key}
                    />
                    <label htmlFor="copy-to-my-workspace">Copy to my workspace</label>
                </div>
                { Object.keys(errors).length > 0 && <div className="errors">
                    { errors.chartKey && <div className="error">{errors.chartKey}</div> }
                    { errors.workspaceKey && <div className="error">{errors.workspaceKey}</div> }
                    { errors.global && <div className="error">{errors.global}</div> }
                </div> }
                { isSubmitting || status === 'submitted'
                    ? this.renderWaiting()
                    : <input type="submit" value={lang.d('copy_chart')} className="FormButton" />
                }
            </form>
        )
    }
}

const CopySingleChart = () => {
    return (
        <Formik
            initialValues={{ workspaceKey: '', chartKey: '', copyToMyWorkspace: false }}
            validate={values => validateCopySingleChart(values)}
            validateOnChange={false}
            onSubmit={(values, { setErrors, setStatus }) => {
                return copySingleChart(values.chartKey, values.workspaceKey)
                    .then(() => {
                        setStatus('submitted')
                    })
                    .catch(err => {
                        const { messages } = err
                        setErrors(parseBackendErrors(messages))
                        setStatus(null)
                    })
            }}
            render={props => <CopySingleChartForm {...props} />}
        />
    )
}

export default CopySingleChart
