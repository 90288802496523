import React, {Component} from "react";
import {
    setCompanyAdminKey,
    setCompanyDefaultRendererSetting,
    setCompanySetting,
    setCompanyValidationSetting,
    updateCompanySettings
} from "../../../actions/user";
import {resetHeader} from "../../../actions/header";
import {closeDialog, openModalDialog} from "../../../actions/modalDialog";
import {connect} from "react-redux";
import lang from "../../../utils/lang";
import OptionSwitcher from "../../../components/GUI/OptionSwitcher";
import * as PropTypes from "prop-types";
import {exposeErrors} from "../../../utils/errors";
import {changeSetting} from "../../../services/accounts.service";

class ChartValidationCompanySettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            validationWithoutLabel: 'warning',
            validationDuplicateLabels: 'warning',
            validationUncategorizedObjects: 'warning',
            validationFocalPoint: 'off',
            validationMultipleObjectsPerCategory: 'off',
            updatingValidationWithoutLabel: false,
            updatingValidationDuplicateLabels: false,
            updatingValidationUncategorizedObjects: false,
            updatingValidationFocalPoint: false,
            updatingValidationMultipleObjectsPerCategory: false,
            updatingValidationEmptyFloor: false,
            updatingValidationNoObjects: false,
        }
    }

    render() {
        return <>
            <div className="setting-page-title">{ lang.d('chart-validation') }</div>
            <div className="setting-page-description">
                <p>{ lang.d('chart_validation_desc') }</p>
            </div>
            <div className="setting">
                <div className="notice">
                    <div className="title">{lang.d('types_of_validation')}</div>
                    <div className="description">
                        <p><strong>{lang.d('validation_off')}:</strong> {lang.d('validation_off_desc')}</p>
                        <p>
                            <strong>{lang.d('validation_warning')}:</strong> {lang.d('validation_warning_desc')}
                        </p>
                        <p><strong>{lang.d('validation_error')}:</strong> {lang.d('validation_error_desc')}
                        </p>
                    </div>
                </div>
                <div className="subtitle">{lang.d('objects_without_label')}</div>
                <div className="text">{lang.d('objects_without_label_desc')}</div>
                <OptionSwitcher
                    value={this.props.setting.chartValidation.VALIDATE_UNLABELED_OBJECTS}
                    options={{
                        OFF: lang.d('validation_off'),
                        WARNING: lang.d('validation_warning'),
                        ERROR: lang.d('validation_error')
                    }}
                    loading={this.state.updatingValidationWithoutLabel}
                    onChange={value => this.setValidationWithoutLabel(value)}
                />

                <div className="subtitle">{lang.d('duplicate_label')}</div>
                <div className="text">{lang.d('duplicate_label_desc')}</div>
                <OptionSwitcher
                    value={this.props.setting.chartValidation.VALIDATE_DUPLICATE_LABELS}
                    options={{
                        OFF: lang.d('validation_off'),
                        WARNING: lang.d('validation_warning'),
                        ERROR: lang.d('validation_error')
                    }}
                    loading={this.state.updatingValidationDuplicateLabels}
                    onChange={value => this.setValidationDuplicateLabels(value)}
                />

                <div className="subtitle">{lang.d('uncategorized_objects')}</div>
                <div className="text">{lang.d('uncategorized_objects_desc')}</div>
                <OptionSwitcher
                    value={this.props.setting.chartValidation.VALIDATE_OBJECTS_WITHOUT_CATEGORIES}
                    options={{
                        OFF: lang.d('validation_off'),
                        WARNING: lang.d('validation_warning'),
                        ERROR: lang.d('validation_error')
                    }}
                    loading={this.state.updatingValidationUncategorizedObjects}
                    onChange={value => this.setValidationUncategorizedObjects(value)}
                />

                <div className="subtitle">{lang.d('missing_focal_point')}</div>
                <div className="text">{lang.d('missing_focal_point_desc')}</div>
                <OptionSwitcher
                    value={this.props.setting.chartValidation.VALIDATE_FOCAL_POINT}
                    options={{
                        OFF: lang.d('validation_off'),
                        WARNING: lang.d('validation_warning'),
                        ERROR: lang.d('validation_error')
                    }}
                    loading={this.state.updatingValidationFocalPoint}
                    onChange={value => this.setValidationFocalPoint(value)}
                />

                <div className="subtitle">{lang.d('multiple_objects_per_category')}</div>
                <div className="text">{lang.d('multiple_objects_per_category_desc')}</div>
                <OptionSwitcher
                    value={this.props.setting.chartValidation.VALIDATE_OBJECT_TYPES_PER_CATEGORY}
                    options={{
                        OFF: lang.d('validation_off'),
                        WARNING: lang.d('validation_warning'),
                        ERROR: lang.d('validation_error')
                    }}
                    loading={this.state.updatingValidationMultipleObjectsPerCategory}
                    onChange={value => this.setValidationMultipleObjectsPerCategory(value)}
                />
                <>
                    <div className="subtitle">{lang.d('no_objects')}</div>
                    <div className="text">{lang.d('no_objects_desc')}</div>
                    <OptionSwitcher
                        value={this.props.setting.chartValidation.VALIDATE_NO_OBJECTS}
                        options={{
                            OFF: lang.d('validation_off'),
                            WARNING: lang.d('validation_warning'),
                            ERROR: lang.d('validation_error')
                        }}
                        loading={this.state.updatingValidationNoObjects}
                        onChange={value => this.setValidationNoObjects(value)}
                    />
                </>
                <>
                    <div className="subtitle">{lang.d('empty_floor')}</div>
                    <div className="text">{lang.d('empty_floor_desc')}</div>
                    <OptionSwitcher
                        value={this.props.setting.chartValidation.VALIDATE_EMPTY_FLOOR}
                        options={{
                            OFF: lang.d('validation_off'),
                            WARNING: lang.d('validation_warning'),
                            ERROR: lang.d('validation_error')
                        }}
                        loading={this.state.updatingValidationEmptyFloor}
                        onChange={value => this.setValidationEmptyFloor(value)}
                    />
                </>
            </div>
        </>
    }

    async setValidationWithoutLabel(value) {
        this.setState({
            updatingValidationWithoutLabel: true
        })

        try {
            await exposeErrors(changeSetting('VALIDATE_UNLABELED_OBJECTS', value.toUpperCase()))
            this.props.setValidationSetting('VALIDATE_UNLABELED_OBJECTS', value)
        } finally {
            this.setState({
                updatingValidationWithoutLabel: false
            })
        }
    }

    async setValidationDuplicateLabels(value) {
        this.setState({
            updatingValidationDuplicateLabels: true
        })

        try {
            await exposeErrors(changeSetting('VALIDATE_DUPLICATE_LABELS', value.toUpperCase()))
            this.props.setValidationSetting('VALIDATE_DUPLICATE_LABELS', value)
        } finally {
            this.setState({
                updatingValidationDuplicateLabels: false
            })
        }
    }

    async setValidationUncategorizedObjects(value) {
        this.setState({
            updatingValidationUncategorizedObjects: true
        })

        try {
            await exposeErrors(changeSetting('VALIDATE_OBJECTS_WITHOUT_CATEGORIES', value.toUpperCase()))
            this.props.setValidationSetting('VALIDATE_OBJECTS_WITHOUT_CATEGORIES', value)
        } finally {
            this.setState({
                updatingValidationUncategorizedObjects: false
            })
        }
    }

    async setValidationFocalPoint(value) {
        this.setState({
            updatingValidationFocalPoint: true
        })

        try {
            await exposeErrors(changeSetting('VALIDATE_FOCAL_POINT', value.toUpperCase()))
            this.props.setValidationSetting('VALIDATE_FOCAL_POINT', value)
        } finally {
            this.setState({
                updatingValidationFocalPoint: false
            })
        }
    }

    async setValidationMultipleObjectsPerCategory(value) {
        this.setState({
            updatingValidationMultipleObjectsPerCategory: true
        })

        try {
            await exposeErrors(changeSetting('VALIDATE_OBJECT_TYPES_PER_CATEGORY', value.toUpperCase()))
            this.props.setValidationSetting('VALIDATE_OBJECT_TYPES_PER_CATEGORY', value)
        } finally {
            this.setState({
                updatingValidationMultipleObjectsPerCategory: false
            })
        }
    }

    async setValidationNoObjects(value) {
        this.setState({
            updatingValidationNoObjects: true
        })

        try {
            await exposeErrors(changeSetting('VALIDATE_NO_OBJECTS', value.toUpperCase()))
            this.props.setValidationSetting('VALIDATE_NO_OBJECTS', value)
        } finally {
            this.setState({
                updatingValidationNoObjects: false
            })
        }
    }

    async setValidationEmptyFloor(value) {
        this.setState({
            updatingValidationEmptyFloor: true
        })

        try {
            await exposeErrors(changeSetting('VALIDATE_EMPTY_FLOOR', value.toUpperCase()))
            this.props.setValidationSetting('VALIDATE_EMPTY_FLOOR', value)
        } finally {
            this.setState({
                updatingValidationEmptyFloor: false
            })
        }
    }
}


ChartValidationCompanySettings.propTypes = {
    adminKey: PropTypes.string,
    user: PropTypes.object,
    company: PropTypes.object,
    setting: PropTypes.object.isRequired,
    updateCompanySettings: PropTypes.func.isRequired,
    setSetting: PropTypes.func.isRequired,
    setValidationSetting: PropTypes.func.isRequired,
    setDefaultRendererSetting: PropTypes.func.isRequired,
    resetHeader: PropTypes.func.isRequired,
    openModalDialog: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    setCompanyAdminKey: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    setting: state.context.company.settings,
    adminKey: state.context.company.adminKey,
    user: state.user,
    company: state.context.company
})

const mapDispatchToProps = dispatch => ({
    updateCompanySettings: (user) => dispatch(updateCompanySettings(user)),
    setSetting: (setting, value) => dispatch(setCompanySetting(setting, value)),
    setValidationSetting: (setting, value) => dispatch(setCompanyValidationSetting(setting, value)),
    setDefaultRendererSetting: (setting, value) => dispatch(setCompanyDefaultRendererSetting(setting, value)),
    setCompanyAdminKey: adminKey => dispatch(setCompanyAdminKey(adminKey)),
    resetHeader: () => dispatch(resetHeader()),
    openModalDialog: payload => dispatch(openModalDialog(payload)),
    closeModalDialog: payload => dispatch(closeDialog(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChartValidationCompanySettings)
