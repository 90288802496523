import { useEffect } from 'react'
import { persistencyService } from '../../services/persistency.service'
import { history } from '../../store'
import { switchToDefaultWorkspace } from '../../actions/user'
import { useDispatch } from 'react-redux'

const SwitchToDefaultWorkspace = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(switchToDefaultWorkspace())
        persistencyService.remove('charts-scroll')
        history.push('/')
    }, [dispatch])

    return null
}

export default SwitchToDefaultWorkspace
