import axios from 'axios'
import store from '../store'
import { backendUrlOfActiveCompany } from '../domain/regions'

export const regenerateCompanyAdminKey = async (companyId) => {
    const result = await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/actions/regenerate-admin-key`,
        auth: { username: store.getState().user.secretKey }
    })
    return result.data.adminKey
}

export const retrieveCompany = async (companyId) => {
    const result = await axios({
        method: 'get',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}`,
        auth: { username: store.getState().user.secretKey }
    })
    return result.data
}

export const unsubscribe = async (companyId) => {
    const result = await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/actions/unsubscribe`,
        auth: { username: store.getState().user.secretKey }
    })
    return result.data
}

export const resubscribe = async (companyId) => {
    const result = await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/actions/resubscribe`,
        auth: { username: store.getState().user.secretKey }
    })
    return result.data
}

export const prepareNewPaymentMethod = async (companyId) => {
    let response = await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/actions/prepare-new-payment-method`,
        auth: {username: store.getState().user.secretKey},
        data: {}
    })
    return response.data
}

export const getPaymentMethods = async (companyId) => {
    let response = await axios({
        method: 'get',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/payment-methods`,
        auth: { username: store.getState().user.secretKey }
    })
    return response.data
}

export const removePaymentMethod = async (companyId, paymentMethodId) => {
    await axios({
        method: 'delete',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/actions/remove-payment-method/${paymentMethodId}`,
        auth: { username: store.getState().user.secretKey }
    })
}

export const updateDefaultPaymentMethod = async (companyId, paymentMethodId) => {
    await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/actions/update-default-payment-method/${paymentMethodId}`,
        auth: { username: store.getState().user.secretKey }
    })
}

export const postImmediateUpgradeAccountInfo = (companyId, info) => {
    return axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/actions/upgrade-account`,
        auth: { username: store.getState().user.secretKey },
        data: info
    })
}

export const prepareForPayment = async (companyId) => {
    let response = await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/actions/prepare-for-payment`,
        auth: {username: store.getState().user.secretKey},
        data: {}
    })
    return response.data
}

export const getBusinessDetails = async (companyId) => {
    let response = await axios({
        method: 'get',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/business-details`,
        auth: { username: store.getState().user.secretKey }
    })
    return response.data
}

export const updateBusinessDetails = async (companyId, info) => {
    return axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/actions/update-business-details`,
        auth: { username: store.getState().user.secretKey },
        data: info
    })
}

export const getUpgradeSubscriptionPlan = async (companyId) => {
    let response = await axios({
        method: 'get',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/upgrade-subscription-plan`,
        auth: { username: store.getState().user.secretKey }
    })
    return response.data
}

export const getCompanyUpgradeInfo = async (companyId) => {
    let response = await axios({
        method: 'get',
        url: `${backendUrlOfActiveCompany()}/companies/${companyId}/company-upgrade-info`,
        auth: { username: store.getState().user.secretKey }
    })
    return response.data
}
