import React, { useEffect, useState } from 'react'
import { userService } from '../../services/user.service'
import SplashScreenGeneralErrorModalDialog from '../../components/GUI/SplashScreenGeneralErrorModalDialog'
import * as PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { setUser } from '../../actions/user'
import queryString from 'query-string'
import { DEFAULT_REGION } from '../../domain/region'

const ConfirmEmail = props => {
    const [status, setStatus] = useState(null)
    const dispatch = useDispatch()
    const confirmationCode = props.match.params.confirmationCode
    const region = queryString.parse(props.location.search).region || DEFAULT_REGION

    useEffect(() => {
        userService.confirmEmail(confirmationCode, region).then(user => {
            dispatch(setUser(user))
            setStatus('success')
        }).catch((error) => {
            if ((error.errors.find(e => e.code === "USER_ALREADY_CONFIRMED"))) {
                setStatus('success')
            } else {
                setStatus('error')
            }
        })
    }, [confirmationCode, dispatch, region])

    if (status === 'success') {
        window.location.href = '/'
        return null
    } else if (status === 'error') {
        return <SplashScreenGeneralErrorModalDialog />
    }
}

ConfirmEmail.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object.isRequired
}

export default ConfirmEmail
