import React from 'react'
import { Link } from 'react-router-dom'
import lang from '../../utils/lang'
import Thumbnail from '../GUI/Thumbnail'
import { notificationService, DEFAULT_NOTIFICATION_TIMEOUT } from '../../services/notification.service'
import { exposeErrors } from '../../utils/errors'
import InfiniteScroller from '../GUI/InfiniteScroller'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { setPendingNewChart } from '../../actions/charts'

const placeHolders = (
    <>
        <div className="archived-chart loading-placeholder" key="placeholder-0">
            <div className="fake-item long"/>
            <div className="fake-item square"/>
            <div className="fake-item short"/>
        </div>
        <div className="archived-chart loading-placeholder" key="placeholder-1">
            <div className="fake-item long"/>
            <div className="fake-item square"/>
            <div className="fake-item short"/>
        </div>
        <div className="archived-chart loading-placeholder" key="placeholder-2">
            <div className="fake-item long"/>
            <div className="fake-item square"/>
            <div className="fake-item short"/>
        </div>
    </>
)

const EmptyArchiveMessage = () => (
    <div className="empty-page-message">
        <div className="title">{ lang.d('archive_empty') }</div>
        <div className="message">{ lang.d('archive_empty_description') }</div>
        <Link to="/" className="arrow back">{lang.d('all_seating_charts')}</Link>
    </div>
)

class ArchivedChartList extends React.Component {
    state = {
        restoringChart: null
    }

    async restoreChart (archivedChart) {
        if (this.state.restoringChart) return
        const { key, name } = archivedChart
        this.setState({ restoringChart: key })
        await exposeErrors(this.props.restore(key))
        this.setState({ restoringChart: null })
        this.props.remove(key)
        this.props.setPendingNewChart()
        notificationService.info(lang.d('seating_chart_restored'), name, DEFAULT_NOTIFICATION_TIMEOUT)
        if (this.props.list.length === 0) {
            this.props.clearArchive()
        }
    }

    render () {
        if (this.props.isListEmpty()) {
            return <EmptyArchiveMessage />
        }

        return (
            <InfiniteScroller
                className="ChartArchive"
                placeholder={placeHolders}
                firstPage={this.props.getFirstPage}
                restOfPages={this.props.getPageAfter}
                handleNewItems={this.props.bulkAdd}
                disableFetchMore={this.props.disableFetchMore}
                shouldLoadMore={this.props.shouldLoadMore}
                nextPageStartsAfter={this.props.nextPageStartsAfter}
                name={this.props.name}
                ready={this.props.ready}
                rememberPosition={false}
            >
                {
                    this.props.list.map(archivedChart => {
                        const { id, key, name, publishedVersionThumbnailUrl } = archivedChart
                        const loading = this.state.restoringChart === key
                        return <div key={id} className={`archived-chart ${loading && 'loading'}`}>
                            <div className="title">{name}</div>
                            <Thumbnail image={publishedVersionThumbnailUrl} chartKey={key}/>
                            <button className="ghost-button" onClick={() => this.restoreChart(archivedChart)}>{lang.d('restore')}</button>
                        </div>
                    })
                }
            </InfiniteScroller>
        )
    }
}

ArchivedChartList.propTypes = {
    list: PropTypes.array,
    isListEmpty: PropTypes.func,
    ready: PropTypes.bool,
    name: PropTypes.string,
    nextPageStartsAfter: PropTypes.number,
    getFirstPage: PropTypes.func,
    getPageAfter: PropTypes.func,
    disableFetchMore: PropTypes.func,
    restore: PropTypes.func,
    remove: PropTypes.func,
    shouldLoadMore: PropTypes.bool,
    clearArchive: PropTypes.func,
    bulkAdd: PropTypes.func,
    setPendingNewChart: PropTypes.func
}

const mapStateToProps = state => ({
    list: state.chartArchive.list,
    ready: state.chartArchive.ready,
    shouldLoadMore: state.chartArchive.shouldLoadMore,
    nextPageStartsAfter: state.chartArchive.nextPageStartsAfter
})

const mapDispatchToProps = dispatch => ({
    disableFetchMore: () => dispatch({ type: 'CHARTARCHIVE/DISABLE_FETCH_MORE' }),
    clearArchive: () => dispatch({ type: 'CLEAR_ARCHIVE' }),
    setPendingNewChart: () => dispatch(setPendingNewChart())
})

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedChartList)
