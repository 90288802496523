import axios from 'axios'
import { Environment } from '../environment'
import store from '../store'
import { backendUrlOfActiveCompany, getRegionById } from '../domain/regions'

export const userService = {
    login,
    signUp,
    resetPassword,
    confirmResetPassword,
    confirmEmail,
    resendConfirmationEmail,
    acceptInvitationForNewUser,
    acceptInvitationForExistingUser,
    updateUser,
    editEmail,
    retrieveCurrentUser
}

async function getRegionId (email) {
    try {
        const res = await axios.get(Environment.backendUrl + '/system/public/users/region/by-email/' + encodeURIComponent(email))
        return res.data.region
    } catch (e) {
        throw e.response.data
    }
}

async function login (email, password) {
    try {
        const regionId = await getRegionId(email)
        const res = await axios({
            url: getRegionById(regionId).url + '/login',
            method: 'post',
            auth: { username: email, password }
        })
        return setRegion(res.data, regionId)
    } catch (e) {
        throw e.response.data
    }
}

async function signUp (email, password, regionId) {
    try {
        const res = await axios.post(getRegionById(regionId).url + '/system/public/users', { email, password })
        return setRegion(res.data, regionId)
    } catch (e) {
        throw e.response.data
    }
}

async function confirmEmail (confirmationCode, regionId) {
    try {
        const res = await axios.post(`${getRegionById(regionId).url}/system/public/users/actions/confirm-account/${confirmationCode}`)
        return setRegion(res.data, regionId)
    } catch (e) {
        throw e.response.data
    }
}

async function acceptInvitationForNewUser (code, password, regionId) {
    try {
        const res = await axios.post(`${getRegionById(regionId).url}/system/public/users/actions/accept-invitation-for-new-user`, {
            code,
            password
        })
        return setRegion(res.data, regionId)
    } catch (e) {
        throw e.response.data
    }
}

async function acceptInvitationForExistingUser (code, regionId) {
    try {
        const res = await axios.post(`${getRegionById(regionId).url}/system/public/users/actions/accept-invitation-for-existing-user`, { code })
        return setRegion(res.data, regionId)
    } catch (e) {
        throw e.response.data
    }
}

async function resetPassword (email) {
    const regionId = await getRegionId(email)
    return axios.post(`${getRegionById(regionId).url}/system/public/users/actions/initiate-password-reset/${btoa(email)}`)
}

async function confirmResetPassword (userId, regionId, resettedPasswordCode, newPassword) {
    try {
        return await axios.post(`${getRegionById(regionId).url}/system/public/users/actions/reset-password`, {
            userId,
            resettedPasswordCode,
            newPassword
        })
    } catch (e) {
        throw e.response.data
    }
}

async function resendConfirmationEmail (email) {
    try {
        const regionId = await getRegionId(email)
        await axios.post(`${getRegionById(regionId).url}/system/public/users/actions/resend-confirmation-email/email/${email}`)
    } catch (e) {
        throw e.response.data
    }
}

function editEmail (email) {
    return axios.post(`${backendUrlOfActiveCompany()}/accounts/me/actions/change-email`, { email }, {
        auth: { username: store.getState().user.secretKey }
    })
}

async function updateUser (id, role, workspaces = undefined) {
    const result = await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/users/${id}`,
        auth: { username: store.getState().user.secretKey },
        data: { workspaces: workspaces, role: role },
        headers: { 'X-Company-ID': store.getState().context.company.id }
    })
    return result.data
}

async function retrieveCurrentUser (region) {
    const result = await axios({
        method: 'get',
        url: `${backendUrlOfActiveCompany()}/accounts/me`,
        auth: { username: store.getState().user.secretKey },
        headers: { 'X-Company-ID': store.getState().context.company.id }
    })
    return setRegion(result.data, region)
}

function setRegion (user, regionId) {
    user.company.region = regionId
    return user
}
