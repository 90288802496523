import React from 'react'
import * as PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import lang from '../utils/lang'

export const WelcomeMessage = (props) => (
    <div className="empty-page-message">
        { props.withSearch && <> <div className="title">{lang.d('no_results')}</div> <br /> </>}
        <div className="title">{lang.d('welcome_to_seats')}</div>
        <div className="message">{lang.d('get_started_create_seating_chart')}</div>
        <Link to="/create-chart" className="button">
            <span className="icon icon-new-floor-plan"></span>
            <span className="caption">{lang.d('create_chart')}</span>
        </Link>
    </div>
)

WelcomeMessage.propTypes = {
    withSearch: PropTypes.bool
}
