import React from 'react'
import * as PropTypes from 'prop-types'
import { ROLE_COMPANY_ADMIN, ROLE_NON_ADMIN } from '../../reducers/user'
import lang from '../../utils/lang'
import Input from '../../components/Input'
import { formatWorkspaceName } from '../../utils/helperFunctions'
import { workspacesService } from '../../services/workspaces.service'
import { GlobalError } from '../../components/GUI/ModalDialogGlobalError'

export class InviteUserForm extends React.Component {
    render () {
        const {
            errors,
            touched,
            handleChange,
            values,
            isSubmitting,
            dirty,
            status
        } = this.props

        const emailErrors = touched.email && errors.email

        const adminSelected = values.role && values.role === ROLE_COMPANY_ADMIN
        const nonAdminSelected = values.role && values.role === ROLE_NON_ADMIN
        const nonAdminHasAccessToAllWorkspaces = values.nonAdminHasAccessToAllWorkspaces === 'true'

        return <>
            <div className="form">
                <div className="form-field email-input">
                    <Input
                        name="email"
                        type="text"
                        value={values.email}
                        onChange={handleChange}
                        disabled={(isSubmitting && dirty) || status === 'submitted'}
                        placeholder={lang.d('email')}
                        className={emailErrors && 'error'}
                        autoFocus
                    />
                    { emailErrors && <div className="errors">
                        { emailErrors && <div className="error">{errors.email}</div> }
                    </div> }
                    <div className="hint">{ lang.d('invite_team_member_email_msg') }</div>
                </div>
                <RoleSelection adminSelected={adminSelected} nonAdminSelected={nonAdminSelected} handleChange={handleChange} errors={errors} touched={touched} title={lang.d('invite_as')}/>
                <NonAdminWorkspaceAccessTypeSelection nonAdminSelected={nonAdminSelected} nonAdminHasAccessToAllWorkspaces={nonAdminHasAccessToAllWorkspaces} handleChange={handleChange} errors={errors} touched={touched} title={lang.d('with_access_to')}/>
                <NonAdminWorkspaceSelection workspaces={this.props.workspaces} selectedWorkspaces={values.selectedWorkspaces || []} nonAdminHasAccessToAllWorkspaces={nonAdminHasAccessToAllWorkspaces} nonAdminSelected={nonAdminSelected} handleWorkspaceSelection={handleChange} errors={errors} touched={touched} />
            </div>
            <GlobalError errors={errors} />
        </>
    }
}

InviteUserForm.propTypes = {
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    isSubmitting: PropTypes.bool,
    dirty: PropTypes.bool,
    status: PropTypes.string,
    values: PropTypes.object,
    touched: PropTypes.object,
    workspaces: PropTypes.array
}

export const RoleSelection = props => {
    const { adminSelected, nonAdminSelected, handleChange, errors, touched, title } = props
    const roleErrors = errors.role && touched.email

    return <div className="form-field workspaces-role-selection">
        <div className="subtitle">{ title }</div>
        <div className="field-options">
            <div className={`field-option ${adminSelected && 'selected'}`}>
                <Input
                    name="role"
                    type="radio"
                    value={ROLE_COMPANY_ADMIN}
                    onChange={handleChange}
                    id={ROLE_COMPANY_ADMIN}
                />
                <label htmlFor={ROLE_COMPANY_ADMIN}>
                    <div className={'icon icon-admin-account'}></div>
                    <div className="caption">{lang.d('admin')}</div>
                </label>
            </div>
            <div className={`field-option ${nonAdminSelected && 'selected'}`}>
                <Input
                    name="role"
                    type="radio"
                    value={ROLE_NON_ADMIN}
                    onChange={handleChange}
                    id={ROLE_NON_ADMIN}
                />
                <label htmlFor={ROLE_NON_ADMIN}>
                    <div className={'icon icon-account'}></div>
                    <div className="caption">{lang.d('non-admin')}</div>
                </label>
            </div>
        </div>
        { roleErrors && <div className="errors">
            { roleErrors && <div className="error">{errors.role}</div> }
        </div> }
    </div>
}

RoleSelection.propTypes = {
    adminSelected: PropTypes.bool,
    nonAdminSelected: PropTypes.bool,
    handleChange: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object,
    title: PropTypes.string
}

export const NonAdminWorkspaceAccessTypeSelection = props => {
    const { nonAdminHasAccessToAllWorkspaces, nonAdminSelected, handleChange, title } = props

    return <div className={`form-group ${!nonAdminSelected && 'hidden'}`}>
        <div className="subtitle">{ title }</div>
        <div className="field-options">
            <div className={`field-option ${!nonAdminHasAccessToAllWorkspaces && 'selected'}`}>
                <Input
                    name={'nonAdminHasAccessToAllWorkspaces'}
                    type="radio"
                    value={false}
                    onChange={handleChange}
                    id="NON_ADMIN_HAS_ACCESS_TO_SOME_WORKSPACES"
                />
                <label htmlFor="NON_ADMIN_HAS_ACCESS_TO_SOME_WORKSPACES">
                    <div className="caption">{lang.d('some_workspaces_user')}</div>
                </label>
            </div>
            <div className={`field-option ${nonAdminHasAccessToAllWorkspaces && 'selected'}`}>
                <Input
                    name={'nonAdminHasAccessToAllWorkspaces'}
                    type="radio"
                    value={true}
                    onChange={handleChange}
                    id="NON_ADMIN_HAS_ACCESS_TO_ALL_WORKSPACES"
                />
                <label htmlFor="NON_ADMIN_HAS_ACCESS_TO_ALL_WORKSPACES">
                    <div className="caption">{lang.d('all_workspaces_user')}</div>
                </label>
            </div>
        </div>
    </div>
}

NonAdminWorkspaceAccessTypeSelection.propTypes = {
    nonAdminSelected: PropTypes.bool,
    nonAdminHasAccessToAllWorkspaces: PropTypes.bool,
    handleChange: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object,
    title: PropTypes.string
}

export class NonAdminWorkspaceSelection extends React.Component {
    constructor (props) {
        super(props)
        this.state = { workspaces: null }
    }

    async componentDidMount () {
        const workspaces = await workspacesService.listActiveWorkspaces()
        this.setState({ workspaces })
    }

    renderPlaceholder () {
        return <div key="placeholder" className="workspaces-list loading-placeholder">
            <div className="workspaces-list-item fake-item"></div>
            <div className="workspaces-list-item fake-item"></div>
            <div className="workspaces-list-item fake-item"></div>
            <div className="workspaces-list-item fake-item"></div>
        </div>
    }

    renderFormField () {
        const { errors, touched, handleWorkspaceSelection, selectedWorkspaces } = this.props
        const workspaceErrors = errors.workspace && touched.role
        return <>
            <div className="form-field input-list">
                { this.state.workspaces.map(workspace =>
                    <div className="list-item" key={workspace.id}>
                        <Input
                            type="checkbox"
                            name="selectedWorkspaces"
                            value={workspace.key}
                            id={workspace.id}
                            onChange={handleWorkspaceSelection}
                            checked={selectedWorkspaces.includes(workspace.key)}
                        />
                        <label htmlFor={workspace.id}>
                            <div>{formatWorkspaceName(workspace)}</div>
                        </label>
                    </div>)
                }
            </div>
            { workspaceErrors && <div className="errors">
                { workspaceErrors && <div className="error">{errors.workspace}</div> }
            </div> }
        </>
    }

    render () {
        const shouldBeHidden = !this.props.nonAdminSelected || this.props.nonAdminHasAccessToAllWorkspaces
        return <>
            <div style={{marginTop: '10px'}} className={`form-group ${shouldBeHidden && 'hidden'}`}>
                { this.state.workspaces
                    ? this.renderFormField()
                    : this.renderPlaceholder()
                }
            </div>
        </>
    }
}

NonAdminWorkspaceSelection.propTypes = {
    nonAdminSelected: PropTypes.bool,
    nonAdminHasAccessToAllWorkspaces: PropTypes.bool,
    handleWorkspaceSelection: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object,
    title: PropTypes.string,
    selectedWorkspaces: PropTypes.array
}
