import React from 'react'
import { getAllMonthsReport, getMonthlyReport } from '../services/reports.service'
import lang from '../utils/lang'

import DropDown from '../components/GUI/DropDown'
import NavigationList from '../components/GUI/NavigationList'
import { exposeErrors } from '../utils/errors'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { setHeader } from '../actions/header'
import MonthlyUsageReportChart from '../components/Reports/MonthlyUsageReportChart'
import OptionSwitcher from '../components/GUI/OptionSwitcher'
import { workspacesService } from '../services/workspaces.service'
import { sameMonth } from '../utils/helperFunctions'
import Badge from '../components/GUI/Badge'

const LoadingPlaceholder = () => (
    <div className="Reports">
        <div className="container">
            <div className="loading-placeholder">
                <div className="fake-item"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
            </div>
        </div>
    </div>
)

const EmptyReport = () => (
    <div className="empty-page-message">
        <div className="title">{lang.d('report_empty')}</div>
        <div className="message">{lang.d('report_empty_description')}</div>
        <Link to="/" className="arrow back">{lang.d('all_seating_charts')}</Link>
    </div>
)

const FailedReport = () => (
    <div className="empty-page-message">
        <div className="title">{lang.d('report_failed')}</div>
        <div className="message">{lang.d('report_failed_description')}</div>
        <Link to="/" className="arrow back">{lang.d('all_seating_charts')}</Link>
    </div>
)

class Reports extends React.Component {
    state = {
        data: [],
        selectedMonth: {},
        monthlyReport: [],
        selectedChartReport: {},
        fetchFailed: false,
        loading: true
    }

    constructor (props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
        this.handleChangeMonth = this.handleChangeMonth.bind(this)
        this.handleChartChange = this.handleChartChange.bind(this)
    }

    async componentDidMount () {
        this._isMounted = true
        this.props.setHeader({
            showSearch: true,
            caption: 'search_seating_charts',
            onFocus: () => {
                const usageDetails = document.getElementsByClassName('usage-details')[0]
                const screenPosition = usageDetails.getBoundingClientRect()
                document.getElementsByClassName('Reports')[0].scrollTo(0, screenPosition.y)
            }
        })

        try {
            const data = await getAllMonthsReport()
            const usage = data.usage
            const workspaces = await workspacesService.listAllWorkspaces()
            this.setState({
                selected: usage[usage.length - 1],
                data: usage,
                cutoffDate: data.usageCutoffDate,
                loading: false,
                workspaces
            }, this.getChartsInSelectedMonth)
        } catch (e) {
            this.handleReportFetchError()
        }
    }

    handleReportFetchError () {
        this.setState({
            fetchFailed: true,
            loading: false
        })
    }

    componentWillUnmount () {
        this._isMounted = false
    }

    handleClick (data, _index) {
        this.setState({
            selected: data.payload
        }, this.getChartsInSelectedMonth)
    }

    handleChangeMonth (e) {
        this.setState({
            selected: this.state.data[e.target.value]
        }, this.getChartsInSelectedMonth)
    }

    async getChartsInSelectedMonth () {
        if (this.isReportEmpty()) return null
        const monthlyReport = await exposeErrors(getMonthlyReport(this.state.selected.month.month, this.state.selected.month.year))
        if (monthlyReport.length > 0 && monthlyReport[0].usageByChart.length > 0) {
            this.setState({
                monthlyReport,
                selectedChartReport: monthlyReport[0].usageByChart[0]
            })
            this.props.setMonthlyReport(monthlyReport)
        } else {
            this.setState({
                monthlyReport: [],
                selectedChartReport: {}
            })
            this.props.setMonthlyReport([])
        }
    }

    handleChartChange (workspaceIndex, chartIndex) {
        this.setState({
            selectedChartReport: this.props.monthlyReport[workspaceIndex].usageByChart[chartIndex]
        })
    }

    getChartsInWorkspacesForNavigation () {
        const result = []
        this.props.monthlyReport
            .forEach((item, parentIndex) => {
                const totalForWorkspace = item.usageByChart
                    .reduce((total, usageByChart) => {
                        return total + usageByChart.usageByEvent.reduce((total, usageByEvent) => {
                            return total + usageByEvent.numUsedObjects
                        }, 0)
                    }, 0)

                const workspace = this.state.workspaces.find(workspace => workspace.id === item.workspace)
                const title = {
                    type: 'title',
                    caption: `Workspace: ${(workspace.name || workspace.key)}`,
                    subCaption: lang.d('x_used_seats', { smart_count: totalForWorkspace })
                }
                result.push(title)
                if (item.usageByChart.length > 0) {
                    item.usageByChart.forEach((usageByChartItem, index) => {
                        if (usageByChartItem.chart) {
                            const chartItem = {
                                type: 'usage-chart-button',
                                caption: usageByChartItem.chart.name,
                                codename: usageByChartItem.chart.key,
                                className: `nav-button ${this.state.selectedChartReport.chart && this.state.selectedChartReport.chart.key === usageByChartItem.chart.key && 'selected'}`,
                                parentIndex: parentIndex,
                                index: index
                            }
                            result.push(chartItem)
                        }
                    })
                }
            })

        if (this.props.searchValue) {
            return result.filter(i => i.codename && i.caption.toUpperCase().indexOf(this.props.searchValue.toUpperCase()) !== -1)
        }

        return result
    }

    getBillableCountForSelectedChart () {
        return this.state.selectedChartReport.usageByEvent.reduce((acc, curr) => {
            return acc + curr.numUsedObjects
        }, 0)
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.searchValue !== prevProps.searchValue) {
            this.setState({
                selectedChartReport: {}
            })
        }
        return true
    }

    isReportEmpty () {
        if (!this._isMounted) return null
        return this.state.data.reduce((acc, curr) => {
            return acc + curr.FIRST_BOOKING + curr.FIRST_BOOKING_OR_SELECTION
        }, 0) === 0
    }

    setShowAllMonths (value) {
        this.setState({ showAllMonths: value })
    }

    activeIndex () {
        return this.state.data.findIndex(item => sameMonth(item.month, this.state.selected.month))
    }

    render () {
        if (!this.state.data) return null
        if (this.state.fetchFailed) return <FailedReport/>
        if (this.isReportEmpty()) return <EmptyReport/>
        if (this.state.loading) return <LoadingPlaceholder/>
        return (
            <div className="Reports">
                <div className="container">
                    <MonthlyUsageReportChart
                        data={this.state.data.slice(this.state.showAllMonths ? 0 : -12)}
                        cutoffDate={this.state.cutoffDate}
                        barDataKey="numUsedObjects"
                        activeMonth={this.state.selected.month}
                        barName={lang.d('used_seats')}
                        rotatedXAxis={this.state.showAllMonths}
                        onClick={this.handleClick}
                    />
                    <div className="time-controls">
                        <OptionSwitcher
                            value={this.state.showAllMonths ? 'ALL' : 'LAST_12'}
                            options={{
                                LAST_12: lang.d('last_12_months'),
                                ALL: lang.d('all_months')
                            }}
                            onChange={value => this.setShowAllMonths(value === 'ALL')}
                        />
                        <DropDown
                            list={this.state.data.map(item => (
                                {
                                    value: this.state.data.indexOf(item),
                                    label: item.month.fullLabel
                                }
                            ))}
                            onChange={this.handleChangeMonth}
                            selectedIndex={this.activeIndex()}
                        />
                    </div>

                    {this.state.selected && (
                        <div
                            className="sub-title">{lang.d('x_used_seats', { smart_count: this.state.selected.numUsedObjects || 0 })}</div>
                    )}
                    {this.props.monthlyReport.length > 0 && (
                        <>
                            <div className="usage-details">
                                <NavigationList
                                    options={this.getChartsInWorkspacesForNavigation()}
                                    onSelect={this.handleChartChange}
                                />
                                {this.state.selectedChartReport.chart && <div className="usage-by-events">
                                    <div className="title">{this.state.selectedChartReport.chart.name}</div>
                                    <div className="chart-key">{lang.d('chart_key')}: <span
                                        className="fixed-width">{this.state.selectedChartReport.chart.key}</span></div>
                                    <div
                                        className="total">{lang.d('x_used_seats', { smart_count: this.getBillableCountForSelectedChart() })}</div>
                                    <div className="DataTable">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>{lang.d('event_key')}</th>
                                                    <th>{lang.d('used_seats')}</th>
                                                </tr>
                                                {this.state.selectedChartReport.usageByEvent.map(event =>
                                                    <tr key={event.event.id}>
                                                        <td className="fixed-width">
                                                            <Link
                                                                to={`/reports/${this.state.selectedChartReport.chart.key}/${this.state.selected.month.year}/${this.state.selected.month.month}/${event.event.id}/${event.event.key}`}>
                                                                {event.event.key}
                                                            </Link>
                                                            {event.event.deleted &&
                                                            <Badge type="production">deleted</Badge>}
                                                        </td>
                                                        <td>{event.numUsedObjects}</td>
                                                    </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }
}

Reports.propTypes = {
    definitionOfUse: PropTypes.string,
    defaultWorkspaceId: PropTypes.number,
    setHeader: PropTypes.func,
    setMonthlyReport: PropTypes.func,
    monthlyReport: PropTypes.array,
    searchValue: PropTypes.string
}

const mapStateToProps = state => ({
    definitionOfUse: state.context.company.definitionOfUse || 'FIRST_BOOKING',
    defaultWorkspaceId: state.context.company.defaultWorkspace.id,
    monthlyReport: state.reports.monthlyReport,
    searchValue: state.search.reports.value
})

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload)),
    setMonthlyReport: payload => dispatch({
        type: 'REPORTS/SET_MONTHLY_REPORT',
        payload
    })
})

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
