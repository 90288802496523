import React from 'react'
import * as PropTypes from 'prop-types'
import lang from '../../utils/lang'
import { connect } from 'react-redux'
import ActiveWorkspaceListItem from './ActiveWorkspaceListItem'
import { workspacesService } from '../../services/workspaces.service'
import debounce from 'lodash/debounce'
import { updateWorkspacesList } from '../../actions/workspaces'
import FormButton from './FormButton'

const placeholder = <>
    <tr className="loading-placeholder">
        <td><div className="fake-item"></div></td>
        <td><div className="fake-item"></div></td>
    </tr>
    <tr className="loading-placeholder">
        <td><div className="fake-item"></div></td>
        <td><div className="fake-item"></div></td>
    </tr>
    <tr className="loading-placeholder">
        <td><div className="fake-item"></div></td>
        <td><div className="fake-item"></div></td>
    </tr>
</>

const noResult = (
    <div className="empty-page-message">
        <div className="title">{lang.d('no_results')}</div>
    </div>
)

class ActiveWorkspaceList extends React.Component {
    state = {
        searchResult: null,
        loading: false,
        fetchingMoreResults: false
    }

    doDebouncedSearch = debounce(this.doSearch, 400)

    componentDidUpdate (prevProps) {
        if (this.props.searchValue !== prevProps.searchValue) {
            this.setState({ loading: true })
            if (this.props.searchValue) this.doDebouncedSearch()
            else this.setState({ searchResult: null, loading: false })
        }
    }

    onButtonClicked = () => {
        workspacesService.listActiveWorkspacesAfterId(this.props.nextId).then(workspaces => {
            const { items, nextPageStartsAfter } = workspaces;
            this.props.updateWorkspacesList(items, nextPageStartsAfter)
            this.setState({ fetchingMoreResults: false })
        })

        this.setState({ fetchingMoreResults: true })
    }

    async doSearch () {
        const searchResult = await workspacesService.listActiveWorkspaces(this.props.searchValue)
        this.setState({ searchResult, loading: false })
    }

    renderHeaders () {
        return <>
            <th width="50%">{ lang.d('name') }</th>
            <th width="45%">{ lang.d('workspace_key') }</th>
            <th width="5%"></th>
        </>
    }

    renderRows () {
        if (this.state.loading) return placeholder
        const list = this.state.searchResult ? this.state.searchResult : this.props.list

        return list.map((workspace, i) => <ActiveWorkspaceListItem
            key={workspace.id}
            workspace={workspace}
            animateIn={i === 0 && this.props.animateInFirstItem}
            readOnly={this.props.readOnly}
            doSearch={this.doSearch.bind(this)}
        />)
    }

    renderTable () {
        return <>
            <div className="DataTable">
                <table>
                    <thead>
                        <tr>
                            { this.renderHeaders() }
                        </tr>
                    </thead>
                    <tbody>
                        { this.renderRows() }
                        { this.state.fetchingMoreResults && placeholder }
                    </tbody>
                </table>
            </div>
        </>
    }

    render () {
        return <div className="WorkspacesList sectioned-page">
            <div className="sectioned-page-container with-expanded-header">
                <div className="section">
                    <div className="page-title">{ lang.d('workspaces') }</div>
                    <div className="page-description">
                        { lang.d('workspaces-description') } <a href="http://support.seats.io/en/articles/3649370" target="_blank" rel="noopener noreferrer" className="arrow right">{ lang.d('learn-more') }</a>
                    </div>
                </div>
                <div className="section wide">
                    { (this.state.searchResult && this.state.searchResult.length === 0) ? noResult : this.renderTable()}
                </div>
                {!this.state.fetchingMoreResults && this.props.nextId &&
                    <div className="button-container">
                        <FormButton className="button-load" onClick={this.onButtonClicked}>{lang.d('show_more')}</FormButton>
                    </div>
                }
                {this.state.fetchingMoreResults && placeholder}
            </div>
        </div>
    }
}

ActiveWorkspaceList.propTypes = {
    list: PropTypes.array,
    nextId: PropTypes.string,
    name: PropTypes.string,
    searchValue: PropTypes.string,
    readOnly: PropTypes.bool,
    animateInFirstItem: PropTypes.bool,
    updateWorkspacesList: PropTypes.func
}

const mapStateToProps = state => ({
    list: state.workspaces.list,
    nextId: state.workspaces.nextId,
    searchValue: state.search.workspaces.value
})

const mapDispatchToProps = dispatch => ({
    updateWorkspacesList: (workspaces, nextId) => {
        return dispatch(updateWorkspacesList(workspaces, nextId))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ActiveWorkspaceList)
