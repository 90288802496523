export const setHeader = payload => ({
    type: 'HEADER/SET',
    payload
})

export const resetHeader = payload => ({
    type: 'HEADER/RESET',
    payload
})

export const hideHeader = payload => ({
    type: 'HEADER/HIDE',
    payload
})
