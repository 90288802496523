import { connect } from 'react-redux'
import React, { Component } from 'react'
import DeploymentPipeline from './DeploymentPipeline'
import { apps } from '../../services/admin.service'
import { setHeader } from '../../actions/header'
import * as PropTypes from 'prop-types'
import DropDown from '../../components/GUI/DropDown'
import { history } from '../../store'

class DeploymentPipelines extends Component {

    async componentDidMount () {
        this.props.setHeader({
            showSearch: false,
            hasItems: true,
            centerItems: [],
            rightItems: [],
            leftItems: [{
                to: '/admin',
                icon: 'icon-arrow-light-left',
                string_key: 'admin',
                className: 'soft-button'
            }]
        })
    }

    render () {
        let appsList = this.appsList()
        let currentApp = this.props.match.params.app
        return <div className="sectioned-page">
            <div id="deploymentPipelines" className="sectioned-page-container">
                <DropDown
                    list={appsList}
                    onChange={this.handleChange}
                    selectedIndex={appsList.findIndex(app => app.value === currentApp)}
                    name="app"
                />
                <DeploymentPipeline key={`deployment-pipline-${currentApp}`} app={apps[currentApp]}/>
            </div>
        </div>
    }

    handleChange (e) {
        history.push(`./${e.target.value}`)
    }

    appsList () {
        return Object.values(apps)
            .map(v => {
                return {
                    label: v.githubRepo,
                    value: v.name
                }
            })
    }

}

DeploymentPipelines.propTypes = {
    setHeader: PropTypes.func,
    match: PropTypes.object
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(null, mapDispatchToProps)(DeploymentPipelines)
