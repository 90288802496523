import React from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import lang from '../../../utils/lang'
import LoadingIndicator from '../../../components/GUI/LoadingIndicator'
import {
    getBusinessDetails,
    updateBusinessDetails
} from '../../../services/companies.service'
import PropTypes from 'prop-types'
import { setHeader } from '../../../actions/header'
import { CountryDropdown } from 'react-country-region-selector'
import { checkVAT, countries } from 'jsvat'
import FormButton from '../../../components/GUI/FormButton'
import { isEeaCountry } from '../../../utils/countries'
import { notificationService } from '../../../services/notification.service'

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    street: yup.string().required(),
    zipcode: yup.string().required(),
    city: yup.string().required(),
    state: yup.string(),
    countryCode: yup.string().required(),
    vat: yup.string().notRequired(),
    billingEmail: yup.string().email().required()
})

class UpdateBillingDetails extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            initialValues: {},
            showVat: false
        }
    }

    async componentDidMount () {
        this.props.setHeader({})
        const businessDetails = await getBusinessDetails(this.props.companyId)
        const existingValues = {}
        existingValues.name = businessDetails.name
        existingValues.billingEmail = businessDetails.email
        existingValues.vat = businessDetails.vat
        existingValues.street = businessDetails.address.street
        existingValues.zipcode = businessDetails.address.zipcode
        existingValues.city = businessDetails.address.city
        existingValues.state = businessDetails.address.state
        existingValues.countryCode = businessDetails.address.countryCode
        this.setState({initialValues : existingValues, showVat: isEeaCountry(existingValues.countryCode)})
    }

    renderCustomerDetails () {
        const errorClassName = (props, fieldName) => props.errors[fieldName] ? 'error' : null
        return <Formik initialValues={this.state.initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
            validate={(values) => {
                const errors = {};
                if (values.vat) {
                    const country = countries.filter(c => c.codes.indexOf(values.countryCode) >= 0);
                    if (country.length > 0) {
                        const vatCheckResult = checkVAT(values.vat, country)
                        if (!vatCheckResult.isValid) {
                            errors.vat = 'Invalid VAT number'
                        }
                    }
                }
                return errors
            }}
            onSubmit={async (values, { setStatus, resetForm }) => {
                try {
                    await updateBusinessDetails(this.props.companyId, values)
                    notificationService.info(lang.d('billing_details_updated'), lang.d('billing_details_updated_message'))
                    resetForm({values})
                } catch (e) {
                    notificationService.error(lang.d('something_went_wrong'), e.response.data.messages[0])
                    setStatus(null)
                }
            }}>
            {props =>
                <form className={`newStyle ${props.isSubmitting && 'submitting'} ${props.status}`}
                    onSubmit={props.handleSubmit}>
                    <div className="form-field">
                        <div className="description">
                            <label htmlFor="name">{lang.d('business-name-and-legal-form')}</label>
                        </div>
                        <div className="field">
                            <input type="text" placeholder={lang.d('business-name-and-legal-form-placeholder')}
                                id="name" name="name" className={errorClassName(props, 'name')}
                                onChange={props.handleChange} value={props.values.name}/>
                        </div>
                    </div>
                    <div className="form-field">
                        <div className="description">
                            <label htmlFor="street">{lang.d('legal-address')}</label>
                        </div>
                        <div className="field">
                            <input type="text" id="street" name="street" onChange={props.handleChange}
                                value={props.values.street} placeholder={lang.d('street-address')}
                                className={errorClassName(props, 'street')}/>
                            <div className="flex-wrapper">
                                <input type="text" name="zipcode" onChange={props.handleChange}
                                    value={props.values.zipcode} placeholder={lang.d('postal-zipcode')}
                                    className={errorClassName(props, 'zipcode')}/>
                                <input type="text" name="city" onChange={props.handleChange}
                                    value={props.values.city} placeholder={lang.d('city')}
                                    className={errorClassName(props, 'city')}/>
                            </div>
                            <div className="flex-wrapper">
                                <input type="text" name="state" onChange={props.handleChange}
                                    value={props.values.state} placeholder={lang.d('state-province')}
                                    className={errorClassName(props, 'state')}/>
                                <CountryDropdown
                                    name="countryCode"
                                    valueType="short"
                                    defaultOptionLabel={lang.d('country')}
                                    onChange={(val, e) => {
                                        props.handleChange(e)
                                        this.setState({showVat: isEeaCountry(val)})
                                    }}
                                    value={props.values.countryCode}
                                    className={errorClassName(props, 'countryCode')}
                                />
                            </div>
                        </div>
                    </div>
                    {this.state.showVat && <div className="form-field">
                        <div className="description">
                            <label htmlFor="vat">{lang.d('vat-number')}</label>
                            <div className="hint">
                                <strong>{lang.d('optional')}.</strong>{ props.values.countryCode && isEeaCountry(props.values.countryCode) && <>{lang.d('vat-number-hint')} {lang.d('vat-notice')}</>}
                            </div>
                        </div>
                        <div className="field">
                            <input type="text" id="vat" name="vat" onChange={props.handleChange}
                                value={props.values.vat} className={errorClassName(props, 'vat')}/>
                        </div>
                    </div>}
                    <div className="form-field">
                        <div className="description">
                            <label htmlFor="billingEmail">{lang.d('contact-email')}</label>
                            <div className="hint">{lang.d('contact-email-hint')}</div>
                        </div>
                        <div className="field">
                            <input type="text" id="billingEmail" name="billingEmail" onChange={props.handleChange}
                                value={props.values.billingEmail} className={errorClassName(props, 'billingEmail')}/>
                        </div>
                    </div>
                    <hr className="divider" />
                    <div className="form-actions">
                        {props.isSubmitting
                            ? <LoadingIndicator/>
                            :  <FormButton disabled={!props.dirty} className="action">{lang.d('update_billing_details')}</FormButton>
                        }
                    </div>
                </form> }
        </Formik>
    }

    render () {
        return <div className="UpdateBillingDetails">
            <div className="sectioned-page">
                <div className="sectioned-page-container">
                    <div className="section">
                        {Object.keys(this.state.initialValues).length > 0 && this.renderCustomerDetails()}
                    </div>
                </div>
            </div>
        </div>
    }
}

UpdateBillingDetails.propTypes = {
    setHeader: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    companyId: PropTypes.number
}

const mapStateToProps = (state) => ({
    companyId: state.context.company.id
})

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBillingDetails)
