import React from 'react'
import * as PropTypes from 'prop-types'
import { setHeader, resetHeader } from '../../actions/header'
import { connect } from 'react-redux'
import Invitations from './Invitations'
import Members from './Members'
import { openModalDialog } from '../../actions/modalDialog'
import { addInvitation } from '../../actions/team'
import lang from '../../utils/lang'
import { usersService } from '../../services/users.service'
import { ROLE_NON_ADMIN } from '../../reducers/user'
import { validateEmail } from '../../utils/validators'
import { InviteUserForm } from './InviteUserForm'

class Team extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            newInvitations: []
        }
    }

    async componentDidMount () {
        this.props.setHeader({
            leftItems: [{
                button: true,
                onClick: this.openInviteUserModal.bind(this),
                icon: 'icon-invite-by-email',
                string_key: 'invite_team_member'
            }]
        })
    }

    openInviteUserModal () {
        this.props.openModalDialog({
            title: lang.d('invite_team_member'),
            successMessage: lang.d('team_member_invited'),
            onSubmit: values => {
                if (values.role === ROLE_NON_ADMIN) {
                    return usersService.inviteUser(values.email, values.role, values.selectedWorkspaces)
                } else {
                    return usersService.inviteUser(values.email, values.role, undefined)
                }
            },
            onSubmitSuccess: (_, response) => {
                this.onUserInvited(response)
            },
            settings: {
                acceptCaption: lang.d('invite'),
                formik: {
                    initialValues: { email: '' },
                    validate: values => {
                        const errors = {}
                        if (!validateEmail(values.email)) {
                            errors.email = lang.d('invalid_email')
                        }
                        if (!values.role) {
                            errors.role = lang.d('invalid_role')
                        }
                        if (values.role === ROLE_NON_ADMIN && !values.nonAdminHasAccessToAllWorkspaces && (!values.selectedWorkspaces || values.selectedWorkspaces.length === 0)) {
                            errors.workspace = lang.d('at_least_one_workspace')
                        }
                        return errors
                    },
                    render: props => <InviteUserForm {...props}/>
                }
            }
        })
    }

    componentWillUnmount () {
        this.props.resetHeader()
    }

    onUserInvited (invitation) {
        this.props.addInvitation(invitation)
    }

    render () {
        return <div className="Users">
            <Invitations/>
            <Members user={this.props.user}/>
        </div>
    }
}

Team.propTypes = {
    setHeader: PropTypes.func,
    resetHeader: PropTypes.func,
    addInvitation: PropTypes.func,
    openModalDialog: PropTypes.func.isRequired,
    user: PropTypes.object
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload)),
    resetHeader: () => dispatch(resetHeader()),
    addInvitation: (payload) => dispatch(addInvitation(payload)),
    openModalDialog: payload => dispatch(openModalDialog(payload))
})

export default connect(null, mapDispatchToProps)(Team)
