import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as PropTypes from 'prop-types'
import { resetHeader } from '../../actions/header'
import { connect } from 'react-redux'
import { getSuperAdminEnvironmentSettings } from '../../domain/superAdminEnvironmentSettings'
import UsersInProxySyncer from './UsersInProxySyncer'

class AdminHome extends Component {
    componentDidMount () {
        this.props.resetHeader()
    }

    render () {
        const superAdminEnvironmentSettings = getSuperAdminEnvironmentSettings()
        return <>
            <div className="Admin sectioned-page">
                <div className="sectioned-page-container">
                    <div className="section">
                        <div className="title">Admin</div>
                        <div className="link-list wide">
                            <Link to="/admin/companies" className="arrow">Companies</Link>
                            <Link to="/admin/usage-report" className="arrow">Usage report</Link>
                            <Link to="/admin/billing-report" className="arrow">Billing report</Link>
                            <Link to="/admin/renderer-config-report" className="arrow">Used renderer config keys report</Link>
                            <Link to="/admin/feature-analytics-report" className="arrow">Feature Analytics</Link>
                            <Link to="/admin/copy-charts" className="arrow">Copy charts from one account to the other</Link>
                            <Link to="/admin/search-user-events" className="arrow">Search through user events</Link>
                            <Link to="/admin/log-in-as-customer-through-chart-key" className="arrow">Log in as customer through chart key</Link>
                            <Link to="/admin/email-templates" className="arrow">Email templates</Link>
                            <Link to="/admin/jobs" className="arrow">Manage server-side jobs</Link>
                            <Link to="/admin/email-companies" className="arrow">Email companies</Link>
                            <Link to="/admin/deployment-pipeline/CORE_V2" className="arrow">Deployment pipeline</Link>
                        </div>
                    </div>
                    <div className="section">
                        <div className="title">Emergency app</div>
                        <a href={superAdminEnvironmentSettings.emergencyAppUrl} target="_blank" rel="noopener noreferrer nofollow">Open Emergency App</a><br/>
                        Username: {superAdminEnvironmentSettings.emergencyAppUsername}<br/>
                        Password: {superAdminEnvironmentSettings.emergencyAppPasswordHint}
                    </div>
                    <UsersInProxySyncer />
                </div>
            </div>
        </>
    }

}

AdminHome.propTypes = {
    resetHeader: PropTypes.func
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
    resetHeader: () => dispatch(resetHeader())
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome)
