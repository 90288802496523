import React from 'react'
import * as PropTypes from 'prop-types'
import lang from '../../utils/lang'
import format from 'date-fns/format'
import { invitationsService } from '../../services/invitations.service'
import { connect } from 'react-redux'
import { cancelInvitation, setInvitations } from '../../actions/team'
import { showFailedToContactServerError } from '../../utils/errors'
import { openModalDialog } from '../../actions/modalDialog'

class Invitations extends React.Component {

    async componentDidMount () {
        await this.loadInvitations()
    }

    async loadInvitations () {
        const invitations = await invitationsService.listInvitations()
        this.props.dispatch(setInvitations(invitations))
    }

    cancelInvitation (id, email) {
        this.props.dispatch(openModalDialog({
            title: lang.d('cancel_invitation', { email }),
            message: lang.d('cancel_invitation_message'),
            successMessage: lang.d('invitation_canceled'),
            doneIcon: 'delete',
            settings: {
                dangerousAction: true,
                waitOnAccept: true,
                acceptCaption: lang.d('cancel_invitation_confirm'),
                cancelCaption: lang.d('nevermind'),
                onAccept: async () => {
                    try {
                        await invitationsService.cancel(id)
                        this.props.dispatch(cancelInvitation(id))
                    } catch (e) {
                        showFailedToContactServerError()
                    }
                }
            }
        }))
    }

    render () {
        const { invitations } = this.props
        if (invitations.length > 0) {
            return <div className="invitations container">
                <div className="title">{lang.d('pending_invitations')}</div>
                <div className="DataTable">
                    <table>
                        <thead>
                            <tr>
                                {this.renderHeaders()}
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows(invitations)}
                        </tbody>
                    </table>
                </div>
            </div>
        } else return null
    }

    renderHeaders () {
        return <>
            <th style={{ width: '60%' }}>{lang.d('email')}</th>
            <th style={{ width: '40%' }}>{lang.d('date')}</th>
        </>
    }

    renderRows (invitations) {
        return invitations
            .map((invitation) =>
                <tr key={invitation.id}>
                    <td>{invitation.email}</td>
                    <td>{format(invitation.date, 'd MMM yyyy')}</td>
                    <td><div onClick={() => this.cancelInvitation(invitation.id, invitation.email)} className="icon icon-close-light cancel-invitation" /></td>
                </tr>
            )
    }
}

Invitations.propTypes = {
    invitations: PropTypes.array.isRequired,
    dispatch: PropTypes.func
}

const mapStateToProps = state => ({
    invitations: state.team.invitations
})

export default connect(mapStateToProps)(Invitations)
