import React from 'react'
import * as PropTypes from 'prop-types'

const MAP_POSITIONS = {
    eu: {
        x: 48,
        y: 31
    },
    na: {
        x: 16,
        y: 32
    },
    sa: {
        x: 30,
        y: 69
    },
    oc: {
        x: 90,
        y: 76
    }
}

const MapPicker = ({ locations, label, value, placeholder, help, error, onChange }) => {
    const positionedLocations = locations.map(location => Object.assign({}, MAP_POSITIONS[location.value], location))
    const getLocation = (value) => positionedLocations.find(location => location.value === value)

    return <div className={`MapPicker ${error && 'error'}`}>
        <div className="map">
            { positionedLocations.map(location => <MapLocation {...location} selected={value === location.value} onSelect={value => onChange(value)} />) }
        </div>
        <div className={`label ${!value && 'placeholder'}`}>{ value ? `${label}: ${getLocation(value).label}` : placeholder }</div>
        { help && <div className="help">
            <div className="icon icon-help"></div>
            <div className="tooltip">{ help }</div>
        </div> }
    </div>
}

MapPicker.propTypes = {
    locations: PropTypes.arrayOf(PropTypes.object),
    label: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    help: PropTypes.string,
    error: PropTypes.bool,
    onChange: PropTypes.func
}

const MapLocation = ({ label, value, x, y, selected, onSelect }) => {
    return <div
        className={`MapLocation ${selected && 'selected'}`}
        style={{ left: `${x}%`, top: `${y}%` }}
        title={label}
        onClick={() => onSelect(value)}
    />
}

MapLocation.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    selected: PropTypes.bool,
    onSelect: PropTypes.func
}

export default MapPicker
