import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ChangePasswordForm } from './ChangePasswordForm'
import lang from '../../utils/lang'
import { resetHeader } from '../../actions/header'
import { closeAndClearDialog, openModalDialog } from '../../actions/modalDialog'
import { EditEmailForm } from '../../components/GUI/EditEmailForm'
import { userService } from '../../services/user.service'
import { updateUser } from '../../actions/user'

class UserSettings extends Component {
    constructor (props) {
        super(props)
        this.state = {
            loading: true
        }
        this.updateUser = this.updateUser.bind(this)
    }

    async componentDidMount () {
        this.props.resetHeader()
        this.setState({ loading: false })
    }

    updateUser (email) {
        this.props.updateUser({ email })
    }

    editEmail () {
        this.props.openModalDialog({
            title: lang.d('edit_email'),
            onSubmit: values => {
                if (values.email === this.props.email) return this.props.closeModalDialog()
                return userService.editEmail(values.email)
            },
            onSubmitSuccess: (values, _) => this.updateUser(values.email),
            successMessage: lang.d('email_updated'),
            settings: {
                waitOnAccept: true,
                acceptCaption: lang.d('update'),
                formik: {
                    initialValues: {
                        email: this.props.email
                    },
                    validate: values => {
                        const errors = {}
                        if (values.email === '') {
                            errors.email = lang.d('required')
                        }
                        return errors
                    },
                    render: props => <EditEmailForm {...props} />
                }
            }
        })
    }

    renderLoadingPlaceholder () {
        return <div className="loading-placeholder">
            <div className="fake-item"></div>
            <div className="fake-item"></div>
            <div className="fake-item"></div>
        </div>
    }

    render () {
        if (this.state.loading) {
            return <div className="Settings">
                <div className="simple-page">
                    {this.renderLoadingPlaceholder()}
                    {this.renderLoadingPlaceholder()}
                    {this.renderLoadingPlaceholder()}
                </div>
            </div>
        }
        return (
            <div className="Settings">
                <div className="simple-page">
                    <div className="page-title">{ lang.d('user-settings') }</div>
                    <div className="setting">
                        <div className="title">{lang.d('email')}</div>
                        <div className="key editable">
                            <div>{this.props.email}</div>
                            <div className="btn-container">
                                <button id="editEmailButton" className="button-link" onClick={this.editEmail.bind(this)}><span className="icon icon-edit"/></button>
                            </div>
                        </div>
                    </div>
                    <div className="setting">
                        <div className="title">{lang.d('change_password')}</div>
                        <ChangePasswordForm/>
                    </div>
                </div>
            </div>
        )
    }
}

UserSettings.propTypes = {
    context: PropTypes.object.isRequired,
    resetHeader: PropTypes.func.isRequired,
    openModalDialog: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
    context: state.context,
    email: state.user.email
})

const mapDispatchToProps = dispatch => ({
    resetHeader: () => dispatch(resetHeader()),
    openModalDialog: payload => dispatch(openModalDialog(payload)),
    closeModalDialog: payload => dispatch(closeAndClearDialog(payload)),
    updateUser: payload => dispatch(updateUser(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings)
