import React from 'react'
import lang from '../../utils/lang'
import { Link } from 'react-router-dom'
import SplashScreenModalDialog from '../../components/GUI/SplashScreenModalDialog'
import { userService } from '../../services/user.service'
import * as PropTypes from 'prop-types'
import { hideHeader } from '../../actions/header'
import { connect } from 'react-redux'

class AccountNotActive extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            status: null
        }
    }

    componentDidMount () {
        this.props.hideHeader()
    }

    render () {
        const accountIsUnconfirmed = this.props.location.state.reason === 'USER_UNCONFIRMED'
        const messageProp = accountIsUnconfirmed ? 'account_unconfirmed' : 'account_inactive'
        return <>
            <SplashScreenModalDialog
                title={lang.d(`${messageProp}_title`)}
                message={lang.d(messageProp)}
                overlayShown={this.state.status === 'confirmationEmailResent'}
                overlayTitle={lang.d('confirmation_email_resent_successfully_title')}
                overlayMessage={lang.d('confirmation_email_resent_successfully')}
                errorOverlayShown={this.state.status === 'confirmationEmailError'}
                actions={<>
                    <Link to="/logout" className="action highlighted">
                        {lang.d('logout')}
                    </Link>

                    {accountIsUnconfirmed &&
                    <button onClick={this.resendConfirmationEmail.bind(this)} className="action highlighted">
                        {lang.d('resend_confirmation_email')}
                    </button>}
                </>}
                isLoading={this.state.status === 'sendingConfirmationEmail'}
            />
        </>
    }

    async resendConfirmationEmail () {
        try {
            this.setState({ status: 'sendingConfirmationEmail' })
            await userService.resendConfirmationEmail(this.props.user.email)
            this.setState({ status: 'confirmationEmailResent' })
        } catch (e) {
            this.setState({ status: 'confirmationEmailError' })
            throw e
        }
    }
}

AccountNotActive.propTypes = {
    location: PropTypes.object,
    hideHeader: PropTypes.func,
    user: PropTypes.object
}

const mapStateToProps = state => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    hideHeader: payload => dispatch(hideHeader(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountNotActive)
