import { merge } from '../utils/helperFunctions'

const defaultState = {
    list: [],
    nextId: null,
    shouldLoadMore: true,
    ready: false
}

const workspaces = (state = defaultState, action) => {
    switch (action.type) {
    case 'WORKSPACES/SET':
        return {
            ...state,
            ...action.payload,
            list: merge(state.list, action.payload.list),
            nextId: action.payload.nextId
        }
    case 'WORKSPACES/SET_WORKSPACES':
        return {
            ...state,
            ...action.payload,
            ready: true,
            list: action.payload.list,
            nextId: action.payload.nextId
        }
    case 'CLEAR_WORKSPACES':
        return {
            ...state,
            shouldLoadMore: true,
            list: [],
            nextPageStartsAfter: null
        }
    case 'WORKSPACES/CLEAR_SEARCH':
        return {
            ...state
        }
    case 'WORKSPACES/ADD':
        return {
            ...state,
            list: [
                action.payload,
                ...state.list
            ]
        }
    default:
        return state
    }
}

export default workspaces
