import React from 'react'
import lang from '../../utils/lang'
import { ROLE_COMPANY_ADMIN, ROLE_NON_ADMIN } from '../../reducers/user'
import { NonAdminWorkspaceAccessTypeSelection, NonAdminWorkspaceSelection, RoleSelection } from './InviteUserForm'
import * as PropTypes from 'prop-types'

export class UserForm extends React.Component {
    constructor (props) {
        super(props)
        const selectedWorkspaces = this.props.values.role === ROLE_NON_ADMIN && !this.props.values.nonAdminHasAccessToAllWorkspaces ? this.props.values.userWorkspaces.map(workspace => workspace.key) : []
        this.state = { selectedWorkspaces: selectedWorkspaces, nonAdminHasAccessToAllWorkspaces: this.props.values.nonAdminHasAccessToAllWorkspaces }
        this.handleWorkspaceSelection = this.handleWorkspaceSelection.bind(this)
        this.handleNonAdminWorkspaceAccessTypeSelection = this.handleNonAdminWorkspaceAccessTypeSelection.bind(this)
    }

    handleWorkspaceSelection (event) {
        const selectedWorkspaces = event.target.checked ? [...this.state.selectedWorkspaces, event.target.value] : this.state.selectedWorkspaces.filter(key => key !== event.target.value)
        this.setState({ selectedWorkspaces })
        this.props.setFieldValue('selectedWorkspaces', selectedWorkspaces)
    }

    handleNonAdminWorkspaceAccessTypeSelection (event) {
        const nonAdminHasAccessToAllWorkspaces = event.target.value === 'true'
        this.setState({ nonAdminHasAccessToAllWorkspaces })
        this.props.setFieldValue('nonAdminHasAccessToAllWorkspaces', nonAdminHasAccessToAllWorkspaces)
    }

    render () {
        const {
            errors,
            touched,
            handleChange,
            values
        } = this.props

        const adminSelected = values.role && values.role === ROLE_COMPANY_ADMIN
        const nonAdminSelected = values.role && values.role === ROLE_NON_ADMIN

        return <>
            <div className="form">
                <RoleSelection adminSelected={adminSelected} nonAdminSelected={nonAdminSelected} handleChange={handleChange} errors={errors} touched={touched} title={lang.d('role')}/>
                <NonAdminWorkspaceAccessTypeSelection nonAdminSelected={nonAdminSelected} nonAdminHasAccessToAllWorkspaces={this.state.nonAdminHasAccessToAllWorkspaces} handleChange={this.handleNonAdminWorkspaceAccessTypeSelection} errors={errors} touched={touched} title={lang.d('with_access_to')}/>
                <NonAdminWorkspaceSelection selectedWorkspaces={this.state.selectedWorkspaces} nonAdminSelected={nonAdminSelected} nonAdminHasAccessToAllWorkspaces={this.state.nonAdminHasAccessToAllWorkspaces} handleWorkspaceSelection={this.handleWorkspaceSelection} errors={errors} touched={touched} />
            </div>
        </>
    }
}

UserForm.propTypes = {
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    isSubmitting: PropTypes.bool,
    dirty: PropTypes.bool,
    status: PropTypes.string,
    values: PropTypes.object,
    touched: PropTypes.object
}
