import { ChartListParams } from 'seatsio'
import { getClient } from './base.service'

export const chartService = {
    getChartsInFirstPage,
    getChartsAfter,
    retrieveChartWithEvents,
    archiveChart,
    copyChart,
    discardDraft,
    publishDraft,
    moveChartOutOfArchive,
    listAllTags,
    addTag,
    removeTag,
    getArchivedChartsFirstPage,
    getArchivedChartsPageAfter,
    retrieveChart,
    copyToWorkspace,
    getPublishedChartValidation,
    getDraftChartValidation
}

function getChartsInFirstPage (pageSize, filter = null, tag = null, evensLimit = null) {
    return getClient().charts.listFirstPage(chartListParams(filter, tag, evensLimit), pageSize)
}

function getChartsAfter (afterId, pageSize, filter = null, tag = null, evensLimit = null) {
    return getClient().charts.listPageAfter(afterId, chartListParams(filter, tag, evensLimit), pageSize)
}

function chartListParams (filter, tag, evensLimit) {
    return new ChartListParams().withExpandEvents(true).withTag(tag).withFilter(filter).withValidation(true).withEventsLimit(evensLimit)
}

function retrieveChart (key) {
    return getClient().charts.retrieve(key)
}

function retrieveChartWithEvents (key) {
    return getClient().charts.retrieveWithEvents(key)
}

function getArchivedChartsFirstPage (pageSize) {
    return getClient().charts.archive.firstPage(null, pageSize)
}

function getArchivedChartsPageAfter (afterId, pageSize) {
    return getClient().charts.archive.pageAfter(afterId, null, pageSize)
}

function archiveChart (key) {
    return getClient().charts.moveToArchive(key)
}

function copyChart (chartKey) {
    return getClient().charts.copy(chartKey)
}

function discardDraft (chartKey) {
    return getClient().charts.discardDraftVersion(chartKey)
}

function publishDraft (chartKey) {
    return getClient().charts.publishDraftVersion(chartKey)
}

function moveChartOutOfArchive (chartKey) {
    return getClient().charts.moveOutOfArchive(chartKey)
}

function listAllTags () {
    return getClient().charts.listAllTags()
}

function addTag (chartKey, tag) {
    return getClient().charts.addTag(chartKey, tag)
}

function removeTag (chartKey, tag) {
    return getClient().charts.removeTag(chartKey, tag)
}

function copyToWorkspace (chartKey, workspaceKey) {
    return getClient().charts.copyToWorkspace(chartKey, workspaceKey)
}

function getPublishedChartValidation (chartKey) {
    return getClient().charts.validatePublishedVersion(chartKey)
}

function getDraftChartValidation (chartKey) {
    return getClient().charts.validateDraftVersion(chartKey)
}
