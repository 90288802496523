import * as PropTypes from 'prop-types'
import lang from '../../utils/lang'
import { useState } from 'react'
import { toEuroString } from './utils'

const getEventLevel = (event) => {
    const eventType = event.data?.type || event.eventType
    return eventType === 'PAYMENT_FAILED' ? 'error' : 'normal'
}

const getEventTitle = (event, describePricingPlan) => {
    const eventType = event.data?.type || event.eventType
    switch (eventType) {
    case 'EMAIL_CONFIRMED':
        return `${lang.d('EMAIL_CONFIRMED')} ${event.data?.confirmedUserEmail ? `: ${event.data?.confirmedUserEmail}` : ''}`
    case 'SUBSCRIPTION_EVENT_ADDED':
        return `Subscribed to ${describePricingPlan(event.data.pricingPlanId, event.data.isYearly)}`
    case 'USAGE_MANUALLY_CORRECTED':
        return `Manually corrected usage to ${event.data.correctedUsage}`
    case 'PAYMENT_METHOD_ATTACHED':
        return `${lang.d('PAYMENT_METHOD_ATTACHED')}: ${event.data.brand} ****${event.data.last4}`
    case 'PAYMENT_SUCCEEDED':
        return `${lang.d('PAYMENT_SUCCEEDED')}: ${toEuroString(event.data.amount)} Invoice ID: ${event.data.invoiceId}`
    case 'PAYMENT_FAILED':
        return `${lang.d('PAYMENT_FAILED')}: ${toEuroString(event.data.amount)} Invoice ID: ${event.data.invoiceId}`
    case 'ACTIVATED':
    case 'COMPANY_CREATED':
    case 'DEACTIVATED':
    case 'PRICING_PLAN_CHANGED':
    case 'RESUBSCRIBED':
    case 'SUBSCRIBED':
    case 'SUBSCRIPTION_EVENT_DELETED':
    case 'SUBSCRIPTION_EVENT_UPDATED':
    case 'SUBSCRIPTION_REQUESTED':
    case 'TRIAL_PERIOD_EXPIRED':
    case 'UNSUBSCRIBED':
    case 'UNWHITELABELLED':
    case 'WHITELABELLED':
    default:
        return lang.d(event.eventType)
    }
}

const removeIgnoredEvents = event => {
    return event.eventType !== 'SUBSCRIBED'
}

export const Timeline = (props) => {
    const [expanded, setExpanded] = useState({})
    const [reverseTimeline, setReverseTimeline] = useState(true)
    const { getPricingPlanName, getEventDetails } = props
    const entries = props.entries.filter(removeIgnoredEvents)

    const toggleIndex = (index) => {
        const isExpanded = expanded[index] === true ? false : true
        setExpanded({ ...expanded, [index]: isExpanded })
    }

    return (
        <div className="timeline section">
            <div className="title">
                {lang.d('timeline')}
                <button className={`icon-button ${reverseTimeline ? 'newest-first' : 'oldest-first'}`} onClick={() => setReverseTimeline(!reverseTimeline)}></button>
            </div>
            {entries.length === 0 ?
                (<div className="no-events-message">No timeline events</div>) :
                (<div className="timelineContainer">
                    <div className="timeline-line"></div>
                    {(reverseTimeline ? entries.toReversed() : entries).map((event, index) => {
                        const date = new Date(event.timestamp)
                        const monthName = date.toLocaleString('en-US', {month: 'long'});
                        const dateString = `${monthName} ${date.getDate()}, ${date.getFullYear()}`
                        const time = date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit"})
                        const hasData = event.data !== undefined && Object.keys(event.data).length > 0
                        const isExpanded = expanded[index] === true
                        const isError = getEventLevel(event) === 'error'
                        return (<div key={event.timestamp} className="timeline-event">
                            <div className="timeline-event-point">
                                <div className="timeline-event-point-inner"></div>
                            </div>
                            <div className="timeline-event-datetime">
                                <span>{dateString}</span>
                                <span className="time">{time}</span>
                            </div>
                            <div className="timeline-event-container">
                                <div className="timeline-event-title">
                                    {isError && (<span className="icon icon-restricted error" />)}
                                    {getEventTitle(event, getPricingPlanName)}
                                </div>
                                <div className="timeline-event-user">
                                    {event.user ? event.user.email : lang.d('system_event')}
                                    {hasData && <button className={`icon-button ${isExpanded ? 'icon-close-light' : 'icon-plus'}`} onClick={() => toggleIndex(index)}></button>}
                                </div>
                            </div>
                            {hasData && (
                                <div className={`timeline-event-details ${isExpanded ? 'visible': ''}`}>
                                    <table className="timeline-event-data">
                                        <tbody>
                                            {getEventDetails(event.data)}
                                        </tbody>
                                    </table>
                        	</div>
                            )}
                        </div>)
                    })}
                </div>)}
        </div>
    )
}

Timeline.propTypes = {
    entries: PropTypes.arrayOf(PropTypes.shape({
        data: PropTypes.object,
        eventType: PropTypes.string,
        timestamp: PropTypes.string,
        user: PropTypes.shape({
            id: PropTypes.number,
            email: PropTypes.string,
            secretKey: PropTypes.string,
            designerKey: PropTypes.string,
            role: PropTypes.string,
            createdOn: PropTypes.string,
            isActive: PropTypes.bool,
            status: PropTypes.string,
            confirmationCode: PropTypes.string
        }),
    })),
    reverseTimeline: PropTypes.bool,
    getPricingPlanName: PropTypes.func,
    getEventDetails: PropTypes.func
}
