// @ts-check
import * as React from 'react'
import { chartJSUrlByRegionId } from '../../domain/regions'
import { SeatsioSeatingChart } from '@seatsio/seatsio-react'
import * as PropTypes from 'prop-types'
import lang from '../../utils/lang'

class ChartPreview extends React.PureComponent {

    constructor (props) {
        super(props)
        this.state = {
            showRowLabels: false,
            showObjectLabels: false
        }
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    }

    handleCheckboxChange (event) {
        this.setState({
            [event.target.name]: event.target.checked
        })
    }

    objectLabelFn () {
        if (this.state.showObjectLabels) {
            return o => o.labels.own
        }
        return (o, defaultLabel) => defaultLabel
    }

    render () {
        return <div className="preview-container">
            <div className="seating-chart">
                <SeatsioSeatingChart
                    region="eu"
                    workspaceKey={this.props.match.params.workspaceKey}
                    chartJsUrl={chartJSUrlByRegionId(this.props.match.params.region)}
                    mode="static"
                    objectLabel={this.objectLabelFn()}
                    showRowLabels={this.state.showRowLabels}
                    // @ts-expect-error
                    chart={this.props.match.params.chartKey}
                />
            </div>
            <div className="options">
                <div>
                    <label>
                        <input
                            name="showRowLabels"
                            type="checkbox"
                            checked={this.state.showRowLabels}
                            onChange={this.handleCheckboxChange}/>
                        <span className="label">{ lang.d('public_preview_show_row_labels') }</span>
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            name="showObjectLabels"
                            type="checkbox"
                            checked={this.state.showObjectLabels}
                            onChange={this.handleCheckboxChange}/>
                        <span className="label">{ lang.d('public_preview_show_object_labels') }</span>
                    </label>
                </div>
            </div>
        </div>
    }
}

ChartPreview.propTypes = {
    match: PropTypes.object
}

export default ChartPreview
