export const defaultState = null

const user = (state = defaultState, action) => {
    switch (action.type) {
    case 'UPDATE_USER':
        return {
            ...state,
            ...action.payload
        }
    case 'SET_USER':
        return { ...action.payload }
    case 'USER/SET_SECRET_KEY':
        return {
            ...state,
            secretKey: action.secretKey
        }
    case 'SET_HAS_CHOSEN_SUBSCRIPTION_PLAN':
        return {
            ...state,
            company: {
                ...state.company,
                hasChosenSubscriptionPlan: true
            }
        }
    default:
        return state
    }
}

function hasRole (user, role) {
    return user.role === role
}

export function isNonAdmin (user) {
    return !isCompanyOrSuperAdmin(user)
}

export function isCompanyOrSuperAdmin (user) {
    return isCompanyAdmin(user) || isSuperAdmin(user)
}

export function isCompanyAdmin (user) {
    return hasRole(user, ROLE_COMPANY_ADMIN)
}

export function isSuperAdmin (user) {
    return hasRole(user, ROLE_SUPER_ADMIN)
}

export function isSuperAdminImpersonating (user, context) {
    return isSuperAdmin(user) && user.company.id !== context.company.id
}

export function hasSignedUp (user) {
    return user.id === user.company.initialUserId
}

export function getWorkspaceIds (user) {
    if (!user.workspaces) {
        return user.workspaces
    }
    return user.workspaces.map(workspaceOrWorkspaceId => {
        if (typeof workspaceOrWorkspaceId === 'number') {
            return workspaceOrWorkspaceId
        }
        return workspaceOrWorkspaceId.id
    })
}

export const ROLE_NON_ADMIN = 'NON_ADMIN'
export const ROLE_COMPANY_ADMIN = 'COMPANY_ADMIN'
export const ROLE_SUPER_ADMIN = 'SUPER_ADMIN'

export default user
