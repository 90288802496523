import React from 'react'
import * as PropTypes from 'prop-types'
import lang from '../../utils/lang'

const IconButtonToSelect = ({ value, onChange, options }) => {
    const getIcon = () => {
        const currentOption = options.find(option => option.value === value)
        if (currentOption) {
            return currentOption.icon
        }
        return 'help'
    }

    return <div className="IconButtonToSelect">
        <div className="container">
            <select onChange={onChange} value={value}>
                { options.map(({ value }) =>
                    <option key={value} value={value}>{ lang.d(value) }</option>)
                }
            </select>
            <div className={`icon icon-${getIcon()}`}></div>
            <div className="arrows icon-arrow-expand-vertical"></div>
        </div>
    </div>
}

IconButtonToSelect.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.object),
    icon: PropTypes.string,
    value: PropTypes.string
}

export default IconButtonToSelect
