import React from 'react'
import * as PropTypes from 'prop-types'

export function GlobalError (props) {
    const errors = props.errors
    return <>
        { errors.global && <div className="errors">
            <div className="error">{errors.global}</div>
        </div> }
    </>
}

GlobalError.propTypes = {
    errors: PropTypes.object.isRequired
}
