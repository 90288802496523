import React from "react";
import * as PropTypes from "prop-types";
import TextInput from "../../components/GUI/TextInput";
import {exposeErrors} from "../../utils/errors";
import {changeSetting} from "../../services/accounts.service";
import {isValidDomainName} from "../../utils/domainValidator";

class WhitelistedDomains extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addNewDomain: false,
            whitelistedDomains: this.props.whitelistedDomains,
            removingDomain: null,
            updateStatus: ''
        }
        this.submitChanges = this.submitChanges.bind(this)
        this.validateDomain = this.validateDomain.bind(this)
        this.closeDomainInput = this.closeDomainInput.bind(this)
    }

    async openDomainInput() {
        this.setState({addNewDomain: true})
    }

    openDomainInputWithDelay() {
        setTimeout(() => this.openDomainInput(), 200)
    }

    closeDomainInput() {
        this.setState({
            addNewDomain: false,
            loadingDomain: false,
            invalidDomain: false,
            domainExists: false,
        })
    }

    validateDomain(domain) {
        if (!domain) {
            return false
        }
        const validFormat = isValidDomainName(domain)
        const alreadyExists = this.state.whitelistedDomains.indexOf(domain) >= 0
        return !alreadyExists && validFormat
    }

    async submitChanges(newValue) {
        if (this.validateDomain(newValue)) {
            this.setState({updateStatus: 'loading'})
            await this.addDomain(newValue)
            this.setState({updateStatus: ''})
        }
        this.closeDomainInput()
    }

    async addDomain(domain) {
        if (this.state.whitelistedDomains.indexOf(domain) === -1) {
            let domains = [...this.state.whitelistedDomains, domain];
            await this.persistDomains(domains)
            this.setState({
                whitelistedDomains: domains
            })
        }
    }

    async removeDomain(domain) {
        const domains = this.state.whitelistedDomains.filter(t => t !== domain)
        this.setState({removingDomain: domain})
        await this.persistDomains(domains)
        this.setState({removingDomain: null})
        this.setState({
            whitelistedDomains: domains
        })
    }

    async persistDomains(domains) {
        let onError = () => {this.closeDomainInput(); this.setState({updateStatus: ''})}
        return exposeErrors(changeSetting('WHITELISTED_DOMAINS', domains.join(";")), onError)
    }

    render() {
        const {whitelistedDomains, addNewDomain, removingDomain, updateStatus} = this.state

        return <div className="WhitelistedDomains">
            {whitelistedDomains.map(domain => {
                return <span
                    className={`domain ${removingDomain === domain && 'loading'}`}
                    key={`domain-${domain}`}
                    onClick={() => this.removeDomain(domain)}
                >
                    <span className="caption">{domain}</span>
                    <span className="icon icon-close-light"/>
                </span>
            })}

            {addNewDomain &&
                <TextInput
                    onChangeFinal={this.submitChanges}
                    disabled={updateStatus === 'loading'}
                    onCancel={this.closeDomainInput}
                    loading={updateStatus === 'loading'}
                    onEnter={() => this.openDomainInputWithDelay()}
                    showLoadingIndicator
                    escCancelsEdit
                    autoFocus
                    submitOnBlur
                />
            }

            {!this.state.addNewDomain &&
                <div className={`button ${this.state.addNewDomain && 'input-field-shown'}`}
                    onClick={() => this.openDomainInput()}>
                    <span className="icon icon-plus-bold"/>
                    <span className="caption">Add domain</span>
                </div>
            }
        </div>
    }

}


WhitelistedDomains.propTypes = {
    whitelistedDomains: PropTypes.array,
}

export default WhitelistedDomains
