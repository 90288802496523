import styles from '../../styles/GUI/PlanDetails.less'
import PropTypes from 'prop-types'

export const PlanDetails = (props) => {
    return (
        <div className={styles.PlanDetails}>
            <span className={styles.title}>{props.title}</span>
            <span className={styles.description}>{props.description}</span>
        </div>
    )
}

PlanDetails.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string.isRequired
}
