import React from 'react'
import Input from '../components/Input'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import lang from '../utils/lang'
import { userService } from '../services/user.service'
import { parseBackendErrors } from '../utils/backendErrors'
import { history } from '../store'
import LoadingIndicator from './GUI/LoadingIndicator'
import { validateEmail, validatePassword } from '../utils/validators'
import * as PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { setUser } from '../actions/user'
import { Environment } from '../environment'
import { getRegions } from '../domain/regions'
import MapPicker from './GUI/MapPicker'

const SignupForm = (props) => {
    const {
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleSubmit,
        setFieldValue,
        values
    } = props

    const emailError = errors.email && touched.email
    const passwordError = errors.password && touched.password
    const mismatchError = errors.passwordMismatch && touched.confirm_password
    const missingRegion = errors.missingRegion && touched.region

    return <>
        <form name="signupForm" onSubmit={handleSubmit} className={'AccountForm dialog with-quicklinks columns-when-narrow'}>
            <div className="title">{ lang.d('signup') }</div>
            <div className="form">
                <div className="column">
                    <div className="form-field">
                        <Input name="email" type="text" value={values.email} placeholder={lang.d('work_email')} onChange={handleChange} autoFocus />
                        { emailError && <div className="errors">
                            <div className="error" style={{ margin: '4px' }}>{errors.email}</div>
                        </div> }
                    </div>
                    <div className="form-field">
                        <Input name="password" type="password" value={values.password} placeholder={lang.d('password')} onChange={handleChange} />
                        { passwordError && <div className="errors">
                            <div className="error" style={{ margin: '4px' }}>{errors.password}</div>
                        </div> }
                    </div>
                    <div className="form-field">
                        <Input name="confirm_password" type="password" value={values.confirm_password} placeholder={lang.d('confirm_password')} onChange={handleChange} />
                        { mismatchError && <div className="errors">
                            { mismatchError && <div className="error" style={{ margin: '4px' }}>{errors.passwordMismatch}</div> }
                        </div> }
                    </div>
                </div>
                <div className="column">
                    <div className="form-field">
                        <MapPicker
                            name="region"
                            locations={getRegions().map(region => ({ label: region.name, value: region.id }))}
                            label={lang.d('server_region')}
                            onChange={value => setFieldValue('region', value)}
                            value={values.region}
                            placeholder={lang.d('server_region_hint')}
                            help={lang.d('server_region_help')}
                            error={missingRegion}
                        />
                    </div>
                </div>
            </div>
            <div className="actions">
                { isSubmitting
                    ? <LoadingIndicator />
                    : <input type="submit" value={lang.d('signup')} className="action highlighted" />
                }
            </div>
        </form>
        <div className="quick-links">
            <Link to="/login" className="arrow quick-link">{ lang.d('login') }</Link>
        </div>
    </>
}

SignupForm.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    isSubmitting: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
    values: PropTypes.object
}

function SignUpFormContainer (props) {
    const dispatch = useDispatch()
    return (
        <Formik
            initialValues={{ email: '', password: '', confirm_password: '', region: null }}
            validate={values => {
                const errors = {}
                if (!values.email) {
                    errors.email = lang.d('required')
                } else if (!validateEmail(values.email)) {
                    errors.email = lang.d('invalid_email')
                }
                if (!values.password) {
                    errors.password = lang.d('required')
                } else if (!validatePassword(values.password)) {
                    errors.password = lang.d('invalid_password')
                }
                if (values.password !== values.confirm_password) {
                    errors.passwordMismatch = lang.d('password_mismatch')
                }
                if (values.region === null) {
                    errors.missingRegion = true
                }
                return errors
            }}
            onSubmit={(values, { setErrors }) => {
                return userService.signUp(values.email, values.password, values.region).then(user => {
                    dispatch(setUser(user))
                    history.push('/')
                }).catch(err => {
                    const { messages } = err
                    setErrors(parseBackendErrors(messages))
                })
            }}
            render={SignupForm}
        />
    )
}

export default SignUpFormContainer
