import React from 'react'
import { connect } from 'react-redux'
import { getEventInMonthReport } from '../services/reports.service'
import lang from '../utils/lang'
import { setHeader } from '../actions/header'
import * as PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const EmptyEventReportMessage = () => (
    <div className="empty-page-message">
        <div className="title">{ lang.d('empty_event_report') }</div>
        <div className="message">{ lang.d('empty_event_report_description') }</div>
        <Link to="/reports" className="arrow back">{lang.d('all_reports')}</Link>
    </div>
)

class EventReport extends React.Component {
    state = {
        objects: {},
        ready: false
    }

    async componentDidMount () {
        const { eventId, year, month } = this.props.match.params
        this.props.setHeader({
            leftItems: [{
                to: '/reports',
                icon: 'icon-arrow-light-left',
                label: lang.d('all_reports'),
                className: 'soft-button'
            }]
        })

        try {
            const objects = await getEventInMonthReport(eventId, month, year)
            this.setState({ objects, ready: true })
        } catch (e) {
            this.handleReportNotFound()
        }
    }

    handleReportNotFound () {
        this.props.setHeader({
            leftItems: [{
                to: '/reports',
                icon: 'icon-arrow-light-left',
                label: lang.d('all_reports'),
                className: 'soft-button'
            }]
        })

        this.setState({
            objects: {},
            ready: true
        })
    }

    renderPlaceholder () {
        return <div className="EventReport">
            <div className="container">
                <div className="loading-placeholder">
                    <div className="fake-item"></div>
                    <div className="fake-item"></div>
                </div>
                <div className="DataTable">
                    <div className="loading-placeholder">
                        <div className="fake-item"></div>
                        <div className="fake-item"></div>
                        <div className="fake-item"></div>
                        <div className="fake-item"></div>
                    </div>
                </div>
            </div>
        </div>
    }

    render () {
        if (!Object.keys(this.state.objects).length && !this.state.ready) return this.renderPlaceholder()
        if (!Object.keys(this.state.objects).length && this.state.ready) return <EmptyEventReportMessage />

        const { eventKey } = this.props.match.params

        if (this.state.objects[0].usageByReason) {
            return <EventReportTableNew objects={this.state.objects} eventKey={eventKey} />
        } else {
            return <EventReportTableOld objects={this.state.objects} eventKey={eventKey} definitionOfUse={this.props.definitionOfUse} />
        }
    }
}

function EventReportTableOld (props) {
    function getBillableCountForSelectedChart () {
        if (props.definitionOfUse === 'FIRST_BOOKING_OR_SELECTION') {
            return props.objects.reduce((acc, curr) => {
                return acc + curr.numFirstBookingsOrSelections
            }, 0)
        }

        return props.objects.reduce((acc, curr) => {
            return acc + curr.numFirstBookings
        }, 0)
    }

    function renderTableHeader () {
        if (props.definitionOfUse === 'FIRST_BOOKING_OR_SELECTION') {
            return (
                <tr>
                    <th>{lang.d('object')}</th>
                    <th>{lang.d('first_status_changes')}</th>
                    <th>{lang.d('first_selections')}</th>
                    <th>{lang.d('used_seats')}</th>
                </tr>
            )
        } else {
            return (
                <tr>
                    <th>{lang.d('object')}</th>
                    <th>{lang.d('used_seats')}</th>
                </tr>
            )
        }
    }

    function renderTableRow (o) {
        if (props.definitionOfUse === 'FIRST_BOOKING_OR_SELECTION') {
            return (
                <tr key={props.objects.indexOf(o)}>
                    <td>{o.object}</td>
                    <td>{o.numFirstBookings}</td>
                    <td>{o.numFirstSelections}</td>
                    <td>{o.numFirstBookingsOrSelections}</td>
                </tr>
            )
        } else {
            return (
                <tr key={props.objects.indexOf(o)}>
                    <td>{o.object}</td>
                    <td>{o.numFirstBookings}</td>
                </tr>
            )
        }
    }

    return <div className="EventReport">
        <div className="container">
            <div className="title">{`${lang.d('event_key')}: ${props.eventKey}`}</div>
            <div className="sub-title">{lang.d('x_used_seats', { smart_count: getBillableCountForSelectedChart() })}</div>
            <div className="DataTable">
                <table>
                    <thead>{renderTableHeader()}</thead>
                    <tbody>
                        {props.objects.map(o => renderTableRow(o))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}

EventReportTableOld.propTypes = {
    eventKey: PropTypes.string,
    definitionOfUse: PropTypes.string,
    objects: PropTypes.array
}

function EventReportTableNew (props) {
    function getBillableCountForSelectedChart () {
        return props.objects.reduce((acc, curr) => {
            return acc + curr.numUsedObjects
        }, 0)
    }

    function renderTableHeader () {
        return (
            <tr>
                <th>{lang.d('object')}</th>
                <th>{lang.d('used_seats')}</th>
                <th>{lang.d('usage_reason')}</th>
            </tr>
        )
    }

    function renderTableRow (o) {
        return <tr key={props.objects.indexOf(o)}>
            <td>{o.object}</td>
            <td>{o.numUsedObjects}</td>
            <td>{formatUsageReasons(o)}</td>
        </tr>
    }

    function formatUsageReasons (usage) {
        const reasons = []
        const usageByReason = usage.usageByReason

        if (usageByReason.SELECTED) {
            reasons.push(lang.d('x_usage_reason_selected', { smart_count: usageByReason.SELECTED }))
        }
        if (usageByReason.STATUS_CHANGED) {
            reasons.push(lang.d('x_usage_reason_status_changed', { smart_count: usageByReason.STATUS_CHANGED }))
        }
        if (usageByReason.ASSIGNED_TO_CHANNEL) {
            reasons.push(lang.d('x_usage_reason_assigned_to_channel', { smart_count: usageByReason.ASSIGNED_TO_CHANNEL }))
        }

        return reasons.join(' + ')
    }

    return <div className="EventReport">
        <div className="container">
            <div className="title">{`${lang.d('event_key')}: ${props.eventKey}`}</div>
            <div className="sub-title">{lang.d('x_used_seats', { smart_count: getBillableCountForSelectedChart() })}</div>
            <div className="DataTable">
                <table>
                    <thead>{renderTableHeader()}</thead>
                    <tbody>
                        {props.objects.map(o => renderTableRow(o))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}

EventReportTableNew.propTypes = {
    eventKey: PropTypes.string,
    objects: PropTypes.array
}

EventReport.propTypes = {
    match: PropTypes.object,
    definitionOfUse: PropTypes.string,
    setHeader: PropTypes.func
}

const mapStateToProps = state => {
    return {
        definitionOfUse: state.context.company.definitionOfUse
    }
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(EventReport)
