import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { openModalDialog } from '../../actions/modalDialog'
import * as PropTypes from 'prop-types'
import { getRegionById } from '../../domain/regions'
import DropDown from '../../components/GUI/DropDown'
import { adminService } from '../../services/admin.service'
import { getSuperAdminEnvironmentSettings } from '../../domain/superAdminEnvironmentSettings'

export function Region (props) {
    const dispatch = useDispatch()
    const { companyRegionId, companyId, companyStatus } = props
    const [regionMigrationInProgress, setRegionMigrationInProgress] = useState(isMigrationInProgress(companyStatus))

    function migrateToRegion () {
        const regions = getSuperAdminEnvironmentSettings().regions
            .filter(region => region.id !== companyRegionId)
        dispatch(openModalDialog({
            title: 'Migrate company to other region?',
            successMessage: 'Migration started',
            onSubmit: async values => {
                await adminService.startRegionMigration(companyId, companyRegionId, values.region)
                setRegionMigrationInProgress(true)
            },
            settings: {
                acceptCaption: 'Start migration',
                dangerousAction: true,
                waitOnAccept: true,
                formik: {
                    initialValues: {
                        region: regions[0].id
                    },
                    render: props => <RegionMigrationForm regions={regions} companyRegionId={companyRegionId} {...props} />
                }
            }
        }))
    }

    function canMigrateToOtherRegion () {
        const regions = getSuperAdminEnvironmentSettings().regions
        const region = regions.find(region => region.id === companyRegionId)
        return region.isPrimary
    }

    function showRegion () {
        return <>
            <div className="region">{getRegionById(companyRegionId).name} ({companyRegionId})</div>
            {canMigrateToOtherRegion() && <div>
                <button className="FormButton btn" onClick={() => migrateToRegion()}>Migrate to other region</button>
            </div>}
        </>
    }

    function isMigrationInProgress (status) {
        return status === 'MIGRATING_NON_EVENT_DATA' ||
            status === 'MIGRATING_OBJECT_STATUSES' ||
            status === 'MIGRATING_STATUS_CHANGES'
    }

    return <div className="section" id="region">
        <div className="title">Region</div>
        <div className="body">
            {regionMigrationInProgress ? <div>Region migration in progress</div> : showRegion()}
        </div>
    </div>
}

Region.propTypes = {
    companyId: PropTypes.number.isRequired,
    companyRegionId: PropTypes.string.isRequired,
    companyStatus: PropTypes.string.isRequired
}

function RegionMigrationForm (props) {
    const { errors, touched, handleChange, values } = props
    const regions = props.regions.map(region => ({
        label: region.name,
        value: region.id
    }))
    return <div id="regionMigrationForm">
        <DropDown
            list={regions}
            onChange={handleChange}
            selectedIndex={regions.findIndex(regions => regions.value === values.region)}
            name="region"
        />
    </div>
}

RegionMigrationForm.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    values: PropTypes.object,
    companyRegionId: PropTypes.string.isRequired,
    regions: PropTypes.array.isRequired
}
