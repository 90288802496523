import React from 'react'
import lang from '../../utils/lang'
import InfiniteScroll from 'react-infinite-scroller'
import SearchInput from './SearchInput'
import * as PropTypes from 'prop-types'

class DataTable extends React.Component {
    renderHeaders () {
        return this.props.headers || <th></th>
    }

    renderRows () {
        return this.props.rows || <tr><td></td></tr>
    }

    renderLoadingPlaceholder () {
        return this.props.placeholder || <div key="placeholder" className="loading-placeholder">
            <div className="fake-item"></div>
            <div className="fake-item"></div>
            <div className="fake-item"></div>
            <div className="fake-item"></div>
            <div className="fake-item"></div>
        </div>
    }

    renderNoResults () {
        return this.props.noResults || <div className="center-message">{ lang.d('no_results') }</div>
    }

    render () {
        return <>
            <div className="DataTable" ref={(ref) => this.scrollParentRef = ref}>
                { this.props.onSearch && (!this.props.emptyList || this.props.searchValue) && !this.props.waitingForFirstData && <SearchInput
                    displayValue={this.props.searchValue}
                    search={this.props.onSearch}
                    searchValue={this.props.searchValue}
                    caption={this.props.searchCaption}
                    clearResults={this.props.onSearch}
                    searchFilterType={this.props.searchFilterType}
                    searchFilterTypes={this.props.searchFilterTypes}
                /> }
                { !this.props.emptyList
                    ? <InfiniteScroll
                        pageStart={0}
                        hasMore={this.props.hasMore}
                        loadMore={this.props.loadMore}
                        loader={this.renderLoadingPlaceholder()}
                        useWindow={false}
                        getScrollParent={() => this.scrollParentRef}
                        className="infinite-scroll-container"
                    >
                        { this.props.hasData && <table>
                            <tbody>
                                <tr>
                                    { this.renderHeaders() }
                                </tr>

                                { this.renderRows() }
                            </tbody>
                        </table> }
                        { this.props.waitingForFirstData && this.renderLoadingPlaceholder() }
                    </InfiniteScroll>
                    : this.renderNoResults()
                }
            </div>
        </>
    }
}

DataTable.propTypes = {
    emptyList: PropTypes.bool,
    hasData: PropTypes.bool,
    hasMore: PropTypes.bool,
    loadMore: PropTypes.func,
    headers: PropTypes.node,
    rows: PropTypes.node,
    placeholder: PropTypes.node,
    noResults: PropTypes.node,
    onSearch: PropTypes.func,
    searchValue: PropTypes.string,
    searchCaption: PropTypes.string,
    waitingForFirstData: PropTypes.bool,
    searchFilterTypes: PropTypes.arrayOf(PropTypes.string),
    searchFilterType: PropTypes.string
}

export default DataTable
