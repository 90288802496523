import React from 'react'
import * as PropTypes from 'prop-types'

const types = [
    { value: 'SUBSCRIBED', label: 'Subscribed' },
    { value: 'UNSUBSCRIBED', label: 'Unsubscribed' },
    { value: 'USAGE_MANUALLY_CORRECTED', label: 'Usage manually adjusted' }
]

const months = [
    { value: '1', label: 'Jan' },
    { value: '2', label: 'Feb' },
    { value: '3', label: 'Mar' },
    { value: '4', label: 'Apr' },
    { value: '5', label: 'May' },
    { value: '6', label: 'Jun' },
    { value: '7', label: 'Jul' },
    { value: '8', label: 'Aug' },
    { value: '9', label: 'Sep' },
    { value: '10', label: 'Oct' },
    { value: '11', label: 'Nov' },
    { value: '12', label: 'Dec' }
]

const years = []
for (let i = 2014; i <= new Date().getFullYear() + 5; ++i) {
    years.push({
        value: i.toString(),
        label: i.toString()
    })
}

export const SubscriptionEventForm = props => {
    const { errors, touched, handleChange, values } = props

    const typeSpecificFields = () => {
        switch (values.type) {
        case 'SUBSCRIBED': return subscribedFields()
        case 'USAGE_MANUALLY_CORRECTED': return usageManuallyCorrectedFields(errors, touched)
        default: return ''
        }
    }

    const pricingPlans = () => {
        return props.pricingPlans.map(plan => {
            const monthlyOrYearly = plan.isYearly ? 'year' : 'month'
            return ({
                value: plan.id.toString() + '-' + plan.isYearly,
                label: `${plan.name || 'Custom'}: ${plan.numberOfCommittedSeats} seats / ${monthlyOrYearly} @ ${plan.pricePerSeat}€`
            })
        })
    }

    const subscribedFields = () => {
        return <>
            <div className="form-field">
                <div className="description">
                    <label>Pricing plan</label>
                </div>
                <div className="field pricing-plan">
                    <select onChange={handleChange} value={values.pricingPlanId} name="pricingPlanId">
                        {pricingPlans().map(pricingPlan => {
                            return (
                                <option value={pricingPlan.value} key={`dropdown-${pricingPlan.label}`}>{pricingPlan.label}</option>
                            )
                        })}
                    </select>
                </div>
            </div>
        </>
    }

    const usageManuallyCorrectedFields = (errors, touched) => {
        const correctedUsageErrors = errors.correctedUsage && touched.correctedUsage

        return <>
            <div className="form-field">
                <div className="description">
                    <label>Amount</label>
                </div>
                <div className="field">
                    <input type="text" name="correctedUsage" value={values.correctedUsage} onChange={handleChange} />
                    { correctedUsageErrors &&
                    <div className="errors">
                        { correctedUsageErrors && <div className="error">{errors.correctedUsage}</div> }
                    </div>
                    }
                </div>
            </div>
        </>
    }

    const monthLabel = () => {
        switch (values.type) {
        case 'SUBSCRIBED': return 'First month to bill'
        case 'UNSUBSCRIBED': return 'Last billable month (inclusive)'
        default: return 'Month'
        }
    }

    const monthWarning = () => {
        if (values.type === 'UNSUBSCRIBED') {
            return <strong>⚠️ i.e. the month <i>before</i> they would normally renew.</strong>
        }
    }

    return <div className="two-column edit-subscription-event-form">
        <div className="form-field">
            <div className="description">
                <label>Type</label>
            </div>
            <div className="field">
                <select onChange={handleChange} value={values.type} name="type" disabled={props.isForUpdate ? true : null}>
                    {types.map(type => (
                        <option value={type.value} key={`dropdown-${type.label}`}>{type.label}</option>
                    ))}
                </select>
            </div>
        </div>

        <div className="form-field">
            <div className="description">
                <label>{monthLabel()}</label>
                {monthWarning()}
            </div>
            <div className="field">
                <select onChange={handleChange} value={values.month} name="month">
                    {months.map(month => (
                        <option value={month.value} key={`dropdown-${month.label}`}>{month.label}</option>
                    ))}
                </select>
                <select onChange={handleChange} value={values.year} name="year">
                    {years.map(year => (
                        <option value={year.value} key={`dropdown-${year.label}`}>{year.label}</option>
                    ))}
                </select>
            </div>
        </div>

        {typeSpecificFields()}

        { errors.global && <div className="errors">
            <div className="error" key="global">{errors.global}</div>
        </div> }
    </div>
}

SubscriptionEventForm.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    values: PropTypes.object,
    isSubmitting: PropTypes.bool,
    status: PropTypes.string,
    pricingPlans: PropTypes.array,
    isForUpdate: PropTypes.bool
}
