import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import lang from '../../utils/lang'
import Button from '../../components/GUI/Button'
import { contextualMenuService } from '../../services/contextualMenu.service'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { copyToClipboard } from '../../utils/clipboard'
import { DEFAULT_NOTIFICATION_TIMEOUT, notificationService } from '../../services/notification.service'
import { updateEventKey, updateEventName } from '../../actions/events'
import { cloneObject } from '../../utils/helperFunctions'

const editEventMenuOptions = [
    {
        type: 'edit',
        caption: lang.d('edit_key'),
        uiEvent: 'editKey'
    },
    {
        type: 'edit',
        caption: lang.d('edit_name'),
        uiEvent: 'editName'
    },
]

const contextMenuOptions = [
    {
        type: 'delete',
        caption: lang.d('delete_event'),
        uiEvent: 'delete'
    },
    {
        type: 'copy',
        caption: lang.d('copy_event_key'),
        uiEvent: 'copyEventKey'
    }
]

class EventListItem extends Component {
    constructor (props) {
        super(props)
        this.state = {
            contextualMenuOpened: false,
            event: this.props.event,
            eventKey: this.props.event.key
        }
    }

    getContextMenu() {
        if (this.props.event.isInThePast) {
            return contextMenuOptions
        } else {
            return Array.of(...editEventMenuOptions, ...contextMenuOptions)
        }
    }

    onContextMenu (e) {
        this.setState({ contextualMenuOpened: true })
        contextualMenuService.show(this.getContextMenu(), {
            onSelect: uiEvent => this.onAction(uiEvent),
            onClose: () => this.setState({ contextualMenuOpened: false }),
            position: {
                left: e.clientX,
                top: e.clientY
            }
        })
    }

    onAction (uiEvent) {
        this.setState({
            contextualMenuOpened: false,
            saveStateClassName: null
        })
        switch (uiEvent) {
        case 'editKey':
            this.props.updateEventKey(this.props.event.chartKey, this.state.eventKey)
            break
        case 'editName':
            this.props.updateEventName(this.state.eventKey, this.state.event.name, name => this.onEventNameUpdated(name))
            break
        case 'delete':
            this.props.requestDeleteEvent(this.props.event.key)
            break
        case 'copyEventKey':
            this.copyEventKey()
            break
        default:
            throw new Error('Unknown uiEvent: ' + uiEvent)
        }
    }

    onEventNameUpdated (name) {
        const event = cloneObject(this.state.event)
        event.name = name
        this.setState({ event: event })
    }

    copyEventKey () {
        if (copyToClipboard(this.state.eventKey)) {
            notificationService.info(lang.d('event_key_copied'), this.props.event.key, DEFAULT_NOTIFICATION_TIMEOUT)
        } else {
            notificationService.error(lang.d('error'), lang.d('splash_screen_general_error_modal_dialog_message'), DEFAULT_NOTIFICATION_TIMEOUT)
        }
    }

    render () {
        return (
            <div className={`EventListItem ${this.state.contextualMenuOpened && 'contextual-menu-opened'} ${(this.props.addedEvent === this.props.event.key) && 'success'}`} key={this.props.id} onContextMenu={e => this.onContextMenu(e)}>
                <Link to={`/charts/${this.props.event.chartKey}/events/${this.state.eventKey}`} onClick={(e) => this.state.editingEventKey && e.preventDefault()}>
                    <div className="event-key" title={this.state.event.name ? this.state.event.key : ''}>
                        {this.state.event.name ? this.state.event.name : this.state.eventKey}
                    </div>
                </Link>
                <Button preset="round" type="more" onClick={e => this.onContextMenu(e)} />
            </div>
        )
    }
}

EventListItem.propTypes = {
    requestDeleteEvent: PropTypes.func.isRequired,
    addedEvent: PropTypes.string,
    event: PropTypes.object.isRequired,
    id: PropTypes.number,
    updateEventKey: PropTypes.func,
    requestMarkEventAsInThePast: PropTypes.func.isRequired,
    updateEventName: PropTypes.func
}

const mapStateToProps = state => ({
    addedEvent: state.charts.newestAddedEvent
})

const mapDispatchToProps = dispatch => ({
    updateEventKey: (chartKey, currentKey, refreshCallback) => dispatch(updateEventKey(chartKey, currentKey, refreshCallback)),
    updateEventName: (eventKey, currentName, onSubmitSuccessCallback) => dispatch(updateEventName(eventKey, currentName, false, onSubmitSuccessCallback))
})

export default connect(mapStateToProps, mapDispatchToProps)(EventListItem)
