export function parseBackendErrors (errorMessages) {
    function addParsedError (parsedErrors, key, msg) {
        if (parsedErrors[key]) {
            parsedErrors[key] += ' - ' + msg
        } else {
            parsedErrors[key] = msg
        }
    }

    function parseError (error) {
        const matches = error.match(/^#\/(.*?):\s(.+)/)
        if (matches === null) {
            return { field: 'global', error: error }
        }
        return {
            field: matches[1],
            error: matches[2]
        }
    }

    function parseErrors (errors) {
        const parsedErrors = {}
        errors.forEach(error => {
            const parsedError = parseError(error)
            addParsedError(parsedErrors, parsedError.field, parsedError.error)
        })
        return parsedErrors
    }

    if (errorMessages === undefined) {
        return {}
    }
    return parseErrors(errorMessages)
}
