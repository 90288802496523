import React, { Component } from 'react'
import * as PropTypes from 'prop-types';
import { setHeader } from '../../actions/header';
import { connect } from 'react-redux';
import lang from '../../utils/lang';
import LogInAsCompanyThroughChartKey from '../../components/Admin/LogInAsCompanyThroughChartKey'

class AdminLogInAsCompanyThroughChartKey extends Component {

    componentDidMount () {
        this.props.setHeader({
            showSearch: false,
            hasItems: true,
            centerItems: [],
            rightItems: [],
            leftItems: [{
                to: '/admin',
                icon: 'icon-arrow-light-left',
                string_key: 'admin',
                className: 'soft-button'
            }]
        })
    }

    render() {
        return <div className="Admin sectioned-page">
            <div className="sectioned-page-container">
                <div className="section">
                    <div className="title">{lang.d('log_in_as_company_through_chart_key')}</div>
                    <LogInAsCompanyThroughChartKey/>
                </div>
            </div>
        </div>
    }
}

AdminLogInAsCompanyThroughChartKey.propTypes = {
    setHeader: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(null, mapDispatchToProps)(AdminLogInAsCompanyThroughChartKey)
