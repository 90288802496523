import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { exposeErrors } from '../utils/errors'
import { webhooksService } from '../services/webhooks.service'

import { setHeader } from '../actions/header'
import { useHistory } from 'react-router-dom'

const Webhooks = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [portalUrl, setPortalUrl] = useState('');

    useEffect(() => {
        dispatch(setHeader({}))
    }, [dispatch, history])

    useEffect(() => {
        exposeErrors(webhooksService.generatePortalUrl())
            .then((url) => setPortalUrl(url));
    }, []);

    return <div className="Webhooks">
        <div className="title">Webhooks</div>
        <iframe src={portalUrl}
            className="iframe"
            allow="clipboard-write"
            loading="lazy"
            title="webhooks">
        </iframe>
    </div>
};

export default connect(null, null)(Webhooks)
