import React from 'react'
import * as PropTypes from 'prop-types'
import { contextualMenuService } from '../../services/contextualMenu.service'
import Button from './Button'
import lang from '../../utils/lang'
import { formatWorkspaceName } from '../../utils/helperFunctions'
import { history } from '../../store'
import { connect } from 'react-redux'
import Badge from './Badge'
import { EditWorkspaceForm } from './EditWorkspaceForm'
import { closeModalDialog, openModalDialog } from '../../actions/modalDialog'
import { workspacesService } from '../../services/workspaces.service'
import { exposeErrors } from '../../utils/errors'
import { getDefaultWorkspace } from '../../utils/hooks'

class ActiveWorkspaceListItem extends React.Component {
    state = {
        contextualMenuOpened: false
    }

    editWorkspace () {
        this.props.openModalDialog({
            title: lang.d('edit_workspace_name'),
            successMessage: lang.d('workspace_updated'),
            onSubmit: values => workspacesService.updateWorkspace(this.props.workspace.key, values.name),
            onSubmitSuccess: values => this.updateWorkspaceInStateAndDoSearch(values.name),
            settings: {
                waitOnAccept: true,
                acceptCaption: lang.d('update'),
                formik: {
                    initialValues: {
                        name: formatWorkspaceName(this.props.workspace) || ''
                    },
                    validate: values => {
                        const errors = {}
                        if (values.name === '') {
                            errors.name = lang.d('required')
                        }
                        return errors
                    },
                    render: props => <EditWorkspaceForm {...props} />
                }
            }
        })
    }

    updateWorkspaceInStateAndDoSearch (newName) {
        this.props.updateWorkspaceInState({ ...this.props.workspace, name: newName })
        if (this.props.searchValue) {
            this.props.doSearch()
        }
    }

    getContextMenuOptions () {
        return this.getContextMenuOptionsWorkspace()
    }

    getContextMenuOptionsWorkspace () {
        const options = []
        options.push({
            type: 'invert-horizontal',
            caption: lang.d('switch_to_workspace'),
            uiEvent: 'switch',
            disabled: this.isCurrentWorkspace(this.props.workspace)
        })
        if (!this.props.readOnly) {
            options.push({
                type: 'edit',
                caption: lang.d('edit_name'),
                uiEvent: 'edit'
            })
            options.push({
                type: 'workspace',
                caption: lang.d('make_default_workspace'),
                uiEvent: 'make-default',
                disabled: this.props.workspace.isDefault
            })
            options.push({
                type: 'power',
                caption: lang.d('deactivate_workspace'),
                uiEvent: 'deactivate-workspace',
                disabled: this.isCurrentWorkspace(this.props.workspace) || this.props.workspace.isDefault
            })
        }
        return options
    }

    onContextMenu (e) {
        e.preventDefault()
        window.getSelection().removeAllRanges()
        this.setState({ contextualMenuOpened: true })
        contextualMenuService.show(this.getContextMenuOptions(), {
            onSelect: uiEvent => this.onAction(uiEvent),
            onClose: () => this.setState({ contextualMenuOpened: false }),
            position: {
                left: e.clientX,
                top: e.clientY
            }
        })
    }

    onAction (uiEvent) {
        this.setState({ contextualMenuOpened: false })
        switch (uiEvent) {
        case 'edit':
            this.editWorkspace()
            break
        case 'switch':
            this.switchToWorkspace()
            break
        case 'deactivate-workspace':
            this.requestDeactivate()
            break
        case 'make-default':
            this.requestMakeDefault()
            break
        default:
            throw new Error('Unknown uiEvent: ' + uiEvent)
        }
    }

    async switchToWorkspace () {
        history.push(`/switch-to-workspace/${this.props.workspace.key}`)
    }

    requestDeactivate () {
        this.props.openModalDialog({
            title: lang.d('deactivate_workspace'),
            message: lang.d('deactivate_workspace_confirmation', { workspace: formatWorkspaceName(this.props.workspace) }),
            successMessage: lang.d('workspace_deactivated'),
            settings: {
                onAccept: () => this.deactivate(),
                acceptCaption: lang.d('deactivate'),
                dangerousAction: true,
                waitOnAccept: true
            }
        })
    }

    async deactivate () {
        await exposeErrors(workspacesService.deactivateWorkspace(this.props.workspace.key), () => this.props.closeModalDialog())
        this.props.updateWorkspaceInState({ ...this.props.workspace, isActive: false })
    }

    requestMakeDefault () {
        this.props.openModalDialog({
            title: lang.d('make_default_workspace'),
            message: lang.d('make_default_workspace_confirmation'),
            successMessage: lang.d('default_workspace_changed'),
            settings: {
                onAccept: () => this.makeDefault(),
                acceptCaption: lang.d('make_default'),
                dangerousAction: true,
                waitOnAccept: true
            }
        })
    }

    async makeDefault () {
        await exposeErrors(workspacesService.setDefault(this.props.workspace.key), () => this.props.closeModalDialog())
        this.props.setDefaultWorkspaceInState(this.props.workspace.key)
    }

    isDefaultWorkspace (workspace) {
        return workspace.key === this.props.userDefaultWorkspace.key
    }

    getWorkspaceBadges (workspace) {
        const badges = []
        if (this.isCurrentWorkspace(workspace)) {
            badges.push(<Badge key="current" type="current">{ lang.d('current') }</Badge>)
        }
        if (this.isDefaultWorkspace(workspace)) {
            badges.push(<Badge key="default" type="default">{ lang.d('default') }</Badge>)
        }
        if (workspace.isTest) {
            badges.push(<Badge key="test" type="test">{ lang.d('test') }</Badge>)
        } else {
            badges.push(<Badge key="production" type="production">{ lang.d('production-short') }</Badge>)
        }
        return badges
    }

    workspaceNameColumn (workspace) {
        if (this.isCurrentWorkspace(workspace)) {
            return <td>{ formatWorkspaceName(workspace) } { this.getWorkspaceBadges(workspace) }</td>
        }
        return <td className="action-cell">
            <span title={lang.d('switch_to_workspace')} onClick={() => this.switchToWorkspace()}>
                <span>{ formatWorkspaceName(workspace) }</span>
                <span className="icon icon-invert-horizontal"></span>
                { this.getWorkspaceBadges(workspace) }
            </span>
        </td>
    }

    isCurrentWorkspace (workspace) {
        return this.props.context.workspace.key === workspace.key
    }

    render () {
        const workspace = this.props.workspace
        return (
            <tr className={`${this.state.contextualMenuOpened && 'contextual-menu-opened'} ${this.props.animateIn && 'enter'}`} onContextMenu={e => this.onContextMenu(e)}>
                { this.workspaceNameColumn(workspace) }
                <td className="fixed-width">{workspace.key}</td>
                <td className="has-button"><Button preset="round" type="more" onClick={e => this.onContextMenu(e)} /></td>
            </tr>
        )
    }
}

ActiveWorkspaceListItem.propTypes = {
    animateIn: PropTypes.bool,
    workspace: PropTypes.object.isRequired,
    switchToWorkspace: PropTypes.func,
    context: PropTypes.object,
    readOnly: PropTypes.bool,
    userDefaultWorkspace: PropTypes.object,
    setDefaultWorkspaceInState: PropTypes.func,
    updateWorkspaceInState: PropTypes.func,
    doSearch: PropTypes.func,
    openModalDialog: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    searchValue: PropTypes.string
}

const mapStateToProps = state => ({
    context: state.context,
    userDefaultWorkspace: getDefaultWorkspace(),
    searchValue: state.search.workspaces.value
})

const mapDispatchToProps = (dispatch) => ({
    setDefaultWorkspaceInState: workspaceKey => dispatch({ type: 'SET_DEFAULT_WORKSPACE', workspaceKey }),
    updateWorkspaceInState: workspace => dispatch({ type: 'UPDATE_WORKSPACE', workspace }),
    openModalDialog: payload => dispatch(openModalDialog(payload)),
    closeModalDialog: payload => dispatch(closeModalDialog(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ActiveWorkspaceListItem)
