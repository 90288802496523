import { MODAL_DIALOG_CLEAR_TIMEOUT } from '../constants/modalDialog'

export const openModalDialog = (payload) => ({
    type: 'MODAL_DIALOG/OPEN',
    payload: {
        ...payload,
        newModal: true
    }
})

export const closeModalDialog = () => ({
    type: 'MODAL_DIALOG/CLOSE'
})

export const clearModalDialog = () => ({
    type: 'MODAL_DIALOG/CLEAR'
})

export const onChange = (payload) => ({
    type: 'MODAL_DIALOG/ON_CHANGE',
    payload
})

export const onSuccess = payload => ({
    type: 'MODAL_DIALOG/ON_SUCCESS',
    payload
})

export const setModalSubmitting = (payload) => ({
    type: 'MODAL_DIALOG/SET_SUBMITTING',
    payload
})

export const setSubmitEnabled = enabled => ({
    type: 'MODAL_DIALOG/SET_SUBMIT_ENABLED',
    payload: enabled
})

export const closeDialog = () => ({ type: 'MODAL_DIALOG/CLOSE' })
export const dialogAccepted = () => ({ type: 'MODAL_DIALOG/ACCEPTED' })
export const closeAndClearDialog = () => async (dispatch) => {
    dispatch(closeDialog())
    setTimeout(() => {
        dispatch(clearModalDialog())
    }, MODAL_DIALOG_CLEAR_TIMEOUT)
}
