import React from 'react'
import ModalDialog from './GUI/ModalDialog'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    clearModalDialog,
    closeDialog,
    dialogAccepted,
    onSuccess,
    setModalSubmitting
} from '../actions/modalDialog'
import { MODAL_DIALOG_CLEAR_TIMEOUT } from '../constants/modalDialog'

class ModalDialogContainer extends React.Component {
    constructor (props) {
        super(props)
        this.successCloseTimeout = null
        this.onKeyDown = this.onKeyDown.bind(this)
    }

    componentDidMount () {
        window.addEventListener('keydown', this.onKeyDown)
    }

    componentWillUnmount () {
        window.removeEventListener('keydown', this.onKeyDown)
    }

    onKeyDown (e) {
        if (e.key === 'Escape' && !this.props.modalDialog.closed) {
            this.onCancel()
        }
    }

    isVisible () {
        const { title, message, settings } = this.props.modalDialog
        return Boolean(title || message || Object.keys(settings).length > 0)
    }

    isWaiting () {
        return this.props.modalDialog.settings.waitOnAccept && this.props.modalDialog.accepted
    }

    isWaitingCompleted () {
        return this.props.modalDialog.done
    }

    async onAccept () {
        const { settings } = this.props.modalDialog
        if (this.isWaiting() && !this.isWaitingCompleted()) {
            return false
        }
        try {
            if (settings.onAccept) {
                await settings.onAccept()
            }
            if (settings.waitOnAccept) {
                this.props.dispatch(dialogAccepted())
            } else {
                this.props.dispatch(clearModalDialog())
            }
        } catch (error) {
            if (settings.onAcceptFailed) settings.onAcceptFailed(error)
        }
    }

    close () {
        clearTimeout(this.successCloseTimeout)
        this.props.dispatch(closeDialog())
        setTimeout(() => this.props.dispatch(clearModalDialog()), MODAL_DIALOG_CLEAR_TIMEOUT)
    }

    onCancel () {
        if ((this.isWaiting() && !this.isWaitingCompleted()) || this.props.modalDialog.isSubmitting || this.props.modalDialog.accepted) {
            return false
        }
        if (this.props.modalDialog.settings.onCancel) this.props.modalDialog.settings.onCancel()
        this.close()
    }

    showSuccessMessageAndClose () {
        if (this.props.modalDialog.successMessage) {
            this.props.dispatch(onSuccess({ done: true, doneMessage: this.props.modalDialog.successMessage, doneIcon: this.props.modalDialog.doneIcon }))
            this.successCloseTimeout = setTimeout(() => this.close(), 1300 + (this.props.modalDialog.successMessage ? 400 : 0))
        } else {
            this.close()
        }
    }

    setModalSubmitting (value) {
        this.props.dispatch(setModalSubmitting(value))
    }

    render () {
        if (!this.props.modalDialog.newModal) return null

        return <ModalDialog
            title={this.props.modalDialog.title}
            message={this.props.modalDialog.message}
            settings={this.props.modalDialog.settings}
            closed={this.props.modalDialog.closed}
            doneMessage={this.props.modalDialog.doneMessage}
            doneIcon={this.props.modalDialog.doneIcon}
            submitEnabled={this.props.modalDialog.submitEnabled}
            visible={this.isVisible()}
            waiting={this.isWaiting()}
            isWaitingCompleted={this.isWaitingCompleted()}
            onAccept={() => this.onAccept()}
            onCancel={() => this.onCancel()}
            showSuccessMessageAndClose={this.showSuccessMessageAndClose.bind(this)}
            close={this.close.bind(this)}
            onSubmit={this.props.modalDialog.onSubmit}
            onSubmitSuccess={this.props.modalDialog.onSubmitSuccess}
            setModalSubmitting={this.setModalSubmitting.bind(this)}
        />
    }
}

ModalDialogContainer.propTypes = {
    modalDialog: PropTypes.object,
    dispatch: PropTypes.func
}

const mapStateToProps = store => ({
    modalDialog: store.modalDialog
})

export default connect(mapStateToProps)(ModalDialogContainer)
