import React from 'react'
import AcceptInvitationForNewUserForm from '../../components/AcceptInvitationForNewUserForm'
import SplashScreenModalDialog from '../../components/GUI/SplashScreenModalDialog'
import * as PropTypes from 'prop-types'
import queryString from 'query-string'
import { DEFAULT_REGION } from '../../domain/region'

const AcceptInvitationForNewUser = (props) => {
    const code = props.match.params.code
    const region = queryString.parse(props.location.search).region || DEFAULT_REGION
    return <SplashScreenModalDialog body={<AcceptInvitationForNewUserForm code={code} region={region}/>}/>
}

AcceptInvitationForNewUser.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object
}

export default AcceptInvitationForNewUser
