import React from 'react'
import {Redirect} from 'react-router-dom'
import * as PropTypes from 'prop-types'
import queryString from 'query-string'

class RedirectTo extends React.Component {

    render () {
        const queryParams = queryString.parse(this.props.location.search)
        const state = {};
        const redirectState = {pathname: queryParams.target, state}

        if (queryParams.messageKey) {
            state.messageKey = queryParams.messageKey
        }
        if (queryParams.tab) {
            state.tab = queryParams.tab
        }

        return <Redirect to={redirectState}/>
    }
}

RedirectTo.propTypes = {
    location: PropTypes.object
}

export default RedirectTo
