import React from 'react'
import * as PropTypes from 'prop-types'
import lang from '../../utils/lang'
import LoadingIndicator from './LoadingIndicator'
import { Formik } from 'formik'
import { parseBackendErrors } from '../../utils/backendErrors'

const ModalDialog = props => {
    const { closed, visible, settings, title, message, submitEnabled, onSubmit, onSubmitSuccess, showSuccessMessageAndClose, onAccept, setModalSubmitting, onCancel } = props
    const formik = settings.formik || {}

    if (onSubmit) {
        formik.onSubmit = async (values, { setSubmitting, setErrors, setStatus }) => {
            setSubmitting(true)
            setModalSubmitting(true)
            try {
                const onSubmitResponse = await onSubmit(values)
                setStatus('submitted')
                setSubmitting(false)
                onSubmitSuccess && onSubmitSuccess(values, onSubmitResponse)
                showSuccessMessageAndClose(settings.doneIcon)
            } catch (error) {
                if (error.request && error.request.status === 0) {
                    setErrors({ server: 'Network Error' })
                    setStatus(null)
                } else {
                    const errorMessages = error.messages || error.response.data.messages
                    const backendErrors = parseBackendErrors(errorMessages)
                    if (Object.keys(backendErrors).length > 0) {
                        setErrors(backendErrors)
                    } else {
                        setErrors({ server: errorMessages })
                    }
                    setStatus(null)
                }
            } finally {
                setSubmitting(false)
                setModalSubmitting(false)
            }
        }
    }

    const renderWaiting = () => {
        return <div className={`waiting-indicator ${props.isWaitingCompleted && 'done'}`}>
            <div className="done-indicator">
                <div className="stamp">
                    <div className="stroke"></div>
                    <div className={`icon icon-${props.doneIcon}`}></div>
                </div>
                <div className="message">{ props.doneMessage }</div>
            </div>
            <LoadingIndicator />
        </div>
    }

    const handleOnCancel = () => {
        if (onCancel) onCancel()
    }

    const handleOnAccept = async (e, usingFormik, { setErrors, setStatus, setSubmitting }) => {
        if (!usingFormik && onAccept) {
            e.preventDefault()
            await onAccept()
            showSuccessMessageAndClose()
        }
    }

    const renderActionButtons = (formikProps) => {
        const { settings } = props
        const { values } = formikProps
        const formik = settings.formik || {}
        const usingFormik = Boolean(formik.render)

        if (settings.prompt !== false) {
            return (
                <>
                    <button type="button" className="action" onClick={() => handleOnCancel()}>
                        {settings.cancelCaption || lang.d('cancel')}
                    </button>
                    <button
                        type={usingFormik ? 'submit' : 'button'}
                        className={`action highlighted ${settings.dangerousAction && 'danger'}`}
                        onClick={e => handleOnAccept(e, usingFormik, formikProps)}
                        disabled={!submitEnabled}
                    >
                        {settings.acceptCaption || lang.d('accept')}
                    </button>
                </>
            )
        } else {
            return (
                <button
                    type="button"
                    className="action highlighted"
                    onClick={e => handleOnCancel()}
                >
                    { lang.d('close') }
                </button>
            )
        }
    }

    const renderContents = (formikProps = {}) => {
        const formik = settings.formik || {}
        const usingFormik = Boolean(formik.render)

        return <form onSubmit={formikProps.handleSubmit}>
            { title && <div className="title">{ title }</div> }
            { message && <div className="message">{ message }</div> }
            { formik.render && formik.render(formikProps) }

            { formikProps.errors.server &&
                <div className="errors">
                    <div className="error">{formikProps.errors.server}</div>
                </div>
            }

            <div className="actions">
                { props.waiting || (usingFormik && (formikProps.isSubmitting || formikProps.status === 'submitted'))
                    ? renderWaiting()
                    : renderActionButtons(formikProps)
                }
            </div>
        </form>
    }

    return <div className={`ModalDialog ${closed && 'closed'}`}>
        { visible && <>
            <div className="backdrop" onClick={handleOnCancel}></div>
            { settings.component
                ? settings.component
                : <div className={`dialog ${settings.dialogClassName && settings.dialogClassName}`}>
                    <Formik
                        {...formik}
                        render={renderContents.bind(this)}
                    />
                </div>
            }
        </> }
    </div>
}

ModalDialog.propTypes = {
    doneIcon: PropTypes.string,
    closed: PropTypes.bool,
    waiting: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.node,
    settings: PropTypes.object,
    doneMessage: PropTypes.string,
    visible: PropTypes.bool,
    children: PropTypes.node,
    isWaitingCompleted: PropTypes.bool,
    onCancel: PropTypes.func,
    onAccept: PropTypes.func,
    submitEnabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    onSubmitSuccess: PropTypes.func,
    showSuccessMessageAndClose: PropTypes.func,
    setModalSubmitting: PropTypes.func
}

export default ModalDialog
