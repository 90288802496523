import React from 'react'
import { SeatsioEventManager } from '@seatsio/seatsio-react'
import lang from '../../utils/lang'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bumpIntercomPosition, restoreIntercomPosition } from '../../actions/intercom'
import { chartJSUrlOfActiveCompany } from '../../domain/regions'

class ManageCategories extends React.PureComponent {
    componentDidMount () {
        this.props.bumpIntercomPosition()
    }

    componentWillUnmount () {
        this.props.restoreIntercomPosition()
    }

    render () {
        return <>
            <div className="hint">{lang.d('categories_manager_hint')}</div>
            <div className="event-manager">
                <SeatsioEventManager
                    region="eu"
                    secretKey={this.props.secretKey}
                    event={this.props.match.params.eventKey}
                    mode="manageCategories"
                    chartJsUrl={chartJSUrlOfActiveCompany()}
                    objectTooltip={{
                        showTechnicalLabel: true
                    }}
                    // @ts-expect-error
                    workspaceKey={this.props.workspaceKey}
                />
            </div>
        </>
    }

}

ManageCategories.propTypes = {
    match: PropTypes.object.isRequired,
    secretKey: PropTypes.string.isRequired,
    workspaceKey: PropTypes.string.isRequired,
    bumpIntercomPosition: PropTypes.func.isRequired,
    restoreIntercomPosition: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    secretKey: state.user.secretKey,
    workspaceKey: state.context.workspace.key
})

const mapDispatchToProps = dispatch => ({
    bumpIntercomPosition: () => dispatch(bumpIntercomPosition()),
    restoreIntercomPosition: () => dispatch(restoreIntercomPosition())
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageCategories)
