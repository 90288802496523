import React, { useEffect, useState } from 'react'
import ChartList from '../components/Charts/ChartList'
import { chartService } from '../services/chart.service'
import DataLoader from '../components/DataLoader'
import * as PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { setHeader } from '../actions/header'
import { setSearchTags } from '../actions/charts'

const Home = () => {
    const dispatch = useDispatch()
    const [ready, setReady] = useState(false)
    useEffect(() => {
        async function init () {
            dispatch(setHeader({
                showSearch: true,
                leftItems: [{
                    to: '/create-chart',
                    icon: 'icon-new-floor-plan',
                    string_key: 'create_chart'
                },
                {
                    to: '/archive',
                    icon: 'icon-archive',
                    string_key: 'view_archive'
                }]
            }))

            const tags = await chartService.listAllTags()
            await dispatch(setSearchTags(tags))
            setReady(true)
        }
        init()
    }, [dispatch])

    if (!ready) return null

    return (
        <DataLoader
            name="charts"
            getFirstPage={chartService.getChartsInFirstPage}
            getPageAfter={chartService.getChartsAfter}
        >
            {({
                state, add, remove, isListEmpty,
                name, bulkAdd, disableFetchMore
            }) => (
                <>
                    <ChartList
                        add={add}
                        remove={remove}
                        state={state}
                        name={name}
                        bulkAdd={bulkAdd}
                        disableFetchMore={disableFetchMore}
                        getFirstPage={chartService.getChartsInFirstPage}
                        getPageAfter={chartService.getChartsAfter}
                        archive={chartService.archiveChart}
                        copy={chartService.copyChart}
                        isListEmpty={isListEmpty}
                    />
                </>
            )}
        </DataLoader>
    )
}

Home.propTypes = {
    location: PropTypes.object,
    charts: PropTypes.object,
    setSearchTags: PropTypes.func,
    setHeader: PropTypes.func
}

export default Home
