/* eslint-disable react/prop-types */
import { Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { adminService, findChartInRegions } from '../../services/admin.service'
import { persistencyService } from '../../services/persistency.service'
import { history } from '../../store'
import { parseBackendErrors } from '../../utils/backendErrors'
import lang from '../../utils/lang'
import LoadingIndicator from '../GUI/LoadingIndicator'
import Input from '../Input'

class LogInAsCompanyThroughChartKeyForm extends React.Component {

    renderWaiting () {
        const { status, setStatus } = this.props

        if (status === 'submitted') {
            setTimeout(() => {
                setStatus(null)
            }, 2000)
        }

        return (
            <div className={`waiting-indicator ${status === 'submitted' && 'done'}`}>
                <LoadingIndicator/>
            </div>
        )
    }

    async logInAs(company, workspace) {
        const pathname = window.location.pathname
        const fullCompany = await adminService.getCompany(company.id, company.region)
        this.props.dispatch({
            type: 'SWITCH_TO_WORKSPACE_OF_COMPANY',
            company: fullCompany,
            workspace
        })
        persistencyService.remove('charts-scroll')
        this.props.dispatch({
            type: 'SET_EXIT_PATH',
            pathname
        })
        history.push('/')
    }

    render () {
        const {
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            values,
            status
        } = this.props

        return (
            <>
                <form name="findChartForm" onSubmit={handleSubmit} className="AdminForm">
                    <div className="form-field">
                        <label htmlFor="find_chartKey">{ lang.d('chart_key') }</label>
                        <Input
                            id="find_chartKey"
                            name="chartKey"
                            type="text"
                            value={values.chartKey}
                            onChange={handleChange}
                            className={errors.chartKey && touched.chartKey && 'error'}
                            autoFocus
                        />
                    </div>
                    { Object.keys(errors).length > 0 && <div className="errors">
                        { errors.chartKey && <div className="error">{errors.chartKey}</div> }
                        { errors.global && <div className="error">{errors.global}</div> }
                    </div> }
                    { isSubmitting || status === 'submitted'
                        ? this.renderWaiting()
                        : <input type="submit" value={lang.d('find_chart')} className="FormButton"/>
                    }
                </form>
                { values.chartSummary &&
                    <div className="DataTable">
                        <table>
                            {this.renderHeader()}
                            {this.renderBody(values.chartSummary)}
                        </table>
                    </div>
                }
            </>
        )
    }

    renderHeader () {
        return <thead>
            <tr>
                <th>Company</th>
                <th>Workspace</th>
                <th>Chart</th>
            </tr>
        </thead>
    }

    renderBody (chartSummary) {
        return <tbody>
            <tr>
                <td className="action-cell">
                    <Link to={`/admin/company/${chartSummary.companyRegion}/${chartSummary.company.id}`}><span>{chartSummary.company.name || lang.d('anonymous')}</span></Link>
                </td>
                <td>{chartSummary.workspace.key}</td>
                <td>{chartSummary.chartKey}</td>
                <td>
                    <button onClick={() => this.logInAs(chartSummary.company, chartSummary.workspace)}>
                        <span className="icon icon-login" />
                        <span className="caption">{ lang.d('login_as_company') }</span>
                    </button>
                </td>
            </tr>
        </tbody>
    }
}

const ConnectedLogInAsCompanyThroughChartKeyForm = connect()(LogInAsCompanyThroughChartKeyForm)

const LogInAsCompanyThroughChartKey = () => {
    return (
        <Formik
            initialValues={{ chartKey: '' }}
            onSubmit={(values, { setErrors, setStatus }) => {
                values.chartSummary = undefined
                return findChartInRegions(values.chartKey)
                    .then(chartSummary => {
                        values.chartSummary = new ChartSummary(chartSummary)
                        setStatus(null)
                    })
                    .catch(err => {
                        const { messages } = err.errors
                        setErrors(parseBackendErrors(messages))
                        setStatus(null)
                    })
            }}
            render={props => <ConnectedLogInAsCompanyThroughChartKeyForm {...props}  />}
        />
    )
}

class ChartSummary {
    constructor (data) {
        this.chartKey = data.key
        this.companyRegion = data.region
        this.workspace = data.workspace
        this.company = data.company
        this.company.defaultWorkspace = this.workspace
        this.company.region = this.companyRegion
    }
}

export default LogInAsCompanyThroughChartKey
