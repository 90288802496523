import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import lang from '../utils/lang'
import * as PropTypes from 'prop-types'

const HeaderItems = ({ header }) => {
    function getHeaderItems (items) {
        return items.map(item => {
            if (item.button) {
                return (
                    <button id={item.id} className={`button ${item.highlighted ? 'highlighted' : undefined}`} onClick={item.onClick} key={items.indexOf(item)}>
                        <span className={`icon ${item.icon}`}/>
                        <span className="caption">{item.label ? item.label : lang.d(item.string_key)}</span>
                    </button>
                )
            }

            return (
                <Link to={item.to} className={`${item.className || 'button'} ${item.highlighted ? 'highlighted' : undefined}`} key={items.indexOf(item)}>
                    <span className={`icon ${item.icon}`}/>
                    <span className="caption">{item.label ? item.label : lang.d(item.string_key)}</span>
                </Link>
            )
        })
    }

    return <>
        <div>
            {header.leftItems.length > 0 && getHeaderItems(header.leftItems)}
        </div>
        <div>
            {header.centerItems.length > 0 && getHeaderItems(header.centerItems)}
        </div>
    </>
}

HeaderItems.propTypes = {
    header: PropTypes.object
}

const mapStateToProps = (state) => ({
    header: state.header
})

export default connect(mapStateToProps)(HeaderItems)
