import React from 'react'
import { useDispatch } from 'react-redux'
import SplashScreenModalDialog from './SplashScreenModalDialog'
import GeneralErrorModalDialogBody from './GeneralErrorModalDialogBody'
import Intercom from '../Intercom'
import { hideHeader } from '../../actions/header'

const SplashScreenGeneralErrorModalDialog = () => {
    const dispatch = useDispatch()
    dispatch(hideHeader())

    return <>
        <SplashScreenModalDialog
            body={
                <div className="dialog">
                    <GeneralErrorModalDialogBody/>
                </div>
            }
        />
        <Intercom />
    </>
}

export default SplashScreenGeneralErrorModalDialog
