import React from 'react'
import * as PropTypes from 'prop-types'

export default function Badge ({ type, icon, title, dark, children }) {
    return <span className={`Badge ${type} ${icon && 'with-icon'} ${dark && 'dark'}`} title={title}>
        { icon && <span className={`icon icon-${icon}`}></span> }
        <span className="label">{ children }</span>
    </span>
}

Badge.propTypes = {
    type: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    dark: PropTypes.bool,
    children: PropTypes.string
}
