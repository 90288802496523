import React from 'react'
import * as PropTypes from 'prop-types'

export const SelectEventsForm = function ({ events, values, handleChange }) {
    return <div className="form-field checkbox-list">
        { events.map(event => <label htmlFor={event.key} className={`field ${values[event.key] && 'selected'}`} key={event.key}>
            <input id={event.key} name={event.key} type="checkbox" checked={values[event.key]} onChange={handleChange} />
            <div className="caption">{ event.name ? event.name : event.key }</div>
        </label>) }
    </div>
}

SelectEventsForm.propTypes = {
    handleChange: PropTypes.func,
    values: PropTypes.object,
    events: PropTypes.arrayOf(PropTypes.object)
}
