import axios from 'axios'

export function setupInterceptors (history, store) {
    axios.interceptors.response.use(null, function (error) {
        if (error.status === 401) {
            store.dispatch({ type: 'LOGOUT' })
            history.push('/login')
        }

        return Promise.reject(error)
    })
    return axios
}
