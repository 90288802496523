import { getClient } from './base.service'

export const usersService = {
    inviteUser,
    getAllCompanyUsers,
    activateUser,
    deactivateUser
}

function inviteUser (email, role, workspaces) {
    return getClient().users.invite(email, role, workspaces)
}

function getAllCompanyUsers () {
    return getClient().users.listAll()
}

function activateUser (userId) {
    return getClient().users.activate(userId)
}

function deactivateUser (userId) {
    return getClient().users.deactivate(userId)
}
