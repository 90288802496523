import { StatusChangesParams } from 'seatsio'
import orderBy from 'lodash/orderBy'
import lang from './lang'
import React from 'react'
import Button from '../components/GUI/Button'

export const cloneStatusChangesParameter = param => {
    const newParam = new StatusChangesParams(param.filter, param.filterType)
    switch (param.sort) {
    case 'date':
        newParam.sortByDate()
        break
    case 'status':
        newParam.sortByStatus()
        break
    case 'objectLabel':
        newParam.sortByObjectLabel()
        break
    default:
        break
    }

    if (param.sortDirection === 'asc') {
        newParam.sortAscending()
    } else if (param.sortDirection === 'desc') {
        newParam.sortAscending()
    }

    return newParam
}

export const merge = (old, updated) => {
    const o = {}

    old.forEach(v => {
        o[v.id] = v
    })

    updated.forEach(v => {
        o[v.id] = v
    })

    const r = []

    for (const p in o) {
        // eslint-disable-next-line no-prototype-builtins
        if (o.hasOwnProperty(p)) {
            r.push(o[p])
        }
    }

    return orderBy(r, 'id', 'desc')
}

export const diffArrays = (a1, a2) => {
    return a1.filter(f => !a2.includes(f))
}

export const cloneObject = obj => JSON.parse(JSON.stringify(obj))

export const wrapAround = (value, min, max) => {
    return value < min
        ? max - (min - value) % (max - min)
        : min + (value - min) % (max - min)
}

export const generateChartValidationListForContextualMenu = (validation) => {
    const errorList = validation.errors.map(error => {
        return {
            type: 'validation-item',
            caption: error,
            validationType: 'error',
            icon: 'icon-validation-error'
        }
    })
    const warningList = validation.warnings.map(warning => {
        return {
            type: 'validation-item',
            caption: warning,
            validationType: 'warning',
            icon: 'icon-validation-warning'
        }
    })
    return warningList.concat(errorList)
}

export const generateChartValidationList = (validation) => {
    const errorList = validation.errors.map(error => <div key={error} className="validation-item error">
        <span className="icon icon-validation-error"/>
        <span className="caption">{lang.d(error)}</span>
    </div>)
    const warningList = validation.warnings.map(warning => <div key={warning} className="validation-item warning">
        <span className="icon icon-validation-warning"/>
        <span className="caption">{lang.d(warning)}</span>
    </div>)
    return warningList.concat(errorList)
}

export const formatNumber = number => new Intl.NumberFormat('en').format(number)

export const formatWorkspaceName = workspace => workspace.name || `${lang.d('untitled')} ${workspace.key.slice(0, 5)}`

export const mapOptionsToButtons = options => {
    return options.map(option =>
        <Button
            preset={option.preset}
            type={option.type}
            caption={option.caption}
            onMouseUp={option.onMouseUp}
            key={option.key}
            name={option.type}
        />
    )
}

export const sameMonth = (month1, month2) => {
    return month1.month === month2.month && month1.year === month2.year
}
