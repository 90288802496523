import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Environment } from '../../environment'

class PreviewLink extends React.Component {

    render () {
        const link = this.createLink()
        return <>
            <div className="content fixed-width">{link}</div>
            <a href={link} className="link external-link" target="_blank" rel="noopener noreferrer"></a>
        </>
    }

    createLink () {
        return window.location.origin + '/preview/' + this.props.regionId + '/' + this.props.workspaceKey + '/' + this.props.chartKey
    }

}

PreviewLink.propTypes = {
    regionId: PropTypes.string,
    workspaceKey: PropTypes.string,
    chartKey: PropTypes.string
}

const mapStateToProps = (state) => ({
    regionId: state.context.company.region,
    workspaceKey: state.context.workspace.key
})

export default connect(mapStateToProps, null)(PreviewLink)
