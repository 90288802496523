import React, { PureComponent } from 'react'
import lang from '../../utils/lang'
import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import * as PropTypes from 'prop-types'
import { formatNumber, sameMonth } from '../../utils/helperFunctions'
import { formatDistance } from 'date-fns'

class RotatedAxisTick extends PureComponent {
    render () {
        const {
            x, y, payload
        } = this.props

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={0} textAnchor="end" fill="#666" fontSize="10" transform="rotate(280)">{payload.value}</text>
            </g>
        )
    }
}

RotatedAxisTick.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    payload: PropTypes.object
}

class MonthlyUsageReportChart extends React.Component {
    render () {
        const { data, barDataKey, barName, cutoffDate } = this.props
        return <>
            <div className="title">
                { lang.d('monthly_usage') }
                { cutoffDate && <div className="soft">Report generated {formatDistance(new Date(), cutoffDate)} ago.</div> }
            </div>
            <ResponsiveContainer minHeight={300}>
                <BarChart
                    height={300}
                    data={this.props.data}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                    barSize={30}
                >
                    <CartesianGrid strokeDasharray="1 1" vertical={false}/>
                    <XAxis
                        dataKey="month.label"
                        minTickGap={1}
                        tick={this.props.rotatedXAxis
                            ? <RotatedAxisTick />
                            : {
                                fill: '#666',
                                fontSize: 10
                            }}
                        tickLine={false}
                        axisLine={{
                            strokeWidth: 2,
                            stroke: '#bbb'
                        }}
                        interval={0}
                    />
                    <YAxis
                        tick={{
                            fill: '#999',
                            fontSize: 14
                        }}
                        tickLine={false}
                        axisLine={{
                            stroke: '#eee'
                        }}
                    />
                    <Tooltip
                        formatter={formatNumber}
                        cursor={{ fill: 'hsla(70, 40%, 50%, 0.25)' }}
                        animationDuration={0}
                        labelStyle={{
                            fontSize: 14,
                            border: 'none',
                            background: 'transparent',
                            fontWeight: 'bold',
                            color: '#333'
                        }}
                        contentStyle={{ fontSize: 13, border: 'none', background: 'transparent' }}
                        wrapperStyle={{
                            padding: '3px 7px 0px 7px',
                            borderRadius: '6px',
                            background: 'white',
                            boxShadow: '0 2px 12px rgba(0, 0, 0, .15)'
                        }}
                    />
                    <Legend
                        iconType="circle"
                        wrapperStyle={{
                            paddingTop: 15,
                            fontSize: 12,
                            color: '#666'
                        }}
                    />
                    <Bar
                        dataKey={barDataKey}
                        fill="#AFCA54"
                        name={barName}
                        onClick={this.props.onClick}
                        minPointSize={2}
                    >
                        {
                            data.map((entry, index) => {
                                const isActive = this.props.activeMonth && sameMonth(entry.month, this.props.activeMonth)
                                let fillColor = isActive ? '#80A71A' : '#AFCA54'
                                if (!entry[this.props.barDataKey]) {
                                    fillColor = 'transparent'
                                }
                                return <Cell cursor="pointer"
                                    fill={fillColor}
                                    key={`cell-${index}`}
                                />
                            })
                        }
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </>
    }
}

MonthlyUsageReportChart.propTypes = {
    barName: PropTypes.string,
    barDataKey: PropTypes.string,
    onClick: PropTypes.func,
    data: PropTypes.array,
    activeMonth: PropTypes.object,
    rotatedXAxis: PropTypes.bool,
    cutoffDate: PropTypes.object
}

export default MonthlyUsageReportChart
