import { useEffect } from 'react'
import { persistencyService } from '../../services/persistency.service'
import { history } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { switchToDefaultCompanyAndWorkspace } from '../../actions/user'

const SwitchToDefaultCompanyAndWorkspace = () => {
    const dispatch = useDispatch()
    const exitPath = useSelector(state => state.context.exitPath)

    useEffect(() => {
        dispatch(switchToDefaultCompanyAndWorkspace(exitPath ? exitPath : '/'))
        persistencyService.remove('charts-scroll')
        exitPath ? history.push(exitPath) : history.push('/')
    }, [dispatch, exitPath])

    return null
}

export default SwitchToDefaultCompanyAndWorkspace
