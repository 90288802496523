import { eventsService } from '../services/events.service'
import lang from '../utils/lang'
import { validateEventKey, validateEventName } from '../utils/validators'
import Input from '../components/Input'
import React from 'react'
import * as PropTypes from 'prop-types'
import { closeDialog, openModalDialog } from './modalDialog'
import { hasErrorCode, showFailedToContactServerError } from '../utils/errors'
import { history } from '../store'
import { MODAL_DIALOG_CLEAR_TIMEOUT } from '../constants/modalDialog'
import { GlobalError } from '../components/GUI/ModalDialogGlobalError'

const EditEventKeyForm = props => {
    const {
        errors,
        touched,
        handleChange,
        values,
        isSubmitting,
        dirty
    } = props

    const invalidKeyError = errors.newEventKeyError && touched.eventKey

    return <>
        <div className="form">
            <div className="form-field">
                <Input name="eventKey" type="text" value={values.eventKey} onChange={handleChange} disabled={(isSubmitting && dirty) || props.status === 'submitted'} autoFocus/>
            </div>
            {invalidKeyError && <div className="errors">
                {invalidKeyError && <div className="error">{errors.newEventKeyError}</div>}
            </div>}
        </div>
        <GlobalError errors={errors} />
    </>
}

EditEventKeyForm.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    isSubmitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    dirty: PropTypes.bool,
    status: PropTypes.string,
    values: PropTypes.object
}

const EditEventNameForm = props => {
    const {
        errors,
        touched,
        handleChange,
        values,
        isSubmitting,
        dirty
    } = props

    const invalidNameWarning = errors.newEventNameError && touched.eventName

    return <>
        <div className="form">
            <div className="form-field">
                <Input
                    name="eventName"
                    type="text"
                    placeholder={lang.d('no_name')}
                    value={values.eventName}
                    onChange={handleChange}
                    disabled={(isSubmitting && dirty) || props.status === 'submitted'}
                    autoFocus
                />
            </div>
            { invalidNameWarning && <div className="errors">
                { invalidNameWarning && <div className="error">{errors.newEventNameError}</div> }
            </div> }
        </div>
        <GlobalError errors={errors} />
    </>
}

EditEventNameForm.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    isSubmitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    dirty: PropTypes.bool,
    status: PropTypes.string,
    values: PropTypes.object
}

export const addEvent = payload => ({
    type: 'CHARTS/ADD_EVENT',
    payload
})

export const updateEvent = (chartKey, oldKey, newKey) => ({
    type: 'CHARTS/UPDATE_EVENT',
    payload: { chartKey, oldKey, newKey }
})

export const setNewestAddedEventKey = (newestAddedEvent) => ({
    type: 'CHARTS/SET_NEWEST_ADDED_EVENT',
    payload: { newestAddedEvent }
})

var newestAddedEventTimeout = -1

export const playNewestAddedEventKey = (dispatch, newestAddedEvent) => {
    dispatch(setNewestAddedEventKey(newestAddedEvent))
    clearTimeout(newestAddedEventTimeout)
    newestAddedEventTimeout = setTimeout(() => dispatch(setNewestAddedEventKey(null)), 1500)
}

export const createEvent = (chartKey) => {
    return async function (dispatch) {
        return doCreateEvent(dispatch, chartKey)
    }
}

const doCreateEvent = async (dispatch, chartKey) => {
    try {
        const resp = await eventsService.create(chartKey)
        return dispatch(addEvent({ key: resp.key, chartKey: chartKey }))
    } catch (e) {
        if (hasErrorCode(e, 'DRAWING_VALIDATION_FAILED')) {
            dispatch(openModalDialog({
                title: lang.d('invalid_chart'),
                message: lang.d('create_event_validation_errors'),
                settings: {
                    acceptCaption: lang.d('fix_in_designer'),
                    onAccept: () => {
                        dispatch(closeDialog())
                        setTimeout(() => {
                            history.push(`/charts/${chartKey}/edit`, { fromSeatsio: true })
                        }, MODAL_DIALOG_CLEAR_TIMEOUT)
                    },
                    waitOnAccept: true
                }
            }))
        } else {
            showFailedToContactServerError()
            throw e
        }
    }
}

export const updateEventKey = (chartKey, currentKey, refresh = null) => {
    return async function (dispatch) {
        dispatch(openModalDialog({
            title: lang.d('edit_event_key'),
            message: lang.d('edit_event_key_message'),
            successMessage: lang.d('eventKey_updated'),
            onSubmit: (values) => {
                const newEventKey = values.eventKey.trim()
                if (currentKey === newEventKey) {
                    dispatch(closeDialog())
                } else {
                    return eventsService.updateEventKey(currentKey, newEventKey)
                }
            },
            onSubmitSuccess: values => {
                const newEventKey = values.eventKey.trim()
                dispatch(updateEvent(chartKey, currentKey, newEventKey))
                if (refresh) {
                    refresh()
                }
            },
            settings: {
                acceptCaption: lang.d('change_key'),
                formik: {
                    initialValues: {
                        eventKey: currentKey
                    },
                    validate: values => {
                        const newEventKey = values.eventKey.trim()
                        return validateEventKey(newEventKey)
                    },
                    render: EditEventKeyForm
                }
            }
        }))
    }
}

export const updateEventName = (eventKey, currentName, isSeason, onSubmitSuccess) => {
    return async function (dispatch) {
        dispatch(openModalDialog({
            title: lang.d(isSeason ? 'edit_season_name' : 'edit_event_name'),
            successMessage: lang.d(isSeason ? 'season_name_updated' : 'event_name_updated'),
            onSubmit: (values) => {
                const newEventName = values.eventName.trim()
                if (currentName === newEventName) {
                    dispatch(closeDialog())
                } else {
                    return eventsService.updateEventName(eventKey, newEventName)
                }
            },
            onSubmitSuccess: values => {
                if (onSubmitSuccess) {
                    onSubmitSuccess(values.eventName.trim())
                }
            },
            settings: {
                acceptCaption: lang.d(currentName ? 'change_name' : 'add_name'),
                formik: {
                    initialValues: {
                        eventName: currentName
                    },
                    validate: values => {
                        const newEventName = values.eventName
                        return validateEventName(newEventName)
                    },
                    render: EditEventNameForm
                }
            }
        }))
    }
}
