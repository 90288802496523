import React from 'react'
import * as PropTypes from 'prop-types'

class Accordion extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            expanded: false
        }
    }

    toggle () {
        this.setState({ expanded: !this.state.expanded })
    }

    render () {
        return <div className="Accordion">
            <div className={`title ${this.state.expanded && 'expanded'}`} onClick={() => this.toggle()}>
                {this.props.title}
                <span className={`icon icon-arrow-medium-down ${this.state.expanded && 'flipped'}`}/>
            </div>
            <div className={`container ${this.state.expanded && 'expanded'}`}>{this.props.children}</div>
        </div>
    }
}

Accordion.propTypes = {
    children: PropTypes.node,
    title: PropTypes.node
}

export default Accordion
