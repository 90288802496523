import store from '../store'

let regions

export const getRegions = () => regions

export const setRegions = newRegions => regions = newRegions

export const backendUrlOfActiveCompany = () => {
    return regionOfActiveCompany().url
}

export const chartJSUrlOfActiveCompany = () => {
    return chartJSUrlOfRegion(regionOfActiveCompany())
}

export const chartJSUrlByRegionId = (regionId) => {
    return chartJSUrlOfRegion(getRegionById(regionId))
}

const chartJSUrlOfRegion = (region) => {
    return region.cdnUrl + '/chart.js'
}

export const getRegionById = regionId => regions.find(region => region.id === regionId)

export function regionOfActiveCompany () {
    const activeCompany = store.getState().context.company
    return getRegionById(activeCompany.region)
}
