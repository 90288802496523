import React from 'react'
import { userService } from '../services/user.service'
import { history } from '../store'
import Input from '../components/Input'
import { Link } from 'react-router-dom'
import lang from '../utils/lang'
import LoadingIndicator from './GUI/LoadingIndicator'
import { Formik } from 'formik'
import { validateLogin } from '../utils/validators'
import * as PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { setUser } from '../actions/user'

const Form = (props) => {
    const {
        errors,
        touched,
        isSubmitting,
        status,
        handleChange,
        handleSubmit,
        values
    } = props

    return <>
        <form name="loginForm" onSubmit={handleSubmit} className="AccountForm dialog with-quicklinks">
            <div className="title">{lang.d('login')}</div>
            <div className="form">
                <div className="form-field">
                    <Input
                        name="email"
                        type="text"
                        value={values.email}
                        placeholder={lang.d('email')}
                        onChange={handleChange}
                        className={errors.email && touched.email && 'error'}
                        autoFocus
                    />
                </div>
                <div className="form-field">
                    <Input
                        name="password"
                        type="password"
                        value={values.password}
                        placeholder={lang.d('password')}
                        onChange={handleChange}
                        className={errors.password && touched.password && 'error'}
                    />
                </div>
                {errors && Object.keys(errors).length > 0 && <div className="errors">
                    {errors.email && touched.email && <div className="error">{errors.email}</div>}
                    {errors.fromServer && <div className="error">{errors.fromServer}</div>}
                </div>}
            </div>
            <div className="actions">
                {isSubmitting || status === 'submitted'
                    ? <LoadingIndicator/>
                    : <input type="submit" value={lang.d('login')} disabled={isSubmitting || status === 'submitted'}
                        className="action highlighted"/>
                }
            </div>
        </form>
        <div className="quick-links">
            <Link to="/signup" className="arrow quick-link">{lang.d('signup')}</Link>
            <Link to="/reset-password" className="arrow quick-link">{lang.d('reset_password')}</Link>
        </div>
    </>
}

Form.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    isSubmitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    status: PropTypes.object,
    values: PropTypes.object
}

const LoginForm = props => {
    const dispatch = useDispatch()
    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validateOnChange={false}
            validate={(values) => validateLogin(values)}
            onSubmit={async (values, { setErrors, setStatus }) => {
                setStatus('submitted')
                try {
                    const user = await userService.login(values.email, values.password)
                    dispatch(setUser(user))
                    history.push('/')
                } catch (err) {
                    setStatus('failed')
                    setErrors(errors(err))
                    console.error(err)
                }
            }}
            render={Form}
        />
    )
}

const errors = err => {
    const errors = {}
    if (err.status === 403) {
        const reason = err.errors[0].data.reason
        errors.fromServer = reason === 'USER_UNCONFIRMED' ? lang.d('account_unconfirmed') : lang.d('account_inactive')
    } else if (err.errors) {
        const reason = err.errors[0].code
        if (reason === 'USER_NOT_FOUND') {
            errors.fromServer = lang.d('wrong_credentials')
        } else {
            errors.fromServer = lang.d('splash_screen_general_error_modal_dialog_message')
        }
    } else {
        errors.fromServer = lang.d('failed_server_connection')
    }
    return errors
}

export default LoginForm
