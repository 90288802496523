export const setWorkspacesList = (workspaces, nextId) => ({
    type: 'WORKSPACES/SET_WORKSPACES',
    payload: {
        list: workspaces,
        nextId
    }
})

export const updateWorkspacesList = (workspaces, nextId) => ({
    type: 'WORKSPACES/SET',
    payload: {
        list: workspaces,
        nextId
    }
})
