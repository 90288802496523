import styles from '../../styles/GUI/SteppedProgress.less'
import PropTypes from 'prop-types'
import lang from '../../utils/lang'

export const SteppedProgress = props => {
    const currentStep = props.steps.find(step => step.status === 'current') || null
    const stepNumber = props.steps.indexOf(currentStep) + 1
    const stepName = currentStep?.name

    const descriptionWithStep = (description) =>
        `${lang.d('stepXofY', { step: stepNumber, totalSteps: props.steps.length })}: ${stepName}`

    return (
        <div className={styles.progress}>
            <div className={styles.steps}>
                {props.steps.map((step, index) => (
                    <div className={styles.stepContainer} key={step.name}>
                        <div className={[styles.step, styles[step.status]].join(' ')}>
                            { step.status === 'current' && <span>{index + 1}</span> }
                            { step.status === 'complete' && <span className="icon icon-check-dense"></span> }
                        </div>
                        { index !== props.steps.length - 1 && <span className={styles.connector} /> }
                    </div>
                ))}
            </div>
            { stepName && <div className={styles.description}>{props.showStepInDesciption ? descriptionWithStep(stepName) : stepName}</div> }
        </div>
    )	
}

const stepType = PropTypes.exact({
    name: PropTypes.string,
    status: PropTypes.oneOf(['notStarted', 'current', 'complete'])
})

SteppedProgress.propTypes = {
    steps: PropTypes.arrayOf(stepType).isRequired,
    showStepInDesciption: PropTypes.bool
}
