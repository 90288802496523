import React from 'react'
import { notificationService } from '../../services/notification.service'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import lang from '../../utils/lang'
import * as PropTypes from 'prop-types'

export default class Notifications extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            notifications: notificationService.all()
        }
        notificationService.addListener(this.onNotifChange.bind(this))
    }

    onNotifChange (notifications) {
        this.setState({ notifications })
    }

    close (id) {
        notificationService.remove(id)
    }

    render () {
        return <TransitionGroup className="Notifications" enter={true} exit={true}>
            { this.state.notifications.map(notif => <CSSTransition
                key={notif.id}
                timeout={400}
                classNames="notification"
            >
                <Notification {...notif} onClose={() => this.close(notif.id)} />
            </CSSTransition>
            ) }
        </TransitionGroup>
    }
}

class Notification extends React.PureComponent {
    componentDidMount () {
        if (this.props.timeout) {
            this.timeout = setTimeout(() => {
                notificationService.remove(this.props.id)
            }, this.props.timeout)
        }
    }

    componentWillUnmount () {
        if (this.timeout) {
            clearTimeout(this.timeout)
        }
    }

    render () {
        return <div className={`Notification ${this.props.type}`} onClick={this.props.onClose}>
            <div className="icon"></div>
            <div className="description">
                { this.props.title && <div className="title">{ this.props.title }</div> }
                { this.props.message && <div className="message">{ this.props.message }</div> }
            </div>
            <div className="hint">{ lang.d('click_to_close') }</div>
            { this.props.timeout && <div className="progress-bar">
                <div className="bar"></div>
                <div className="progress" style={{ animationDuration: `${this.props.timeout - 100}ms` }}></div>
            </div> }
        </div>
    }
}

Notification.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    type: PropTypes.string,
    onClose: PropTypes.func,
    timeout: PropTypes.number,
    id: PropTypes.number
}
