import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'

class IfAuthorized extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            hasRequiredRole: false
        }
    }

    render () {
        if (this.props.role(this.props.user)) {
            return this.props.children
        } else {
            return <></>
        }
    }
}

IfAuthorized.propTypes = {
    children: PropTypes.node.isRequired,
    role: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(IfAuthorized)
