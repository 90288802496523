import React, { Component } from 'react'
import { adminService } from '../../services/admin.service'
import { setHeader } from '../../actions/header'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Accordion from '../../components/GUI/Accordion'
import { exposeErrors } from '../../utils/errors'
import { keyBy } from 'lodash'

class AdminRendererConfigKeysReport extends Component {
    constructor (props) {
        super(props)
        this.state = {
            report: null
        }
    }

    async componentDidMount () {
        this.props.setHeader({
            showSearch: false,
            hasItems: true,
            centerItems: [],
            rightItems: [],
            leftItems: [{
                to: '/admin',
                icon: 'icon-arrow-light-left',
                string_key: 'admin',
                className: 'soft-button'
            }]
        })
        await this.loadData()
    }

    async loadData () {
        const [report, companies] = await exposeErrors(Promise.all([adminService.getUsageReport(), adminService.getAllCompanies()]))
        const list = {}
        const usageForCurrentMonth = report.usage[report.usage.length - 1].usagesByCompany
        usageForCurrentMonth.forEach(company => {
            company.rendererConfigKeys.forEach(key => {
                if (list[key]) {
                    list[key].push(company)
                } else {
                    list[key] = [company]
                }
            })
        })
        const totalCompanies = usageForCurrentMonth.filter(company => company.numChartRenderings !== 0).length
        const companiesById = keyBy({ ...companies }, 'id')
        this.setState({ configList: list, totalCompanies, companiesById })
    }

    getCompanyListForKey (key) {
        return <div className={'companies-list'}>
            { this.state.configList[key].map(company => <div className={'company'} key={company.id}>{this.companyName(company.id)}</div>) }
        </div>
    }

    companyName (id) {
        const company = this.state.companiesById[id]
        if (company) {
            return company.name
        }
        return '<unknown company>' // should only happen when testing locally
    }

    getNumberOfCompanies (key) {
        return this.state.configList[key].length
    }

    render () {
        const accordions = []
        for (const key in this.state.configList) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.state.configList.hasOwnProperty(key)) {
                const title = <>
                    <span>{key}</span>
                    <span className="number-of-companies">
                        <span className="count">{this.getNumberOfCompanies(key)}</span>/{this.state.totalCompanies} companies
                    </span>
                </>
                accordions.push(<Accordion title={title} key={key}> {this.getCompanyListForKey(key)} </Accordion>)
            }
        }

        return <div className="ConfigurationKeys">
            <ul className={'accordion-container'}>
                {accordions}
            </ul>
        </div>
    }
}

AdminRendererConfigKeysReport.propTypes = {
    setHeader: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(null, mapDispatchToProps)(AdminRendererConfigKeysReport)
