import axios from 'axios'
import store from '../store'
import { getClient } from './base.service'
import { backendUrlOfActiveCompany } from '../domain/regions'
import { Account } from '../domain/account'

const getServiceClient = () => getClient().accounts

export const changePassword = password => getServiceClient().changePassword(password)

export const changeDraftsValue = value => {
    if (value === 'TRUE') {
        return getServiceClient().enableDraftChartDrawings()
    } else if (value === 'FALSE') {
        return getServiceClient().disableDraftChartDrawings()
    }
}

export const changeHoldPeriod = period => getServiceClient().changeHoldPeriod(period)

export const changeSetting = (key, value) => getServiceClient().updateSetting(key, value)

export const getAccount = () => {
    return getServiceClient().client.get(`${backendUrlOfActiveCompany()}/accounts/me`)
        .then(response => new Account(response.data))
}

export const editAccount = (info) => {
    return axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/accounts/me/actions/edit`,
        auth: { username: store.getState().user.secretKey },
        data: info,
        headers: { 'X-Company-ID': store.getState().context.company.id }
    })
}
