import React from 'react'
import { Link } from 'react-router-dom'
import lang from '../utils/lang'
import { Formik } from 'formik'
import Input from '../components/Input'
import LoadingIndicator from './GUI/LoadingIndicator'
import { userService } from '../services/user.service'
import { validateEmail } from '../utils/validators'
import * as PropTypes from 'prop-types'

const Form = (props) => {
    const {
        errors,
        touched,
        isSubmitting,
        status,
        handleChange,
        handleSubmit,
        values
    } = props

    return <>
        <form name="loginForm" onSubmit={handleSubmit} className="AccountForm dialog with-quicklinks">
            { status === 'submitted' && <div className="overlay-message">
                <div className="title">{ lang.d('instructions_sent') }</div>
                <div className="message">{ lang.d('reset_password_check_email') }</div>
            </div> }
            <div className="title">{ lang.d('reset_password') }</div>
            <div className="form">
                <div className="form-field">
                    <Input
                        name="email"
                        type="text"
                        value={values.email}
                        placeholder={lang.d('work_email')} onChange={handleChange}
                        className={errors.email && touched.email && 'error'}
                        autoFocus
                    />
                </div>
                { Object.keys(errors).length > 0 && <div className="errors">
                    { errors.email && touched.email && <div className="error">{errors.email}</div> }
                </div> }
            </div>
            <div className="actions">
                { isSubmitting || status === 'submitted'
                    ? <LoadingIndicator />
                    : <input type="submit" className="action highlighted" value={lang.d('reset_password')}/>
                }
            </div>
        </form>
        <div className="quick-links">
            <Link to="/login" className="arrow quick-link">{ lang.d('login') }</Link>
            <Link to="/signup" className="arrow quick-link">{ lang.d('signup') }</Link>
        </div>
    </>
}

Form.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    isSubmitting: PropTypes.func,
    handleSubmit: PropTypes.func,
    status: PropTypes.object,
    values: PropTypes.object
}

export default function ResetPasswordForm () {
    return (
        <Formik
            initialValues={{ email: '', currentPassword: '', newPassword: '', confirmNewPassword: '' }}
            validateOnChange={false}
            validate={values => {
                const errors = {}
                if (!values.email) {
                    errors.email = lang.d('required')
                } else if (!validateEmail(values.email)) {
                    errors.email = lang.d('invalid_email')
                }

                return errors
            }}
            onSubmit={(values, { setErrors, setStatus }) => {
                return userService.resetPassword(values.email)
                    .then(res => {
                        setStatus('submitted')
                    })
                    .catch(err => {
                        if ('messages' in err) {
                            setErrors({ email: err.messages[0] })
                        } else {
                            setErrors({ email: err.response.data.messages[0] })
                        }
                    })
            }}
            render={Form}
        />
    )
}
