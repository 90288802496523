import React, {Component} from "react";
import lang from "../../../utils/lang";
import NumericInput from "../../../components/GUI/NumericInput";
import OptionSwitcher from "../../../components/GUI/OptionSwitcher";
import * as PropTypes from "prop-types";
import {
    setCompanyAdminKey,
    setCompanyDefaultRendererSetting,
    setCompanySetting,
    setCompanyValidationSetting,
    updateCompanySettings
} from "../../../actions/user";
import {resetHeader} from "../../../actions/header";
import {closeDialog, openModalDialog} from "../../../actions/modalDialog";
import {connect} from "react-redux";
import {exposeErrors} from "../../../utils/errors";
import {changeHoldPeriod, changeSetting} from "../../../services/accounts.service";

class HoldsCompanySettings extends Component {

    constructor(props) {
        super(props)
        this.state = {
            updatingHoldPeriod: false,
            updatingGenerateShortHoldTokens: false,
            updatingAllowPublicHoldTokenCreation: true,
            updatingMaxHoldsPerToken: false
        }
    }

    render() {
        return <>
            <div className="setting-page-title">{ lang.d('hold-tokens') }</div>
            {this.renderHoldPeriod()}
            {this.renderShortHoldTokens()}
            {this.renderPublicHoldTokensEnabled()}
            {this.renderMaxHoldsPerToken()}
        </>
    }

    renderHoldPeriod() {
        return <div className="setting">
            <div className="notice">
                <div className="title">{lang.d('hint')}</div>
                <div className="description">
                    <p>{lang.d('hold_period_description')}</p>
                    <p><a className="arrow"
                        href="https://docs.seats.io/docs/renderer-config-session" target="_blank"
                        rel="noopener noreferrer">{lang.d('read_more')}</a></p>
                </div>
            </div>
            <div className="title">{lang.d('hold_period')}</div>
            <div className="text">{lang.d('hold_period_usage')}</div>
            <NumericInput
                value={this.props.setting.holdPeriodInMinutes}
                pixelsForStep={3}
                min={1}
                max={120}
                unit={lang.d('minutes_short')}
                disableSteppers={true}
                loading={this.state.updatingHoldPeriod}
                onChangeFinal={value => this.setHoldPeriod(value)}
                title={lang.d('hold_period_in_minutes')}
            />
        </div>
    }

    renderShortHoldTokens() {
        return <div className="setting">
            <div className="title">{lang.d('short_hold_tokens')}</div>
            <div className="text">When enabled, hold tokens are 10-character strings (e.g. <code>YDF34b67lh</code>)
                instead of UUIDs (e.g. <code>550e8400-e29b-41d4-a716-446655440000</code>). Short keys allow for more
                efficient storage.
            </div>
            <OptionSwitcher
                value={this.props.setting.generateShortHoldTokens.toString().toUpperCase()}
                options={{
                    TRUE: lang.d('enabled'),
                    FALSE: lang.d('disabled')
                }}
                loading={this.state.updatingGenerateShortHoldTokens}
                onChange={value => this.setGenerateShortHoldTokens(value)}
            />
        </div>
    }

    renderPublicHoldTokensEnabled() {
        return <div className="setting" id="allow_public_hold_token_creation">
            <div className="notice">
                <div className="title">{lang.d('important_notice')}</div>
                <div className="description">When disabled, seating charts configured with <code>session: start/continue</code> will fail to load.
                    Proceed with caution!
                </div>
            </div>
            <div className="title">{lang.d('public_hold_token_creation')}</div>
            <div className="text">
                <p>
                    Disabling this will prevent new sessions to be created when a chart loads with the session: start/continue settings.
                    Instead, you must use <a className="link" href="https://docs.seats.io/docs/renderer/config-session/" target="_blank"><code>session:
                    'manual'</code></a> and pass in a token generated via an <a className="link" href="https://docs.seats.io/docs/api/create-a-hold-token/" target="_blank">API call</a>.
                </p>

            </div>
            <OptionSwitcher
                value={this.props.setting.allowPublicHoldTokenCreation.toString().toUpperCase()}
                options={{
                    TRUE: lang.d('enabled'),
                    FALSE: lang.d('disabled')
                }}
                loading={this.state.updatingAllowPublicHoldTokenCreation}
                onChange={value => this.setAllowPublicHoldTokenCreation(value)}
            />
        </div>
    }

    renderMaxHoldsPerToken() {
        return <div className="setting">
            <div className="title">{lang.d('max_holds_per_token')}</div>
            <div className="text">{lang.d('max_holds_per_token_usage')}</div>
            <NumericInput
                value={this.props.setting.maxHoldsPerToken || 200}
                pixelsForStep={3}
                min={1}
                max={9999}
                disableSteppers={true}
                loading={this.state.updatingMaxHoldsPerToken}
                onChangeFinal={value => this.setMaxHoldsPerToken(value.toString())}
                title={lang.d('max_holds_per_token')}
            />
        </div>
    }

    async setHoldPeriod(value) {
        const oldValue = this.props.setting.holdPeriodInMinutes
        this.props.setSetting('holdPeriodInMinutes', value)

        this.setState({
            updatingHoldPeriod: true
        })

        try {
            await exposeErrors(changeHoldPeriod(value), () => this.props.setSetting('holdPeriodInMinutes', oldValue))
            this.props.setSetting('holdPeriodInMinutes', value)
        } finally {
            this.setState({
                updatingHoldPeriod: false
            })
        }
    }

    async setGenerateShortHoldTokens(value) {
        const oldValue = this.props.setting.generateShortHoldtokens
        this.props.setSetting('generateShortHoldTokens', value)

        this.setState({
            updatingGenerateShortHoldTokens: true
        })

        try {
            await exposeErrors(changeSetting('GENERATE_SHORT_HOLD_TOKENS', value), () => this.props.setSetting('generateShortHoldTokens', oldValue))
            this.props.setSetting('generateShortHoldTokens', value)
        } finally {
            this.setState({
                updatingGenerateShortHoldTokens: false
            })
        }
    }

    async setAllowPublicHoldTokenCreation(value) {
        if (value === 'FALSE') {
            const message = <div>
                If you disable this setting, seating charts configured with session: start/continue will stop working!
            </div>
            this.props.openModalDialog({
                title: 'Are you sure?',
                message: message,
                successMessage: "Setting disabled",
                submitEnabled: true,
                settings: {
                    onAccept: () => this.doSetAllowPublicHoldTokenCreation(value),
                    acceptCaption: "Yes, I'm sure",
                    waitOnAccept: true,
                    dangerousAction: true
                }
            })
        } else {
            await this.doSetAllowPublicHoldTokenCreation(value)
        }
    }

    async doSetAllowPublicHoldTokenCreation(value) {
        const oldValue = this.props.setting.allowPublicHoldTokenCreation
        this.props.setSetting('allowPublicHoldTokenCreation', value)

        this.setState({
            updatingAllowPublicHoldTokenCreation: true
        })

        try {
            await exposeErrors(changeSetting('ALLOW_PUBLIC_HOLD_TOKEN_CREATION', value), () => this.props.setSetting('allowPublicHoldTokenCreation', oldValue))
            this.props.setSetting('allowPublicHoldTokenCreation', value)
        } finally {
            this.setState({
                updatingAllowPublicHoldTokenCreation: false
            })
        }
    }

    async setMaxHoldsPerToken(value) {
        const oldValue = this.props.setting.maxHoldsPerToken
        this.props.setSetting('maxHoldsPerToken', value)

        this.setState({
            updatingMaxHoldsPerToken: true
        })
        try {
            await exposeErrors(changeSetting('MAX_HOLDS_PER_TOKEN', value), () => this.props.setSetting('maxHoldsPerToken', oldValue))
            this.props.setSetting('maxHoldsPerToken', value)
        } finally {
            this.setState({
                updatingMaxHoldsPerToken: false
            })
        }
    }


}


HoldsCompanySettings.propTypes = {
    adminKey: PropTypes.string,
    user: PropTypes.object,
    company: PropTypes.object,
    setting: PropTypes.object.isRequired,
    updateCompanySettings: PropTypes.func.isRequired,
    setSetting: PropTypes.func.isRequired,
    setValidationSetting: PropTypes.func.isRequired,
    setDefaultRendererSetting: PropTypes.func.isRequired,
    resetHeader: PropTypes.func.isRequired,
    openModalDialog: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    setCompanyAdminKey: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    setting: state.context.company.settings,
    adminKey: state.context.company.adminKey,
    user: state.user,
    company: state.context.company
})

const mapDispatchToProps = dispatch => ({
    updateCompanySettings: (user) => dispatch(updateCompanySettings(user)),
    setSetting: (setting, value) => dispatch(setCompanySetting(setting, value)),
    setValidationSetting: (setting, value) => dispatch(setCompanyValidationSetting(setting, value)),
    setDefaultRendererSetting: (setting, value) => dispatch(setCompanyDefaultRendererSetting(setting, value)),
    setCompanyAdminKey: adminKey => dispatch(setCompanyAdminKey(adminKey)),
    resetHeader: () => dispatch(resetHeader()),
    openModalDialog: payload => dispatch(openModalDialog(payload)),
    closeModalDialog: payload => dispatch(closeDialog(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(HoldsCompanySettings)
