import React from 'react'
import EventListItem from './EventListItem'
import * as PropTypes from 'prop-types'

class EventList extends React.Component {
    render () {
        if (this.props.events === null) return <div></div>
        return (
            <div className="EventList">
                {this.props.events
                    .map(item => (
                        <EventListItem
                            key={item.key}
                            id={item.id}
                            event={item}
                            requestDeleteEvent={this.props.requestDeleteEvent}
                            requestMarkEventAsInThePast={this.props.requestMarkEventAsInThePast}
                        />
                    ))}
            </div>
        )
    }
}

EventList.propTypes = {
    requestDeleteEvent: PropTypes.func.isRequired,
    events: PropTypes.arrayOf(PropTypes.object).isRequired,
    requestMarkEventAsInThePast: PropTypes.func.isRequired,
    searchParameters: PropTypes.object
}

export default EventList
