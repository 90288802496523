import React, { useState } from 'react'
import EventListItem from './EventListItem'
import * as PropTypes from 'prop-types'
import lang from '../../utils/lang'

const CHUNK_LABEL_REASSIGNMENTS = {
    reservedByToken: 'temporarily_held'
}

const CHUNK_SUPPORTED_CLASS_NAMES = {
    booked: 'booked',
    not_for_sale: 'not-for-sale',
    reservedByToken: 'temporarily-held'
}

const SUGGESTED_ORDER_BY_CLASS_NAME = [
    'not-for-sale',
    'disabled-by-rules',
    'booked',
    'custom-status',
    'temporarily-held'
]

const COMBINE_CUSTOM_STATUSES_THRESHOLD = 10

function OccupancyProgressBar ({ eventReport }) {
    const [hoveredChunk, setHoveredChunk] = useState()
    const availableCount = eventReport.available ? eventReport.available.count : 0
    let unsortedChunks = Object.keys(eventReport).map(key => {
        if (key === 'available') {
            return null
        }
        const isCustomStatus = !(key in CHUNK_SUPPORTED_CLASS_NAMES)
        return {
            label: CHUNK_LABEL_REASSIGNMENTS[key] || key,
            className: isCustomStatus ? 'custom-status' : CHUNK_SUPPORTED_CLASS_NAMES[key],
            count: eventReport[key].count,
            isCustomStatus
        }
    }).filter(chunk => chunk)

    const customStatusChunks = unsortedChunks.filter(chunk => chunk.isCustomStatus)
    if (customStatusChunks.length >= COMBINE_CUSTOM_STATUSES_THRESHOLD) {
        const totalCustomStatuses = customStatusChunks.reduce((total, chunk) => total + chunk.count, 0)
        unsortedChunks = unsortedChunks.filter(chunk => !chunk.isCustomStatus)
        unsortedChunks.push({
            label: 'custom_status',
            className: 'custom-status',
            count: totalCustomStatuses
        })
    }

    const chunks = []
    SUGGESTED_ORDER_BY_CLASS_NAME.forEach(className => {
        unsortedChunks.forEach(chunk => {
            if (chunk.className === className) {
                chunks.push(chunk)
            }
        })
    })
    const unavailablePlaces = chunks.reduce((total, chunk) => total + chunk.count, 0)
    const totalPlaces = unavailablePlaces + availableCount

    const renderProgressChunk = ({ label, className, count }) => {
        if (count < 1) {
            return null
        }
        const rate = count / totalPlaces * 100
        return <div
            key={label}
            className={`progress ${className} ${hoveredChunk === label && 'highlighted'}`}
            style={{ width: `${rate}%` }}
            onMouseOver={() => setHoveredChunk(label)}
            onMouseOut={() => setHoveredChunk(null)}
        />
    }

    const renderLegendItem = ({ label, className, count, isCustomStatus }) => {
        if (count === undefined || (count === 0 && label !== 'free')) {
            return null
        }
        return <div key={label} className={`item ${hoveredChunk === label && `highlighted ${className}`}`} onMouseOver={label !== 'free' ? () => setHoveredChunk(label) : undefined} onMouseOut={() => setHoveredChunk(null)}>
            <div className={`color ${className}`}></div>
            <div className="label">{ count.toLocaleString() } { isCustomStatus ? label : lang.dl(label) }</div>
        </div>
    }

    return (
        <div className="OccupancyProgressBar">
            <div className="top-labels">
                <div className="item">{ unavailablePlaces.toLocaleString() } { lang.dl('unavailable') }</div>
                <div className="item">{ totalPlaces.toLocaleString() } { lang.dl('capacity') }</div>
            </div>
            <div className="bar">
                { chunks.map(chunk => renderProgressChunk(chunk)) }
            </div>
            <div className="legend">
                { chunks.map(chunk => renderLegendItem(chunk)) }
                { renderLegendItem('free', 'free', availableCount, false) }
            </div>
        </div>
    )
}

OccupancyProgressBar.propTypes = {
    eventReport: PropTypes.object.isRequired
}

export default OccupancyProgressBar
