import React from 'react'
import { getSubscriptionReport } from '../../services/reports.service'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { setHeader } from '../../actions/header'
import SubscriptionReport from '../../components/Reports/SubscriptionReport'

const LoadingPlaceholder = () => (
    <div className="Reports">
        <div className="container">
            <div className="loading-placeholder">
                <div className="fake-item"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
            </div>
        </div>
    </div>
)

class ManageSubscription extends React.Component {
    state = {
        data: [],
        fetchFailed: false,
        loading: true
    }

    async componentDidMount () {
        this._isMounted = true
        this.props.setHeader({
            showSearch: true,
            caption: 'search_seating_charts',
            onFocus: () => {
                const usageDetails = document.getElementsByClassName('usage-details')[0]
                const screenPosition = usageDetails.getBoundingClientRect()
                document.getElementsByClassName('Reports')[0].scrollTo(0, screenPosition.y)
            }
        })

        try {
            const subscriptionReport = await getSubscriptionReport()
            this.setState({
                subscriptionReport,
                loading: false
            }, this.getChartsInSelectedMonth)
        } catch (e) {
            this.handleReportFetchError()
        }
    }

    handleReportFetchError () {
        this.setState({
            fetchFailed: true,
            loading: false
        })
    }

    componentWillUnmount () {
        this._isMounted = false
    }

    render () {
        if (!this.state.data) return null
        if (this.state.loading) return <LoadingPlaceholder/>
        return (
            <div className="ManageSubscription">
                <div className="container">
                    <SubscriptionReport data={this.state.subscriptionReport} />
                </div>
            </div>
        )
    }
}

ManageSubscription.propTypes = {
    defaultWorkspaceId: PropTypes.number,
    setHeader: PropTypes.func,
    setMonthlyReport: PropTypes.func,
    monthlyReport: PropTypes.array,
    searchValue: PropTypes.string
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload)),
})

export default connect(undefined, mapDispatchToProps)(ManageSubscription)
