const defaultState = {
    bumpPosition: false
}

const intercom = (state = defaultState, action) => {
    switch (action.type) {
    case 'INTERCOM/BUMP_POSITION':
        return {
            ...state,
            bumpPosition: true
        }
    case 'INTERCOM/RESTORE_POSITION':
        return {
            ...state,
            bumpPosition: false
        }
    default:
        return state
    }
}

export default intercom
