import React from 'react'
import * as PropTypes from 'prop-types'

export default class OptionSwitcher extends React.PureComponent {
    state = {
        value: null,
        loading: false
    }

    async setValue (newValue) {
        if (this.props.disabled || this.getValue() === newValue) {
            return
        }
        this.setState({ value: newValue, loading: true })
        try {
            await this.props.onChange(newValue)
        } finally {
            this.setState({ value: null, loading: false })
        }
    }

    render () {
        const value = this.getValue()
        return <div className={`OptionSwitcher ${this.props.disabled && 'disabled'} ${this.state.loading && 'loading'}`}>
            <div className="switcher">
                {
                    (Object.keys(this.props.options) || []).map(key =>
                        <div key={key} className={`option ${value === key && 'selected'}`} onClick={() => this.setValue(key)}>
                            { this.props.options[key] }
                        </div>
                    )
                }
            </div>
        </div>
    }

    getValue() {
        return this.state.value || this.props.value;
    }
}

OptionSwitcher.propTypes = {
    value: PropTypes.string,
    options: PropTypes.object,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
}
