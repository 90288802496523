import { notificationService } from './notification.service'
import lang from '../utils/lang'

const SLOW_RESPONSE_TIME = 5000

let warningShown = false
let numRequestsInProgress = 0
let warningId = null

function slowRequestDetector () {
    let requestInProgress = false

    function doSlowRequestCheck (start) {
        requestAnimationFrame(() => {
            if (!requestInProgress) {
                return
            }

            if (warningShown) {
                return doSlowRequestCheck(start)
            }

            const requestTime = new Date().getTime() - start.getTime()
            if (requestTime >= SLOW_RESPONSE_TIME) {
                warningShown = true
                warningId = notificationService.warn(lang.d('slow_response_title'), lang.d('slow_response'))
            } else {
                doSlowRequestCheck(start)
            }
        })
    }

    return {
        onRequestStarted: () => {
            requestInProgress = true
            numRequestsInProgress++
            doSlowRequestCheck(new Date())
        },

        onRequestEnded: () => {
            requestInProgress = false
            numRequestsInProgress--
            if (warningId !== null && numRequestsInProgress === 0) {
                warningShown = false
                notificationService.remove(warningId)
            }
        }
    }
}

function init () {
    warningShown = false
    numRequestsInProgress = 0
    warningId = null
}

export const slowRequestDetectionService = {
    slowRequestDetector,
    init
}
