import { merge } from '../utils/helperFunctions'

const defaultState = {
    list: [],
    shouldLoadMore: true
}

const chartDetail = (state = defaultState, action) => {
    switch (action.type) {
    case 'CHARTDETAIL/SET':
        return {
            ...state,
            ...action.payload,
            list: merge(state.list, action.payload.list)
        }
    case 'CHARTDETAIL/SET_LIST':
        return {
            ...state,
            ...action.payload,
            list: action.payload.list
        }
    case 'CHARTDETAIL/CLEAR_SEARCH':
        return {
            ...state,
            list: [],
            shouldLoadMore: true,
            finishedMounting: false,
            nextPageStartsAfter: null,
            searchEmpty: false
        }
    default:
        return state
    }
}

export default chartDetail
