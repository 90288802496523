import React, { PureComponent } from 'react'
import * as PropTypes from 'prop-types'
import lang from '../../utils/lang'
import { wrapAround } from '../../utils/helperFunctions'
import DropDown from './DropDown'

export class SearchInput extends PureComponent {
    state = {
        opened: false,
        focus: false,
        tagFilter: null,
        tagCursor: -1,
        selectedTagForRemoval: false
    }

    constructor (props) {
        super(props)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.wrapperRef = React.createRef()
        this.inputRef = React.createRef()
    }

    componentDidMount () {
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount () {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside (event) {
        if (this.state.opened && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ opened: false })
        }
    }

    getTagRegex () {
        return /(?:＃|#)([^\s,.!@#＃$%^&*()[\]{};':"\\|`~<>/?]+)/ig
    }

    selectTag (tag) {
        this.tag = tag
        this.inputRef.current.focus()
        this.onSearch(this.props.searchValue.replace(this.getTagRegex(), ''), this.props.searchFilterType)
    }

    clearTag () {
        this.selectTag(null)
    }

    onSearch (value, searchFilterType) {
        const match = this.getTagRegex().exec(value)
        this.setState({
            tagFilter: match && match[1],
            tagCursor: -1,
            selectedTagForRemoval: false
        })
        if (match && !this.tag) {
            const matchedTags = this.getTags().filter(tag => tag.toLowerCase().includes(match[1].toLowerCase()))
            if (matchedTags.length === 1) {
                this.selectTag(matchedTags[0])
                return
            }
        }
        this.props.search(value, this.tag, searchFilterType)
    }

    onKeyDown (e) {
        const matchedTags = this.getTags().filter(tag => e.target.value.search(`(?:＃|#)(${tag})`) >= 0)
        if (e.key === 'Tab') {
            e.preventDefault()
            if (matchedTags.length > 0) {
                this.selectTag(matchedTags.sort((a, b) => b.length - a.length)[0])
            }
        }
        if (e.key === 'ArrowDown') {
            e.preventDefault()
            if (!this.isPickingTags()) {
                this.setState({ tagCursor: 0 })
            }
        }
        if (this.isPickingTags()) {
            if (e.key === 'ArrowUp') {
                e.preventDefault()
                this.setState({ tagCursor: -1 })
            }
            const cursorMovement = e.key === 'ArrowLeft' ? -1 : (e.key === 'ArrowRight' ? 1 : 0)
            if (cursorMovement !== 0) {
                e.preventDefault()
                this.setState({ tagCursor: wrapAround(this.state.tagCursor + cursorMovement, 0, this.getVisibleTags().length) })
            }
            if (e.key === 'Enter') {
                e.preventDefault()
                this.selectTag(this.getVisibleTags()[this.state.tagCursor])
                this.setState({ tagCursor: -1 })
            }
        }
        if (this.tag && e.key === 'Backspace' && this.inputRef.current.selectionStart === 0) {
            e.preventDefault()
            if (this.state.selectedTagForRemoval) this.clearTag()
            this.setState({ selectedTagForRemoval: !this.state.selectedTagForRemoval })
        } else if (this.tag && e.key === 'ArrowLeft' && this.inputRef.current.selectionStart === 0) {
            e.preventDefault()
            this.setState({ selectedTagForRemoval: true })
        } else {
            this.setState({ selectedTagForRemoval: false })
        }
    }

    isPickingTags () {
        return this.state.tagCursor >= 0
    }

    getTags () {
        return this.props.tags || []
    }

    getVisibleTags () {
        let tags = this.getTags()
        if (this.state.tagFilter) {
            tags = tags.filter(tag => tag.toLowerCase().includes(this.state.tagFilter.toLowerCase()))
        }
        return tags.filter(tag => this.props.tagValue !== tag)
    }

    clearSearch () {
        this.tag = null
        this.props.clearResults()
    }

    onFilterTypeChanged (searchFilterType) {
        this.inputRef.current.focus()
        this.onSearch(this.props.searchValue.replace(this.getTagRegex(), ''), searchFilterType)
    }

    render () {
        const visibleTags = this.getVisibleTags()
        const hasSearchValue = this.props.searchValue.length > 0 || this.props.tagValue
        const searchFilterTypes = this.props.searchFilterTypes && this.props.searchFilterTypes.map(t => ({
            label: lang.d(t),
            value: t
        }))
        return (
            <div className={`SearchInput ${this.state.opened && 'opened'} ${this.props.searchFilterTypes && 'with-dropdown'}`} ref={this.wrapperRef}>
                <div
                    className={`search-bar ${hasSearchValue && 'with-value'} ${this.state.focus && 'focus'} ${this.state.selectedTagForRemoval && 'selected-tag-for-removal'}`}>
                    {hasSearchValue && <button className="clear-button icon-close-light" onClick={this.clearSearch}/>}
                    <div className="icon icon-loupe" onClick={() => this.inputRef.current.focus()}/>
                    {this.props.tagValue &&
                        <div className="tag" onClick={() => this.clearTag()}>
                            <span className="value">{this.props.tagValue}</span>
                            <span className="icon icon-close-light"/>
                        </div>
                    }
                    <input
                        type="text"
                        placeholder={this.props.caption || lang.d('search')}
                        value={this.props.displayValue}
                        onChange={e => this.onSearch(e.target.value, this.props.searchFilterType)}
                        onFocus={() => this.setState({ focus: true, opened: true })}
                        onBlur={() => this.setState({ focus: false })}
                        onKeyDown={e => this.onKeyDown(e)}
                        ref={this.inputRef}
                    />
                </div>
                { this.props.searchFilterTypes &&
                    <DropDown
                        list={searchFilterTypes}
                        onChange={(e) => this.onFilterTypeChanged(e.target.value)}
                        selectedIndex={searchFilterTypes.findIndex(o => o.value === this.props.searchFilterType)}
                        name="searchFilterType"
                    />
                }
                {visibleTags.length > 0 &&
                    <div className="tags-dropdown">
                        {visibleTags.map((tag, i) => <div className={`tag ${i === this.state.tagCursor && 'active'}`}
                            key={tag} onClick={() => this.selectTag(tag)}>{tag}</div>)}
                    </div>
                }
            </div>
        )
    }
}

SearchInput.propTypes = {
    searchValue: PropTypes.string,
    displayValue: PropTypes.string,
    tagValue: PropTypes.string,
    caption: PropTypes.string,
    tags: PropTypes.array,
    search: PropTypes.func,
    clearResults: PropTypes.func,
    onFocus: PropTypes.func,
    searchFilterTypes: PropTypes.arrayOf(PropTypes.string),
    searchFilterType: PropTypes.string
}

export default SearchInput
