export const contextualMenuService = {
    show,
    clear,
    addListener
}

const onChangeListeners = []

/**
 *
 * @param {array} options [{ type: string, caption: string, uiEvent: string }, ...]
 * @param {object} settings { position: { left: number, top: number }, onSelect: callback, onClose: callback }
 */
function show (options, settings) {
    onChangeListeners.forEach(listener => listener(options, settings))
}

function clear () {
    onChangeListeners.forEach(listener => listener([], {}))
}

function addListener (listener) {
    return onChangeListeners.push(listener)
}
