const dictionary = {
    id: 'ID',
    email: 'Email',
    work_email: 'Email de trabajo',
    password: 'Contraseña',
    confirm_password: 'Confirmar contraseña',
    change_password: 'Cambiar contraseña',
    name: 'Nombre',
    secret_key: 'Clave secreta',
    anonymous: 'Anónimo',
    seating_charts: 'Planos',
    usage: 'Uso',
    settings: 'Configuración',
    admin: 'Administración',
    error: 'Error',
    search: 'Buscar',
    signup: 'Crear cuenta',
    login: 'Iniciar sesión',
    logout: 'Cerrar sesión',
    required: 'Requerido',
    invalid_email: 'Email inválido',
    password_mismatch: 'Las contraseñas no coinciden',
    email_password_required: 'Correo y contraseña son requeridos',
    loading: 'Cargando',
    x_events: '%{smart_count} evento |||| %{smart_count} eventos',
    no_events: 'No hay eventos',
    offline: 'Sin conexión',
    cancel: 'Cancelar',
    accept: 'Aceptar',
    'numeric-input-scrubber-hint': 'Click & arrastrar para cambiar el valor',
    edit_seating_chart: 'Editar plano',
    actions: 'Acciones',
    see_all_events: 'Ver todos los eventos',
    no_results: 'No se encontraron resultados',
    view_archive: 'Ver archivo',
    archive_chart: 'Archivar plano',
    duplicate_chart: 'Duplicar plano',
    VALIDATE_EMPTY_FLOOR: 'Piso vacio',
    show_more: 'Mostrar más'
}

export default dictionary
