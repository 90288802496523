export const reportsDefaultState = {
    monthlyReport: []
}

const reports = (state = reportsDefaultState, action) => {
    switch (action.type) {
    case 'REPORTS/SET_MONTHLY_REPORT':
        return {
            ...state,
            monthlyReport: action.payload
        }
    default:
        return state
    }
}

export default reports
