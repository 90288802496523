import React from 'react'
import * as PropTypes from 'prop-types'

class FormButton extends React.PureComponent {
    render () {
        const classNames = ['FormButton']
        if (this.props.className) classNames.push(this.props.className)
        if (this.props.loading) classNames.push('loading')
        const style = this.props.noMargin ? { margin: 0 } : {}
        return <button className={classNames.join(' ')} style={style} onClick={this.props.onClick} disabled={this.props.disabled} type={this.props.type}>
            {this.props.children}
        </button>
    }
}

FormButton.propTypes = {
    className: PropTypes.string,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string
    ]),
    noMargin: PropTypes.bool
}

export default FormButton
