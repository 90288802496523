import React from 'react'
import { NavLink } from 'react-router-dom'
import * as PropTypes from 'prop-types'
import IconButtonToSelect from './IconButtonToSelect'

export default class NavigationList extends React.Component {
    getItem (option) {
        const key = `${option.type}-${option.codename || option.caption}`
        switch (option.type) {
        case 'title':
            return <div key={key} className="item title">
                <div className="caption">
                    <div className="main-caption">
                        { option.caption } &nbsp;
                        { option.sortOptions &&
                            <IconButtonToSelect
                                options={option.sortOptions}
                                value={option.sortValue}
                                onChange={(event) => this.props.onSelect(option.sortButtonCodename, event.target.value)}
                            />
                        }
                    </div>
                    { option.subCaption && <div className="sub-caption">{ option.subCaption }</div>}
                </div>
                { (option.buttonIcon || option.buttonCaption) &&
                    <div className={`button ${option.buttonClassName}`} onClick={() => this.props.onSelect(option.buttonCodename)}>
                        <span className={`icon icon-${option.buttonIcon}`}></span>
                        <span className="caption">{ option.buttonCaption }</span>
                    </div>
                }
            </div>
        case 'link':
            return <div>
                <NavLink
                    key={key}
                    activeClassName={'selected'}
                    className={`item link ${option.codename && option.codename === this.props.value && 'selected'} ${option.className} ${option.icon && 'with-icon'} ${option.highlighted && 'highlighted'}`}
                    to={option.to}
                    isActive={match => match && (option.exact ? match.isExact : match.url.includes(option.to))}
                >
                    { option.icon && <span className={`icon icon-${option.icon}`} /> }
                    <div className={`main-caption ${option.captionStyle}`}>{ option.caption }</div>
                    { option.subCaption && <div className={`sub-caption ${option.subCaptionStyle}`}>{ option.subCaption }</div> }
                </NavLink>
            </div>
        case 'usage-chart-button':
            return <button
                key={key}
                className={`item link ${option.className}`}
                onClick={() => this.props.onSelect(option.parentIndex, option.index)}
            >
                { option.caption }
            </button>
        case 'info':
            return <div
                key={key}
                className={`item option ${option.className}`}
            >
                { option.caption }
            </div>
        default:
            return <div
                key={key}
                className={`item option ${option.codename === this.props.value && 'selected'} ${option.className}`}
                onClick={() => this.props.onSelect(option.codename)}
            >
                { option.caption }
            </div>
        }
    }

    render () {
        return <div className="NavigationList">
            { this.props.options.map(option => this.getItem(option)) }
        </div>
    }
}

NavigationList.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.string,
    onSelect: PropTypes.func
}
