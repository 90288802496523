import React from 'react'
import { downloadInvoice, retrieveInvoices } from '../../../services/invoices.service'
import lang from '../../../utils/lang'
import { setHeader } from '../../../actions/header'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '../../../components/GUI/Button'
import { contextualMenuService } from '../../../services/contextualMenu.service'
import { notificationService } from '../../../services/notification.service'
import { exposeErrors } from '../../../utils/errors'
import { upperFirst } from 'lodash'

class Invoices extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            invoices: null,
            contextualMenuOpened: false
        }
    }

    async componentDidMount () {
        this.props.setHeader({})
        await this.loadInvoices()
    }

    async loadInvoices () {
        retrieveInvoices()
            .then(invoices => this.setState({invoices: invoices}))
    }

    onContextMenu (e, invoiceId, invoiceName, pdfUrl, creditNotes) {
        this.setState({ contextualMenuOpened: true })
        const options = [
            {
                type: 'download',
                caption: lang.d('download'),
                uiEvent: {
                    type: 'downloadInvoice',
                    url: pdfUrl
                }
            }
        ]

        if (creditNotes) {
            for (let i = 0; i < creditNotes.length; i++) {
                options.push({
                    type: 'download',
                    caption: lang.d('download_credit_note', {creditNoteNumber: creditNotes[i].creditNoteNumber}),
                    uiEvent: {
                        type: 'downloadCreditNote',
                        url: creditNotes[i].pdfUrl
                    }
                })
            }
        }

        contextualMenuService.show(options, {
            onSelect: uiEvent => this.onAction(uiEvent, invoiceId, invoiceName),
            onClose: () => this.setState({ contextualMenuOpened: false }),
            position: {
                left: e.clientX,
                top: e.clientY
            }
        })
    }

    async onAction (uiEvent, invoiceId, invoiceNumber) {
        switch (uiEvent.type) {
        case 'downloadInvoice':
            await this.doDownloadInvoice(invoiceId, invoiceNumber, uiEvent.url)
            break
        case 'downloadCreditNote':
            window.open(uiEvent.url, "_blank", "noreferrer")
            break
        default:
            break
        }
    }

    async doDownloadInvoice (invoiceId, invoiceNumber, pdfUrl) {
        if (pdfUrl) {
            window.open(pdfUrl, "_blank", "noreferrer");
        } else {
            notificationService.info(lang.d('downloading'), lang.d('downloading_invoice', {invoice_number: invoiceNumber}), 3000)
            await exposeErrors(downloadInvoice(invoiceId, invoiceNumber))
        }
    }

    render () {
        return <div className="Invoices" key={`Invoices-page-${this.state.requestedPage}`}>
            <div className="container">
                {this.state.invoices
                    ? this.renderTable()
                    : this.renderPlaceholder()
                }
            </div>
        </div>
    }

    renderTable () {
        return <div className="DataTable">
            <table>
                <thead>
                    <tr>
                        { this.renderHeaders() }
                    </tr>
                </thead>
                <tbody>
                    { this.renderRows() }
                </tbody>
            </table>
        </div>
    }

    renderHeaders () {
        return <>
            <th style={{width: '24%'}}>{lang.d('due_date')}</th>
            <th style={{width: '24%'}}>{lang.d('invoice_number')}</th>
            <th style={{width: '24%'}}>{lang.d('amount')}</th>
            <th style={{width: '24%'}}>{lang.d('status')}</th>
            <th style={{width: '4%'}}></th>
        </>
    }

    renderRows () {
        return this.state.invoices
            .map((invoice) => {
                const total = invoice.total.toFixed(2)
                const dueDate = new Date(invoice.dueDate).toLocaleDateString('en-gb')
                const status = upperFirst(invoice.status)
                return <tr key={invoice.invoiceId}>
                    <td>{dueDate}</td>
                    <td>{invoice.invoiceNumber}</td>
                    <td>{invoice.currencySymbol}{total}</td>
                    <td>{status}</td>
                    <td>
                        <Button preset="round" type="more" onClick={e => this.onContextMenu(e, invoice.invoiceId, invoice.invoiceNumber, invoice.pdfUrl, invoice.creditNotes)}/>
                    </td>
                </tr>
            })
    }

    renderPlaceholder () {
        return <div className="DataTable">
            <div className="loading-placeholder">
                <div className="fake-item double"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
            </div>
        </div>
    }
}

Invoices.propTypes = {
    setHeader: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(null, mapDispatchToProps)(Invoices)
