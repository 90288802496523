import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {
    setCompanyAdminKey,
    setCompanyDefaultRendererSetting,
    setCompanySetting,
    setCompanyValidationSetting,
    updateCompanySettings
} from "../../../actions/user";
import {resetHeader} from "../../../actions/header";
import {closeDialog, openModalDialog} from "../../../actions/modalDialog";
import {connect} from "react-redux";
import lang from "../../../utils/lang";
import OptionSwitcher from "../../../components/GUI/OptionSwitcher";
import {Link} from "react-router-dom";
import {IntercomAPI} from "react-intercom";
import {exposeErrors} from "../../../utils/errors";
import {changeDraftsValue} from "../../../services/accounts.service";

class AdvancedCompanySettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            updatingDrafts: false
        }
    }

    render() {
        return <>
            <div className="setting-page-title">{ lang.d('advanced') }</div>
            {this.renderWebhooks()}
            {this.renderDraftsForPublishedCharts()}
        </>
    }

    renderWebhooks() {
        return <div className="setting">
            <div className="title">{lang.d('webhooks')}</div>
            {this.props.setting.webhooksEnabled ? this.webhooksEnabled() : this.webhooksDisabled()}
            <OptionSwitcher
                disabled={true}
                value={this.props.setting.webhooksEnabled ? 'ENABLED' : 'DISABLED'}
                options={{
                    ENABLED: lang.d('enabled'),
                    DISABLED: lang.d('disabled')
                }}
            />
        </div>
    }

    webhooksEnabled() {
        return <div className="text">
            <Link to={`/webhooks`} className="arrow"><span>Manage webhooks</span></Link>
            <p>If webhooks are enabled, we send a message to your server when certain things happen in seats.io (e.g. a
                chart gets created).</p>
            <p>Please refer to our documentation for all <a href="https://docs.seats.io/docs/webhooks" target="_blank">available
                event types.</a></p>
        </div>
    }

    webhooksDisabled() {
        return <div className="text">
            <p>If webhooks are enabled, we send a message to your server when certain things happen in seats.io (e.g. a
                chart gets created).</p>
            <p>We currently offer this as a beta feature. <Link onClick={() => IntercomAPI('show')}>Get in
                touch</Link> if you're interested.</p>
        </div>
    }

    renderDraftsForPublishedCharts() {
        return <div className="setting">
            <div className="notice">
                <div className="title">{lang.d('important_notice')}</div>
                <div className="description">{lang.d('notice_enable_drafts')}</div>
            </div>
            <div className="title">{lang.d('drafts_for_published_charts')}</div>
            <div className="text">{lang.d('drafts_for_published_charts_usage')}</div>
            <OptionSwitcher
                value={this.props.setting.draftChartDrawingsEnabled.toString().toUpperCase()}
                options={{
                    TRUE: lang.d('enabled'),
                    FALSE: lang.d('disabled')
                }}
                loading={this.state.updatingDrafts}
                onChange={value => this.setDrafts(value)}
            />
        </div>
    }

    async setDrafts(value) {
        this.setState({
            updatingDrafts: true
        })

        try {
            await exposeErrors(changeDraftsValue(value))
            this.props.setSetting('draftChartDrawingsEnabled', value)
        } finally {
            this.setState({
                updatingDrafts: false
            })
        }
    }


}


AdvancedCompanySettings.propTypes = {
    adminKey: PropTypes.string,
    user: PropTypes.object,
    company: PropTypes.object,
    setting: PropTypes.object.isRequired,
    updateCompanySettings: PropTypes.func.isRequired,
    setSetting: PropTypes.func.isRequired,
    setValidationSetting: PropTypes.func.isRequired,
    setDefaultRendererSetting: PropTypes.func.isRequired,
    resetHeader: PropTypes.func.isRequired,
    openModalDialog: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    setCompanyAdminKey: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    setting: state.context.company.settings,
    adminKey: state.context.company.adminKey,
    user: state.user,
    company: state.context.company
})

const mapDispatchToProps = dispatch => ({
    updateCompanySettings: (user) => dispatch(updateCompanySettings(user)),
    setSetting: (setting, value) => dispatch(setCompanySetting(setting, value)),
    setValidationSetting: (setting, value) => dispatch(setCompanyValidationSetting(setting, value)),
    setDefaultRendererSetting: (setting, value) => dispatch(setCompanyDefaultRendererSetting(setting, value)),
    setCompanyAdminKey: adminKey => dispatch(setCompanyAdminKey(adminKey)),
    resetHeader: () => dispatch(resetHeader()),
    openModalDialog: payload => dispatch(openModalDialog(payload)),
    closeModalDialog: payload => dispatch(closeDialog(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedCompanySettings)
