import { notificationService } from '../services/notification.service'
import lang from './lang'
import { isSuperAdmin } from '../reducers/user'

export function showFailedToContactServerError (e, state) {
    if (e && e.response && e.response.data && state && isSuperAdmin(state.user)) {
        notificationService.error(lang.d('error'), e.response.data.messages[0])
    } else {
        notificationService.error(lang.d('error'), lang.d('failed_to_contact_server'))
    }
}

export function hasErrorCode (e, code) {
    return e.errors && e.errors.some(error => error.code === code)
}

export async function exposeErrors (promise, onError = null, state) {
    try {
        return await promise
    } catch (e) {
        showFailedToContactServerError(e, state)
        if (onError) {
            onError()
        }
        throw e
    }
}

export async function exposeConnectionErrors (promise) {
    try {
        return await promise
    } catch (e) {
        if (!e.messages) {
            showFailedToContactServerError()
        }
        throw e
    }
}
