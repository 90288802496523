import React from 'react'
import queryString from 'query-string'
import ConfirmResetPasswordForm from './ConfirmResetPasswordForm'
import * as PropTypes from 'prop-types'
import { DEFAULT_REGION } from '../../domain/region'

const ConfirmResetPassword = (props) => {
    const values = queryString.parse(props.location.search)
    return (
        <div className="ModalDialog SplashScreen">
            <div className="backdrop"></div>
            <ConfirmResetPasswordForm resettedPasswordCode={values.resettedPasswordCode} userId={values.userId} region={values.region || DEFAULT_REGION}/>
        </div>
    )
}

ConfirmResetPassword.propTypes = {
    location: PropTypes.object
}

export default ConfirmResetPassword
