import React from 'react'
import * as PropTypes from 'prop-types'
import lang from '../../utils/lang'
import { connect } from 'react-redux'
import InactiveWorkspaceListItem from './InactiveWorkspaceListItem'
import { workspacesService } from '../../services/workspaces.service'
import debounce from 'lodash/debounce'
import { Link } from 'react-router-dom'
import { remove } from 'lodash'
import { setWorkspacesList } from '../../actions/workspaces'

const placeholder = <>
    <tr className="loading-placeholder">
        <td><div className="fake-item"></div></td>
        <td><div className="fake-item"></div></td>
    </tr>
    <tr className="loading-placeholder">
        <td><div className="fake-item"></div></td>
        <td><div className="fake-item"></div></td>
    </tr>
    <tr className="loading-placeholder">
        <td><div className="fake-item"></div></td>
        <td><div className="fake-item"></div></td>
    </tr>
</>

const noResult = (
    <div className="empty-page-message">
        <div className="title">{lang.d('no_results')}</div>
    </div>
)

class InactiveWorkspaceList extends React.Component {
    state = {
        searchResult: null,
        loading: true
    }

    doDebouncedSearch = debounce(this.doSearch, 400)

    constructor (props) {
        super(props)
        this.doSearch()
    }

    componentDidUpdate (prevProps) {
        if (this.props.searchValue !== prevProps.searchValue) {
            this.setState({ loading: true })
            this.doDebouncedSearch()
        }
    }

    async doSearch () {
        const searchResult = await workspacesService.listInactiveWorkspaces(this.props.searchValue)
        this.setState({ searchResult, loading: false })
    }

    renderHeaders () {
        return <>
            <th width="50%">{ lang.d('name') }</th>
            <th width="45%">{ lang.d('workspace_key') }</th>
            <th width="5%"></th>
        </>
    }

    renderRows () {
        if (this.state.loading) return placeholder
        return this.state.searchResult.map((workspace, i) => <InactiveWorkspaceListItem
            key={workspace.id}
            workspace={workspace}
            animateIn={i === 0 && this.props.animateInFirstItem}
            readOnly={this.props.readOnly}
            doSearch={this.doSearch.bind(this)}
            onWorkspaceActivated={this.onWorkspaceActivated.bind(this)}
        />)
    }

    renderTable () {
        return <>
            <div className="DataTable">
                <table>
                    <thead>
                        <tr>
                            { this.renderHeaders() }
                        </tr>
                    </thead>
                    <tbody>
                        { this.renderRows() }
                    </tbody>
                </table>
            </div>
        </>
    }

    async onWorkspaceActivated (activatedWorkspace) {
        const inactiveWorkspaces = this.state.searchResult.slice()
        remove(inactiveWorkspaces, workspace => workspace.key === activatedWorkspace.key)
        this.setState({ searchResult: inactiveWorkspaces })

        const workspaces = await workspacesService.listFirstPage(100)
        this.props.setWorkspacesList(workspaces.items)
    }

    render () {
        if (this.state.searchResult && this.state.searchResult.length === 0 && !this.props.searchValue) {
            return <NoInactiveWorkspacesMessage />
        }
        return <div className="InactiveWorkspacesList sectioned-page">
            <div className="sectioned-page-container">
                <div className="section wide">
                    <div className="page-title">{ lang.d('inactive_workspaces') }</div>
                    { (this.state.searchResult && this.state.searchResult.length === 0) ? noResult : this.renderTable()}
                </div>
            </div>
        </div>
    }
}

const NoInactiveWorkspacesMessage = () => (
    <div className="empty-page-message">
        <div className="title">{ lang.d('no_inactive_workspaces') }</div>
        <Link to="/workspaces" className="arrow back">{lang.d('active_workspaces')}</Link>
    </div>
)

InactiveWorkspaceList.propTypes = {
    name: PropTypes.string,
    searchValue: PropTypes.string,
    readOnly: PropTypes.bool,
    animateInFirstItem: PropTypes.bool,
    setWorkspacesList: PropTypes.func
}

const mapStateToProps = state => ({
    searchValue: state.search.workspaces.value
})

const mapDispatchToProps = dispatch => ({
    setWorkspacesList: workspaces => {
        return dispatch(setWorkspacesList(workspaces))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(InactiveWorkspaceList)
