import FormButton from '../../components/GUI/FormButton'
import { adminService } from '../../services/admin.service'
import React from 'react'
import { exposeErrors } from '../../utils/errors'
import lang from '../../utils/lang'
import * as PropTypes from 'prop-types'
import { openModalDialog } from '../../actions/modalDialog'
import { connect } from 'react-redux'

function UsersInProxySyncer (props) {
    async function sync () {
        props.openModalDialog({
            title: 'Sync users in proxy?',
            successMessage: 'Users synced',
            settings: {
                onAccept: () => exposeErrors(adminService.syncUsersInProxy()),
                acceptCaption: lang.d('Sync'),
                dangerousAction: true,
                waitOnAccept: true
            }
        })
    }

    return <div className="section">
        <div className="title">User sync</div>
        <FormButton onClick={sync} noMargin={true}>
            Sync users in Proxy
        </FormButton>
    </div>
}

UsersInProxySyncer.propTypes = {
    openModalDialog: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
    openModalDialog: payload => dispatch(openModalDialog(payload))
})

export default connect(null, mapDispatchToProps)(UsersInProxySyncer)
