import React from 'react'
import * as PropTypes from 'prop-types'
import SplashScreenGeneralErrorModalDialog from './GUI/SplashScreenGeneralErrorModalDialog'
import * as Sentry from '@sentry/browser'
import { Environment } from '../environment'

export default class ErrorBoundary extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            hasError: false
        }
    }

    componentDidCatch (error, errorInfo) {
        if (Environment.sentryEnabled) {
            Sentry.captureException(error)
        }
        this.setState({ hasError: true })
    }

    render () {
        if (this.state.hasError) {
            return <SplashScreenGeneralErrorModalDialog />
        }

        return this.props.children
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.element
}
