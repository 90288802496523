import React from 'react'
import ReactIntercom from 'react-intercom'
import { useSelector } from 'react-redux'
import { Environment } from '../environment'
import { hasSignedUp } from '../reducers/user'

const Intercom = () => {
    const user = useSelector(state => state.user)
    const intercom = useSelector(state => state.intercom)
    const { intercomAppId } = Environment

    if (!intercomAppId) return null
    if (!user) return null

    if (!Environment.intercomAppId) return null
    const intercomUser = {
        email: user.email,
        created_at: user.createdOn ? user.createdOn : new Date(0),
        hasSignedUp: hasSignedUp(user),
        company: {
            name: user.company.name,
            id: user.company.id
        }
    }

    return (
        <ReactIntercom
            appID={intercomAppId}
            vertical_padding={intercom.bumpPosition ? 60 : null}
            hide_default_launcher={true}
            {...intercomUser}
        />
    )
}

export default Intercom
