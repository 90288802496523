export const setSecretKey = secretKey => ({
    type: 'USER/SET_SECRET_KEY',
    secretKey
})

export const setDesignerKey = designerKey => ({
    type: 'USER/SET_DESIGNER_KEY',
    designerKey
})

export const setCompanySetting = (setting, value) => ({
    type: 'SET_SINGLE_COMPANY_SETTING',
    setting,
    value
})

export const updateCompanySettings = (settings) => ({
    type: 'UPDATE_COMPANY_SETTINGS',
    settings
})

export const setWorkspaceSetting = (setting, value) => ({
    type: 'SET_SINGLE_WORKSPACE_SETTING',
    setting,
    value
})

export const deleteWorkspaceSetting = (setting) => ({
    type: 'DELETE_SINGLE_WORKSPACE_SETTING',
    setting
})

export const setCompanyAdminKey = (adminKey) => ({
    type: 'SET_CONTEXT_COMPANY_ADMIN_KEY',
    adminKey
})

export const setCompanyValidationSetting = (setting, value) => ({
    type: 'SET_COMPANY_VALIDATION_SETTING',
    setting,
    value
})

export const setCompanyDefaultRendererSetting = (setting, value) => ({
    type: 'SET_COMPANY_DEFAULT_RENDERER_SETTING',
    setting,
    value
})

export const setUser = payload => ({
    type: 'SET_USER',
    payload
})

export const updateUser = payload => ({
    type: 'UPDATE_USER',
    payload
})

export const setHasChosenSubscriptionPlan = () => ({
    type: 'SET_HAS_CHOSEN_SUBSCRIPTION_PLAN'
})

export const setUpcomingSubscriptionPlan = upcomingSubscriptionPlan => ({
    type: 'SET_UPCOMING_SUBSCRIPTION_PLAN',
    upcomingSubscriptionPlan
})

export const logout = () => ({ type: 'LOGOUT' })
export const switchToDefaultCompanyAndWorkspace = (path) => ({ type: 'SWITCH_TO_DEFAULT_COMPANY_AND_WORKSPACE_OF_USER', path })
export const switchToDefaultWorkspace = () => ({ type: 'SWITCH_TO_DEFAULT_WORKSPACE_OF_ACTIVE_COMPANY' })
export const switchToWorkspace = (workspace) => ({ type: 'SWITCH_TO_WORKSPACE_OF_ACTIVE_COMPANY', workspace })
