export const publishDraft = (key, thumbnailUrl) => ({
    type: 'CHARTS/PUBLISH_DRAFT',
    payload: { key, thumbnailUrl }
})

export const deleteEvent = (chartKey, eventKey) => ({
    type: 'CHARTS/DELETE_EVENT',
    payload: { chartKey, eventKey }
})

export const createChart = payload => ({
    type: 'CHARTS/ADD',
    payload
})

export const setChartList = (charts) => ({
    type: 'CHARTS/SET_LIST',
    payload: {
        list: charts
    }
})

export const setPendingRefreshChart = chartKey => ({
    type: 'CHARTS/SET_PENDING_REFRESH_CHART',
    payload: { chartKey }
})

export const setPendingNewChart = chartKey => ({
    type: 'CHARTS/SET_PENDING_NEW_CHART',
    payload: { chartKey }
})

export const setSearchTags = tags => {
    return async function (dispatch) {
        dispatch({
            type: 'CHARTS/SET_SEARCH_TAGS',
            payload: tags
        })
    }
}

export const markEventAsInThePast = (eventKey) => ({
    type: 'CHARTS/MARK_EVENT_AS_IN_THE_PAST',
    payload: { eventKey }
})

