import React from 'react'
import { Formik } from 'formik'
import { validateCopyChartsByTag } from '../../utils/validators'
import { copyChartsByTag } from '../../services/admin.service'
import { parseBackendErrors } from '../../utils/backendErrors'
import lang from '../../utils/lang'
import Input from '../Input'
import * as PropTypes from 'prop-types'
import LoadingIndicator from '../GUI/LoadingIndicator'
import { useDispatch } from 'react-redux'
import { openModalDialog } from '../../actions/modalDialog'

class CopyChartsByTagForm extends React.Component {
    renderWaiting () {
        const { status, setStatus } = this.props

        if (status === 'submitted') {
            setTimeout(() => {
                setStatus(null)
            }, 2000)
        }

        return (
            <div className={`waiting-indicator ${status === 'submitted' && 'done'}`}>
                <div className="done-indicator">
                    <div className="stamp">
                        <div className="stroke"></div>
                        <div className={'icon icon-check'}></div>
                    </div>
                    <div className="message">DONE</div>
                </div>
                <LoadingIndicator/>
            </div>
        )
    }

    render () {
        const {
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            values,
            status
        } = this.props
        return (
            <form name="copyChartForm" onSubmit={handleSubmit} className="AdminForm">
                <div className="form-field">
                    <label htmlFor="copy_from_email">{lang.d('copy_charts_from_email')}</label>
                    <Input
                        id="copy_from_email"
                        name="fromEmail"
                        type="text"
                        value={values.fromEmail}
                        placeholder="john@doe.com"
                        onChange={handleChange}
                        className={errors.fromEmail && touched.fromEmail && 'error'}
                    />
                </div>
                <div className="form-field">
                    <label htmlFor="copy_to_email">{lang.d('copy_charts_to_email')}</label>
                    <Input
                        id="copy_to_email"
                        name="toEmail"
                        type="text"
                        value={values.toEmail}
                        placeholder="jane@doe.com"
                        onChange={handleChange}
                        className={errors.toEmail && touched.toEmail && 'error'}
                    />
                </div>
                <div className="form-field">
                    <label htmlFor="copy_tag">{lang.d('copy_charts_tag')}</label>
                    <Input
                        id="copy_tag"
                        name="tag"
                        type="text"
                        value={values.tag}
                        onChange={handleChange}
                        className={errors.tag && touched.tag && 'error'}
                    />
                </div>

                {Object.keys(errors).length > 0 && <div className="errors">
                    {errors.fromEmail && touched.fromEmail && <div className="error">{errors.fromEmail}</div>}
                    {errors.toEmail && touched.toEmail && <div className="error">{errors.toEmail}</div>}
                    {errors.tag && touched.tag && <div className="error">{errors.tag}</div>}
                    { errors.global && <div className="error">{errors.global}</div> }
                </div>}
                {isSubmitting || status === 'submitted'
                    ? this.renderWaiting()
                    : <input type="submit" value={lang.d('copy_charts')} className="FormButton"/>
                }
            </form>
        )
    }
}

CopyChartsByTagForm.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object,
    isSubmitting: PropTypes.bool,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    values: PropTypes.object,
    status: PropTypes.string,
    setStatus: PropTypes.func
}

const CopyChartsByTag = () => {
    const dispatch = useDispatch()

    return (
        <Formik
            initialValues={{ fromEmail: '', toEmail: '', tag: '' }}
            validate={values => validateCopyChartsByTag(values)}
            validateOnChange={false}
            onSubmit={(values, { setErrors, setStatus }) => {
                const dialogServiceDescription = <div>{lang.d('copy_charts_can_be_many_warning')}
                    <div className="draft-validation-warning">
                        <div key="fromEmail" className="validation-item"><span className="caption">{lang.d('from')}: {values.fromEmail}</span></div>
                        <div key="toEmail" className="validation-item"><span className="caption">{lang.d('to')}: {values.toEmail}</span></div>
                        <div key="tag" className="validation-item"><span className="caption">{lang.d('tag')}: {values.tag ? values.tag : lang.d('n/a')}</span></div>
                    </div>
                    {!values.tag && <div className="draft-validation-warning warning">{lang.d('no_tag_to_copy_specified', { from: values.fromEmail, to: values.toEmail })}</div>}
                    {values.tag && <div className="draft-validation-warning warning">{lang.d('tag_to_copy_specified', { tag: values.tag, from: values.fromEmail, to: values.toEmail })}</div>}
                </div>

                dispatch(openModalDialog({
                    title: lang.d('copy_charts') + '?',
                    message: dialogServiceDescription,
                    successMessage: lang.d('copied'),
                    settings: {
                        onCancel: () => {},
                        onAccept: () => copyChartsByTag(values.fromEmail, values.toEmail, values.tag),
                        onAcceptFailed: error => {
                            const { messages } = error
                            setErrors(parseBackendErrors(messages))
                            setStatus(null)
                        },
                        acceptCaption: lang.d('confirm_copy_charts'),
                        dangerousAction: true,
                        waitOnAccept: true
                    }
                }))
            }}
            render={props => <CopyChartsByTagForm {...props} />}
        />
    )
}

export default CopyChartsByTag
