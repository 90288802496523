import lang from './lang'

const validEventKey = (key) => {
    const expr = /[-]*[a-z0-9]+[-]*/ig
    const reducedKey = key.match(expr) ? key.match(expr).reduce((accumulator, currentValue) => accumulator + currentValue) : ''
    return (key.match(expr) !== null && reducedKey.length === key.length)
}

const keyIsEmptyOrJustDashes = (key) => {
    if (key === '') return true

    const expr = /[-]*/ig
    const reducedKey = key.match(expr) ? key.match(expr).reduce((accumulator, currentValue) => accumulator + currentValue) : ''
    return (key.match(expr) !== null && reducedKey.length === key.length)
}

const validateLogin = (values) => {
    const errors = {}
    if (!values.email || !values.password) {
        errors.email = lang.d('email_password_required')
    } else if (!validateEmail(values.email)) {
        errors.email = lang.d('invalid_email')
    }
    return errors
}

const validateCopySingleChart = (values) => {
    const errors = {}
    if (!values.workspaceKey) {
        errors.workspaceKey = lang.d('workspace_key_required')
    }
    if (!values.chartKey) {
        errors.chartKey = lang.d('chart_key_required')
    }
    return errors
}

const validateCopyChartsByTag = (values) => {
    const errors = {}
    if (!values.fromEmail) {
        errors.fromEmail = lang.d('from_email_required')
    }
    if (!values.toEmail) {
        errors.toEmail = lang.d('to_email_required')
    }
    if (!errors.fromEmail && !validateEmail(values.fromEmail)) {
        errors.fromEmail = lang.d('invalid_email')
    }
    if (!errors.toEmail && !validateEmail(values.toEmail)) {
        errors.toEmail = lang.d('invalid_email')
    }
    return errors
}

const validateEmail = email => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
}

const validatePassword = password => {
    return password.length > 7
}

const validateEventKey = (key) => {
    const errors = {}
    if (keyIsEmptyOrJustDashes(key)) {
        errors.newEventKeyError = lang.d('empty_key_warning')
    } else if (!validEventKey(key)) {
        errors.newEventKeyError = lang.d('invalid_key_warning')
    }
    return errors
}

const validateEventName = (name) => {
    const errors = {}
    if (!name || !/\S/.test(name)) {
        errors.newEventNameError = lang.d('empty_name_warning')
    } else if (name.length > 80) {
        errors.newEventNameError = lang.d('name_too_long_warning', {maxChars: 80})
    }
    return errors
}

export {
    validEventKey,
    keyIsEmptyOrJustDashes,
    validateLogin,
    validateCopySingleChart,
    validateCopyChartsByTag,
    validateEventKey,
    validateEmail,
    validatePassword,
    validateEventName
}
