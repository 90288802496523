import React, {Component} from 'react'
import {connect} from 'react-redux'
import {setHeader} from '../../actions/header'
import {exposeErrors} from '../../utils/errors'
import {adminService} from '../../services/admin.service'
import * as PropTypes from 'prop-types'
import store, {history} from '../../store'
import {openModalDialog} from '../../actions/modalDialog'
import {addMonths, format, isThisMonth, subMonths} from 'date-fns'
import sortBy from 'lodash/sortBy'
import {copyToClipboard} from '../../utils/clipboard'
import {DEFAULT_NOTIFICATION_TIMEOUT, notificationService} from '../../services/notification.service'
import lang from '../../utils/lang'

class AdminBillingReport extends Component {
    state = {
        report: null
    }

    async componentDidMount () {
        this.props.setHeader({
            showSearch: true,
            hasItems: true,
            centerItems: [],
            rightItems: [],
            leftItems: [{
                to: '/admin',
                icon: 'icon-arrow-light-left',
                string_key: 'admin',
                className: 'soft-button'
            }]
        })
        const [report, companies, pricingPlans, invoices] = await exposeErrors(Promise.all([
            adminService.getBillingReport(this.getMonth(), this.getYear()),
            adminService.getAllCompanies(),
            adminService.getPricingPlans(),
            adminService.getInvoices(this.getMonth(), this.getYear())
        ]))
        this.setState({ report, companies, pricingPlans, invoices })
    }

    async componentDidUpdate (prevProps) {
        if (this.props.match.params.month !== prevProps.match.params.month ||
            this.props.match.params.year !== prevProps.match.params.year) {
            const [report, invoices] = await exposeErrors(Promise.all([
                adminService.getBillingReport(this.getMonth(), this.getYear()),
                adminService.getInvoices(this.getMonth(), this.getYear())
            ]))
            this.setState({ report, invoices })
        }
    }

    switchToMonth (month) {
        history.push(`/admin/billing-report/${month.getFullYear()}/${month.getMonth() + 1}`)
    }

    getMonth () {
        const monthInUrl = this.props.match.params.month
        if (monthInUrl) {
            return parseInt(monthInUrl)
        }
        return new Date().getMonth() + 1
    }

    getYear () {
        const yearInUrl = this.props.match.params.year
        if (yearInUrl) {
            return parseInt(yearInUrl)
        }
        return new Date().getFullYear()
    }

    render () {
        const formatPrice = (price) => {
            const decimalPlaces = 3
            return Number(Math.round(parseFloat(price + 'e' + decimalPlaces)) + 'e-' + decimalPlaces) + '€'
        }

        const pricingPlanName = (billingItem, pricingPlan) => {
            const name = pricingPlan.name || 'custom pricing plan'
            if (billingItem.isYearly) {
                return `yearly ${name}`
            } else {
                return `monthly ${name}`
            }
        }

        const invoiceControls = (billingItem, company) => {
            const invoice = this.state.invoices.find(invoice => invoice.type === billingItem.type && invoice.companyId === company.id)
            if (invoice !== undefined) {
                const deleteInvoiceButton = <button type="button" className="button icon icon-delete left-and-right-space" title="Delete invoice" onClick={e => removeInvoice(invoice, e.target)}></button>
                if (invoice.zohoInvoiceLink) {
                    return <span className="table-tools">
                        <a className="link external-link" href={invoice.zohoInvoiceLink} target="_blank">View invoice</a>
                        {deleteInvoiceButton}
                    </span>
                } else {
                    return <span className="table-tools">
                        <input type="checkbox" checked={true} readOnly={true} title="An invoice exists in the database but no Zoho link is available"/>
                        {deleteInvoiceButton}
                    </span>
                }
            } else {
                return <button type="button" className="button icon icon-plus" title="Create invoice" onClick={e => createInvoice(billingItem.type, company.id, company.region, e.target)}></button>
            }
        }

        const createInvoice = async (type, companyId, companyRegionId, element) => {
            element.disabled = true
            await exposeErrors(adminService.createInvoice(type, this.getMonth(), this.getYear(), companyId, companyRegionId),
                () => element.disabled = false,
                store.getState())
                .then(invoice => this.setState({ invoices: [...this.state.invoices, invoice] }))
        }

        const removeInvoice = async (invoice, element) => {
            element.disabled = true
            await exposeErrors(adminService.removeInvoice(invoice), () => element.disabled = false, store.getState())
                .then(() => {
                    const newInvoices = [...this.state.invoices]
                    newInvoices.splice(this.state.invoices.indexOf(invoice), 1)
                    this.setState({ invoices: newInvoices })
                })
        }

        const descriptionOnInvoice = (message) => {
            return <button className="button-link" onClick={() => copyStringToClipboard(message)} title={message}>
                <span className="icon icon-copy"/>
                &nbsp;<span>Copy</span>
            </button>
        }

        const copyStringToClipboard = (message) => {
            if (copyToClipboard(message)) {
                notificationService.info('Copied to clipboard', '"' + message + '"', DEFAULT_NOTIFICATION_TIMEOUT)
            } else {
                notificationService.error(lang.d('error'), lang.d('splash_screen_general_error_modal_dialog_message'), DEFAULT_NOTIFICATION_TIMEOUT)
            }
        }

        if (!this.state.report) {
            return null
        }

        return <div className="Reports" id="billing-report">
            <div className="container">
                <AdminBillingReportNavigation
                    month={new Date(this.getYear(), this.getMonth() - 1)}
                    monthSwitched={month => this.switchToMonth(month)}
                />
                <AdminBillingReportDetail
                    report={this.state.report}
                    companies={this.state.companies}
                    columnHeaders={['Pricing plan', 'Rate / seat', 'Usage month', 'Committed usage', 'Actual usage', 'Description', 'To be invoiced', 'Invoiced?']}
                    pricingPlans={this.state.pricingPlans}
                    columnValues={(billingItem, pricingPlan, company) => [
                        pricingPlanName(billingItem, pricingPlan),
                        formatPrice(pricingPlan.pricePerSeat),
                        formatPeriod(billingItem.period),
                        pricingPlan.numberOfCommittedSeats,
                        billingItem.usage,
                        descriptionOnInvoice(`seats.io subscription ${pricingPlanName(billingItem, pricingPlan)} ${formatPeriodLong(billingItem.period)}`),
                        formatPrice(billingItem.amountToInvoice),
                        invoiceControls(billingItem, company)]
                    }
                    title="Monthly subscriptions"
                    filter={billingItem => billingItem.type === 'MONTHLY_SUBSCRIPTION'}
                />
                <AdminBillingReportDetail
                    report={this.state.report}
                    companies={this.state.companies}
                    columnHeaders={['Pricing plan', 'Rate / seat', 'Usage month', 'Actual usage', 'Accumulated usage', 'Description', 'To be invoiced', 'Invoiced?']}
                    pricingPlans={this.state.pricingPlans}
                    columnValues={(billingItem, pricingPlan, company) =>
                        [
                            pricingPlanName(billingItem, pricingPlan),
                            formatPrice(pricingPlan.pricePerSeat),
                            formatPeriod(billingItem.period),
                            billingItem.usage,
                            billingItem.accumulatedUsage,
                            descriptionOnInvoice(`seats.io subscription usage during ${formatPeriodLong(billingItem.period)} above the initial ${pricingPlan.numberOfCommittedSeats} purchased`),
                            formatPrice(billingItem.amountToInvoice),
                            invoiceControls(billingItem, company)
                        ]
                    }
                    title="Yearly subscriptions - monthly overuse"
                    filter={billingItem => billingItem.type === 'YEARLY_SUBSCRIPTION_MONTHLY_OVERUSE'}
                />
                <AdminBillingReportDetail
                    report={this.state.report}
                    companies={this.state.companies}
                    columnHeaders={['Pricing plan', 'Renewal period', 'Committed minimum', 'Description', 'To be invoiced', 'Invoiced?']}
                    pricingPlans={this.state.pricingPlans}
                    columnValues={(billingItem, pricingPlan, company) => [
                        pricingPlanName(billingItem, pricingPlan),
                        formatPeriod(billingItem.period),
                        billingItem.committedMinimum,
                        descriptionOnInvoice(`seats.io subscription, renewal of ${pricingPlanName(billingItem, pricingPlan)} from ${formatPeriodLong(billingItem.period)}`),
                        formatPrice(billingItem.amountToInvoice),
                        invoiceControls(billingItem, company)]
                    }
                    title="Yearly subscriptions - renewals"
                    filter={billingItem => billingItem.type === 'YEARLY_SUBSCRIPTION' && billingItem.isRenewal}
                />
                <AdminBillingReportDetail
                    report={this.state.report}
                    companies={this.state.companies}
                    columnHeaders={['Pricing plan', 'Subscription period', 'Committed minimum', 'Description', 'To be invoiced', 'Invoiced?']}
                    pricingPlans={this.state.pricingPlans}
                    columnValues={(billingItem, pricingPlan, company) => [
                        pricingPlanName(billingItem, pricingPlan),
                        formatPeriod(billingItem.period),
                        billingItem.committedMinimum,
                        descriptionOnInvoice(`seats.io subscription to ${pricingPlanName(billingItem, pricingPlan)} from ${formatPeriodLong(billingItem.period)}`),
                        formatPrice(billingItem.amountToInvoice),
                        invoiceControls(billingItem, company)]
                    }
                    title="Yearly subscriptions - initial subscriptions"
                    filter={billingItem => billingItem.type === 'YEARLY_SUBSCRIPTION' && billingItem.isYearly && !billingItem.isRenewal}
                />
            </div>
        </div>
    }
}

AdminBillingReport.propTypes = {
    setHeader: PropTypes.func,
    match: PropTypes.object
}

function AdminBillingReportNavigation (props) {
    const switchToPreviousMonth = month => {
        const newMonth = subMonths(month, 1)
        props.monthSwitched(newMonth)
    }

    const switchToNextMonth = month => {
        const newMonth = addMonths(month, 1)
        props.monthSwitched(newMonth)
    }

    return <>
        <div className="summary">
            <div className="title">
                <span className="icon icon-arrow-left" onClick={() => switchToPreviousMonth(props.month)}/>
                Invoices of {formatDate(props.month)}
                <span className={`icon icon-arrow-right ${isThisMonth(props.month) && 'hidden'}`} onClick={() => switchToNextMonth(props.month)}/>
            </div>
        </div>
    </>
}

AdminBillingReportNavigation.propTypes = {
    monthSwitched: PropTypes.func,
    month: PropTypes.object
}

function AdminBillingReportDetail (props) {
    const companiesById = companies => {
        const result = {}
        companies.forEach(company => {
            result[company.id] = company
        })
        return result
    }

    const pricingPlansByIdAndYearly = pricingPlans => {
        const result = {}
        pricingPlans.forEach(pricingPlan => {
            result[pricingPlan.id + '-' + pricingPlan.isYearly] = pricingPlan
        })
        return result
    }

    const rowData = () => {
        return props.report.map(reportItem => {
            const applicableBillingItems = reportItem.billingItems.filter(billingItem => props.filter(billingItem))
            if (applicableBillingItems.length === 0) {
                return null
            }
            const applicableBillingItem = applicableBillingItems[0]
            const pricingPlan = pricingPlansByIdAndYearly(props.pricingPlans)[applicableBillingItem.pricingPlanId + '-' + applicableBillingItem.isYearly]
            const company = companiesById(props.companies)[reportItem.companyId]
            return {
                reportItem: reportItem,
                company: company,
                applicableBillingItem: applicableBillingItem,
                pricingPlan: pricingPlan
            }
        }).filter(item => item !== null)
    }

    return <>
        <div className="title">{props.title}</div>
        <div className="DataTable billing">
            <table style={{ cellPadding: '5' }}>
                <thead>
                    <tr>
                        <th style={{ width: '200px' }}>Company</th>
                        {props.columnHeaders.map(header => <th>{header}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {
                        sortBy(rowData(), row => {
                            return row.pricingPlan.id + '-' + row.pricingPlan.isYearly
                        }).map(row => {
                            return <tr key={`report-item-${row.reportItem.companyId}`}>
                                <td><a href={`/admin/company/${row.company.region}/${row.company.id}`}>{row.company.name}</a>
                                </td>
                                {props.columnValues(row.applicableBillingItem, row.pricingPlan, row.company).map(value =>
                                    <td>{value}</td>)}
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    </>
}

AdminBillingReportDetail.propTypes = {
    report: PropTypes.array,
    companies: PropTypes.array,
    pricingPlans: PropTypes.array,
    columnHeaders: PropTypes.array,
    filter: PropTypes.func,
    columnValues: PropTypes.func,
    title: PropTypes.string
}

const formatPeriod = period => {
    const toDate = month => new Date(month.year, month.month - 1)

    if (period.start) {
        return `${formatDate(toDate(period.start))} to ${formatDate(toDate(period.end))}`
    }
    return formatDate(toDate(period))
}

const formatDate = month => {
    return format(month, 'MMM yyyy')
}

const formatPeriodLong = period => {
    const toDate = month => new Date(month.year, month.month - 1)

    if (period.start) {
        return `${formatDateLong(toDate(period.start))} to ${formatDateLong(toDate(period.end))}`
    }
    return formatDateLong(toDate(period))
}

const formatDateLong = month => {
    return format(month, 'MMMM yyyy')
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload)),
    openModalDialog: payload => dispatch(openModalDialog(payload))
})

export default connect(null, mapDispatchToProps)(AdminBillingReport)
