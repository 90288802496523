import React, { useEffect, useState } from 'react'
import { userService } from '../../services/user.service'
import { history } from '../../store'
import * as PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../actions/user'
import SplashScreenModalDialog from '../../components/GUI/SplashScreenModalDialog'
import lang from '../../utils/lang'
import { hasErrorCode } from '../../utils/errors'
import SplashScreenGeneralErrorModalDialog from '../../components/GUI/SplashScreenGeneralErrorModalDialog'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { DEFAULT_REGION } from '../../domain/region'

const AcceptInvitationForExistingUser = props => {
    const [error, setError] = useState(false)
    const loggedInUser = useSelector(state => state.user)
    const [splashForCancelledInvitation, setSplashForCancelledInvitation] = useState(null)
    const dispatch = useDispatch()
    const code = props.match.params.code
    const region = queryString.parse(props.location.search).region || DEFAULT_REGION

    useEffect(() => {
        async function init () {
            try {
                const user = await userService.acceptInvitationForExistingUser(code, region)
                dispatch(setUser(user))
                history.push('/')
            } catch (e) {
                if (hasErrorCode(e, 'INVITATION_CANCELLED')) {
                    setSplashForCancelledInvitation(<SplashScreenModalDialog
                        title={lang.d('splash_screen_general_error_modal_dialog_title')}
                        message={lang.d('invitation_cancelled')}
                        actions={<>
                            <Link to="/" className="action highlighted">
                                { loggedInUser ? lang.d('go_to_home') : lang.d('go_to_log_in')}
                            </Link>
                        </>}
                    />)
                }

                setError(true)
            }
        }

        init()
    }, [dispatch, code, loggedInUser, region])

    return error
        ? splashForCancelledInvitation || <SplashScreenGeneralErrorModalDialog />
        : <SplashScreenModalDialog body={<div className="dialog">Accepting invitation...</div>}/>
}

AcceptInvitationForExistingUser.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object
}

export default AcceptInvitationForExistingUser
