import React from 'react'
import lang from '../../utils/lang'
import Input from '../Input'
import * as PropTypes from 'prop-types'
import { GlobalError } from './ModalDialogGlobalError'

export const EditEmailForm = props => {
    const {
        errors,
        touched,
        handleChange,
        isSubmitting,
        values
    } = props

    const emailErrors = errors.email && touched.email

    return <>
        <div className="form">
            <div className="form-field wide">
                <Input name="email" type="text" value={values.email} placeholder={lang.d('email')} className={emailErrors && 'error'} onChange={handleChange} disabled={isSubmitting || props.status === 'submitted'} autoFocus />
                { emailErrors &&
                <div className="errors">
                    { emailErrors && <div className="error">{errors.email}</div> }
                </div>
                }
            </div>
        </div>
        <GlobalError errors={errors} />
    </>
}

EditEmailForm.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    values: PropTypes.object,
    isSubmitting: PropTypes.bool,
    status: PropTypes.string
}
