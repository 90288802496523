import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import lang from '../utils/lang'

import Input from '../components/Input'
import { connect } from 'react-redux'
import { setHeader } from '../actions/header'
import ActiveWorkspaceList from '../components/GUI/ActiveWorkspaceList'
import { workspacesService } from '../services/workspaces.service'
import { isCompanyOrSuperAdmin } from '../reducers/user'
import { openModalDialog, setModalSubmitting } from '../actions/modalDialog'
import { GlobalError } from '../components/GUI/ModalDialogGlobalError'
import store from '../store'

const TEST_WORKSPACE = 'test'
const PRODUCTION_WORKSPACE = 'production'

const CreateWorkspaceForm = props => {
    const {
        errors,
        touched,
        handleChange,
        isSubmitting,
        values
    } = props

    const nameErrors = errors.name && touched.name
    return <>
        <div className="form">
            <div className="form-field wide">
                <Input name="name" type="text" value={values.name} placeholder={lang.d('name')} onChange={handleChange} disabled={isSubmitting || props.status === 'submitted'} autoFocus />
                { nameErrors &&
                    <div className="errors">
                        { nameErrors && <div className="error">{errors.name}</div> }
                    </div>
                }
            </div>
            <div className="form-field">
                <div className="field-options no-icons">
                    <div className={`field-option production ${values.workspaceType === PRODUCTION_WORKSPACE && 'selected'}`}>
                        <input id="productionWorkspace" name="workspaceType" type="radio" onChange={handleChange} value={PRODUCTION_WORKSPACE} />
                        <label htmlFor="productionWorkspace">{ lang.d('production') }</label>
                    </div>
                    <div className={`field-option test ${values.workspaceType === TEST_WORKSPACE && 'selected'}`}>
                        <input id="testWorkspace" name="workspaceType" type="radio" onChange={handleChange} value={TEST_WORKSPACE} />
                        <label htmlFor="testWorkspace">{ lang.d('test') }</label>
                    </div>
                </div>
            </div>
            { values.workspaceType === TEST_WORKSPACE &&
                <div className="hint center">{lang.d('test_workspace_hint')}</div>
            }
            { values.workspaceType === PRODUCTION_WORKSPACE && props.hasChosenSubscriptionPlan &&
                <div className="hint center">{lang.d('production_workspace_hint_active_subscription')}</div>
            }
            { values.workspaceType === PRODUCTION_WORKSPACE && !props.hasChosenSubscriptionPlan &&
                <div className="hint center">{lang.d('production_workspace_hint_no_active_subscription')}</div>
            }
        </div>
        <GlobalError errors={errors} />
    </>
}

CreateWorkspaceForm.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    values: PropTypes.object,
    isSubmitting: PropTypes.bool,
    status: PropTypes.string,
    setFieldValue: PropTypes.func,
    hasChosenSubscriptionPlan: PropTypes.bool
}

class Workspaces extends Component {
    constructor (props) {
        super(props)
        this.createWorkspace = this.createWorkspace.bind(this)
        this.startTimerForAnimation = this.startTimerForAnimation.bind(this)
        this.animateInFirstItemTimer = null
        this.state = {
            animateInFirstItem: false
        }
    }

    refreshCache () {
        this.setState({ cacheId: this.state.cacheId + 1 })
    }

    componentDidMount () {
        this.props.setHeader({
            showSearch: true,
            leftItems: isCompanyOrSuperAdmin(this.props.user)
                ? [{
                    button: true,
                    icon: 'icon-plus-bold',
                    string_key: 'create_workspace',
                    id: 'createWorkspaceButton',
                    onClick: this.createWorkspace
                },
                {
                    to: '/workspaces/inactive',
                    icon: 'icon-archive',
                    string_key: 'inactive_workspaces'
                }]
                : []
        })
    }

    componentWillUnmount () {
        if (this.animateInFirstItemTimer) clearTimeout(this.animateInFirstItemTimer)
    }

    createWorkspace () {
        this.setState({ animateInFirstItem: false })
        this.props.openModalDialog({
            title: lang.d('create_workspace'),
            message: lang.d('create_workspace_description'),
            onSubmit: values => workspacesService.createWorkspace(values.name, values.workspaceType === TEST_WORKSPACE),
            onSubmitSuccess: (_, response) => this.props.add(response),
            successMessage: lang.d('workspace_created'),
            settings: {
                acceptCaption: lang.d('create'),
                formik: {
                    initialValues: { name: '', workspaceType: PRODUCTION_WORKSPACE },
                    validate: values => {
                        const errors = {}
                        if (values.name === '') {
                            errors.name = lang.d('required')
                        }
                        return errors
                    },
                    render: props => <CreateWorkspaceForm {...props} hasChosenSubscriptionPlan={this.props.company.hasChosenSubscriptionPlan} />
                },
                dialogClassName: 'create-workspace-dialog'
            }
        })
    }

    startTimerForAnimation () {
        this.setState({
            animateInFirstItem: true
        })
        if (this.animateInFirstItemTimer) clearTimeout(this.animateInFirstItemTimer)
        this.animateInFirstItemTimer = setTimeout(() => {
            if (this.state.animateInFirstItem) this.setState({ animateInFirstItem: false })
        }, 2000)
    }

    render () {
        return <>
            <ActiveWorkspaceList
                animateInFirstItem={this.state.animateInFirstItem}
                readOnly={!isCompanyOrSuperAdmin(this.props.user)}
            />
        </>
    }
}

Workspaces.propTypes = {
    user: PropTypes.object,
    company: PropTypes.object,
    setHeader: PropTypes.func,
    location: PropTypes.object,
    add: PropTypes.func,
    updateWorkspace: PropTypes.func,
    openModalDialog: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        user: state.user,
        company: state.context.company
    }
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload)),
    add: payload => dispatch({
        type: 'WORKSPACES/ADD',
        payload
    }),
    setModalSubmitting: payload => dispatch(setModalSubmitting(payload)),
    openModalDialog: payload => dispatch(openModalDialog(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Workspaces)
