import React from 'react'
import lang from '../../utils/lang'
import { Link } from 'react-router-dom'
import * as PropTypes from 'prop-types'
import LoadingIndicator from './LoadingIndicator'

export default class Thumbnail extends React.PureComponent {
    constructor (props) {
        super(props)
        this.state = { isLoading: true }
    }

    handleImageLoaded () {
        this.setState({ isLoading: false })
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.image !== prevProps.image && this.props.image === 'couldNotRetrieve') {
            this.setState({ isLoading: true })
        }
    }

    render () {
        const { chartKey, image } = this.props
        const withOptions = !!chartKey
        const linkTo = {
            pathname: `/charts/${chartKey}/edit`,
            state: { fromSeatsio: true }
        }

        return (
            <div className={`Thumbnail ${withOptions && 'with-options'}`}>
                {image !== 'couldNotRetrieve' && <img
                    src={image}
                    alt={chartKey}
                    onLoad={this.handleImageLoaded.bind(this)}
                />}
                {this.state.isLoading && <LoadingIndicator/>}
                { withOptions && <div className="options">
                    <Link to={linkTo}>
                        {lang.d('edit_seating_chart')}
                    </Link>
                </div> }
            </div>
        )
    }
}

Thumbnail.propTypes = {
    chartKey: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
}
