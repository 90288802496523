const defaultState = {
    invitations: []
}

const reports = (state = defaultState, action) => {
    switch (action.type) {
    case 'TEAM/SET_INVITATIONS':
        return {
            ...state,
            invitations: action.payload
        }
    case 'TEAM/ADD_INVITATION':
        return {
            ...state,
            invitations: [
                action.payload,
                ...state.invitations
            ]
        }
    case 'TEAM/CANCEL_INVITATION':
        // eslint-disable-next-line no-case-declarations
        const index = state.invitations.findIndex((i) => i.id === action.id)
        return {
            ...state,
            invitations: [
                ...state.invitations.slice(0, index),
                ...state.invitations.slice(index + 1)
            ]
        }
    default:
        return state
    }
}

export default reports
