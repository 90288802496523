import axios from 'axios'
import { Environment } from '../environment'
import store from '../store'

export async function retrieveRegions () {
    try {
        const res = await axios({
            method: 'get',
            url: Environment.backendUrl + '/system/public/regions'
        })
        return res.data
    } catch (e) {
        throw e.response.data
    }
}
