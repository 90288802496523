import React, {Component} from "react";
import {
    setCompanyAdminKey,
    setCompanyDefaultRendererSetting,
    setCompanySetting,
    setCompanyValidationSetting,
    updateCompanySettings
} from "../../../actions/user";
import {resetHeader} from "../../../actions/header";
import {closeDialog, openModalDialog} from "../../../actions/modalDialog";
import {connect} from "react-redux";
import lang from "../../../utils/lang";
import {formatWorkspaceName} from "../../../utils/helperFunctions";
import {backendUrlOfActiveCompany, chartJSUrlOfActiveCompany, regionOfActiveCompany} from "../../../domain/regions"
import * as PropTypes from "prop-types"
import {regenerateCompanyAdminKey} from '../../../services/companies.service'
import {exposeErrors} from "../../../utils/errors";

class GeneralCompanySettings extends Component {

    render() {
        return <>
            <div className="setting-page-title">{ lang.d('general') }</div>
            {this.renderAdminKey()}
            {this.renderDefaultWorkspace()}
            {this.renderRegion()}
        </>
    }

    renderAdminKey() {
        return <div className="setting">
            <div className="notice">
                <div className="title">{lang.d('security_notice')}</div>
                <div className="description">{lang.d('security_notice_admin_key')}</div>
                <p><a
                    className="arrow"
                    href="https://docs.seats.io/docs/api/authentication#using-the-company-admin-key"
                    target="_blank"
                    rel="noopener noreferrer">{lang.d('read_more')}
                </a></p>
            </div>
            <div className="title">{lang.d('admin_key')}</div>
            <div className="key editable">
                <div>{this.props.adminKey}</div>
                <div className="btn-container">
                    <button id="regenerateAdminKeyButton" title={lang.d('regenerate_company_admin_key')}
                        className="button-link" onClick={this.regenerateAdminKey.bind(this)}><span
                            className="icon icon-refresh"/></button>
                </div>
            </div>
        </div>
    }

    regenerateAdminKey() {
        this.props.openModalDialog({
            title: lang.d('regenerate_company_admin_key'),
            message: lang.d('regenerate_company_admin_key_confirmation'),
            successMessage: lang.d('company_admin_key_regenerated'),
            settings: {
                onAccept: () => this.handleRegenerateAdminKey(),
                acceptCaption: lang.d('regenerate'),
                dangerousAction: true,
                waitOnAccept: true
            }
        })
    }

    async handleRegenerateAdminKey() {
        const newAdminKey = await exposeErrors(regenerateCompanyAdminKey(this.props.company.id), () => this.props.closeModalDialog())
        this.props.setCompanyAdminKey(newAdminKey)
    }

    renderDefaultWorkspace() {
        return <div className="setting">
            <div className="notice">
                <div className="title">{lang.d('default_workspace_notice')}</div>
                <div className="description">{lang.d('default_workspace_notice_description')}</div>
            </div>
            <div className="title">{lang.d('default_workspace')}</div>
            <div className="key">{formatWorkspaceName(this.props.company.defaultWorkspace)}</div>
        </div>
    }

    renderRegion() {
        return <div className="setting">
            <div className="notice">
                <div className="description">
                    <p>{lang.d('company_region_notice_1')}</p>
                    <p>{lang.d('company_region_notice_2')} <a className="link arrow"
                        href="https://support.seats.io/en/articles/5129612-regions"
                        target="_blank">{lang.d('read_more')}</a></p>
                </div>
            </div>
            <div className="title">Seats.io region</div>
            <div className="key">{regionOfActiveCompany().name} ({regionOfActiveCompany().id})</div>
            <div className="sub-title">{lang.d('api_url')}</div>
            <div className="key">{backendUrlOfActiveCompany()}</div>
            <div className="sub-title">{lang.d('chartjs_url')}</div>
            <div className="key">{chartJSUrlOfActiveCompany()}</div>
        </div>
    }


}

GeneralCompanySettings.propTypes = {
    adminKey: PropTypes.string,
    user: PropTypes.object,
    company: PropTypes.object,
    setting: PropTypes.object.isRequired,
    updateCompanySettings: PropTypes.func.isRequired,
    setSetting: PropTypes.func.isRequired,
    setValidationSetting: PropTypes.func.isRequired,
    setDefaultRendererSetting: PropTypes.func.isRequired,
    resetHeader: PropTypes.func.isRequired,
    openModalDialog: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    setCompanyAdminKey: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    setting: state.context.company.settings,
    adminKey: state.context.company.adminKey,
    user: state.user,
    company: state.context.company
})

const mapDispatchToProps = dispatch => ({
    updateCompanySettings: (user) => dispatch(updateCompanySettings(user)),
    setSetting: (setting, value) => dispatch(setCompanySetting(setting, value)),
    setValidationSetting: (setting, value) => dispatch(setCompanyValidationSetting(setting, value)),
    setDefaultRendererSetting: (setting, value) => dispatch(setCompanyDefaultRendererSetting(setting, value)),
    setCompanyAdminKey: adminKey => dispatch(setCompanyAdminKey(adminKey)),
    resetHeader: () => dispatch(resetHeader()),
    openModalDialog: payload => dispatch(openModalDialog(payload)),
    closeModalDialog: payload => dispatch(closeDialog(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(GeneralCompanySettings)
