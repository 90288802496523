import React from 'react'
import * as PropTypes from 'prop-types'

/**
 * @prop {string} preset Button style or theme
 * @prop {string} type Icon type to display
 * @prop {string} softIcon Icon displayed as an action hint
 * @prop {string} caption
 * @prop {string} tooltip
 * @prop {Integer} size
 * @prop {string} color
 * @prop {Object} style
 * @prop {boolean} selected
 * @prop {boolean} dropdownSelected
 * @prop {boolean} disabled
 * @prop {boolean} softTooltip
 * @prop {callback} onMouseOver
 * @prop {callback} onMouseOut
 * @prop {callback} onMouseDown
 * @prop {callback} onMouseUp
 * @prop {callback} onClick
 * @prop {callback} onDoubleClick
 * @prop {callback} onHold
 * @prop {Integer} onHoldThreshold
 * @prop {callback} onDrag
 * @prop {Integer} dragThreshold
 * @prop {callback} childRef
 */
export default class Button extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            pressed: false
        }
        this.onMouseUp = this.onMouseUp.bind(this)
        this.onHoldTriggered = false
    }

    onMouseDown (e) {
        if (this.props.disabled || this.onHoldTriggered) return
        if (this.props.onMouseDown) this.props.onMouseDown()
        this.setState({ pressed: true })
        this.dragStartPosition = { x: e.clientX, y: e.clientY }
        if (this.props.onHold) {
            this.onHoldTimeout = setTimeout(() => {
                this.props.onHold()
                this.onHoldTriggered = true
            }, this.props.onHoldThreshold || 220)
        }
        window.addEventListener('mouseup', this.onMouseUp)
    }

    onMouseUp () {
        if (this.props.disabled) return
        if (!this.onHoldTriggered && this.props.onMouseUp) this.props.onMouseUp()
        this.setState({ pressed: false })
        if (this.onHoldTimeout) {
            clearTimeout(this.onHoldTimeout)
        }
        window.removeEventListener('mouseup', this.onMouseUp)
        requestAnimationFrame(() => this.onHoldTriggered = false)
    }

    onMouseMove (e) {
        if (this.state.pressed && this.props.onDrag) {
            const deltaX = this.dragStartPosition.x - e.clientX
            const deltaY = this.dragStartPosition.y - e.clientY
            const distance = Math.sqrt((deltaX * deltaX) + (deltaY * deltaY))
            if (distance > (this.dragThreshold || 2)) {
                this.props.onDrag()
            }
        }
    }

    onClick (e) {
        if (this.props.disabled || this.onHoldTriggered) return
        if (this.doubleClickTimeout) {
            this.props.onDoubleClick(e)
        } else {
            if (this.props.onClick) {
                this.props.onClick(e)
            }
            if (this.props.onDoubleClick) {
                this.doubleClickTimeout = setTimeout(() => {
                    this.doubleClickTimeout = null
                }, 300)
            }
        }
    }

    triggerOnHold () {
        if (this.props.disabled) return
        this.onHoldTriggered = true
        this.setState({ pressed: false })
        if (this.onHoldTimeout) {
            clearTimeout(this.onHoldTimeout)
        }
        window.removeEventListener('mouseup', this.onMouseUp)
        if (this.props.onHold) {
            this.props.onHold()
        }
    }

    compositeTooltip () {
        return this.props.tooltip || (this.props.caption ? this.props.caption + (this.props.keyHint ? ` (${this.props.keyHint})` : '') : '')
    }

    componentWillUnmount () {
        window.removeEventListener('mouseup', this.onMouseUp)
    }

    render () {
        let content
        let tooltip
        let withCaption

        switch (this.props.preset) {
        case 'contextual-menu-item':
            withCaption = this.props.caption
            tooltip = withCaption && !this.props.keyHint ? null : this.compositeTooltip()
            content = <span key="wrapper" className={`wrapper ${this.props.softIcon && 'with-soft-icon'}`}>
                <span key="icon" className={'icon icon-' + this.props.type}></span>
                { withCaption && <span key="caption" className="caption">{this.props.caption}</span> }
                { this.props.softIcon && <span className={`softIcon icon-${this.props.softIcon}`}></span>}
            </span>
            break
        case 'round':
            content = <span title={this.props.tooltip || this.props.caption} className={'icon icon-' + this.props.type}></span>
            break
        default:
            content = this.props.caption
        }

        const localStyle = this.props.size ? { fontSize: this.props.size + 'px' } : {}

        return <button
            className={`Button preset-${this.props.preset} color-${this.props.color} ${this.props.selected && 'selected'} ${this.props.dropdownSelected && 'dropdown-selected'} ${withCaption && 'with-caption'} ${this.props.showTooltip && 'show-tooltip'} ${this.props.softTooltip && 'soft-tooltip'}`}
            style={this.props.style ? localStyle.merge(this.props.style) : localStyle}
            onMouseOver={this.props.onMouseOver}
            onMouseOut={this.props.onMouseOut}
            onMouseMove={this.onMouseMove.bind(this)}
            onMouseDown={this.onMouseDown.bind(this)}
            onMouseUp={this.onMouseUp.bind(this)}
            onClick={this.onClick.bind(this)}
            disabled={this.props.disabled}
            title={tooltip || ''}
            ref={this.props.childRef}
            name={this.props.name}
        >
            { content }
        </button>
    }
}

Button.propTypes = {
    type: PropTypes.string,
    softIcon: PropTypes.string,
    preset: PropTypes.string,
    caption: PropTypes.string,
    tooltip: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
    onHoldThreshold: PropTypes.number,
    dragThreshold: PropTypes.number,
    style: PropTypes.object,
    selected: PropTypes.bool,
    dropdownSelected: PropTypes.bool,
    disabled: PropTypes.bool,
    softTooltip: PropTypes.bool,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onHold: PropTypes.func,
    onDrag: PropTypes.func,
    childRef: PropTypes.func,
    onClick: PropTypes.func,
    keyHint: PropTypes.string,
    showTooltip: PropTypes.bool,
    name: PropTypes.string
}
