import React from 'react'

export default class LoadingIndicator extends React.PureComponent {
    render () {
        return <div className="LoadingIndicator">
            <div className="indicator"></div>
            <div className="indicator"></div>
            <div className="indicator"></div>
            <div className="indicator"></div>
            <div className="indicator"></div>
        </div>
    }
}
