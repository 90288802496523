import React from 'react'
import { useDispatch } from 'react-redux'
import SplashScreenModalDialog from './SplashScreenModalDialog'
import lang from '../../utils/lang'
import { hideHeader } from '../../actions/header'

const SplashScreenNotFound = () => {
    const dispatch = useDispatch()
    dispatch(hideHeader())

    return <SplashScreenModalDialog
        body={
            <div className="dialog">
                <div className="title">{lang.d('splash_screen_not_found_modal_dialog_title')}</div>
                <div className="message">{lang.d('splash_screen_not_found_modal_dialog_message')}</div>
                <div className="actions">
                    <a href="/" className="action highlighted">
                        {lang.d('back')}
                    </a>
                </div>
            </div>
        }
    />
}

export default SplashScreenNotFound
