import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { showFailedToContactServerError } from '../utils/errors'
import { cloneObject } from '../utils/helperFunctions'

class DataLoader extends React.Component {
    constructor (props) {
        super(props)
        this.bulkAdd = this.bulkAdd.bind(this)
        this.disableFetchMore = this.disableFetchMore.bind(this)
        this.add = this.add.bind(this)
        this.remove = this.remove.bind(this)
        this.clearResults = this.clearResults.bind(this)
        this.rehydrateList = this.rehydrateList.bind(this)
        this.isListEmpty = this.isListEmpty.bind(this)
        this.setUnpaginatedList = this.setUnpaginatedList.bind(this)
        this.pageSize = this.props.pageSize || 10
        this.reFetchTimeout = null

        this.state = {
            ready: false,
            searchEmpty: false,
            list: [],
            shouldLoadMore: true,
            nextPageStartsAfter: null,
            finishedMounting: false,
            loading: false
        }
    }

    hasSearch () {
        const { searchValue, tagValue } = this.props
        return (searchValue || tagValue)
    }

    async componentDidMount () {
        if (this.props.nopagination) return this.setUnpaginatedList()

        if (this.props.list.length > 0) {
            this.reFetchTimeout = setTimeout(() => this.rehydrateList(), 1500)
        } else {
            this.props.setList({
                list: [],
                shouldLoadMore: true,
                ready: true,
                finishedMounting: true
            })
        }
    }

    componentWillUnmount () {
        clearTimeout(this.reFetchTimeout)
    }

    componentDidUpdate () {
        if (this.shouldRefreshUnpaginatedList()) {
            this.setUnpaginatedList()
        }
    }

    shouldRefreshUnpaginatedList () {
        return this.props.list.length === 0 && this.props.nopagination && !this.props.ready
    }

    async rehydrateList () {
        const serverList = await this.getListUpdates(0, this.props.list.length / this.pageSize)

        this.setState({ finishedMounting: true })
        this.props.setList({
            list: serverList
        })
    }

    async getListUpdates (afterId = 0, iterations = 0, list = []) {
        let res
        if (afterId === 0) {
            res = await this.props.getFirstPage(this.pageSize, this.props.searchValue, this.props.tagValue)
        } else {
            res = await this.props.getPageAfter(afterId, this.pageSize, this.props.searchValue, this.props.tagValue)
        }

        res.items.forEach((i) => {
            list.push(cloneObject(i))
        })

        if (list.length === iterations * this.pageSize) {
            return list
        }

        if (res.nextPageStartsAfter) await this.getListUpdates(res.nextPageStartsAfter, iterations, list)

        return list
    }

    async setUnpaginatedList () {
        const list = []

        const iterator = this.props.fetch()
        try {
            for await (const item of iterator) {
                list.push(item)
            }
        } catch (e) {
            showFailedToContactServerError()
            throw e
        }

        this.props.set({
            list,
            ready: true
        })
    }

    bulkAdd (result) {
        const items = result.items.map(item => cloneObject(item))
        const nextPageStartsAfter = result.nextPageStartsAfter === null ? null : parseInt(result.nextPageStartsAfter)
        this.props.set({
            list: items,
            nextPageStartsAfter: nextPageStartsAfter,
            shouldLoadMore: Boolean(result.nextPageStartsAfter)
        })

        this.setState({
            finishedMounting: true
        })
    }

    add (item) {
        this.props.add(item)
    }

    update (item) {
        this.props.update(item)
    }

    remove (item) {
        this.props.remove(item)
    }

    disableFetchMore () {
        this.props.disableFetchMore()

        this.setState({
            shouldLoadMore: !this.state.shouldLoadMore,
            finishedMounting: true
        })
    }

    clearResults () {
        this.props.clear({
            shouldLoadMore: true,
            list: [],
            nextPageStartsAfter: null
        })

        if (this.props.nopagination) this.setUnpaginatedList()
    }

    isListEmpty () {
        return this.props.finishedMounting && this.props.list.length === 0 && this.props.ready === true && !this.props.shouldLoadMore
    }

    render () {
        return this.props.children({
            state: this.state,
            search: this.search,
            filterByTag: this.filterByTag,
            bulkAdd: this.bulkAdd,
            disableFetchMore: this.disableFetchMore,
            add: this.add,
            remove: this.remove,
            clearResults: this.clearResults,
            name: this.props.name,
            className: this.props.className,
            getFirstPage: this.props.getFirstPage,
            getPageAfter: this.props.getPageAfter,
            update: this.props.update,
            isListEmpty: this.isListEmpty
        })
    }
}

DataLoader.propTypes = {
    nopagination: PropTypes.bool,
    getFirstPage: PropTypes.func,
    getPageAfter: PropTypes.func,
    search: PropTypes.func,
    fetch: PropTypes.func,
    children: PropTypes.any,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    dispatch: PropTypes.func,
    list: PropTypes.array,
    setList: PropTypes.func,
    set: PropTypes.func,
    add: PropTypes.func,
    clear: PropTypes.func,
    ready: PropTypes.bool,
    setReady: PropTypes.func,
    remove: PropTypes.func,
    disableFetchMore: PropTypes.func,
    searchValue: PropTypes.string,
    tagValue: PropTypes.string,
    update: PropTypes.func,
    finishedMounting: PropTypes.bool,
    shouldLoadMore: PropTypes.bool,
    pageSize: PropTypes.number
}

const mapStateToProps = (state, ownProps) => ({
    list: state[ownProps.name].list,
    shouldLoadMore: state[ownProps.name].shouldLoadMore,
    ready: state[ownProps.name].ready,
    searchValue: state.search[ownProps.name].value,
    tagValue: state.search[ownProps.name].tag,
    finishedMounting: state[ownProps.name].finishedMounting
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    set: payload => dispatch({
        type: `${ownProps.name.toUpperCase()}/SET`,
        payload
    }),
    setList: payload => dispatch({
        type: `${ownProps.name.toUpperCase()}/SET_LIST`,
        payload
    }),
    clear: () => dispatch({
        type: `CLEAR_${ownProps.name.toUpperCase()}`
    }),
    add: payload => dispatch({
        type: `${ownProps.name.toUpperCase()}/ADD`,
        payload
    }),
    update: payload => dispatch({
        type: `${ownProps.name.toUpperCase()}/UPDATE`,
        payload
    }),
    remove: payload => dispatch({
        type: `${ownProps.name.toUpperCase()}/REMOVE`,
        payload
    }),
    disableFetchMore: () => dispatch({
        type: `${ownProps.name.toUpperCase()}/DISABLE_FETCH_MORE`
    }),
    setReady: () => dispatch({
        type: `${ownProps.name.toUpperCase()}/SET_READY`
    })
})

export default connect(mapStateToProps, mapDispatchToProps)(DataLoader)
