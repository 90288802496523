import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { setHeader } from '../../actions/header'
import lang from '../../utils/lang'
import format from 'date-fns/format'
import debounce from 'lodash/debounce'
import { Link } from 'react-router-dom'
import { persistencyService } from '../../services/persistency.service'
import { history } from '../../store'
import { getCompanies, getCompany, setPlan } from '../../actions/company'

class Companies extends Component {
    constructor (props) {
        super(props)
        this.state = {
            list: null
        }
        this.debouncedLoadData = debounce(this.loadData, 400)
    }

    renderHeaders () {
        return <>
            <th width="30%">{ lang.d('name') }</th>
            <th width="10%">{lang.d('created_on')}</th>
            <th width="30%">{lang.d('comment')}</th>
            <th width="10%">{lang.d('whitelabeled')}</th>
            <th width="10%">{lang.d('status')}</th>
        </>
    }

    async componentDidMount () {
        this.loadData()
        this.props.setHeader({
            showSearch: true,
            hasItems: true,
            centerItems: [],
            rightItems: [],
            leftItems: [
                {
                    to: '/admin',
                    icon: 'icon-arrow-light-left',
                    string_key: 'admin',
                    className: 'soft-button'
                },
                {
                    to: '/admin/email-companies',
                    icon: 'icon-email',
                    string_key: 'email_companies',
                    className: 'soft-button'
                }
            ]
        })
    }

    componentDidUpdate (prevProps) {
        if (this.props.searchValue !== prevProps.searchValue) {
            this.debouncedLoadData()
        }
    }

    async loadData () {
        const companies = await this.props.getCompanies(this.props.searchValue)
        this.setState({ list: companies })
    }

    renderRows () {
        return this.state.list.map((company) =>
            <tr key={company.id}>
                <td className="action-cell">
                    <Link to={`/admin/company/${company.region}/${company.id}`}><span>{company.name || lang.d('anonymous')}</span></Link>
                    {this.props.loggedInCompanyId !== company.id &&
                    <span title={lang.d('login_as_company')} onClick={() => this.switchToCompany(company.id, company.region)}>
                        <span className="icon icon-login"/>
                    </span>
                    }
                </td>
                <td>{company.createdOn ? format(company.createdOn, 'd MMM yyyy') : '-'}</td>
                <td ><div className="user-comment">{company.comment}</div></td>
                <td>{company.whitelabeled ? 'whitelabeled' : 'watermark shown'}</td>
                <td>{company.status}</td>
            </tr>
        )
    }

    async switchToCompany (id, region) {
        const company = await this.props.getCompany(id, region)
        this.props.switchToCompany(company)
        persistencyService.remove('charts-scroll')
        this.props.setExitPath(this.props.pathname)
        history.push('/')
    }

    render () {
        if (this.state.list) {
            return <div className="Companies">
                <div className="container">
                    <div className="DataTable">
                        <table>
                            <thead>
                                <tr>
                                    { this.renderHeaders() }
                                </tr>
                            </thead>
                            <tbody>
                                { this.renderRows() }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        } else {
            return <>
                <div className="empty-page-message">
                    <div className="title">{lang.d('no_results')}</div>
                </div>
            </>
        }
    }
}

Companies.propTypes = {
    setHeader: PropTypes.func,
    searchValue: PropTypes.string,
    switchToCompany: PropTypes.func,
    getCompanies: PropTypes.func,
    getCompany: PropTypes.func,
    loggedInCompanyId: PropTypes.number,
    pathname: PropTypes.string,
    setExitPath: PropTypes.func
}

const mapStateToProps = state => ({
    searchValue: state.search.companies.value,
    loggedInCompanyId: state.context.company.id,
    pathname: state.router.location.pathname
})

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload)),
    switchToCompany: company => {
        return dispatch({
            type: 'SWITCH_TO_DEFAULT_WORKSPACE_OF_COMPANY',
            company
        })
    },
    setExitPath: pathname => {
        return dispatch({
            type: 'SET_EXIT_PATH',
            pathname
        })
    },
    setPlan: (id, regionId, value) => dispatch(setPlan(id, regionId, value)),
    getCompanies: searchValue => dispatch(getCompanies(searchValue)),
    getCompany: (id, region) => dispatch(getCompany(id, region))
})

export default connect(mapStateToProps, mapDispatchToProps)(Companies)
