import { SubscriptionEventForm } from './SubscriptionEventForm'
import React from 'react'
import lang from '../../utils/lang'

export function subscriptionEventDialog (onSubmit, pricingPlans, title, acceptCaption, initialValues, isForUpdate = false) {
    return {
        title: title,
        isForUpdate: isForUpdate,
        onSubmit: values => onSubmit(formValuesToSubscriptionEvent(values)),
        settings: {
            waitOnAccept: true,
            acceptCaption: acceptCaption,
            formik: {
                initialValues: { ...defaultInitialValues(pricingPlans), ...initialValues },
                validate: values => {
                    const errors = {}
                    if (values.type === 'USAGE_MANUALLY_CORRECTED') {
                        if (values.correctedUsage === '') {
                            errors.correctedUsage = lang.d('required')
                        }
                    }
                    return errors
                },
                render: props => <SubscriptionEventForm {...props} pricingPlans={pricingPlans} isForUpdate={isForUpdate}/>
            }
        }
    }
}

const defaultInitialValues = pricingPlans => {
    const today = new Date();
    return {
        type: 'SUBSCRIBED',
        pricingPlanId: pricingPlans[0].id.toString() + '-' + pricingPlans[0].isYearly,
        isYearly: pricingPlans[0].isYearly,
        month: (today.getMonth() + 1).toString(),
        year: today.getFullYear().toString(),
        correctedUsage: ''
    }
}

const parsePricingPlanId = str => {
    const parts = str.split('-')
    return {
        id: parseInt(parts[0]),
        isYearly: parts[1] === 'true'
    }
}

const formValuesToSubscriptionEvent = values => {
    const commonValues = {
        type: values.type,
        month: parseInt(values.month),
        year: parseInt(values.year)
    }

    let pricingPlanIdentifier

    switch (values.type) {
    case 'SUBSCRIBED':
        pricingPlanIdentifier = parsePricingPlanId(values.pricingPlanId)
        return {
            pricingPlanId: pricingPlanIdentifier.id,
            isYearly: pricingPlanIdentifier.isYearly,
            ...commonValues
        }
    case 'UNSUBSCRIBED':
        return commonValues
    case 'USAGE_MANUALLY_CORRECTED':
        return {
            correctedUsage: parseInt(values.correctedUsage),
            ...commonValues
        }
    default:
        throw Error('Unknown subscription event type: ' + values.type)
    }
}
