import React, { Component } from 'react'
import lang from '../utils/lang'
import * as PropTypes from 'prop-types'

export default class Input extends Component {
    render = () => {
        const { onClear, ...props } = this.props
        return (
            <>
                {props.valid === false && <span>{ lang.d('error') }</span>}
                <input {...props} />
                {onClear && <button onClick={onClear}>X</button>}
            </>
        )
    }
}

Input.propTypes = {
    onClear: PropTypes.string
}
