import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {
    setCompanyAdminKey,
    setCompanyDefaultRendererSetting,
    setCompanySetting,
    setCompanyValidationSetting,
    updateCompanySettings
} from "../../../actions/user";
import {resetHeader} from "../../../actions/header";
import {closeDialog, openModalDialog} from "../../../actions/modalDialog";
import {connect} from "react-redux";
import lang from "../../../utils/lang";
import OptionSwitcher from "../../../components/GUI/OptionSwitcher";
import {exposeErrors} from "../../../utils/errors";
import {changeSetting} from "../../../services/accounts.service";

class RendererCompanySettings extends Component {

    constructor(props) {
        super(props)
        this.state = {
            updatingMultiSelectEnabled: false,
            updatingShowFullScreenButton: false
        }
    }

    render() {
        return <>
            <div className="setting-page-title">{ lang.d('renderer') }</div>
            <div className="setting-page-description">
                <p>{ lang.d('renderer_settings_desc_1') }</p>
                <p>{ lang.d('renderer_settings_desc_2') }</p>
            </div>
            <div className="setting">
                {this.multiSelectEnabledSetting()}
                {this.showFullScreenButtonSetting()}
            </div>
        </>
    }

    multiSelectEnabledSetting() {
        return <>
            <div className="subtitle">{lang.d('multiSelect_enabled')}</div>
            <div className="text">
                <p><a className="arrow"
                    href="https://docs.seats.io/docs/renderer-config-multiselectenabled" target="_blank"
                    rel="noopener noreferrer">{lang.d('read_more')}</a></p>
            </div>
            <OptionSwitcher
                value={this.props.setting.defaultRendererSettings.multiSelectEnabled.toString().toUpperCase()}
                options={{
                    TRUE: lang.d('enabled'),
                    FALSE: lang.d('disabled')
                }}
                loading={this.state.updatingMultiSelectEnabled}
                onChange={value => this.setMultiSelectEnabled(value)}
            />
        </>
    }

    showFullScreenButtonSetting() {
        return <>
            <div className="subtitle">{lang.d('show_full_screen_button')}</div>
            <div className="text">
                <p><a className="arrow"
                    href="https://docs.seats.io/docs/renderer-config-showfullscreenbutton" target="_blank"
                    rel="noopener noreferrer">{lang.d('read_more')}</a></p>
            </div>
            <OptionSwitcher
                value={this.props.setting.defaultRendererSettings.showFullScreenButton.toString().toUpperCase()}
                options={{
                    TRUE: lang.d('enabled'),
                    FALSE: lang.d('disabled')
                }}
                loading={this.state.updatingShowFullScreenButton}
                onChange={value => this.setShowFullScreenButton(value)}
            />
        </>
    }


    async setMultiSelectEnabled(value) {
        this.setState({
            updatingMultiSelectEnabled: true
        })

        try {
            await exposeErrors(changeSetting('MULTI_SELECT_ENABLED', value.toUpperCase()))
            this.props.setDefaultRendererSetting('multiSelectEnabled', value)
        } finally {
            this.setState({
                updatingMultiSelectEnabled: false
            })
        }
    }

    async setShowFullScreenButton(value) {
        this.setState({
            updatingShowFullScreenButton: true
        })

        try {
            await exposeErrors(changeSetting('SHOW_FULL_SCREEN_BUTTON', value.toUpperCase()))
            this.props.setDefaultRendererSetting('showFullScreenButton', value)
        } finally {
            this.setState({
                updatingShowFullScreenButton: false
            })
        }
    }

}


RendererCompanySettings.propTypes = {
    adminKey: PropTypes.string,
    user: PropTypes.object,
    company: PropTypes.object,
    setting: PropTypes.object.isRequired,
    updateCompanySettings: PropTypes.func.isRequired,
    setSetting: PropTypes.func.isRequired,
    setValidationSetting: PropTypes.func.isRequired,
    setDefaultRendererSetting: PropTypes.func.isRequired,
    resetHeader: PropTypes.func.isRequired,
    openModalDialog: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    setCompanyAdminKey: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    setting: state.context.company.settings,
    adminKey: state.context.company.adminKey,
    user: state.user,
    company: state.context.company
})

const mapDispatchToProps = dispatch => ({
    updateCompanySettings: (user) => dispatch(updateCompanySettings(user)),
    setSetting: (setting, value) => dispatch(setCompanySetting(setting, value)),
    setValidationSetting: (setting, value) => dispatch(setCompanyValidationSetting(setting, value)),
    setDefaultRendererSetting: (setting, value) => dispatch(setCompanyDefaultRendererSetting(setting, value)),
    setCompanyAdminKey: adminKey => dispatch(setCompanyAdminKey(adminKey)),
    resetHeader: () => dispatch(resetHeader()),
    openModalDialog: payload => dispatch(openModalDialog(payload)),
    closeModalDialog: payload => dispatch(closeDialog(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(RendererCompanySettings)
