import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { persistencyService } from '../../services/persistency.service'
import throttle from 'lodash/throttle'
import { exposeErrors } from '../../utils/errors'
import * as PropTypes from 'prop-types'
import lang from '../../utils/lang'

const noResults = (
    <div className="empty-page-message">
        <div className="title">{lang.d('no_results')}</div>
    </div>
)

class InfiniteScroller extends React.Component {
    state = {
        hasMoreItems: true,
        nextId: null,
        scrollPosition: 0
    }

    constructor (props) {
        super(props)
        this.loadMore = this.loadMore.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
        this.isLoading = false
        this.throttledScroll = throttle(this.handleScroll, 1000)
        this.scrollRestored = false
        this.ongoingRequest = null
        this.currentRequestID = null
        this.pageSize = props.pageSize || 10
    }

    componentDidMount () {
        if (!this.props.rememberPosition) return

        const scrollContainers = document.getElementsByClassName('infinite-scroll-container')
        if (scrollContainers.length > 0) {
            scrollContainers[0].addEventListener('scroll', this.throttledScroll)
        }
    }

    componentDidUpdate () {
        if (!this.props.rememberPosition) return

        if (!this.scrollRestored) {
            const el = document.getElementsByClassName('infinite-scroll-container')[0]

            const storedScrollPosition = persistencyService.get(`${this.props.name}-scroll`)

            if (storedScrollPosition) {
                el.scrollTop = storedScrollPosition
            }
            this.scrollRestored = true
        }
    }

    componentWillUnmount () {
        if (!this.props.rememberPosition) return

        this.throttledScroll.cancel()

        const scrollContainers = document.getElementsByClassName('infinite-scroll-container')
        if (scrollContainers.length > 0) {
            scrollContainers[0].removeEventListener('scroll', this.handleScroll)
        }
    }

    handleScroll (e) {
        const scroll = document.getElementsByClassName('infinite-scroll-container')[0].scrollTop
        persistencyService.set(`${this.props.name}-scroll`, scroll)
    }

    loadMore () {
        if (!this.props.shouldLoadMore || !this.props.ready) return
        const requestID = Date.now()
        this.currentRequestID = requestID

        this.isLoading = true
        if (this.props.nextPageStartsAfter !== null) {
            this.ongoingRequest = exposeErrors(this.props.restOfPages(this.props.nextPageStartsAfter, this.pageSize, this.props.loadingParameters.searchValue, this.props.loadingParameters.tagValue))
        } else {
            this.ongoingRequest = exposeErrors(this.props.firstPage(this.pageSize, this.props.loadingParameters.searchValue, this.props.loadingParameters.tagValue))
        }

        this.ongoingRequest.then(result => {
            if (this.currentRequestID === requestID) {
                if (!result.items.length > 0) {
                    this.props.disableFetchMore()
                    return
                }

                this.props.handleNewItems(result)
            }
        })
        this.isLoading = false
    }

    render () {
        if (this.props.isEmpty) { return this.props.onEmpty || noResults }

        return (
            <div className={`${this.props.className || this.props.name} infinite-scroll-container`}>
                <div className="container">
                    <InfiniteScroll
                        pageStart={0}
                        hasMore={this.props.shouldLoadMore && !this.isLoading}
                        loadMore={this.loadMore}
                        loader={this.props.placeholder}
                        useWindow={false}
                        className="infinite-scroll-container"
                        getScrollParent={() => document.getElementsByClassName('infinite-scroll-container')[0]}
                    >
                        {this.props.children}
                    </InfiniteScroll>
                </div>
            </div>
        )
    }
}

InfiniteScroller.defaultProps = {
    loadingParameters: {}
}

InfiniteScroller.propTypes = {
    children: PropTypes.any,
    placeholder: PropTypes.object.isRequired,
    shouldLoadMore: PropTypes.bool.isRequired,
    handleNewItems: PropTypes.func.isRequired,
    disableFetchMore: PropTypes.func.isRequired,
    firstPage: PropTypes.func.isRequired,
    restOfPages: PropTypes.func.isRequired,
    nextPageStartsAfter: PropTypes.number,
    ready: PropTypes.bool,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    loadingParameters: PropTypes.object.isRequired,
    rememberPosition: PropTypes.bool,
    isEmpty: PropTypes.bool,
    onEmpty: PropTypes.node,
    pageSize: PropTypes.number
}

export default InfiniteScroller
