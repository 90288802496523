import React from 'react'
import * as PropTypes from 'prop-types'

export default class PageSwitcher extends React.PureComponent {
    render () {
        return <div className="PageSwitcher">
            { this.props.children }
        </div>
    }
}

PageSwitcher.propTypes = {
    children: PropTypes.node.isRequired
}
