import React from 'react'
import lang from '../../utils/lang'
import { IntercomAPI } from 'react-intercom'
import { useDispatch } from 'react-redux'
import { history } from '../../store'
import { logout } from '../../actions/user'

const GeneralErrorModalDialogBody = () => {
    const dispatch = useDispatch()

    const getSupport = () => {
        IntercomAPI('showNewMessage', lang.d('intercom_message_help_webapp_error'))
    }

    const onLogout = e => {
        e.preventDefault()
        dispatch(logout())
        history.push('/')
    }

    return <>
        <div className="title">{lang.d('splash_screen_general_error_modal_dialog_title')}</div>
        <div className="message">{lang.d('splash_screen_general_error_modal_dialog_message')}</div>
        <div className="actions">
            <button className="action" onClick={getSupport}>
                { lang.d('get_support') }
            </button>
            { window.location.pathname !== '/'
                ? <a href="/" className="action highlighted">
                    { lang.d('go_to_home') }
                </a>
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                : <a href="#" onClick={onLogout} className="action highlighted">
                    { lang.d('logout') }
                </a>
            }
        </div>
    </>
}

export default GeneralErrorModalDialogBody
