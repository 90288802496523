import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { SubscriptionEvent } from './SubscriptionEvent'
import { openModalDialog } from '../../actions/modalDialog'
import { useDispatch } from 'react-redux'
import { subscriptionEventDialog } from './SubscriptionEventDialog'
import { adminService } from '../../services/admin.service'

export function SubscriptionEvents (props) {
    const { pricingPlans } = { ...props }
    const [error, setError] = useState(null)
    const dispatch = useDispatch()

    function errorMessage(err) {
        if (err.response.data && err.response.data.messages && err.response.data.messages.length > 0) {
            return err.response.data.messages[0]
        } else {
            return "An unknown error occurred"
        }
    }

    const onSubscriptionEventUpdated = async (updatedEvent, originalEvent) => {
        setError(null)
        await adminService.updateSubscriptionEvent(props.companyId, props.companyRegionId, originalEvent, updatedEvent)
            .then(_ => getCurrentEventsAndUpdateView(),
                errorResponse => setError(errorMessage(errorResponse)))
    }

    const onSubscriptionEventDeleted = async (deletedEvent) => {
        setError(null)
        await adminService.deleteSubscriptionEvent(props.companyId, props.companyRegionId, deletedEvent)
            .then(_ => getCurrentEventsAndUpdateView(),
                errorResponse => setError(errorMessage(errorResponse)))
    }

    const onSubscriptionEventCreated = async (newEvent) => {
        setError(null)
        await adminService.createSubscriptionEvent(props.companyId, props.companyRegionId, newEvent)
            .then(_ => getCurrentEventsAndUpdateView(),
                errorResponse => setError(errorMessage(errorResponse)))
    }

    const getCurrentEventsAndUpdateView = async () => {
        await adminService.getSubscriptionEvents(props.companyId, props.companyRegionId)
            .then(events => props.onUpdated(events))
    }

    const addSubscriptionEvent = () => {
        const dialog = subscriptionEventDialog(
            onSubscriptionEventCreated,
            pricingPlans,
            'Add subscription event',
            'Add',
            null,
            false
        )
        dispatch(openModalDialog(dialog))
    }

    return <div className="subscription-events section">
        <div className="title">Subscription events</div>
        <div className="add">
            <button className="FormButton btn" onClick={() => addSubscriptionEvent()}>Add</button>
        </div>
        {props.subscriptionEvents.length === 0 && <div className="no-events-message">No subscription events</div>}
        {props.subscriptionEvents.length > 0 &&
        <div className="DataTable">
            <table>
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Type</th>
                        <th>Details</th>
                        <th width="5%"></th>
                    </tr>
                </thead>
                <tbody>
                    {props.subscriptionEvents.map((event, index) =>
                        <SubscriptionEvent
                            subscriptionEvent={event}
                            pricingPlans={pricingPlans}
                            key={index}
                            onUpdated={updatedEvent => onSubscriptionEventUpdated(updatedEvent, event)}
                            onDeleted={() => onSubscriptionEventDeleted(event)}
                        />
                    )}
                </tbody>
            </table>
        </div>
        }
        {error && <>&nbsp;<div className="error"><button type="button" className="button icon icon-close-thin" title="Clear error message" onClick={e => setError(null)}></button> Saving failed. {error}</div></>}
    </div>
}

SubscriptionEvents.propTypes = {
    subscriptionEvents: PropTypes.array.isRequired,
    pricingPlans: PropTypes.array.isRequired,
    companyId: PropTypes.number.isRequired,
    companyRegionId: PropTypes.string.isRequired,
    onUpdated: PropTypes.func.isRequired
}
