import React, {Component} from "react";
import {setHeader} from "../../actions/header";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {exposeErrors} from "../../utils/errors";
import {adminService} from "../../services/admin.service";
import {keyBy} from "lodash";
import {openModalDialog} from "../../actions/modalDialog";
import Accordion from "../../components/GUI/Accordion";

class AdminFeatureAnalyticsReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            report: null
        }
    }

    async componentDidMount() {
        this.props.setHeader({
            showSearch: false,
            hasItems: true,
            centerItems: [],
            rightItems: [],
            leftItems: [{
                to: '/admin',
                icon: 'icon-arrow-light-left',
                string_key: 'admin',
                className: 'soft-button'
            }]
        })
        await this.loadData()
    }

    async loadData() {
        const [report, companies] = await exposeErrors(Promise.all([
            adminService.getFeatureAnalyticsReport(),
            adminService.getAllCompanies()
        ]))
        const companiesById = keyBy({...companies}, 'id')
        this.setState({
            report: report,
            companiesById: companiesById
        })
    }

    companyName(id) {
        const company = this.state.companiesById[id]
        if (company) {
            return company.name
        }
        return '<unknown company>' // should only happen when testing locally
    }

    render() {
        return <div className="FeatureAnalytics">
            <div className="container">
                <h1>Feature Analytics</h1>
                { this.state.report && Object.keys(this.state.report.perFeature).map(feature =>
                    <div key={feature} className="feature">
                        {this.renderFeature(feature)}
                    </div>
                ) }
            </div>
        </div>
    }

    renderFeature(featureName) {
        let featureValues = this.state.report.perFeature[featureName];
        return <>
            <div key={featureName} className="feature-section">
                <h2>{featureName}</h2>
                {Object.keys(featureValues).map(value => this.renderFeatureValue(featureName, value, featureValues[value]))}
            </div>
        </>
    }

    renderFeatureValue(featureName, value, companies) {
        return <div key={value} className="value-section">
            <Accordion key={featureName + "-" + value} title={value}>
                <ul>
                    {companies.map(company => {
                        let companyName = this.companyName(company.companyId);
                        return (
                            <li>
                                {companyName}
                                <button className="button-link view-links-button" onClick={() => this.showExampleUrls(companyName, company.urls)}>
                                    <i className="icon-info"/>
                                </button>
                            </li>
                        )
                    })}
                </ul>
            </Accordion>
        </div>
    }

    showExampleUrls (companyName, urls) {
        this.props.openModalDialog({
            title: "Example URLS: " + companyName,
            message: this.createUrlsList(urls),
            settings: {
                onAccept: () => {},
                waitOnAccept: true,
                prompt: false,
                dialogClassName: 'usage-urls-dialog'
            }
        })
    }

    createUrlsList (urls) {
        return (
            <div className="usage-report-modal-content">
                <ul>{urls.map(url =>
                    <li key={urls.indexOf(url)}>
                        <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                    </li>)
                }</ul>
            </div>
        )
    }
}

AdminFeatureAnalyticsReport.propTypes = {
    setHeader: PropTypes.func,
    openModalDialog: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload)),
    openModalDialog: payload => dispatch(openModalDialog(payload))
})

export default connect(null, mapDispatchToProps)(AdminFeatureAnalyticsReport)

