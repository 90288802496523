import { useEffect, useRef } from 'react'
import { isSuperAdmin } from '../reducers/user'
import store from '../store'

export const useEventListener = (eventName, handler, element = window) => {
    // Create a ref that stores handler
    const savedHandler = useRef()

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler
    }, [handler])

    useEffect(() => {
        // Make sure element supports addEventListener
        // On
        const isSupported = element && element.addEventListener
        if (!isSupported) return

        // Create event listener that calls handler function stored in ref
        const eventListener = event => savedHandler.current(event)

        // Add event listener
        element.addEventListener(eventName, eventListener)

        // Remove event listener on cleanup
        return () => {
            element.removeEventListener(eventName, eventListener)
        }
    },
    [eventName, element] // Re-run if eventName or element changes
    )
}

export const useOnFirstMount = (handler, condition) => {
    const mounted = useRef(false)

    useEffect(() => {
        if (!mounted.current && condition) {
            handler()
        }
        mounted.current = true
    }, [condition, handler])
}

export const getDefaultWorkspace = () => {
    const user = store.getState().user
    const context = store.getState().context
    const isAdminImpersonating = () => {
        return isSuperAdmin(user) && user.company.id !== context.company.id
    }

    return isAdminImpersonating() ? context.company.defaultWorkspace : user.defaultWorkspace
}
