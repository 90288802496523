export const persistencyService = {
    get,
    set,
    remove
}

function get (key, backend = sessionStorage) {
    try {
        const item = backend.getItem(key)
        if (item === null) return undefined
        return JSON.parse(item)
    } catch (err) {
        return undefined
    }
}

function set (key, value, backend = sessionStorage) {
    try {
        const item = JSON.stringify(value)
        backend.setItem(key, item)
    } catch (err) {
        console.error('Persistency Error: ', err)
    }
}

function remove (key, backend = sessionStorage) {
    backend.removeItem(key)
}
