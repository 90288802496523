import React from 'react'
import { eventsService } from '../../services/events.service'
import EventList from '../Events/EventList'
import { Link } from 'react-router-dom'
import lang from '../../utils/lang'
import { exposeErrors } from '../../utils/errors'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { deleteEvent, markEventAsInThePast } from '../../actions/charts'
import { addEvent, createEvent, setNewestAddedEventKey } from '../../actions/events'
import { closeDialog, openModalDialog } from '../../actions/modalDialog'

const EventsTitle = ({ numberOfEvents }) => {
    return <div className="title">{ numberOfEvents > 0
        ? lang.d('events')
        : lang.d('no_events')
    }</div>
}

class ChartItemEvents extends React.Component {
    constructor (props) {
        super(props)
        this.requestDeleteEvent = this.requestDeleteEvent.bind(this)
        this.handleDeleteEvent = this.handleDeleteEvent.bind(this)
        this.requestMarkEventAsInThePast = this.requestMarkEventAsInThePast.bind(this)

        this.state = {
            contextualMenuOpened: false
        }
    }

    requestDeleteEvent (key) {
        this.props.openModalDialog({
            title: lang.d('delete_event'),
            message: lang.d('delete_event_confirmation'),
            successMessage: lang.d('event_deleted'),
            doneIcon: 'delete',
            settings: {
                onAccept: () => this.handleDeleteEvent(key),
                acceptCaption: lang.d('delete'),
                dangerousAction: true,
                waitOnAccept: true
            }
        })
    }

    async handleDeleteEvent (eventKey) {
        await exposeErrors(eventsService.deleteEvent(eventKey), () => this.props.closeModalDialog())
        this.props.deleteEvent(this.props.chartKey, eventKey)
    }

    async createEvent () {
        this.setState({ loadingEvent: true })
        await this.props.createEvent(this.props.chartKey)
        this.setState({ loadingEvent: false })
        this.startNewEventKeyTimer()
    }

    startNewEventKeyTimer () {
        if (this.newEventKeyTimer) clearTimeout(this.newEventKeyTimer)
        this.newEventKeyTimer = setTimeout(() => {
            this.props.clearEventAddedAnimation()
        }, 1500)
    }

    requestMarkEventAsInThePast (eventKey) {
        this.props.openModalDialog({
            title: lang.d('mark_as_in_the_past'),
            message: lang.d('mark_event_as_in_the_past_confirmation'),
            successMessage: lang.d('event_is_in_the_past'),
            doneIcon: 'delete',
            settings: {
                onAccept: () => this.handleMarkEventAsInThePast(eventKey),
                acceptCaption: lang.d('mark_as_in_the_past'),
                dangerousAction: true,
                waitOnAccept: true
            }
        })
    }

    async handleMarkEventAsInThePast (eventKey) {
        await exposeErrors(eventsService.markAsInThePast(eventKey), () => this.props.closeModalDialog())
        this.props.markEventAsInThePast(eventKey)
    }

    render () {
        return (
            <div className="events">
                <div className={`create-new-event ${this.state.loadingEvent && 'loading'}`}>
                    <button className="button-link" onClick={() => this.createEvent()}>
                        <span className="icon icon-plus-bold"/>
                        <span>{ lang.d('create_event') }</span>
                    </button>
                </div>
                <EventsTitle numberOfEvents={this.props.events.length}/>
                <EventList
                    events={this.props.events.slice(0, ChartItemEvents.MAX_EVENTS)}
                    requestDeleteEvent={this.requestDeleteEvent}
                    requestMarkEventAsInThePast={this.requestMarkEventAsInThePast}
                    searchParameters={this.props.searchParameters}
                />
                { this.props.events.length > ChartItemEvents.MAX_EVENTS && <div className="footer-link">
                    <Link to={`/charts/${this.props.chartKey}`} className="arrow">{ lang.d('see_all_events') }</Link>
                </div> }
            </div>
        )
    }
}

ChartItemEvents.MAX_EVENTS = 3

ChartItemEvents.propTypes = {
    events: PropTypes.arrayOf(PropTypes.object).isRequired,
    chartKey: PropTypes.string.isRequired,
    addEvent: PropTypes.func,
    deleteEvent: PropTypes.func,
    clearEventAddedAnimation: PropTypes.func,
    createEvent: PropTypes.func,
    openModalDialog: PropTypes.func,
    closeModalDialog: PropTypes.func.isRequired,
    context: PropTypes.object,
    markEventAsInThePast: PropTypes.func,
    searchParameters: PropTypes.object
}

EventsTitle.propTypes = {
    numberOfEvents: PropTypes.number
}

const mapDispatchToProps = dispatch => ({
    addEvent: payload => dispatch(addEvent(payload)),
    deleteEvent: (chartKey, eventKey) => dispatch(deleteEvent(chartKey, eventKey)),
    clearEventAddedAnimation: () => dispatch(setNewestAddedEventKey(null)),
    createEvent: (chartKey) => dispatch(createEvent(chartKey)),
    openModalDialog: payload => dispatch(openModalDialog(payload)),
    closeModalDialog: payload => dispatch(closeDialog(payload)),
    markEventAsInThePast: (eventKey) => dispatch(markEventAsInThePast(eventKey))
})

export default connect(null, mapDispatchToProps)(ChartItemEvents)
