export const defaultState = {
    charts: {
        value: '',
        displayValue: '',
        tag: '',
        tags: []
    },
    chartDetail: {
        value: '',
        displayValue: '',
        tag: '',
        tags: []
    },
    workspaces: {
        value: '',
        displayValue: ''
    },
    chartArchive: {
        value: '',
        displayValue: '',
        tag: '',
        tags: []
    },
    reports: {
        value: '',
        displayValue: '',
        tag: '',
        tags: []
    },
    users: {
        value: '',
        displayValue: ''
    },
    companies: {
        value: '',
        displayValue: ''
    }
}

const header = (state = defaultState, action) => {
    switch (action.type) {
    case 'CHARTS/SET_SEARCH_VALUE':
        return {
            ...state,
            charts: {
                ...state.charts,
                value: action.payload.value,
                tag: action.payload.tag,
                displayValue: action.payload.displayValue
            }
        }
    case 'CHARTS/CLEAR_SEARCH':
        return {
            ...state,
            charts: {
                ...state.charts,
                value: '',
                displayValue: '',
                tag: null
            }
        }
    case 'CHARTS/SET_SEARCH_TAGS':
        return {
            ...state,
            charts: {
                ...state.charts,
                tags: action.payload
            }
        }

    case 'CHARTDETAIL/SET_SEARCH_VALUE':
        return {
            ...state,
            chartDetail: action.payload
        }
    case 'CHARTDETAIL/CLEAR_SEARCH':
        return {
            ...state,
            chartDetail: defaultState.chartDetail
        }
    case 'WORKSPACES/SET_SEARCH_VALUE':
        return {
            ...state,
            workspaces: {
                ...state.workspaces,
                value: action.payload.value,
                displayValue: action.payload.displayValue
            }
        }
    case 'WORKSPACES/CLEAR_SEARCH':
        return {
            ...state,
            workspaces: defaultState.workspaces
        }

    case 'REPORTS/SET_SEARCH_VALUE':
        return {
            ...state,
            reports: {
                ...state.reports,
                value: action.payload.value,
                tag: '',
                displayValue: action.payload.displayValue
            }
        }
    case 'REPORTS/CLEAR_SEARCH':
        return {
            ...state,
            reports: defaultState.reports
        }
    case 'USERS/SET_SEARCH_VALUE':
        return {
            ...state,
            users: {
                ...state.users,
                value: action.payload.value,
                displayValue: action.payload.displayValue
            }
        }
    case 'USERS/CLEAR_SEARCH':
        return {
            ...state,
            users: defaultState.users
        }
    case 'COMPANIES/SET_SEARCH_VALUE':
        return {
            ...state,
            companies: {
                ...state.companies,
                value: action.payload.value,
                displayValue: action.payload.displayValue
            }
        }
    case 'COMPANIES/CLEAR_SEARCH':
        return {
            ...state,
            companies: defaultState.companies
        }
    default:
        return state
    }
}

export default header
