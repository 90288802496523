import axios from 'axios'
import store from '../store'
import { backendUrlOfActiveCompany } from '../domain/regions'
import { parseISO } from 'date-fns'

export const invitationsService = {
    listInvitations,
    cancel
}

function listInvitations () {
    return axios.get(`${backendUrlOfActiveCompany()}/invitations`, {
        auth: { username: store.getState().user.secretKey },
        headers: { 'X-Company-ID': store.getState().context.company.id }
    }).then((res) => res.data.map(invitation => {
        invitation.date = parseISO(invitation.date)
        return invitation
    }))
}

function cancel (id) {
    return axios.post(`${backendUrlOfActiveCompany()}/invitations/${id}/actions/cancel`, {}, {
        auth: { username: store.getState().user.secretKey },
        headers: { 'X-Company-ID': store.getState().context.company.id }
    })
}
