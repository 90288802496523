export const notificationService = {
    info,
    warn,
    error,
    all,
    addListener,
    remove,
    init
}

export const TYPE_INFO = 'info'
export const TYPE_WARNING = 'warning'
export const TYPE_ERROR = 'error'
export const DEFAULT_NOTIFICATION_TIMEOUT = 5000

let notifications = []
let onChangeListeners = []
let idCounter = 0

/**
 * Displays an information notification.
 * @param {string} title
 * @param {string} message
 * @param {number} [timeout] Time in miliseconds to auto-close the notification, if a timeout is provided.
 */
function info (title, message, timeout = null) {
    return push(title, message, TYPE_INFO, timeout)
}

/**
 * Displays a warning notification.
 * @param {string} title
 * @param {string} message
 * @param {number} [timeout] Time in miliseconds to auto-close the notification, if a timeout is provided.
 */
function warn (title, message, timeout = null) {
    return push(title, message, TYPE_WARNING, timeout)
}

/**
 * Displays an error notification.
 * @param {string} title
 * @param {string} message
 * @param {number} [timeout] Time in miliseconds to auto-close the notification, if a timeout is provided.
 */
function error (title, message, timeout = null) {
    return push(title, message, TYPE_ERROR, timeout)
}

function push (title, message, type, timeout) {
    const id = ++idCounter
    notifications.push({
        title,
        message,
        type,
        timeout,
        id
    })
    onChangeListeners.forEach(listener => listener(all()))
    return id
}

function remove (id) {
    notifications = notifications.filter(notif => notif.id !== id)
    onChangeListeners.forEach(listener => listener(all()))
}

function addListener (listener) {
    return onChangeListeners.push(listener)
}

function all () {
    return notifications
}

function init () {
    notifications = []
    onChangeListeners = []
    idCounter = 0
}
