import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import SearchInput from '../components/GUI/SearchInput'
import { history } from '../store'
import * as PropTypes from 'prop-types'
import Navigation from './Navigation'
import HeaderItems from './HeaderItems'
import { isSuperAdminImpersonating } from '../reducers/user'

class ContextualHeader extends Component {
    constructor (props) {
        super(props)
        this.onSearch = this.onSearch.bind(this)
        this.configureHeader = this.configureHeader.bind(this)
        this.debouncedSearch = debounce(this.doSearch.bind(this), 250)
        this.name = null
        this.search = null
        this.searchTimeout = null
        this.asyncSearch = true

        this.state = {
            search: {
                value: '',
                displayValue: '',
                tag: ''
            }
        }

        this.configureHeader(props.pathname)

        history.listen(location => {
            this.configureHeader(location.pathname)
        })
    }

    configureHeader (pathname) {
        const pathnameParts = pathname.split('/')
        if (pathname.includes('login') || pathname.includes('logout') || pathname.includes('signup') || pathname.includes('reset-password')) {
            this.name = null
        } else if (pathname === '/') {
            this.name = 'charts'
            this.asyncSearch = true
        } else if (pathname.includes('/charts')) {
            this.name = 'chartDetail'
            this.asyncSearch = false
        } else if (pathname.includes('/archive')) {
            this.name = 'chartArchive'
            this.asyncSearch = false
        } else if (pathname.includes('/reports')) {
            this.name = 'reports'
        } else if (pathname.includes('/users')) {
            this.name = 'users'
            this.asyncSearch = false
        } else if (pathname.includes('/companies')) {
            this.name = 'companies'
        } else if (pathname.includes('/admin')) {
            this.name = 'admin'
        } else if (pathnameParts[1] !== '') {
            this.name = pathnameParts[1]
            this.asyncSearch = true
        }
    }

    async onSearch (value, tag = '') {
        if (value || tag) {
            this.props.setSearchValue(this.name, value, tag)
            this.asyncSearch && this.debouncedSearch()
        } else {
            this.props.clearSearch(this.name)
        }
    }

    filterByTag (tag) {
        this.search(this.state.search.value, tag)
    }

    doSearch () {
        if (this.searchTimeout) { clearTimeout(this.searchTimeout) }

        this.searchTimeout = setTimeout(() => {
            this.setState({
                loading: true
            }, async () => {
                this.props.setList(this.name, {
                    list: [],
                    shouldLoadMore: true,
                    loading: false,
                    nextPageStartsAfter: null
                })
            })
        }, 200)
    }

    renderSearchInput () {
        return <div>
            <SearchInput
                search={this.onSearch}
                searchValue={this.props.search[this.name].value}
                displayValue={this.props.search[this.name].displayValue}
                tagValue={this.props.search[this.name].tag}
                clearResults={() => this.props.clearSearch(this.name)}
                tags={this.props.search[this.name].tags}
                caption={this.props.header.caption}
                onFocus={this.props.header.onFocus}
            />
        </div>
    }

    render () {
        const { user, pathname, context, header, search, isOffline } = this.props

        if (!this.name) return null
        if (header.hideHeader) return null

        const hasItems = Boolean(header.showSearch || header.leftItems.length > 0 || header.centerItems.length > 0)
        return (
            <div className={`Header ${isOffline && 'offline'} ${hasItems && 'with-context-actions'} ${isSuperAdminImpersonating(user, context) && 'with-admin-banner'}`}>
                { isSuperAdminImpersonating(user, context) &&
                    <div className="superadmin-info">
                        <p>
                            <span role="img" aria-label="warning-emoji">⚠️</span> You are viewing company {this.props.context.company.name}
                        </p>
                    </div>
                }
                <div className="floating-container">
                    <div className="offline-background"></div>

                    { user && <Navigation user={user} pathname={pathname} context={context} /> }

                    <div className="contextual-actions">
                        {hasItems && <HeaderItems /> }
                        {header.showSearch && search[this.name] && this.renderSearchInput()}
                    </div>
                </div>

            </div>
        )
    }
}

ContextualHeader.propTypes = {
    dispatch: PropTypes.func,
    pathname: PropTypes.string,
    search: PropTypes.object,
    isOffline: PropTypes.bool,
    header: PropTypes.object,
    user: PropTypes.object,
    context: PropTypes.object,
    setSearchValue: PropTypes.func,
    setList: PropTypes.func,
    clearSearch: PropTypes.func
}

function mapStateToProps (state) {
    return {
        router: state.router,
        user: state.user,
        pathname: state.router.location.pathname,
        context: state.context,
        header: state.header,
        search: state.search
    }
}

const mapDispatchToProps = dispatch => ({
    setList: (name, payload) => dispatch({
        type: `${name.toUpperCase()}/SET_LIST`,
        payload
    }),
    setSearchValue: (name, value, tag) => dispatch({
        type: `${name.toUpperCase()}/SET_SEARCH_VALUE`,
        payload: { value: value ? value.trim() : value, tag, displayValue: value }
    }),
    clearSearch: name => dispatch({
        type: `${name.toUpperCase()}/CLEAR_SEARCH`
    })
})

export default connect(mapStateToProps, mapDispatchToProps)(ContextualHeader)
