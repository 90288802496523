import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setHeader } from '../../actions/header'
import { exposeErrors } from '../../utils/errors'
import { adminService } from '../../services/admin.service'
import MonthlyUsageReportChart from '../../components/Reports/MonthlyUsageReportChart'
import sortBy from 'lodash/sortBy'
import OptionSwitcher from '../../components/GUI/OptionSwitcher'
import * as PropTypes from 'prop-types'
import lang from '../../utils/lang'
import {
    addMonths,
    differenceInMinutes,
    format,
    formatDistance,
    getDaysInMonth,
    isThisMonth,
    startOfMonth,
    subMonths
} from 'date-fns'
import { history } from '../../store'
import { formatNumber } from '../../utils/helperFunctions'
import { openModalDialog } from '../../actions/modalDialog'

class AdminUsageReport extends Component {
    state = {
        report: null,
        reportDate: null
    }

    async componentDidMount () {
        this.props.setHeader({
            showSearch: true,
            hasItems: true,
            centerItems: [],
            rightItems: [],
            leftItems: [{
                to: '/admin',
                icon: 'icon-arrow-light-left',
                string_key: 'admin',
                className: 'soft-button'
            }]
        })
        const [rawReport, companies] = await exposeErrors(Promise.all([adminService.getUsageReport(), adminService.getAllCompanies()]))
        const reportDate = new Date(rawReport.usageCutoffDate)
        const report = this.processReport(rawReport, companies)
        this.setState({ report, reportDate })
    }

    processReport (rawReport, companies) {
        const companiesById = this.companiesById(companies)
        return rawReport.usage
            .map(usageInMonth => {
                const date = new Date(usageInMonth.month.year, usageInMonth.month.month - 1, 1)
                const processedMonth = {
                    month: usageInMonth.month.month,
                    year: usageInMonth.month.year,
                    label: `${date.toLocaleString('en-us', { month: 'short' })}, ${date.toLocaleString('en-us', { year: '2-digit' })}`,
                    fullLabel: `${date.toLocaleString('en-us', { month: 'long' })} ${usageInMonth.month.year}`,
                    date: new Date(usageInMonth.month.year, usageInMonth.month.month - 1)
                }
                usageInMonth.usagesByCompany.forEach(usageByCompany => {
                    const company = companiesById[usageByCompany.id]
                    if (company) {
                        usageByCompany.definitionOfUse = company.definitionOfUse
                        usageByCompany.comment = company.comment
                        usageByCompany.whitelabeled = company.whitelabeled
                        usageByCompany.name = company.name
                    }
                })
                const numUsedSeats = usageInMonth.usagesByCompany.reduce((total, curr) => {
                    return total + curr.numUsedObjects
                }, 0)

                return { month: processedMonth, numUsedSeats, usage: usageInMonth.usagesByCompany }
            })
    }

    companiesById (companies) {
        const result = {}
        companies.forEach(company => {
            result[company.id] = company
        })
        return result
    }

    switchToMonth (month) {
        history.push(`/admin/usage-report/${month.getFullYear()}/${month.getMonth() + 1}`)
    }

    getMonth () {
        const yearInUrl = this.props.match.params.year
        const monthInUrl = this.props.match.params.month
        if (yearInUrl) {
            return new Date(yearInUrl, monthInUrl - 1)
        }
        return this.state.report[this.state.report.length - 1].month.date
    }

    render () {
        if (!this.state.report) {
            return null
        }

        const month = this.getMonth()
        const reportForMonth = this.state.report.find(reportForMonth => {
            return reportForMonth.month.date.getYear() === month.getYear() &&
                reportForMonth.month.date.getMonth() === month.getMonth()
        })

        return <div className="Reports">
            <div className="container">
                <AdminUsageInMonthSummary
                    report={reportForMonth}
                    reportDate={this.state.reportDate}
                    month={month}
                    monthSwitched={month => this.switchToMonth(month)}
                />
                <AdminMonthlyUsageReportChart
                    report={this.state.report}
                    monthSwitched={month => this.switchToMonth(month)}
                />
                <AdminMonthlyUsageReportDetail
                    report={reportForMonth}
                    openModalDialog={this.props.openModalDialog}
                />
            </div>
        </div>
    }
}

AdminUsageReport.propTypes = {
    setHeader: PropTypes.func,
    match: PropTypes.object,
    openModalDialog: PropTypes.func
}

class AdminMonthlyUsageReportChart extends Component {
    constructor (props) {
        super(props)
        this.state = {
            showAllMonths: false
        }
    }

    setShowAllMonths (value) {
        this.setState({ showAllMonths: value })
    }

    render () {
        return <>
            <MonthlyUsageReportChart
                data={this.props.report.slice(this.state.showAllMonths ? 0 : -25)}
                barDataKey={'numUsedSeats'}
                barName={'Used seats'}
                rotatedXAxis={true}
                onClick={bar => this.props.monthSwitched(bar.month.date)}
            />
            <div className="time-controls">
                <OptionSwitcher
                    value={this.state.showAllMonths ? 'ALL' : 'LAST_25'}
                    options={{
                        LAST_25: lang.d('last_25_months'),
                        ALL: lang.d('all_months')
                    }}
                    onChange={value => this.setShowAllMonths(value === 'ALL')}
                />
            </div>
        </>
    }
}

AdminMonthlyUsageReportChart.propTypes = {
    report: PropTypes.array,
    monthSwitched: PropTypes.func
}

class AdminUsageInMonthSummary extends Component {
    render () {
        const nextMonthAvailable = this.props.reportDate.getYear() !== this.props.month.getYear() ||
            this.props.reportDate.getMonth() !== this.props.month.getMonth()
        return <>
            <div className="summary">
                <div className="title">
                    <span className="icon icon-arrow-left"
                        onClick={() => this.switchToPreviousMonth(this.props.month)}/>
                    {format(this.props.month, 'MMM yyyy')}
                    <span className={`icon icon-arrow-right ${nextMonthAvailable ? '' : 'hidden'}`}
                        onClick={() => this.switchToNextMonth(this.props.month)}/>
                </div>
                {this.props.report
                    ? <>
                        <div
                            className="subTitle">{lang.d('report_generated_at', { distance: formatDistance(new Date(), this.props.reportDate) })}</div>
                        <div className="summaryItemContainer">
                            <div className="summaryItem">
                                <div className="title">{lang.d('total_used_seats')}</div>
                                <div className="number">{formatNumber(this.props.report.numUsedSeats)}</div>
                            </div>
                            <div className="summaryItem">
                                <div className="title">{lang.d('used_seats_per_day')}</div>
                                <div
                                    className="number">{formatNumber(this.numUsedSeatsPerDay(this.props.report))}</div>
                            </div>
                            {isThisMonth(this.props.report.month.date) &&
                            <div className="summaryItem">
                                <div className="title">{lang.d('expected_usage_month')}</div>
                                <div
                                    className="number">{formatNumber(this.expectedUsedSeatsForMonth(this.props.report))}</div>
                            </div>
                            }
                        </div>
                    </>
                    : <div className="noUsageReport">{lang.d('no_report_for_month')}</div>
                }
            </div>
        </>
    }

    numUsedSeatsPerDay (reportForMonth) {
        const monthAsDate = reportForMonth.month.date
        if (isThisMonth(monthAsDate)) {
            const minutesSinceStartOfMonth = differenceInMinutes(this.props.reportDate, startOfMonth(monthAsDate))
            const daysSinceStartOfMonth = minutesSinceStartOfMonth / 24 / 60
            return Math.round(reportForMonth.numUsedSeats / daysSinceStartOfMonth)
        } else {
            return Math.round(reportForMonth.numUsedSeats / getDaysInMonth(monthAsDate))
        }
    }

    expectedUsedSeatsForMonth (reportForMonth) {
        return this.numUsedSeatsPerDay(reportForMonth) * getDaysInMonth(reportForMonth.month.date)
    }

    switchToPreviousMonth (month) {
        const newMonth = subMonths(month, 1)
        this.props.monthSwitched(newMonth)
    }

    switchToNextMonth (month) {
        const newMonth = addMonths(month, 1)
        this.props.monthSwitched(newMonth)
    }
}

AdminUsageInMonthSummary.propTypes = {
    report: PropTypes.object,
    reportDate: PropTypes.object,
    monthSwitched: PropTypes.func,
    month: PropTypes.object
}

class AdminMonthlyUsageReportDetail extends Component {
    createUsageUrlsList (urls) {
        return (
            <div className="usage-report-modal-content">
                <ul>
                    {urls.map(url => {
                        return <li key={urls.indexOf(url)}><a href={url} target="_blank" rel="noopener noreferrer">{url}</a></li>
                    })}
                </ul>
            </div>
        )
    }

    showUsageUrls (urls) {
        this.props.openModalDialog({
            title: lang.d('usage_detail'),
            message: this.createUsageUrlsList(urls),
            settings: {
                onAccept: () => {},
                waitOnAccept: true,
                prompt: false,
                dialogClassName: 'usage-urls-dialog'
            }
        })
    }

    render () {
        return <>
            <div className="title">{lang.d('usage_details')}</div>
            <div className="DataTable details">
                <table style={{ cellPadding: '5' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '5%' }}>{lang.d('name')}</th>
                            <th>{lang.d('usage')}</th>
                            <th>{lang.d('comment')}</th>
                            <th>{lang.d('whitelabeled')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sortBy(this.props.report.usage, 'numUsedObjects')
                                .reverse()
                                .map(usageByCompany => {
                                    return <tr key={usageByCompany.id}>
                                        <td>{usageByCompany.name}</td>
                                        <td className="usage">
                                            <div className="mainUsage">{lang.d('x_' + this.definitionOfUseLanguageKey(usageByCompany), { smart_count: usageByCompany.numUsedObjects })}</div>
                                            <div>{lang.d('x_renderings', { smart_count: usageByCompany.numChartRenderings })} <button className={'button-link'} onClick={() => this.showUsageUrls(usageByCompany.chartRenderingUrls)}><i className="icon-info"/></button></div>
                                        </td>
                                        <td>{usageByCompany.comment}</td>
                                        <td className="whitelabeled">{usageByCompany.whitelabeled ? '✓' : ''}</td>
                                    </tr>
                                })
                        }
                    </tbody>
                </table>
            </div>
        </>
    }

    definitionOfUseLanguageKey (usageByCompany) {
        if (usageByCompany.definitionOfUse === 'FIRST_BOOKING_OR_SELECTION') {
            return 'billable_status_changes_or_selections'
        }
        return 'billable_status_changes'
    }
}

AdminMonthlyUsageReportDetail.propTypes = {
    report: PropTypes.object,
    openModalDialog: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload)),
    openModalDialog: payload => dispatch(openModalDialog(payload))
})

export default connect(null, mapDispatchToProps)(AdminUsageReport)
