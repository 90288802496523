import React, { useState } from 'react'
import lang from '../utils/lang'
import * as PropTypes from 'prop-types'
import { formatWorkspaceName } from '../utils/helperFunctions'

const CopyToForm = props => {
    const {
        values: { context, workspaceList },
        handleChange,
        errors
    } = props

    const [workspaces] = useState(workspaceList)

    return (
        <div className="CopyToForm">
            <div className="copy-to-message">
                <span className="icon icon-copy"/>
                <span className="caption">{lang.d('copy_to_workspace')}</span>
            </div>

            <div className="input-list with-sub-captions">
                { workspaces.filter(workspace => workspace.key !== context.workspace.key).map(workspace =>
                    <div className="list-item" key={workspace.id}>
                        <input type="radio" name="selectedWorkspace" value={workspace.key} id={workspace.id} onChange={handleChange} />
                        <label htmlFor={workspace.id}>
                            <div>{ formatWorkspaceName(workspace) }</div>
                            <div className="sub-caption">{ workspace.key }</div>
                        </label>
                    </div>)
                }
            </div>
        </div>
    )
}

CopyToForm.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func
}

export default CopyToForm
