export function copyToClipboard (str) {
    const createHelperTextArea = () => {
        const tmpTextArea = document.createElement('textarea')
        tmpTextArea.value = str
        tmpTextArea.setAttribute('readonly', '')
        tmpTextArea.style.position = 'absolute'
        tmpTextArea.style.left = '-9999px'
        document.body.appendChild(tmpTextArea)
        return tmpTextArea
    }

    const helperTextArea = createHelperTextArea()
    helperTextArea.select()
    const success = document.execCommand('copy')
    document.body.removeChild(helperTextArea)
    return success
}
