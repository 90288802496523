import React from 'react'
import PropTypes from 'prop-types'
import { setHeader } from '../../actions/header'
import { connect } from 'react-redux'
import { getCompanyUpgradeInfo, getUpgradeSubscriptionPlan } from '../../services/companies.service'
import styles from '../../styles/UpgradeSummary.less'
import lang from '../../utils/lang'
import FormButton from '../../components/GUI/FormButton'

class UpgradeSummary extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            requestedSubscriptionPlan: null,
            currentUpgradeStatus: 'PROCESSING',
            upgradeErrorMessage: null,
            intervalId: null,
            retries: 10
        }
    }

    async componentDidMount () {
        this.props.setHeader({})
        const requestedSubscriptionPlan = await getUpgradeSubscriptionPlan(this.props.companyId)
        this.setState({requestedSubscriptionPlan})

        const companyId = this.props.companyId
        const intervalId = setInterval(async () => {
            this.setState({retries: this.state.retries - 1})
            if (this.state.retries === 0) {
                this.noFinalStateReached()
            }
            const upgradeInfo = await getCompanyUpgradeInfo(companyId)
            if (upgradeInfo.billingInfo && upgradeInfo.billingInfo.paymentMethodCapture) {
                if (upgradeInfo.billingInfo.paymentMethodCapture.status === 'SUCCEEDED') {
                    this.paymentSucceeded(upgradeInfo.billingInfo)
                } else if (upgradeInfo.billingInfo.paymentMethodCapture.status === 'FAILED') {
                    this.paymentFailed(upgradeInfo.billingInfo)
                }
            }
        }, 1000)

        this.setState({requestedSubscriptionPlan, intervalId})
    }

    noFinalStateReached () {
        clearInterval(this.state.intervalId)
        this.setState({intervalId: null, currentUpgradeStatus: 'CHECK_EMAIL'})
    }

    paymentSucceeded(billingInfo) {
        clearInterval(this.state.intervalId)
        this.setState({
            currentUpgradeStatus: billingInfo.paymentMethodCapture.status,
            intervalId: null
        })
    }

    paymentFailed (billingInfo) {
        clearInterval(this.state.intervalId)
        this.setState({
            currentUpgradeStatus: billingInfo.paymentMethodCapture.status,
            upgradeErrorMessage: billingInfo.paymentMethodCapture.lastMessage,
            intervalId: null
        })
    }

    componentWillUnmount () {
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId)
        }
    }

    renderCheckingForUpgrade () {
        return <div className={styles.container}>
            <div className={styles.processingIconContainer}>
                <div className={['icon', 'icon-check-dense', styles.icon].join(' ')} />
            </div>
            <div className={styles.heading}>{lang.d('thankYouForYourOrder')}</div>
            {this.state && this.state.requestedSubscriptionPlan && <div className={styles.details}>
                {lang.d('upgradePleaseWait', { selectedPlan: this.state.requestedSubscriptionPlan.caption })}
            </div>}
        </div>
    }

    renderSuccessfulUpgradeSummary () {
        return <div className={styles.container}>
            <div className={styles.succeededIconContainer}>
                <div className={['icon', 'icon-check-dense', styles.icon].join(' ')} />
            </div>
            <div className={styles.heading}>{lang.d('thankYouForYourOrder')}</div>
            {this.state && this.state.requestedSubscriptionPlan && <div className={styles.details}>
                {lang.d('upgradeThankYou', { selectedPlan: this.state.requestedSubscriptionPlan.caption })}
            </div>}
            <FormButton onClick={() => this.props.history.push('/')} className={styles.actionButton}>{lang.d('returnToCharts')}</FormButton>
        </div>
    }

    renderFailedUpgradeSummary () {
        return <div className={styles.container}>
            <div className={styles.failedIconContainer}>
                <div className={['icon', 'icon-cross-dense', styles.icon].join(' ')} />
            </div>
            <div className={styles.heading}>{lang.d('upgradeProblem')}</div>
            {this.state && this.state.requestedSubscriptionPlan && <div className={styles.details}>
                {lang.d('upgradeProblemMessage', { selectedPlan: this.state.requestedSubscriptionPlan.caption })}
            </div>}
            {this.state && this.state.upgradeErrorMessage && <div className={styles.details}>
                {lang.d('upgradeProblemReason', { failureMessage: this.state.upgradeErrorMessage })}
            </div>}
            <FormButton onClick={() => this.props.history.push('/account/payment-details')} className={styles.actionButton}>{lang.d('checkPaymentDetails')}</FormButton>
        </div>
    }

    renderCheckYourEmail () {
        return <div className={styles.container}>
            <div className={styles.noFinalStateIconContainer}>
                <div className={['icon', 'icon-waiting', styles.icon].join(' ')} />
            </div>
            <div className={styles.heading}>{lang.d('orderPending')}</div>
            {this.state && this.state.requestedSubscriptionPlan && <div className={styles.details}>
                {lang.d('checkYourEmail')}
            </div>}
        </div>
    }

    render () {
        return <div className={styles.PaymentSummary}>
            <div className="sectioned-page">
                <div className="sectioned-page-container">
                    <div className="section">
                        { this.state.currentUpgradeStatus === 'CHECK_EMAIL' && this.renderCheckYourEmail() }
                        { this.state.currentUpgradeStatus === 'SUCCEEDED' && this.renderSuccessfulUpgradeSummary()}
                        { this.state.currentUpgradeStatus === 'FAILED' && this.renderFailedUpgradeSummary()}
                        { (this.state.currentUpgradeStatus === 'NOT_STARTED' || this.state.currentUpgradeStatus === 'PROCESSING') && this.renderCheckingForUpgrade()}
                    </div>
                </div>
            </div>
        </div>
    }
}

UpgradeSummary.propTypes = {
    setHeader: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    companyId: PropTypes.number
}

const mapStateToProps = (state) => ({
    companyId: state.context.company.id
})

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeSummary)
