const defaultState = {
    submitEnabled: true,
    title: null,
    message: null,
    settings: {},
    closed: true,
    accepted: false,
    done: false,
    doneMessage: null,
    doneIcon: 'check',
    isSubmitting: false
}

const modalDialog = (state = defaultState, action) => {
    switch (action.type) {
    case 'MODAL_DIALOG/SET_SUBMIT_ENABLED':
        return {
            ...state,
            submitEnabled: action.payload
        }
    case 'MODAL_DIALOG/SET_SUBMITTING':
        return {
            ...state,
            isSubmitting: action.payload
        }
    case 'MODAL_DIALOG/ACCEPTED':
        return {
            ...state,
            accepted: true
        }
    case 'MODAL_DIALOG/ON_CHANGE':
        return {
            ...state,
            ...action.payload
        }
    case 'MODAL_DIALOG/ON_SUCCESS':
        return {
            ...state,
            ...action.payload,
            done: true,
            accepted: true
        }
    case 'MODAL_DIALOG/OPEN':
        return {
            ...state,
            ...action.payload,
            closed: false
        }
    case 'MODAL_DIALOG/SET_SETTINGS':
        return {
            ...state,
            ...action.payload
        }
    case 'MODAL_DIALOG/CLOSE':
        return {
            ...state,
            closed: true
        }
    case 'MODAL_DIALOG/CLEAR': return defaultState
    default:
        return state
    }
}

export default modalDialog
