import React from 'react'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { setHeader } from '../../actions/header'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { adminService } from '../../services/admin.service'
import FormButton from '../../components/GUI/FormButton'
import LoadingIndicator from '../../components/GUI/LoadingIndicator'
import styles from '../../styles/EmailCompanies.module.less'

const validationSchema = yup.object().shape({
    from: yup.string().required(),
    subject: yup.string().required(),
    text: yup.string().required(),
    html: yup.string().required(),
    isLegallySignificant: yup.boolean().required(),
    sendOnlyToMe: yup.boolean().required()
})

const fromEmailAddresses = [
    {label: 'hello@seats.io', value:'Seats.io <hello@seats.io>'},
    {label: 'sales@seats.io', value:'Seats.io <sales@seats.io>'}
]

const htmlBaseTemplate = `<table width="100%" cellpadding="0" cellspacing="0">
    <tr>
        <td class="content-block">
            Dear Seats.io customer,
        </td>
    </tr>
    <tr>
        <td class="content-block">
            Add some content here. For extra paragraphs, add additional <tr><td>content</td></tr> below the current </tr>
        </td>
    </tr>
    <tr>
        <td class="content-block">
            Thank you for being a customer of seats.io.
        </td>
    </tr>
    <tr>
        <td class="content-block">
            Kind regards,<br/>
            The <a href="https://www.seats.io">Seats.io</a> team
        </td>
    </tr>
</table>
`

const textBaseTemplate = `Dear Seats.io customer,

Kind regards,
The Seats.io team
https://www.seats.io
`

class EmailCompanies extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            initialValues: {
                from: fromEmailAddresses[0].value,
                subject: '',
                text: textBaseTemplate,
                html: htmlBaseTemplate,
                isLegallySignificant: false,
                sendOnlyToMe: true
            },
            selectedFromIndex: 0,
            sendResults: {}
        }
    }

    componentDidMount () {
        this.props.setHeader({
            showSearch: false,
            hasItems: true,
            centerItems: [],
            rightItems: [],
            leftItems: [{
                to: '/admin/companies',
                icon: 'icon-arrow-light-left',
                string_key: 'companies',
                className: 'soft-button'
            }]
        })
    }

    errorClassName (props, fieldName) {
        return props.errors[fieldName] ? 'error' : null
    }

    renderControlPanel (props) {
        return <div className="top-panels">
            <div className="top-panels">
                <div className="controls-panel">
                    <div className="form-field">
                        <div className="description">
                            <label htmlFor="from">From</label>
                        </div>
                        <div className="field">
                            <select>
                                {fromEmailAddresses.map(from => <option key={from.value} value={from.value}>{from.label}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="form-field">
                        <div className="description">
                            <label htmlFor="subject">Subject</label>
                        </div>
                        <div className="field">
                            <input type="text" placeholder="Say something descriptive"
                                id="subject" name="subject" onChange={props.handleChange}
                                value={props.values.subject} className={this.errorClassName(props, 'subject')}/>
                        </div>
                    </div>
                    <div className="flex-wrapper">
                        <span>
                            <input type="checkbox" name="isLegallySignificant"
                                onChange={props.handleChange} value={props.values.isLegallySignificant}/>
                            <span className="checkbox-label">
                                <label htmlFor="isLegallySignificant">Legally significant</label>
                            </span>
                        </span>
                        <span>
                            <input type="checkbox" name="sendOnlyToMe" defaultChecked={props.values.sendOnlyToMe}
                                onChange={props.handleChange} value={props.values.sendOnlyToMe}/>
                            <span className="checkbox-label">
                                <label htmlFor="sendOnlyToMe">Send only to me</label>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    }

    renderContentInput(props) {
        return <div>
            <div className="form-field">
                <span className="checkbox-label">
                    <label htmlFor="html">HTML content</label>
                </span>
                <div className="field">
                    <textarea id="html" name="html" onChange={props.handleChange} cols="80" rows="15"
                        value={props.values.html} className={this.errorClassName(props, 'html')}>
                    </textarea>
                </div>
            </div>
            <div className="form-field">
                <span className="checkbox-label">
                    <label htmlFor="text">Text content</label>
                </span>
                <div className="field">
                    <textarea id="text" name="text" onChange={props.handleChange} cols="80" rows="15"
                        value={props.values.text} className={this.errorClassName(props, 'text')}/>
                </div>
            </div>
        </div>
    }

    renderSendResults() {
        const results = []
        for (const property in this.state.sendResults) {
            results.push(<span>{`${property}: ${this.state.sendResults[property]}`}</span>)
        }
        return <div>
            {results.length > 0 && <div>Email sent. Regional results</div>}
            {results}
        </div>
    }

    render () {
        const readyToSend = this.state.subject && this.state.text && this.state.html
        return <div className={styles.EmailCompanies}>
            <div className="sectioned-page">
                <div className={`sectioned-page-container ${styles.container}`}>
                    <Formik initialValues={this.state.initialValues}
                        validationSchema={validationSchema}
                        validateOnBlur={false}
                        validateOnChange={false}
                        onSubmit={async (values) => {
                            const sendResults = await adminService.emailCompanies(values.from, values.subject, values.isLegallySignificant, values.sendOnlyToMe, values.text, values.html)
                            this.setState({sendResults})
                        }}>
                        {props =>
                            <form className="newStyle" onSubmit={props.handleSubmit}>
                                <div className="section">
                                    {this.renderControlPanel(props)}
                                </div>
                                <div className="section">
                                    {this.renderContentInput(props)}
                                </div>
                                <div className="form-actions">
                                    {props.isSubmitting
                                        ? <LoadingIndicator/>
                                        :  <FormButton className={styles.button} onSubmit={props.handleSubmit} type="submit">Send</FormButton>
                                    }
                                </div>
                                <div>
                                    {this.renderSendResults()}
                                </div>
                            </form>
                        }
                    </Formik>
                </div>
            </div>
        </div>
    }
}

EmailCompanies.propTypes = {
    setHeader: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(null, mapDispatchToProps)(EmailCompanies)
