import React from 'react'
import * as PropTypes from 'prop-types'
import { mapOptionsToButtons } from '../../utils/helperFunctions'

export default class AccountMenu extends React.PureComponent {
    constructor (props) {
        super(props)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
        this.wrapperRef = React.createRef()
    }

    componentDidMount () {
        document.addEventListener('mousedown', this.handleClickOutside)
        document.addEventListener('mousewheel', this.handleScroll)
    }

    componentWillUnmount () {
        document.removeEventListener('mousedown', this.handleClickOutside)
        document.removeEventListener('mousewheel', this.handleScroll)
    }

    handleClickOutside (e) {
        if (!this.wrapperRef.current.contains(e.target)) {
            e.preventDefault()
            e.stopPropagation()
            this.onClose()
        }
    }

    handleScroll (e) {
        e.preventDefault()
    }

    onSelect (uiEvent) {
        this.props.onSelect(uiEvent)
    }

    onClose () {
        this.props.onClose()
    }

    isImpersonating () {
        return this.props.impersonatedCompanyName
    }

    renderImpersonationContents () {
        return <>
            {mapOptionsToButtons(this.props.impersonatorOptions)}
            <div className="impersonation">
                <div className="company">{ this.props.impersonatedCompanyName }</div>
                {mapOptionsToButtons(this.props.userOptions)}
                {mapOptionsToButtons(this.props.companyOptions)}
            </div>
        </>
    }

    renderAccountMenuContents () {
        return <>
            {mapOptionsToButtons(this.props.userOptions)}
            { this.props.companyOptions.length !== 1 &&
                <>
                    <div className="separator"></div>
                    <div className="company">{ this.props.companyName }</div>
                </>
            }
            {mapOptionsToButtons(this.props.companyOptions)}
        </>
    }

    getContents () {
        return <React.Fragment>
            <div className="profile">
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a className="avatar" style={{ backgroundImage: `url(${this.props.avatarUrl})` }} href={this.props.avatarLink} target="_blank" rel="external noopener noreferrer"></a>
                <div className="name">{ this.props.accountName }</div>
            </div>
            { this.isImpersonating()
                ? this.renderImpersonationContents()
                : this.renderAccountMenuContents()
            }
        </React.Fragment>
    }

    render () {
        return <div className="ContextualMenu AccountMenu">
            <div className="menu" ref={this.wrapperRef}>
                <div className="scroll-wrap">
                    { this.getContents() }
                </div>
            </div>
        </div>
    }
}

AccountMenu.propTypes = {
    children: PropTypes.node,
    accountName: PropTypes.string,
    avatarUrl: PropTypes.string,
    avatarLink: PropTypes.string,
    companyName: PropTypes.string,
    impersonatedCompanyName: PropTypes.string,
    onSelect: PropTypes.func,
    onClose: PropTypes.func,
    accountMenuOptions: PropTypes.node,
    userOptions: PropTypes.array,
    companyOptions: PropTypes.array,
    impersonatorOptions: PropTypes.array
}
