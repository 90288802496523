import lang from '../../utils/lang'

const pricingPlans = {
    YEARLY_SILVER: { name: 'Silver', committedSeats: '2,400', pricePerSeat: '0.15', committedEuros: '360', price: 360 },
    MONTHLY_GOLD: { name: 'Gold', committedSeats: '5,000', pricePerSeat: '0.10', committedEuros: '500', price: 500 },
    YEARLY_GOLD: { name: 'Gold', committedSeats: '60,000', pricePerSeat: '0.10', committedEuros: '6,000', price: 6000 },
    MONTHLY_DIAMOND: { name: 'Diamond', committedSeats: '20,000', pricePerSeat: '0.08', committedEuros: '1,600', price: 1600 },
    YEARLY_DIAMOND: { name: 'Diamond', committedSeats: '240,000', pricePerSeat: '0.08', committedEuros: '19,200', price: 19200 },
    MONTHLY_PLATINUM: { name: 'Platinum', committedSeats: '50,000', pricePerSeat: '0.07', committedEuros: '3,500', price: 3500 },
    YEARLY_PLATINUM: { name: 'Platinum', committedSeats: '600,000', pricePerSeat: '0.07', committedEuros: '42,000', price: 42000 },
    MONTHLY_MITHRIL: { name: 'Mithril', committedSeats: '100,000', pricePerSeat: '0.06', committedEuros: '6,000', price: 6000 },
    YEARLY_MITHRIL: { name: 'Mithril', committedSeats: '1,200,000', pricePerSeat: '0.06', committedEuros: '72,000', price: 72000 }
}

function getPeriodicity(selectedPricingPlan) {
    const yearly = selectedPricingPlan.startsWith('YEARLY')
    return yearly ? 'year' : 'month'
}

export const pricingPlanDescription = (selectedPricingPlan) => {
    const periodicity = getPeriodicity(selectedPricingPlan)
    const pricingPlan = pricingPlans[selectedPricingPlan]
    return `${pricingPlan.committedSeats} used seats for €${pricingPlan.committedEuros} per ${periodicity}. €${pricingPlan.pricePerSeat} per additional used seat.`
}

export const firmCommitmentConfirmation = (selectedPricingPlan) => {
    const periodicity = getPeriodicity(selectedPricingPlan)
    const pricingPlan = pricingPlans[selectedPricingPlan]
    return <span>This is a firm commitment to pay <b>at least €{pricingPlan.committedEuros} per {periodicity}</b>.</span>
}

const getPlanByKey = key => {
    if (!pricingPlans[key]) {
        throw new Error(`No plan with name ${key} found.`)
    }
    const pricingPlan = pricingPlans[key]
    pricingPlan.id = key
    pricingPlan.displayName = getPlanDisplayName(pricingPlan)
    return pricingPlan
}

const getPlans = (yearly = false) => {
    const filterKey = yearly ? 'YEARLY' : 'MONTHLY'
    return Object.keys(pricingPlans)
        .filter(key => key.indexOf(filterKey) !== -1)
        .map(key => getPlanByKey(key))
        .sort((a, b) => a.price - b.price)
}

const getPlanDisplayName = plan => lang.d('subscriptionPlanName', {
    committedEuros: plan.committedEuros,
    committedSeats: plan.committedSeats,
    name: plan.name
})

export const getMonthlyPlans = () => getPlans()
export const getYearlyPlans = () => getPlans(true)
export const getPricingPlanByName = name => getPlanByKey(name)