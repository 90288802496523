import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { setHeader } from '../../actions/header'
import lang from '../../utils/lang'
import CopySingleChart from '../../components/Admin/CopySingleChart'
import CopyChartsByTag from '../../components/Admin/CopyChartsByTag'

class AdminCopyCharts extends Component {
    componentDidMount () {
        this.props.setHeader({
            showSearch: false,
            hasItems: true,
            centerItems: [],
            rightItems: [],
            leftItems: [{
                to: '/admin',
                icon: 'icon-arrow-light-left',
                string_key: 'admin',
                className: 'soft-button'
            }]
        })
    }

    render () {
        return <div className="Admin sectioned-page">
            <div className="sectioned-page-container">
                <div className="section">
                    <div className="title">{lang.d('copy_single_chart')}</div>
                    <CopySingleChart/>
                    <br/><br/><br/>
                    <div className="title">{lang.d('copy_multiple_charts')}</div>
                    <CopyChartsByTag/>
                </div>
            </div>
        </div>
    }
}

AdminCopyCharts.propTypes = {
    setHeader: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(null, mapDispatchToProps)(AdminCopyCharts)
