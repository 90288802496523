import { getClient } from './base.service'

export const seasonService = {
    createSeason,
    retrieveSeason,
    createSeasonEvent,
    createPartialSeason,
    addEventsToPartialSeason,
    removeEventsFromPartialSeason
}

function createSeason (chartKey) {
    return getClient().seasons.create(chartKey)
}

function retrieveSeason (key) {
    return getClient().seasons.retrieve(key)
}

function createSeasonEvent (topLevelSeasonKey) {
    return getClient().seasons.createEvents(topLevelSeasonKey, 1)
}

function createPartialSeason (topLevelSeasonKey) {
    return getClient().seasons.createPartialSeason(topLevelSeasonKey)
}

function addEventsToPartialSeason (topLevelSeasonKey, partialSeasonKey, eventKeys) {
    return getClient().seasons.addEventsToPartialSeason(topLevelSeasonKey, partialSeasonKey, eventKeys)
}

function removeEventsFromPartialSeason (topLevelSeasonKey, partialSeasonKey, eventKeys) {
    return Promise.all(eventKeys.map(eventKey => getClient().seasons.removeEventFromPartialSeason(topLevelSeasonKey, partialSeasonKey, eventKey)))
}
