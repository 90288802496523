import React, { Component } from 'react'
import { adminService } from '../../services/admin.service'
import NavigationList from '../../components/GUI/NavigationList'
import { setHeader } from '../../actions/header'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { upperFirst, lowerCase } from 'lodash'

class EmailTemplates extends Component {

    constructor (props) {
        super(props)
        this.state = {
            allTemplates: [],
            internalEmailTemplates: [],
            externalEmailTemplates: [],
            selectedTemplate: null
        }
    }

    async componentDidMount () {
        this.props.setHeader({
            showSearch: false,
            hasItems: true,
            centerItems: [],
            rightItems: [],
            leftItems: [{
                to: '/admin',
                icon: 'icon-arrow-light-left',
                string_key: 'admin',
                className: 'soft-button'
            }]
        })
        const emailTemplates = await adminService.getEmailTemplates()
        const internalTemplates = []
        const externalTemplates = []
        for (const emailTemplate of emailTemplates) {
            if (emailTemplate.destination === 'INTERNAL') {
                internalTemplates.push(emailTemplate)
            } else {
                externalTemplates.push(emailTemplate)
            }
        }
        this.setState({
            allTemplates: emailTemplates,
            internalEmailTemplates: internalTemplates,
            externalEmailTemplates: externalTemplates
        })
    }

    onAction (uiEvent, args) {
        this.setState({
            selectedTemplate: this.state.allTemplates[this.state.allTemplates.map(template => template.name).indexOf(uiEvent)]
        })
    }

    createTemplateItems (templates) {
        const templateItems = []
        for (const template of templates) {
            templateItems.push({
                caption: template.name,
                codename: template.name,
                index: this.state.allTemplates.indexOf(template)
            })
        }
        return templateItems
    }

    renderTemplateList() {
        return <NavigationList
            options={
                [
                    {
                        type: 'title',
                        caption: 'Internal templates'
                    },
                    ...this.createTemplateItems(this.state.internalEmailTemplates),
                    {
                        type: 'title',
                        caption: 'External templates'
                    },
                    ...this.createTemplateItems(this.state.externalEmailTemplates)
                ]
            }
            onSelect={(uiEvent, args) => this.onAction(uiEvent, args)}
        />
    }

    renderEmailTemplate () {
        return <div>
            {this.state.selectedTemplate && <div>
                <table>
                    <tr>
                        <td>Name</td>
                        <td>{this.state.selectedTemplate.name}</td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td>{this.state.selectedTemplate.description}</td>
                    </tr>
                    <tr>
                        <td>Destination</td>
                        <td>{upperFirst(lowerCase(this.state.selectedTemplate.destination))}</td>
                    </tr>
                    <tr>
                        <td>Legally significant?</td>
                        <td><input type="checkbox" disabled="true" checked={this.state.selectedTemplate.isLegallySignificant}/></td>
                    </tr>
                </table>
                <textarea className="contents" key={this.state.allTemplates.map(template => template.name).indexOf(this.state.selectedTemplate.name)} readOnly="true">
                    {this.state.selectedTemplate.contents}
                </textarea>
            </div>}
        </div>
    }

    render () {
        return <div className="EmailTemplates">
            <div className="page">
                {this.renderTemplateList()}
                <div className="emailTemplate">
                    {this.renderEmailTemplate()}
                </div>
            </div>
        </div>
    }
}

EmailTemplates.propTypes = {
    setHeader: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload)),
})

export default connect(null, mapDispatchToProps)(EmailTemplates)
