export const setInvitations = payload => ({
    type: 'TEAM/SET_INVITATIONS',
    payload
})

export const addInvitation = payload => ({
    type: 'TEAM/ADD_INVITATION',
    payload
})

export const cancelInvitation = id => ({
    type: 'TEAM/CANCEL_INVITATION',
    id
})
