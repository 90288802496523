import React from 'react'
import lang from '../../utils/lang'
import Input from '../Input'
import * as PropTypes from 'prop-types'
import { GlobalError } from './ModalDialogGlobalError'

export const EditWorkspaceForm = props => {
    const {
        errors,
        touched,
        handleChange,
        isSubmitting,
        values
    } = props

    const nameErrors = errors.name && touched.name
    return <>
        <div className="form">
            <div className="form-field wide">
                <Input name="name" type="text" value={values.name} placeholder={lang.d('name')} className={nameErrors && 'error'} onChange={handleChange} disabled={isSubmitting || props.status === 'submitted'} autoFocus />
                { nameErrors &&
                <div className="errors">
                    { nameErrors && <div className="error">{errors.name}</div> }
                </div>
                }
            </div>
        </div>
        <GlobalError errors={errors} />
    </>
}

EditWorkspaceForm.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    values: PropTypes.object,
    isSubmitting: PropTypes.bool,
    status: PropTypes.string
}
