import React from 'react'
import * as PropTypes from 'prop-types'
import { retrieveRegions } from '../services/region.service'
import { getRegions, setRegions } from '../domain/regions'

export default class RegionFetcher extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            loaded: false
        }
    }

    async componentDidMount () {
        if (getRegions()) {
            this.setState({ loaded: true })
        } else {
            const regions = await retrieveRegions()
            setRegions(regions)
            this.setState({ loaded: true })
        }
    }

    render () {
        if (this.state.loaded) {
            return this.props.children
        }

        return null
    }
}

RegionFetcher.propTypes = {
    children: PropTypes.element
}
