import { useEffect } from 'react'
import { persistencyService } from '../../services/persistency.service'
import { history } from '../../store'
import { workspacesService } from '../../services/workspaces.service'
import * as PropTypes from 'prop-types'
import { switchToWorkspace } from '../../actions/user'
import { useDispatch } from 'react-redux'

const SwitchToWorkspace = props => {
    const dispatch = useDispatch()
    const workspaceKey = props.match.params.key
    useEffect(() => {
        async function init () {
            const workspace = await workspacesService.retrieveWorkspace(workspaceKey)
            dispatch(switchToWorkspace(workspace))
            persistencyService.remove('charts-scroll')
            history.push('/')
        }

        init()
    }, [dispatch, workspaceKey])

    return null
}

SwitchToWorkspace.propTypes = {
    match: PropTypes.object
}

export default SwitchToWorkspace
