import React from 'react'
import lang from '../../utils/lang'
import { Formik } from 'formik'
import { userService } from '../../services/user.service'
import { history } from '../../store'
import LoadingIndicator from '../../components/GUI/LoadingIndicator'
import * as PropTypes from 'prop-types'
import { validatePassword } from '../../utils/validators'

export default function ConfirmResetPasswordForm (props) {
    return (
        <Formik
            initialValues={{ newPassword: '', confirmPassword: '' }}
            validate={values => {
                const errors = {}

                if (!values.newPassword) {
                    errors.newPassword = lang.d('required')
                } else if (!validatePassword(values.newPassword)) {
                    errors.newPassword = lang.d('invalid_password')
                }

                if (!values.confirmPassword) {
                    errors.confirmPassword = lang.d('required')
                } else if (values.newPassword !== values.confirmPassword) {
                    errors.confirmPassword = lang.d('password_mismatch')
                }

                return errors
            }}
            onSubmit={(values, { setErrors }) => {
                return userService.confirmResetPassword(parseInt(props.userId), props.region, props.resettedPasswordCode, values.newPassword)
                    .then(res => {
                        values.newPassword = ''
                        values.confirmPassword = ''
                        history.push('/login')
                    })
                    .catch(err => {
                        setErrors({ global: lang.d('reset_password_error') })
                        console.error(err)
                    })
            }}
            render={Form}
        />
    )
}

const Form = (props) => {
    const {
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        isSubmitting
    } = props

    const newPasswordError = errors.newPassword && touched.newPassword
    const confirmPasswordError = errors.confirmPassword && touched.confirmPassword

    return (
        <form onSubmit={handleSubmit} className="AccountForm dialog">
            <div className="title">{ lang.d('reset_password') }</div>

            <div className="form">
                <div className="form-field">
                    <input id="newPassword"
                        type="password"
                        placeholder={lang.d('password')}
                        value={values.newPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={newPasswordError && 'error'}
                    />
                    { newPasswordError && <div className="errors">
                        <div className="error" style={{ margin: '4px' }}>{errors.newPassword}</div>
                    </div> }
                </div>
                <div className="form-field">
                    <input id="confirmPassword"
                        type="password"
                        placeholder={lang.d('confirm_password')}
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={confirmPasswordError && 'error'}
                    />
                    { confirmPasswordError && <div className="errors">
                        { confirmPasswordError && <div className="error" style={{ margin: '4px' }}>{errors.confirmPassword}</div> }
                    </div> }
                </div>
                {errors && Object.keys(errors).length > 0 && <div className="errors">
                    {errors.global && <div className="error">{errors.global}</div>}
                </div>}
                <div className="actions">
                    { isSubmitting
                        ? <LoadingIndicator />
                        : <input type="submit" value={lang.d('change_password')} disabled={isSubmitting} className="action highlighted"/>
                    }
                </div>
            </div>
        </form>
    )
}

ConfirmResetPasswordForm.propTypes = {
    userId: PropTypes.string,
    region: PropTypes.string,
    resettedPasswordCode: PropTypes.string
}

Form.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.bool,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleBlur: PropTypes.func,
    values: PropTypes.object,
    isSubmitting: PropTypes.bool
}
