import React, { Component } from 'react'
import { connect } from 'react-redux'
import { exposeErrors } from '../../utils/errors'
import { adminService } from '../../services/admin.service'
import * as PropTypes from 'prop-types'
import FormButton from '../../components/GUI/FormButton'
import { openModalDialog } from '../../actions/modalDialog'
import { Environment } from '../../environment'

class DeploymentPipeline extends Component {

    state = {
        deploymentPipeline: null,
        refreshTimeout: null
    }

    async componentDidMount () {
        await this.scheduleRefreshDeploymentPipelineStatus()
    }

    async componentWillUnmount() {
        clearTimeout(this.state.refreshTimeout)
    }

    async refreshDeploymentPipelineStatus (app) {
        const deploymentPipeline = await exposeErrors(adminService.getDeploymentPipeline(app))
        this.setState({ deploymentPipeline })
    }

    async scheduleRefreshDeploymentPipelineStatus () {
        this.refreshDeploymentPipelineStatus(this.props.app)
            .finally(() => this.setState({ refreshTimeout: setTimeout(this.scheduleRefreshDeploymentPipelineStatus.bind(this), 10000) }))
    }

    render () {
        const deploymentPipeline = this.state.deploymentPipeline
        if (!deploymentPipeline) {
            return null
        }

        const staging = deploymentPipeline.environments.staging.heroku || deploymentPipeline.environments.staging.pulumi
        const prod = deploymentPipeline.environments.prod.heroku || deploymentPipeline.environments.prod.pulumi
        const stagingCommitHash = staging.deployedGitCommitHash
        const prodCommitHash = prod.deployedGitCommitHash

        return <>
            <div className="deploymentPipeline sectioned-page-container">
                <div className="content">
                    <div className="environment">
                        <div>
                            <div className="title">Staging</div>
                            {this.statusBadge('build', deploymentPipeline.build)}
                        </div>
                        {deploymentPipeline.environments.staging.heroku &&
                            <div className="item">
                                <div className="itemTitle">Heroku</div>
                                {!this.stagingCommitHashesEqual()
                                    ? <div className="commitHashError">not equal to Pulumi</div>
                                    : null}
                                {this.commitInfo(deploymentPipeline.build, deploymentPipeline.environments.staging.heroku.deployedGitCommitHash)}
                            </div>
                        }
                        {deploymentPipeline.environments.staging.pulumi &&
                            <div className="item">
                                <div className="itemTitle">Pulumi</div>
                                {!this.stagingCommitHashesEqual()
                                    ? <div className="commitHashError">not equal to Heroku</div>
                                    : null}
                                {this.commitInfo(deploymentPipeline.build, deploymentPipeline.environments.staging.pulumi.deployedGitCommitHash)}
                            </div>
                        }
                    </div>
                    <div className="action">
                        <div>
                            {this.promoteButton(deploymentPipeline.promotion.status)}
                            <div className="diff">
                                <a href={`${this.getGithubUrl()}/compare/${prodCommitHash}...${stagingCommitHash}`}
                                    target="_blank">GitHub diff</a>
                            </div>
                        </div>
                    </div>
                    <div className="environment">
                        <div className="title">Production</div>
                        {this.statusBadge('promote', deploymentPipeline.promotion)}
                        {this.rollbackButton()}
                        {deploymentPipeline.environments.prod.heroku &&
                            <div className="item">
                                <div className="itemTitle">Heroku</div>
                                {this.commitInfo(deploymentPipeline.promotion, deploymentPipeline.environments.prod.heroku.deployedGitCommitHash)}
                            </div>
                        }
                        {deploymentPipeline.environments.prod.pulumi &&
                            <div className="item">
                                <div className="itemTitle">Pulumi</div>
                                {this.commitInfo(deploymentPipeline.promotion, deploymentPipeline.environments.prod.pulumi.deployedGitCommitHash)}
                            </div>
                        }
                        <div className="links">
                            <a href="https://my.papertrailapp.com/groups/24007182/events?q=-MessageSender+-LoggingFilter+-CleanupExpiredHoldTokensJob+-LogAgeOfOldestMessageInQueueJob+-ArchiveOldStatusChangesJob+-DeleteOldEventLogItemsJob"
                                target="_blank">Papertrail</a>
                            <a href="https://my.appoptics.com/s/dashboards/1303549" target="_blank">AppOptics</a>
                            <a href="https://sentry.io/organizations/seatsio/issues/searches/2487758/?environment=PROD&groupStatsPeriod=auto&project=1429616&sort=date&statsPeriod=1h"
                                target="_blank">Sentry</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    promote () {
        const staging = this.state.deploymentPipeline.environments.staging.heroku || this.state.deploymentPipeline.environments.staging.pulumi
        this.props.openModalDialog({
            title: `Promote ${shortenCommitHash(staging.deployedGitCommitHash)} to production?`,
            successMessage: 'Promotion started',
            onSubmit: async () => {
                await exposeErrors(adminService.promoteStagingToProduction(this.props.app))
                await exposeErrors(this.refreshDeploymentPipelineStatus(this.props.app))
            },
            settings: {
                acceptCaption: 'Promote',
                dangerousAction: true,
                waitOnAccept: true,
                formik: {
                    initialValues: {},
                    render: () => this.promotionWarning()
                }
            }
        })
    }

    commitInfo (workflowStatus, deployedCommitHash) {
        if (workflowStatus.commit === deployedCommitHash) {
            return <div className="commitInfo">
                <a href={`${this.getGithubUrl()}/commit/${workflowStatus.commit}`}
                    target="_blank">{shortenCommitHash(workflowStatus.commit)}</a>: {workflowStatus.commitMessage}
            </div>
        }
        return <div className="commitInfo">{shortenCommitHash(deployedCommitHash)}</div>
    }

    getGithubUrl () {
        return `https://github.com/seatsio/${this.props.app.githubRepo}`
    }

    promoteButton (status) {
        return <FormButton onClick={this.promote.bind(this)} noMargin={true}
            loading={status === 'in_progress'}>Promote</FormButton>
    }

    rollbackButton () {
        return <a onClick={this.rollback.bind(this)} href="#" noMargin={true}>Roll back...</a>
    }

    rollback () {
        const deploymentPipeline = this.state.deploymentPipeline
        this.props.openModalDialog({
            title: `Roll back to previous release of ${this.props.app.githubRepo}?`,
            successMessage: 'Rollback started',
            onSubmit: async () => {
                await exposeErrors(adminService.rollbackDeployment(this.props.app))
                await exposeErrors(this.refreshDeploymentPipelineStatus(this.props.app))
            },
            settings: {
                acceptCaption: 'Roll back',
                dangerousAction: true,
                waitOnAccept: true,
                formik: {
                    initialValues: {},
                    render: () => null
                }
            }
        })
    }

    promotionWarning () {
        let message = null
        if (!this.stagingCommitHashesEqual()) {
            message = 'Warning: the Pulumi and Heroku commit hashes on staging don\'t match'
        } else if (!this.stagingAndProdCommitHashesDifferent()) {
            message = 'Warning: there doesn\'t seem to be anything to deploy'
        } else if (this.buildFailed()) {
            message = 'Warning: build failed'
        } else if (this.buildInProgress()) {
            message = 'Warning: build in progress'
        }

        return <ul>
            {message !== null && <li>{message}</li>}
            {!Environment.isBackendProd && <li>This is not a production environment, no deployment will occur</li>}
        </ul>
    }

    stagingCommitHashesEqual () {
        const environments = this.state.deploymentPipeline.environments
        if (!environments.staging.pulumi || !environments.staging.heroku) {
            return true
        }
        const stagingCommitHashPulumi = environments.staging.pulumi.deployedGitCommitHash
        const stagingCommitHashHeroku = environments.staging.heroku.deployedGitCommitHash
        return stagingCommitHashHeroku === stagingCommitHashPulumi
    }

    stagingAndProdCommitHashesDifferent () {
        const environments = this.state.deploymentPipeline.environments
        if (environments.staging.pulumi) {
            const stagingCommitHashPulumi = environments.staging.pulumi.deployedGitCommitHash
            const prodCommitHashPulumi = environments.prod.pulumi.deployedGitCommitHash
            return stagingCommitHashPulumi !== prodCommitHashPulumi
        } else {
            const stagingCommitHashHeroku = environments.staging.heroku.deployedGitCommitHash
            const prodCommitHashHeroku = environments.prod.heroku.deployedGitCommitHash
            return stagingCommitHashHeroku !== prodCommitHashHeroku
        }
    }

    buildInProgress () {
        return this.state.deploymentPipeline.build.status === 'in_progress'
    }

    buildFailed () {
        return this.state.deploymentPipeline.build.status === 'failure'
    }

    statusBadge (name, flow) {
        let url = flow.status === 'never_ran' ? undefined : `${this.getGithubUrl()}/actions/runs/${flow.id}`
        return <a href={url} target="_blank" className="statusBadge">
            <span className="left">{name}</span>
            <span className={`status ${flow.status}`}>{flow.status}</span>
        </a>
    }
}

function shortenCommitHash (commitHash) {
    if (commitHash === null) {
        return null
    }
    return commitHash.substr(0, 8)
}

DeploymentPipeline.propTypes = {
    openModalDialog: PropTypes.func.isRequired,
    app: PropTypes.object
}

const mapDispatchToProps = dispatch => ({
    openModalDialog: payload => dispatch(openModalDialog(payload))
})

export default connect(null, mapDispatchToProps)(DeploymentPipeline)
