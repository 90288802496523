import React from 'react'
import Thumbnail from '../../components/GUI/Thumbnail'
import Tags from '../../components/Charts/Tags'
import lang from '../../utils/lang'
import * as PropTypes from 'prop-types'
import PreviewLink from '../../components/Charts/PreviewLink'

export const loadingPlaceholder = (
    <div className="ChartDetailSubpage">
        <div className="Thumbnail">
            <div className="loading-placeholder">
                <div className="fake-item"></div>
            </div>
        </div>
        <div className="description">
            <div className="loading-placeholder">
                <div className="fake-item"></div>
                <div className="fake-item"></div>
            </div>
        </div>
    </div>
)

class ChartDetailSubpage extends React.Component {
    render () {
        if (Object.keys(this.props.chart).length === 0) return loadingPlaceholder
        const { chart: { key, name, tags, publishedVersionThumbnailUrl } } = this.props

        return <div className="ChartDetailSubpage">
            <Thumbnail chartKey={key} image={publishedVersionThumbnailUrl} />
            <div className="description">
                <div className="title">{name}</div>
                <div className="definition">
                    <div className="label">{ lang.d('chart_key') }</div>
                    <div className="value">
                        <div className="content fixed-width">{key}</div>
                    </div>
                </div>
                <div className="definition">
                    <div className="label">{ lang.d('tags') }</div>
                    <div className="value">
                        <Tags tags={tags} chartKey={key}/>
                    </div>
                </div>
                <div className="definition">
                    <div className="label">{ lang.d('preview_link') }</div>
                    <div className="value">
                        <PreviewLink chartKey={key} />
                    </div>
                </div>
            </div>
        </div>
    }
}

ChartDetailSubpage.propTypes = {
    chart: PropTypes.object
}

export default ChartDetailSubpage
