import React from 'react'
import { Link } from 'react-router-dom'
import lang from '../../utils/lang'
import Button from '../../components/GUI/Button'
import Thumbnail from '../GUI/Thumbnail'
import CopyToForm from '../CopyToForm'
import ChartItemEvents from './ChartItemEvents'
import { history } from '../../store'
import { contextualMenuService } from '../../services/contextualMenu.service'
import * as PropTypes from 'prop-types'
import { chartService } from '../../services/chart.service'
import { generateChartValidationListForContextualMenu } from '../../utils/helperFunctions'
import { isNonAdmin, isSuperAdminImpersonating } from '../../reducers/user'
import { workspacesService } from '../../services/workspaces.service'
import { connect } from 'react-redux'
import { DEFAULT_NOTIFICATION_TIMEOUT, notificationService } from '../../services/notification.service'
import { showFailedToContactServerError } from '../../utils/errors'
import { openModalDialog, setSubmitEnabled } from '../../actions/modalDialog'
import { adminService } from '../../services/admin.service'

const editOption = {
    type: 'edit',
    caption: lang.d('edit_seating_chart'),
    uiEvent: 'editChart'
};
const duplicateOption = {
    type: 'duplicate',
    caption: lang.d('duplicate_chart'),
    uiEvent: 'duplicateChart'
};
let archiveOption = {
    type: 'archive',
    caption: lang.d('archive_chart'),
    uiEvent: 'archiveChart'
};

let copyToWorkspaceOption = {
    type: 'copy',
    caption: lang.d('copy_to_workspace'),
    uiEvent: 'copyToWorkspace'
};


let copyToAdminOption = {
    type: 'copy',
    caption: lang.d('copy_to_admin'),
    uiEvent: 'copyToAdmin'
};

const contextMenuOptionsForWorkspaces = () => {
    return [
        editOption,
        duplicateOption,
        archiveOption
    ]
}

const contextMenuOptions = (enableCopyChart) => contextMenuOptionsForWorkspaces().concat(enableCopyChart ? copyToWorkspaceOption : [])
const contextMenuOptionsSuperAdmin = () =>  contextMenuOptions().concat(copyToAdminOption)

const draftStatusOptions = [
    {
        type: 'publish',
        caption: lang.d('publish_changes'),
        uiEvent: 'publishDraft'
    },
    {
        type: 'delete',
        caption: lang.d('discard_changes'),
        uiEvent: 'discardDraft'
    },
    {
        type: 'view-published',
        caption: lang.d('view_published_version'),
        uiEvent: 'viewPublished'
    }
]

const NOT_USED = 'NOT_USED'
const PUBLISHED = 'PUBLISHED'
const PUBLISHED_WITH_DRAFT = 'PUBLISHED_WITH_DRAFT'

const copyToWorkspaceForm = props => {
    const {
        values,
        handleChange,
        errors,
        setFieldValue
    } = props

    return <CopyToForm values={values} handleChange={handleChange} errors={errors} setFieldValue={setFieldValue} />
}

class ChartListItem extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            statusContextualMenuOpened: false,
            contextualMenuOpened: false,
            contextualMenuPosition: {},
            events: this.props.chart.events,
            validationResult: [],
            validationMenuOpened: false
        }
        this.statusRef = React.createRef()
        this.validationRef = React.createRef()
    }

    async componentDidMount () {
        await this.validateChart()
    }

    async componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.chart.validation !== prevProps.chart.validation) {
            await this.validateChart()
        }
    }

    async validateChart () {
        const validation = this.props.chart.validation || await chartService.getPublishedChartValidation(this.props.chart.key)
        const list = generateChartValidationListForContextualMenu(validation)
        this.setState({ validationResult: list, hasError: validation.errors && validation.errors.length > 0 })
    }

    getContextualMenuOptions () {
        if (isSuperAdminImpersonating(this.props.user, this.props.context)) {
            return contextMenuOptionsSuperAdmin()
        } else {
            return contextMenuOptions(this.props.enableCopyChart)
        }
    }

    onContextMenu (e) {
        this.setState({ contextualMenuOpened: true })
        contextualMenuService.show(this.getContextualMenuOptions(), {
            onSelect: uiEvent => this.onAction(uiEvent),
            onClose: () => this.setState({ contextualMenuOpened: false }),
            position: {
                left: e.clientX,
                top: e.clientY
            }
        })
    }

    onValidationContextMenu () {
        const rect = this.validationRef.current.getBoundingClientRect()
        contextualMenuService.show(this.state.validationResult, {
            onClose: () => this.setState({ validationMenuOpened: false }),
            position: {
                left: rect.left - 4,
                top: rect.bottom - 2
            }
        })
        this.setState({ validationMenuOpened: true })
    }

    onStatusContextMenu () {
        const rect = this.statusRef.current.getBoundingClientRect()
        this.setState({ statusContextualMenuOpened: true })
        contextualMenuService.show(draftStatusOptions, {
            onSelect: uiEvent => this.onAction(uiEvent),
            onClose: () => this.setState({ statusContextualMenuOpened: false }),
            position: {
                left: rect.left - 4,
                top: rect.bottom - 2
            }
        })
    }

    async onAction (uiEvent) {
        this.setState({
            contextualMenuOpened: false,
            statusContextualMenuOpened: false
        })
        switch (uiEvent) {
        case 'editChart':
            history.push(`/charts/${this.props.chart.key}/edit`)
            break
        case 'archiveChart':
            this.setState({ loading: true })
            try {
                await this.props.archiveChart({ key: this.props.chart.key, name: this.props.chart.name })
            } catch (e) {
                console.error('Failed to archive chart.')
            }
            this.setState({ loading: false })
            break
        case 'duplicateChart':
            this.props.handleCopyChart(this.props.chart.key, this.props.chart.validation)
            break
        case 'discardDraft':
            this.props.handleDiscardDraft(this.props.chart.key)
            break
        case 'publishDraft':
            this.props.handlePublishDraft(this.props.chart.key)
            break
        case 'viewPublished':
            history.push(`/charts/${this.props.chart.key}/view-published`)
            break
        case 'copyToWorkspace':
            await this.handleCopyTo()
            break
        case 'copyToAdmin':
            await this.handleCopyToAdmin()
            break
        default:
            break
        }
    }

    async handleCopyToAdmin () {
        try {
            await adminService.copyToAdminAccount(this.props.chart.key)
            notificationService.info(lang.d('copied_to_admin_account'), this.props.chart.key, DEFAULT_NOTIFICATION_TIMEOUT)
        } catch (err) {
            showFailedToContactServerError()
        }
    }

    async handleCopyTo () {
        const { user, context } = this.props
        const workspaceList = await workspacesService.listActiveWorkspaces()
        this.props.setSubmitEnabled(false)
        this.props.openModalDialog({
            title: this.props.chart.name,
            prompt: workspaceList.length !== 0,
            onSubmit: values => chartService.copyToWorkspace(this.props.chart.key, values.selectedWorkspace),
            successMessage: lang.d('copied'),
            settings: {
                acceptCaption: lang.d('copy'),
                formik: {
                    initialValues: { user, context, workspaceList },
                    validate: (values) => {
                        const errors = {}
                        this.props.setSubmitEnabled(values.selectedWorkspace !== '')
                        return errors
                    },
                    render: copyToWorkspaceForm
                }
            }
        })
    }

    howManyTagsCanFit () {
        const { tags } = this.props.chart
        let x = 0
        let sum = 0
        for (; x < tags.length; x++) {
            if (sum + tags[x].length > 58) break
            sum += tags[x].length + 2
        }
        return x
    }

    renderTags () {
        const { tags } = this.props.chart
        if (tags.length === 0) return
        const numberOfTags = this.howManyTagsCanFit()
        const remainingNumberOfTags = tags.length - numberOfTags
        const text = remainingNumberOfTags === 1 ? '1 more tag' : `${remainingNumberOfTags} more tags`
        return <>
            { tags.slice(0, numberOfTags).map((tag, i) => <span className="tag" key={i}>{tag}</span>) }
            { remainingNumberOfTags > 0 && <span className="remaining-tag">{text}</span>}
        </>
    }

    render () {
        const { key, name, status, publishedVersionThumbnailUrl } = this.props.chart
        const showStatus = status !== NOT_USED
        const hasDraft = status === PUBLISHED_WITH_DRAFT
        const isPublished = [PUBLISHED, PUBLISHED_WITH_DRAFT].includes(status)
        const showValidation = this.state.validationResult.length > 0
        const validationType = this.state.hasError ? 'error' : 'warning'

        return (
            <div className={`ChartListItem ${this.state.contextualMenuOpened && 'contextual-menu-opened'} ${(this.props.loading || this.state.loading) && 'loading'}`} onContextMenu={e => e.preventDefault()}>
                <div className="chart">
                    <Link to={`/charts/${key}`} className="surface" onContextMenu={e => this.onContextMenu(e)}>
                        <div className="icon icon-arrow-light-right"></div>
                    </Link>
                    <Button preset="round" type="more" onClick={e => this.onContextMenu(e)} />
                    <Thumbnail chartKey={key} image={publishedVersionThumbnailUrl} />
                    <div className="description">
                        <div>
                            <div className="title">{name}</div>
                            { showStatus &&
                                <div className={`status ${hasDraft && 'with-options'} ${isPublished && 'published'} ${this.state.statusContextualMenuOpened && 'showing-options'}`}>
                                    { isPublished && <span className="caption published">
                                        <span className="icon icon-publish"></span>
                                        { lang.d('published') }
                                    </span> }
                                    { hasDraft && <span className={`caption with-options ${this.state.statusContextualMenuOpened && 'showing-options'}`} onClick={() => this.onStatusContextMenu()} ref={this.statusRef}>
                                        { lang.d('unpublished_changes') }
                                        <span className={`icon icon-arrow-medium-down ${this.state.statusContextualMenuOpened && 'flipped'}`}></span>
                                    </span> }
                                </div>
                            }
                            { showValidation &&
                                <div className="validation" onClick={() => this.onValidationContextMenu()} ref={this.validationRef}>
                                    <span className={`validation-text-and-icons ${validationType} ${this.state.validationMenuOpened && 'showing-options'}`}>
                                        <span className={`icon icon-validation-${validationType}`}/>
                                        { validationType === 'error' ? lang.d('validation_errors') : lang.d('validation_warnings') }
                                        <span className={`icon icon-arrow-medium-down ${this.state.validationMenuOpened && 'flipped'}`}/>
                                    </span>
                                </div>
                            }
                        </div>
                        <div className="tags">
                            {this.renderTags()}
                        </div>
                    </div>
                </div>
                {this.props.chart.events && <ChartItemEvents events={this.props.chart.events} chartKey={key} context={this.props.context} searchParameters={this.props.searchParameters}/>}
            </div>
        )
    }
}

ChartListItem.propTypes = {
    chart: PropTypes.object.isRequired,
    enableCopyChart: PropTypes.bool.isRequired,
    handlePublishDraft: PropTypes.func.isRequired,
    handleDiscardDraft: PropTypes.func.isRequired,
    handleCopyChart: PropTypes.func.isRequired,
    archiveChart: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    user: PropTypes.object,
    context: PropTypes.object,
    setSubmitEnabled: PropTypes.func,
    openModalDialog: PropTypes.func,
    searchParameters: PropTypes.object
}

copyToWorkspaceForm.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
    setSubmitEnabled: enabled => dispatch(setSubmitEnabled(enabled)),
    openModalDialog: payload => dispatch(openModalDialog(payload))
})

export default connect(null, mapDispatchToProps)(ChartListItem)
