import React from 'react'
import { chartService } from '../../services/chart.service'
import lang from '../../utils/lang'
import { exposeErrors } from '../../utils/errors'
import * as PropTypes from 'prop-types'
import TextInput from '../GUI/TextInput'
import { diffArrays } from '../../utils/helperFunctions'

class Tags extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            addNewTag: false,
            chartKey: this.props.chartKey,
            tags: this.props.tags,
            removingTag: null,
            allSuggestions: [],
            filteredSuggestions: [],
            updateStatus: ''
        }

        this.submitChanges = this.submitChanges.bind(this)
        this.validateTag = this.validateTag.bind(this)
        this.closeTagInput = this.closeTagInput.bind(this)
    }

    async openTagInput () {
        this.setState({ addNewTag: true, showAutocomplete: true })
    }

    openTagInputWithDelay () {
        setTimeout(() => this.openTagInput(), 200)
    }

    async componentDidMount () {
        const suggestions = await chartService.listAllTags()
        this.setState({
            allSuggestions: suggestions,
            filteredSuggestions: diffArrays(suggestions, this.props.tags)
        })
    }

    closeTagInput () {
        this.setState({
            addNewTag: false,
            loadingTag: false,
            invalidTag: false,
            tagExists: false,
            showAutocomplete: false
        })
    }

    validateTag (tag) {
        const expr = /[^\s]+/
        const tagIsEmpty = tag.match(expr) === null
        const tagExists = this.state.tags.indexOf(tag) >= 0
        return {
            isValid: !tagExists && !tagIsEmpty,
            errorMessage: tagExists ? lang.d('tag_exists') : tagIsEmpty ? lang.d('empty_tag') : lang.d('invalid_tag')
        }
    }

    async submitChanges (newValue) {
        if (newValue) {
            this.setState({ updateStatus: 'loading' })
            await this.addTag(newValue)
            this.setState({ updateStatus: '' })
        }
        this.closeTagInput()
    }

    async addTag (tag) {
        if (this.state.tags.indexOf(tag) === -1) {
            await exposeErrors(chartService.addTag(this.state.chartKey, tag))
            this.setState({
                tags: [...this.state.tags, tag],
                filteredSuggestions: this.state.filteredSuggestions.filter(t => t !== tag)
            })
        }
    }

    async removeTag (tag) {
        this.setState({ removingTag: tag })
        await exposeErrors(chartService.removeTag(this.state.chartKey, tag))
        this.setState({ removingTag: null })
        const tags = this.state.tags.filter(t => t !== tag)
        this.setState({
            tags,
            filteredSuggestions: [...this.state.filteredSuggestions, tag]
        })
    }

    render () {
        const { tags, addNewTag, removingTag, filteredSuggestions, updateStatus } = this.state
        const hasTags = tags.length > 0

        return <div className="Tags">
            { tags.map(tag => {
                return <span
                    className={`tag ${removingTag === tag && 'loading'}`}
                    key={`tag-${tag}`}
                    onClick={() => this.removeTag(tag)}
                >
                    <span className="caption">{ tag }</span>
                    <span className="icon icon-close-light" />
                </span>
            })}

            { addNewTag &&
                <TextInput
                    onChangeFinal={this.submitChanges}
                    suggestions={filteredSuggestions}
                    disabled={updateStatus === 'loading'}
                    onCancel={this.closeTagInput}
                    loading={updateStatus === 'loading'}
                    onEnter={() => this.openTagInputWithDelay()}
                    showLoadingIndicator
                    autoFocus
                    escCancelsEdit
                    submitOnBlur={true}
                />
            }

            { !this.state.addNewTag &&
                <div className={`button ${this.state.addNewTag && 'input-field-shown'}`} onClick={() => this.openTagInput()}>
                    <span className="icon icon-plus-bold" />
                    { !hasTags && <span className="caption">{ lang.d('add_tag') }</span> }
                </div>
            }
        </div>
    }
}

Tags.propTypes = {
    chartKey: PropTypes.string,
    tags: PropTypes.array
}

export default Tags
