export class Account {
    constructor (data) {
        this.secretKey = data.secretKey
        this.designerKey = data.designerKey
        this.publicKey = data.publicKey
        this.settings = new AccountSettings(data.settings)
        this.company = data.company
        this.email = data.email
        this.role = data.role
    }
}

export class AccountSettings {
    constructor (data) {
        this.draftChartDrawingsEnabled = data.draftChartDrawingsEnabled
        this.holdOnSelectForGAs = data.holdOnSelectForGAs
        this.holdPeriodInMinutes = data.holdPeriodInMinutes
        this.chartValidation = new ChartValidationSettings(data.chartValidation)
        this.defaultRendererSettings = new DefaultRendererSettings(data.defaultRendererSettings)
    }
}

export class ChartValidationSettings {
    constructor (data) {
        this.VALIDATE_DUPLICATE_LABELS = data.VALIDATE_DUPLICATE_LABELS
        this.VALIDATE_OBJECTS_WITHOUT_CATEGORIES = data.VALIDATE_OBJECTS_WITHOUT_CATEGORIES
        this.VALIDATE_UNLABELED_OBJECTS = data.VALIDATE_UNLABELED_OBJECTS
        this.VALIDATE_FOCAL_POINT = data.VALIDATE_FOCAL_POINT
        this.VALIDATE_OBJECT_TYPES_PER_CATEGORY = data.VALIDATE_OBJECT_TYPES_PER_CATEGORY
        this.VALIDATE_EMPTY_FLOOR = data.VALIDATE_EMPTY_FLOOR
    }
}

export class DefaultRendererSettings {
    constructor (data) {
        this.showFullScreenButton = data.showFullScreenButton
        this.multiSelectEnabled = data.multiSelectEnabled
    }
}
