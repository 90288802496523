import lang from '../utils/lang'

const defaultState = {
    showSearch: false,
    caption: null,
    hideHeader: true,
    leftItems: [],
    centerItems: []
}

function fallbackToDefault (payload, key) {
    return payload[key] === undefined ? defaultState[key] : payload[key]
}

const header = (state = defaultState, action) => {
    switch (action.type) {
    case 'HEADER/SET':
        return {
            ...state,
            ...action.payload,
            hideHeader: false,
            caption: action.payload.caption ? lang.d(action.payload.caption) : lang.d('search'),
            showSearch: fallbackToDefault(action.payload, 'showSearch'),
            leftItems: fallbackToDefault(action.payload, 'leftItems'),
            centerItems: fallbackToDefault(action.payload, 'centerItems')
        }
    case 'HEADER/RESET':
        return {
            ...defaultState,
            hideHeader: false
        }
    case 'HEADER/HIDE':
        return {
            hideHeader: true
        }
    default:
        return state
    }
}

export default header
