import React from 'react'
import ChartListItem from './ChartListItem'
import lang from '../../utils/lang'
import { chartService } from '../../services/chart.service'
import InfiniteScroller from '../../components/GUI/InfiniteScroller'
import { exposeErrors } from '../../utils/errors'
import * as PropTypes from 'prop-types'
import { DEFAULT_NOTIFICATION_TIMEOUT, notificationService } from '../../services/notification.service'
import { useDispatch, useSelector } from 'react-redux'
import { publishDraft, setChartList } from '../../actions/charts'
import { WelcomeMessage } from '../WelcomeMessage'
import { history } from '../../store'
import { generateChartValidationList } from '../../utils/helperFunctions'
import { clearModalDialog, closeDialog, openModalDialog } from '../../actions/modalDialog'
import { MODAL_DIALOG_CLEAR_TIMEOUT } from '../../constants/modalDialog'
import ChartItemEvents from './ChartItemEvents'

const placeholder = (
    <div className="ChartListItem loading-placeholder" key={'placeholder-0'}>
        <div className="chart">
            <div className="fake-item"></div>
            <div className="fake-item"></div>
        </div>
        <div className="events">
            <div className="fake-item"></div>
            <div className="fake-item"></div>
            <div className="fake-item"></div>
        </div>
    </div>
)

const noResults = (
    <div className="empty-page-message">
        <div className="title">{lang.d('no_results')}</div>
    </div>
)

const ChartList = (props) => {
    const dispatch = useDispatch()
    const list = useSelector(state => state.charts.list)
    const ready = useSelector(state => state.charts.ready)
    const search = useSelector(state => state.search.charts)
    const shouldLoadMore = useSelector(state => state.charts.shouldLoadMore)
    const nextPageStartsAfter = useSelector(state => state.charts.nextPageStartsAfter)
    const pendingRefreshChart = useSelector(state => state.charts.pendingRefreshChart)
    const pendingNewChart = useSelector(state => state.charts.pendingNewChart)
    const user = useSelector(state => state.user)
    const context = useSelector(state => state.context)
    const workspaces = useSelector(state => state.workspaces)
    const enableCopyChart = workspaces.list.filter(workspace => workspace.isActive).length > 1

    async function archiveChart ({ key, name }) {
        await exposeErrors(props.archive(key))
        props.remove(key)
        notificationService.info(lang.d('seating_chart_archived'), name, DEFAULT_NOTIFICATION_TIMEOUT)
        if (list.length === 0) {
            dispatch({ type: 'CLEAR_CHARTS' })
        }
    }

    async function handleCopyChart (key, validation) {
        const validationResult = validation ? processValidation(validation) : await validateChart(key)
        if (validationResult.hasErrors || validationResult.hasWarnings) {
            const dialogServiceDescription = <div>{lang.d('copy_chart_with_validation_issues')} <div className="draft-validation-warning">{validationResult.list}</div></div>
            dispatch(openModalDialog({
                title: lang.d('duplicate_chart'),
                message: dialogServiceDescription,
                successMessage: lang.d('duplicated'),
                submitEnabled: true,
                settings: {
                    onAccept: () => confirmCopyChart(key),
                    acceptCaption: lang.d('duplicate_chart'),
                    waitOnAccept: true,
                    dangerousAction: true
                }
            }))
        } else await confirmCopyChart(key)
    }

    async function confirmCopyChart (key) {
        const chart = await exposeErrors(props.copy(key))
        props.add(chart)
    }

    function handleDiscardDraft (key) {
        dispatch(openModalDialog({
            title: lang.d('discard_changes'),
            message: lang.d('discard_changes_desc'),
            successMessage: lang.d('discarded'),
            doneIcon: 'delete',
            settings: {
                onAccept: () => confirmDiscardDraft(key),
                acceptCaption: lang.d('discard'),
                dangerousAction: true,
                waitOnAccept: true
            }
        }))
    }

    async function confirmDiscardDraft (key) {
        const charts = list.map(item => {
            if (item.key !== key) return item
            return { ...item, status: 'PUBLISHED' }
        })

        await exposeErrors(chartService.discardDraft(key), () => dispatch(closeDialog()))
        dispatch(setChartList(charts))
    }

    function processValidation (validation) {
        const list = generateChartValidationList(validation)
        return { list, hasErrors: validation.errors.length > 0, hasWarnings: validation.warnings.length > 0 }
    }

    async function validateChart (key, draft = false) {
        let validationResult
        if (draft) validationResult = await exposeErrors(chartService.getDraftChartValidation(key))
        else validationResult = await exposeErrors(chartService.getPublishedChartValidation(key))
        return processValidation(validationResult)
    }

    async function getDialogServiceDescriptionForPublish (key) {
        const validationResult = await validateChart(key, true)
        const description = validationResult.hasErrors
            ? <div>{lang.d('publish_chart_with_validation_errors')} <div className="draft-validation-warning">{validationResult.list}</div></div>
            : validationResult.hasWarnings
                ? <div>{lang.d('publish_changes_desc')} {lang.d('draft_version_has_validation_warnings')}<div className="draft-validation-warning">{validationResult.list}</div></div>
                : <div>{lang.d('publish_changes_desc')}</div>
        return { hasErrors: validationResult.hasErrors, description }
    }

    async function handlePublishDraft (key) {
        const dialogServiceDescription = await getDialogServiceDescriptionForPublish(key)
        const dialogServiceSettings = dialogServiceDescription.hasErrors
            ? {
                onAccept: () => {
                    dispatch(closeDialog())
                    setTimeout(() => {
                        dispatch(clearModalDialog())
                        history.push(`/charts/${key}/edit`, { fromSeatsio: true })
                    }, MODAL_DIALOG_CLEAR_TIMEOUT)
                },
                acceptCaption: lang.d('fix_in_designer'),
                waitOnAccept: true
            }
            : {
                onAccept: () => confirmPublishDraft(key),
                acceptCaption: lang.d('publish'),
                dangerousAction: true,
                waitOnAccept: true
            }
        dispatch(openModalDialog({
            title: lang.d('publish_changes'),
            message: dialogServiceDescription.description,
            successMessage: !dialogServiceDescription.hasErrors ? lang.d('published') : undefined,
            settings: dialogServiceSettings
        }))
    }

    async function confirmPublishDraft (key) {
        await exposeErrors(chartService.publishDraft(key), () => dispatch(closeDialog()))
        let thumbnailUrl
        try {
            const chart = await chartService.retrieveChart(key)
            thumbnailUrl = chart.publishedVersionThumbnailUrl
        } catch (e) {
            thumbnailUrl = 'couldNotRetrieve'
        }

        dispatch(publishDraft(key, thumbnailUrl))
    }

    function maxEvents () {
        return ChartItemEvents.MAX_EVENTS + 1
    }

    return (
        <InfiniteScroller
            className="ChartList"
            placeholder={placeholder}
            firstPage={(pageSize, filter, tags) => props.getFirstPage(pageSize, filter, tags, maxEvents())}
            restOfPages={(afterId, pageSize, filter, tags) => props.getPageAfter(afterId, pageSize, filter, tags, maxEvents())}
            handleNewItems={props.bulkAdd}
            disableFetchMore={() => dispatch({ type: 'CHARTS/DISABLE_FETCH_MORE' })}
            shouldLoadMore={shouldLoadMore}
            nextPageStartsAfter={nextPageStartsAfter}
            name={props.name}
            ready={ready}
            searchValue={search}
            loadingParameters={{ searchValue: search.value, tagValue: search.tag }}
            rememberPosition={true}
            isEmpty={props.isListEmpty()}
            onEmpty={search.value === '' && list.length === 0 ? <WelcomeMessage /> : noResults}
        >
            <div className="container">
                { pendingNewChart && placeholder }
                {list.map(chart => (
                    <ChartListItem
                        key={chart.id}
                        chart={chart}
                        archiveChart={archiveChart}
                        enableCopyChart={enableCopyChart}
                        handleCopyChart={handleCopyChart}
                        handleDiscardDraft={handleDiscardDraft}
                        handlePublishDraft={handlePublishDraft}
                        loading={pendingRefreshChart === chart.key}
                        user={user}
                        context={context}
                        searchParameters={{ searchValue: search.value }}
                    />
                ))}
            </div>
        </InfiniteScroller>
    )
}

ChartList.propTypes = {
    getFirstPage: PropTypes.func,
    getPageAfter: PropTypes.func,
    bulkAdd: PropTypes.func,
    disableFetchMore: PropTypes.func,
    add: PropTypes.func,
    copy: PropTypes.func,
    remove: PropTypes.func,
    archive: PropTypes.func,
    setList: PropTypes.func,
    publishDraft: PropTypes.func,
    state: PropTypes.object,
    name: PropTypes.string,
    shouldLoadMore: PropTypes.bool,
    nextPageStartsAfter: PropTypes.number,
    searchEmpty: PropTypes.bool,
    loading: PropTypes.bool,
    pendingRefreshChart: PropTypes.string,
    pendingNewChart: PropTypes.bool,
    isListEmpty: PropTypes.func,
    search: PropTypes.object,
    user: PropTypes.object,
    context: PropTypes.object
}

export default ChartList
