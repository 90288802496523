import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setHeader } from '../actions/header'
import InactiveWorkspaceList from '../components/GUI/InactiveWorkspaceList'

class InactiveWorkspaces extends Component {
    componentDidMount () {
        this.props.setHeader({
            showSearch: true,
            leftItems: [{
                to: '/workspaces',
                icon: 'icon-arrow-light-left',
                string_key: 'active_workspaces',
                className: 'soft-button'
            }]
        })
    }

    render () {
        return <>
            <InactiveWorkspaceList />
        </>
    }
}

InactiveWorkspaces.propTypes = {
    setHeader: PropTypes.func,
    location: PropTypes.object
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(undefined, mapDispatchToProps)(InactiveWorkspaces)
