import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { contextualMenuService } from '../../services/contextualMenu.service'
import { openModalDialog } from '../../actions/modalDialog'
import Button from '../../components/GUI/Button'
import * as PropTypes from 'prop-types'
import { showFailedToContactServerError } from '../../utils/errors'
import { subscriptionEventDialog } from './SubscriptionEventDialog'

export function SubscriptionEvent (props) {
    const { subscriptionEvent, pricingPlans } = { ...props }
    const dispatch = useDispatch()
    const [contextualMenuOpened, setContextualMenuOpened] = useState(false)

    const onContextMenu = (e, isEditable) => {
        e.preventDefault()
        setContextualMenuOpened(true)
        contextualMenuService.show(getContextMenuOptions(isEditable), {
            onSelect: uiEvent => onAction(uiEvent),
            onClose: () => setContextualMenuOpened(false),
            position: {
                left: e.clientX,
                top: e.clientY
            }
        })
    }

    const getContextMenuOptions = (isEditable) => {
        const options = []

        if (isEditable) {
            options.push({
                type: 'edit',
                caption: 'Edit',
                uiEvent: 'edit'
            })
        }

        options.push({
            type: 'delete',
            caption: 'Delete',
            uiEvent: 'delete'
        })
        return options
    }

    const onAction = (uiEvent) => {
        setContextualMenuOpened(false)
        switch (uiEvent) {
        case 'edit':
            editSubscriptionEvent()
            break
        case 'delete':
            requestDelete()
            break
        default:
            throw new Error('Unknown uiEvent: ' + uiEvent)
        }
    }

    const editSubscriptionEvent = () => {
        const dialog = subscriptionEventDialog(
            props.onUpdated,
            pricingPlans,
            'Edit subscription event',
            'Update',
            initialFormValues(),
            true
        )
        dispatch(openModalDialog(dialog))
    }

    const initialFormValues = () => {
        const commonValues = {
            type: subscriptionEvent.type,
            month: subscriptionEvent.month.toString(),
            year: subscriptionEvent.year.toString()
        }

        switch (subscriptionEvent.type) {
        case 'SUBSCRIPTION_REQUESTED':
            return {
                pricingPlanId: subscriptionEvent.pricingPlanId.toString() + '-' + subscriptionEvent.isYearly,
                isYearly: subscriptionEvent.isYearly,
                ...commonValues
            }
        case 'SUBSCRIBED':
            return {
                pricingPlanId: subscriptionEvent.pricingPlanId.toString() + '-' + subscriptionEvent.isYearly,
                isYearly: subscriptionEvent.isYearly,
                ...commonValues
            }
        case 'UNSUBSCRIBED':
            return commonValues
        case 'USAGE_MANUALLY_CORRECTED':
            return {
                correctedUsage: subscriptionEvent.correctedUsage,
                ...commonValues
            }
        default: throw Error('Unknown subscription event type: ' + subscriptionEvent.type)
        }
    }

    const subscriptionEventType = () => {
        switch (subscriptionEvent.type) {
        case 'SUBSCRIPTION_REQUESTED': return 'Subscription requested'
        case 'SUBSCRIBED': return 'Subscribed'
        case 'UNSUBSCRIBED': return 'Unsubscribed'
        case 'USAGE_MANUALLY_CORRECTED': return 'Usage manually adjusted'
        default: return undefined
        }
    }

    const subscriptionEventDetails = () => {
        switch (subscriptionEvent.type) {
        case 'SUBSCRIPTION_REQUESTED': return `${pricingPlan(subscriptionEvent.pricingPlanId).name} - ${subscriptionEvent.isYearly ? 'yearly' : 'monthly'}`
        case 'SUBSCRIBED': return `${pricingPlan(subscriptionEvent.pricingPlanId).name} - ${subscriptionEvent.isYearly ? 'yearly' : 'monthly'}`
        case 'UNSUBSCRIBED': return ''
        case 'USAGE_MANUALLY_CORRECTED': return subscriptionEvent.correctedUsage
        default: return undefined
        }
    }

    const pricingPlan = id => {
        return pricingPlans.find(pricingPlan => pricingPlan.id === id)
    }

    const requestDelete = () => {
        dispatch(openModalDialog({
            title: 'Delete subscription event',
            message: 'Are you sure you want to delete this subscription event?',
            settings: {
                onAccept: () => confirmDelete(),
                acceptCaption: 'Delete',
                dangerousAction: true,
                waitOnAccept: true
            }
        }))
    }

    const confirmDelete = async () => {
        try {
            await props.onDeleted()
        } catch (err) {
            showFailedToContactServerError()
            throw err
        }
    }

    const formatMonth = month => {
        const date = new Date(2020, month - 1, 1)
        return date.toLocaleString('en', { month: 'short' })
    }

    const isEditable = subscriptionEvent => {
        return subscriptionEvent.type !== 'SUBSCRIPTION_REQUESTED'
    }

    return <tr className={`${contextualMenuOpened && 'contextual-menu-opened'}`}>
        <td>{formatMonth(subscriptionEvent.month)}-{subscriptionEvent.year}</td>
        <td>{subscriptionEventType()}</td>
        <td>{subscriptionEventDetails()}</td>
        <td className="has-button"><Button preset="round" type="more" onClick={e => onContextMenu(e, isEditable(subscriptionEvent))} /></td>
    </tr>
}

SubscriptionEvent.propTypes = {
    subscriptionEvent: PropTypes.object.isRequired,
    pricingPlans: PropTypes.array.isRequired,
    onUpdated: PropTypes.func.isRequired,
    onDeleted: PropTypes.func.isRequired
}
