import React from 'react'
import { contextualMenuService } from '../../services/contextualMenu.service'
import Button from './Button'
import lang from '../../utils/lang'

export default class ContextualMenu extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            options: [],
            settings: {}
        }
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
        this.wrapperRef = React.createRef()
    }

    onMenuChange (options, settings) {
        this.setState({ options, settings })
    }

    close () {
        contextualMenuService.clear()
    }

    componentDidMount () {
        document.addEventListener('mousedown', this.handleClickOutside)
        document.addEventListener('mousewheel', this.handleScroll)
        contextualMenuService.addListener(this.onMenuChange.bind(this))
    }

    componentWillUnmount () {
        document.removeEventListener('mousedown', this.handleClickOutside)
        document.removeEventListener('mousewheel', this.handleScroll)
    }

    handleClickOutside (e) {
        if (this.isVisible() && !this.wrapperRef.current.contains(e.target)) {
            e.preventDefault()
            e.stopPropagation()
            this.onClose()
        }
    }

    handleScroll (e) {
        if (this.isVisible() && !this.wrapperRef.current.contains(e.target)) {
            e.preventDefault()
        }
    }

    onLink (link) {
        window.open(link)
        contextualMenuService.clear()
    }

    onSelect (uiEvent) {
        if (this.state.settings.onSelect) {
            this.state.settings.onSelect(uiEvent)
        }
        contextualMenuService.clear()
    }

    onClose () {
        if (this.state.settings.onClose) {
            this.state.settings.onClose()
        }
        contextualMenuService.clear()
    }

    getPosition () {
        let position = this.state.settings.position
        if (position) {
            if (position.left > window.innerWidth * 0.75) {
                position = {
                    right: window.innerWidth - position.left,
                    top: position.top,
                    bottom: position.bottom
                }
            }
            if (position.top > window.innerHeight * 0.8) {
                position = {
                    right: position.right,
                    left: position.left,
                    bottom: window.innerHeight - position.top
                }
            }
        }
        return position
    }

    isVisible () {
        return Object.keys(this.state.options).length > 0
    }

    getOptionIcon (option) {
        if (option.selected) {
            return 'check'
        }
        if (option.link) {
            return 'external-link'
        }
        return null
    }

    getContents () {
        return this.state.options.map((option, i) => {
            switch (option.type) {
            case 'separator':
                return <div key={`separator-${i}`} className="separator"/>
            case 'validation-item':
                return <div key={option.caption} className={`validation-item ${option.validationType}`}>
                    <span className={`icon ${option.icon}`}/>
                    <span className="caption">{ lang.d(option.caption) }</span>
                </div>
            default:
                return <Button
                    key={`${option.type}-${option.caption}`}
                    preset="contextual-menu-item"
                    type={option.type}
                    softIcon={this.getOptionIcon(option)}
                    caption={option.caption}
                    disabled={option.disabled}
                    onMouseUp={() => option.uiEvent ? this.onSelect(option.uiEvent) : this.onLink(option.link)}
                />
            }
        })
    }

    render () {
        return <div className={`ContextualMenu ${this.isVisible() && 'open'}`}>
            <div className="menu" style={this.getPosition()} ref={this.wrapperRef}>
                <div className="scroll-wrap">
                    { this.getContents() }
                </div>
            </div>
        </div>
    }
}
