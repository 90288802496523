export function base64ToString(base64) {
    const binString = atob(base64);
    let bytes = Uint8Array.from(binString, (m) => m.codePointAt(0))
    return new TextDecoder().decode(bytes);
}

export function stringToBase64(s) {
    const bytes = new TextEncoder().encode(s)
    const binString = Array.from(bytes, (byte) =>
        String.fromCodePoint(byte),
    ).join("");
    return btoa(binString);
}
