import { merge } from '../utils/helperFunctions'

const defaultState = {
    list: [],
    shouldLoadMore: true,
    nextPageStartsAfter: null,
    ready: false,
    pendingRefreshChart: null,
    pendingNewChart: false
}

const chart = (state = defaultState, action) => {
    switch (action.type) {
    case 'CHARTS/SET':
        return {
            ...state,
            ...action.payload,
            list: merge(state.list, action.payload.list),
            pendingRefreshChart: null,
            pendingNewChart: false
        }
    case 'CLEAR_CHARTS':
        return {
            ...state,
            shouldLoadMore: true,
            list: [],
            nextPageStartsAfter: null,
            pendingRefreshChart: null,
            pendingNewChart: false
        }
    case 'CHARTS/SET_READY':
        return {
            ...state,
            ready: true
        }
    case 'CHARTS/SET_LIST':
        return {
            ...state,
            ...action.payload,
            list: action.payload.list,
            ready: true,
            pendingRefreshChart: null,
            pendingNewChart: false
        }
    case 'CHARTS/CLEAR_SEARCH':
        return {
            ...state,
            list: [],
            shouldLoadMore: true,
            finishedMounting: false,
            nextPageStartsAfter: null,
            searchEmpty: false,
            ready: true
        }
    case 'CHARTS/DISABLE_FETCH_MORE':
        return {
            ...state,
            finishedMounting: true,
            shouldLoadMore: false,
            nextPageStartsAfter: null,
            ready: true
        }
    case 'CHARTS/ADD':
        return {
            ...state,
            list: [
                action.payload,
                ...state.list
            ]
        }
    case 'CHARTS/REMOVE':
        // eslint-disable-next-line no-case-declarations
        const index = state.list.findIndex((i) => i.key === action.payload)
        return {
            ...state,
            list: [
                ...state.list.slice(0, index),
                ...state.list.slice(index + 1)
            ]
        }
    case 'CHARTS/ADD_EVENT':
        return {
            ...state,
            newestAddedEvent: action.payload.key,
            list: state.list.map(item => {
                if (item.key !== action.payload.chartKey) return item
                item.status = 'PUBLISHED'
                item.events = [
                    { key: action.payload.key, chartKey: action.payload.chartKey },
                    ...item.events
                ]
                return item
            })
        }
    case 'CHARTS/UPDATE_EVENT':
        return {
            ...state,
            list: state.list.map(item => {
                if (item.key !== action.payload.chartKey) return item
                item.events = item.events.map(event => {
                    if (event.key !== action.payload.oldKey) return event
                    event.key = action.payload.newKey
                    return event
                })
                if (item.events.length === 0) item.status = 'NOT_USED'
                return item
            }),
            updatedEventKey: action.payload.newKey
        }
    case 'CHARTS/DELETE_EVENT':
    case 'CHARTS/MARK_EVENT_AS_IN_THE_PAST':
        return {
            ...state,
            list: state.list.map(item => {
                if (item.key !== action.payload.chartKey) return item
                item.events = item.events.filter(event => event.key !== action.payload.eventKey)
                if (item.events.length === 0) item.status = 'NOT_USED'
                return item
            })
        }
    case 'CHARTS/PUBLISH_DRAFT':
        return {
            ...state,
            list: state.list.map(item => {
                if (item.key !== action.payload.key) return item
                return { ...item, status: 'PUBLISHED', publishedVersionThumbnailUrl: action.payload.thumbnailUrl }
            })
        }
    case 'CHARTS/SET_EVENT_KEY_UPDATE_STATUS':
        return {
            ...state,
            eventKeyUpdateStatus: action.payload.updateStatus,
            updatedEventKey: action.payload.eventKey
        }
    case 'CHARTS/SET_NEWEST_ADDED_EVENT':
        return {
            ...state,
            newestAddedEvent: action.payload.newestAddedEvent
        }
    case 'CHARTS/SET_PENDING_REFRESH_CHART':
        return {
            ...state,
            pendingRefreshChart: action.payload.chartKey
        }
    case 'CHARTS/SET_PENDING_NEW_CHART':
        return {
            ...state,
            pendingNewChart: true
        }
    default:
        return state
    }
}

export default chart
