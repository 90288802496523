import React, { Component } from 'react'
import { chartService } from '../../services/chart.service.js'
import ArchivedChartList from '../../components/Charts/ArchivedChartList'
import DataLoader from '../../components/DataLoader'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { setHeader } from '../../actions/header'

class ChartArchive extends Component {
    componentDidMount () {
        this.props.setHeader({
            leftItems: [{
                to: '/',
                icon: 'icon-arrow-light-left',
                string_key: 'all_seating_charts',
                className: 'soft-button'
            }]
        })
    }

    render () {
        return (
            <DataLoader
                name="chartArchive"
                getFirstPage={chartService.getArchivedChartsFirstPage}
                getPageAfter={chartService.getArchivedChartsPageAfter}
            >
                {({
                    state, add, remove, isListEmpty,
                    name, bulkAdd, disableFetchMore
                }) => (
                    <>
                        <ArchivedChartList
                            state={state}
                            name={name}
                            bulkAdd={bulkAdd}
                            remove={remove}
                            disableFetchMore={disableFetchMore}
                            getFirstPage={chartService.getArchivedChartsFirstPage}
                            getPageAfter={chartService.getArchivedChartsPageAfter}
                            restore={chartService.moveChartOutOfArchive}
                            isListEmpty={isListEmpty}
                        />
                    </>
                )}
            </DataLoader>
        )
    }
}

ChartArchive.propTypes = {
    list: PropTypes.array,
    setHeader: PropTypes.func,
    setList: PropTypes.func,
    ready: PropTypes.bool
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(null, mapDispatchToProps)(ChartArchive)
