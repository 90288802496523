import { merge } from '../utils/helperFunctions'

const defaultState = {
    list: [],
    shouldLoadMore: true,
    nextPageStartsAfter: null,
    ready: false
}

const archive = (state = defaultState, action) => {
    switch (action.type) {
    case 'CHARTARCHIVE/SET':
        return {
            ...state,
            ...action.payload,
            list: merge(state.list, action.payload.list)
        }
    case 'CHARTARCHIVE/SET_LIST':
        return {
            ...state,
            ...action.payload,
            list: action.payload.list
        }
    case 'CHARTARCHIVE/CLEAR_SEARCH':
        return {
            ...state,
            list: [],
            shouldLoadMore: true,
            finishedMounting: false,
            nextPageStartsAfter: null,
            searchEmpty: false
        }
    case 'CHARTARCHIVE/SET_READY':
        return {
            ...state,
            ready: true
        }
    case 'CHARTARCHIVE/REMOVE':
        // eslint-disable-next-line no-case-declarations
        const index = state.list.findIndex((i) => i.key === action.payload)
        return {
            ...state,
            list: [
                ...state.list.slice(0, index),
                ...state.list.slice(index + 1)
            ]
        }
    case 'CHARTARCHIVE/DISABLE_FETCH_MORE':
        return {
            ...state,
            finishedMounting: true,
            shouldLoadMore: false,
            nextPageStartsAfter: null,
            ready: true
        }
    case 'CLEAR_ARCHIVE':
        return {
            ...state,
            shouldLoadMore: true,
            list: [],
            nextPageStartsAfter: null
        }
    default:
        return state
    }
}

export default archive
