import React, { Component } from 'react'
import { connect } from 'react-redux'
import lang from '../../utils/lang'
import { exposeErrors } from '../../utils/errors'
import { adminService } from '../../services/admin.service'
import format from 'date-fns/format'
import queryString from 'query-string'
import * as PropTypes from 'prop-types'
import { history } from '../../store'
import { setHeader } from '../../actions/header'
import { openModalDialog } from '../../actions/modalDialog'
import { base64ToString, stringToBase64 } from '../../utils/base64'

class SearchParams extends Component {

    constructor (props) {
        super(props)
        this.state = {
            filter: '',
            filterError: null,
            eventType: 'CHART_RENDERED',
            columns: [{ name: '', formatter: '' }]
        }
    }

    componentDidMount () {
        const queryParams = queryString.parse(this.props.location.search)
        if (queryParams.q) {
            const searchParams = JSON.parse(base64ToString(queryParams.q))
            this.setState({
                filter: searchParams.filter,
                eventType: searchParams.eventType,
                columns: searchParams.columns
            }, () => {
                this.props.onSearch(this.state.filter, this.state.eventType, this.state.columns)
            })
        } else {
            this.props.onSearch(this.state.filter, this.state.eventType, this.state.columns)
        }
    }

    render () {
        return <form onSubmit={e => { e.preventDefault() }}>
            <div className="form-field">
                <label htmlFor="filter">Filter</label>
                <input id="filter" className="filter" value={this.state.filter} onChange={e => this.setFilter(e.target.value)} placeholder="e.metadata.renderingTimeMillis > 5000" />
                {this.state.filterError && <div className="filterError">{this.state.filterError.toString()}</div>}
            </div>
            <div className="form-field">
                <label htmlFor="eventType">Event type</label>
                <input id="eventType" className="eventType" value={this.state.eventType} onChange={e => this.setEventType(e.target.value)} />
            </div>
            <div className="form-field columns">
                <label>Columns</label>
                {this.state.columns.map((column, index) =>
                    <div key={index}>
                        <input className="columnName" value={column.name} onChange={e => this.setColumnName(index, e.target.value)} placeholder="Name" />
                        &nbsp;
                        <input className="columnValue" value={column.formatter} onChange={e => this.setColumnFormatter(index, e.target.value)} placeholder="Value" />
                        <button type="button" className="button-link delete" onClick={() => this.deleteColumn(index)}>
                            <span className="icon icon-delete"/>
                        </button>
                    </div>
                )}
                <button type="button" className="button-link add" onClick={this.addColumn.bind(this)}>
                    <span className="icon icon-plus-bold"/>
                    Add column
                </button>
            </div>
            <div>
                <input type="submit" value="Search" className="FormButton btn search" onClick={this.onSearch.bind(this)} />
            </div>
        </form>
    }

    setFilter (filter) {
        this.setState({ filter })
    }

    setEventType (eventType) {
        this.setState({ eventType })
    }

    addColumn () {
        this.setState({ columns: [...this.state.columns, {}] })
    }

    deleteColumn (index) {
        const newColumns = this.state.columns.filter((x, currentIndex) => currentIndex !== index)
        this.setState({ columns: newColumns })
    }

    setColumnName (index, name) {
        const columns = JSON.parse(JSON.stringify(this.state.columns))
        columns[index].name = name
        this.setState({ columns })
    }

    setColumnFormatter (index, formatter) {
        const columns = JSON.parse(JSON.stringify(this.state.columns))
        columns[index].formatter = formatter
        this.setState({ columns })
    }

    onSearch () {
        history.push('?q=' + stringToBase64(this.serializeParams()))
        this.props.onSearch(this.state.filter, this.state.eventType, this.state.columns)
    }

    serializeParams () {
        const params = {
            filter: this.state.filter,
            eventType: this.state.eventType,
            columns: this.state.columns
        }
        return JSON.stringify(params)
    }
}

SearchParams.propTypes = {
    location: PropTypes.object,
    onSearch: PropTypes.func
}

class AdminUserEventSearcher extends Component {

    constructor (props) {
        super(props)
        this.props.setHeader({
            showSearch: false,
            hasItems: true,
            centerItems: [],
            rightItems: [],
            leftItems: [{
                to: '/admin',
                icon: 'icon-arrow-light-left',
                string_key: 'admin',
                className: 'soft-button'
            }]
        })
        this.state = {
            list: [],
            loading: false,
            fetcher: null
        }
    }

    async loadData (filterFn = null) {
        if (this.state.fetcher) {
            this.state.fetcher.stop()
        }
        const fetcher = new UserEventFetcher(this.state.eventType, filterFn, 1000, 10000)
            .onStarted(() => this.setState({ list: [], filterError: null, loading: true }))
            .onMatchingEvents(events => this.setState({ list: this.state.list.concat(events) }))
            .onDone(() => this.setState({ loading: false }))
            .onError(e => this.setState({ list: [], filterError: e, loading: false }))
            .start()
        this.setState({ fetcher })
    }

    render () {
        return <div className="Admin UserEventSearcher sectioned-page">
            <div className="sectioned-page-container">
                <div className="section">
                    <SearchParams
                        location={this.props.location}
                        onSearch={this.onSearch.bind(this)}
                    />
                </div>
                <div className="section">
                    {this.renderEvents()}
                </div>
            </div>
        </div>
    }

    onSearch (filter, eventType, columns) {
        this.setState({ filter, eventType, columns }, () => {
            if (!filter) {
                this.loadData()
            } else {
                try {
                    /* eslint-disable no-eval */
                    const filterFn = eval(`e => ${filter}`)
                    this.loadData(filterFn)
                } catch (e) {
                    this.setState({ filterError: e })
                }
            }
        })
    }

    renderEvents () {
        return <>
            {this.state.loading && this.state.list.length === 0 && this.loading()}
            {this.noResults() && this.noResult()}
            {this.state.list.length > 0 &&
            <>
                <div className="title">
                    {this.state.filter ? 'Events that match filter' : 'Most recent events'}
                    <span className="numMatchingEvents">{this.state.loading && ` (found ${this.state.list.length} events)`}</span>
                </div>
                <div className="container">
                    <div className="DataTable">
                        <table>
                            <thead>
                                <tr>
                                    {this.renderHeaders()}
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderRows()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
            }
        </>
    }

    renderHeaders () {
        return <>
            <th/>
            <th>Date</th>
            { this.columnNames().map((name, index) => <th key={index}>{name}</th>)}
        </>
    }

    columnNames () {
        const userDefinedColumns = this.userDefinedColumnNames()
        if (userDefinedColumns.length === 0) {
            return ['URL']
        }
        return userDefinedColumns.map(column => column.name)
    }

    userDefinedColumnNames () {
        return this.state.columns.filter(column => column.name)
    }

    renderRows () {
        return this.state.list
            .map((event, index) =>
                <tr key={index}>
                    <td><button className={'button-link'} onClick={() => this.showEvent(event)}><i className="icon-info"/></button></td>
                    <td>{format(event.date, 'd MMM HH:mm:ss')}</td>
                    { this.userDefinedColumnValues(event).map((value, index) => <td key={index}>{value}</td>)}
                </tr>
            )
    }

    userDefinedColumnValues (event) {
        const userDefinedColumns = this.userDefinedColumnNames()
        if (userDefinedColumns.length === 0) {
            return [this.formatUrl(event.url)]
        }
        return userDefinedColumns.map(column => this.format(event, column.formatter))
    }

    format (event, columnFormatter) {
        try {
            const fn = eval(`e => { return ${columnFormatter} }`)
            return fn(event)
        } catch (e) {
            return e.toString()
        }
    }

    formatUrl (url) {
        return <>
            {url && url.length > 60 ? url.substr(0, 60) + '...' : url}
            &nbsp;
            {url && <a className="arrow" href={url} target="_blank" rel="noopener noreferrer">Open</a>}
        </>
    }

    noResults () {
        return !this.state.loading && this.state.list.length === 0
    }

    showEvent (event) {
        this.props.openModalDialog({
            title: `Event ${event.id}`,
            message: <textarea readOnly={true} value={JSON.stringify(event, null, 2)} />,
            settings: {
                prompt: false,
                dialogClassName: 'rendering-event-dialog'
            }
        })
    }

    loading () {
        return <div className="empty-page-message" >
            <div className="title">Loading...</div>
        </div>
    }

    noResult () {
        return <div className="empty-page-message">
            <div className="title">{lang.d('no_results')}</div>
        </div>
    }
}

AdminUserEventSearcher.propTypes = {
    location: PropTypes.object,
    setHeader: PropTypes.func,
    openModalDialog: PropTypes.func
}

class UserEventFetcher {
    constructor (eventType, filterFn, maxMatchingEvents, maxEventsToFetch) {
        this.eventType = eventType
        this.filterFn = filterFn
        this.maxEventsToFetch = maxEventsToFetch
        this.maxMatchingEvents = maxMatchingEvents
        this.numFetchedEvents = 0
        this.numMatchingEvents = 0
        this.stopped = false
    }

    onStarted (onStartedFn) {
        this.onStartedFn = onStartedFn
        return this
    }

    onMatchingEvents (onMatchingEventsFn) {
        this.onMatchingEventsFn = onMatchingEventsFn
        return this
    }

    onDone (onDoneFn) {
        this.onDoneFn = onDoneFn
        return this
    }

    onError (onErrorFn) {
        this.onErrorFn = onErrorFn
        return this
    }

    start () {
        this.onStartedFn()
        this.fetch()
        return this
    }

    stop () {
        this.stopped = true
    }

    isDone () {
        return this.numFetchedEvents > this.maxEventsToFetch || this.numMatchingEvents > this.maxMatchingEvents || this.stopped
    }

    async fetch (startAfterId = null) {
        if (this.isDone()) {
            this.onDoneFn()
            return
        }

        const events = await exposeErrors(adminService.getUserEvents(this.eventType, startAfterId))

        if (events.length === 0) {
            this.onDoneFn()
            return
        }

        this.numFetchedEvents += events.length

        let matchingEvents = null
        try {
            matchingEvents = this.filterFn ? events.filter(this.filterFn) : events
        } catch (e) {
            this.onErrorFn(e)
        }

        this.onMatchingEventsFn(matchingEvents.slice(0, this.maxMatchingEvents - this.numMatchingEvents))
        this.numMatchingEvents += matchingEvents.length

        this.fetch(events[events.length - 1].id)
    }
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload)),
    openModalDialog: payload => dispatch(openModalDialog(payload))
})

export default connect(null, mapDispatchToProps)(AdminUserEventSearcher)
