import React from 'react'
import * as PropTypes from 'prop-types'
import lang from '../../utils/lang'
import format from 'date-fns/format'
import { usersService } from '../../services/users.service'
import { UserForm } from './EditUser'
import Button from '../../components/GUI/Button'
import { contextualMenuService } from '../../services/contextualMenu.service'
import { formatWorkspaceName } from '../../utils/helperFunctions'
import store from '../../store'
import { exposeConnectionErrors, showFailedToContactServerError } from '../../utils/errors'
import Input from '../../components/Input'
import { connect } from 'react-redux'
import { openModalDialog } from '../../actions/modalDialog'
import { ROLE_NON_ADMIN } from '../../reducers/user'
import { userService } from '../../services/user.service'

class Members extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            users: null,
            contextualMenuOpened: false
        }
        this.updateUser = this.updateUser.bind(this)
        this.getContextMenuOptions = this.getContextMenuOptions.bind(this)
    }

    async componentDidMount () {
        await this.loadMembers()
    }

    async loadMembers () {
        const users = []
        for await (const user of usersService.getAllCompanyUsers()) {
            if (user.status !== 'INVITATION_PENDING') {
                users.push(user)
            }
        }
        this.setState({ users })
    }

    updateUser (updatedUser) {
        const users = this.state.users.map(user => {
            if (user.id !== updatedUser.id) return user
            return updatedUser
        })
        this.setState({ users })
    }

    onContextMenu (e, user) {
        e.preventDefault()
        window.getSelection().removeAllRanges()
        this.setState({ contextualMenuOpened: true })
        contextualMenuService.show(this.getContextMenuOptions(user), {
            onSelect: uiEvent => this.onAction(uiEvent, user),
            onClose: () => this.setState({ contextualMenuOpened: false }),
            position: {
                left: e.clientX,
                top: e.clientY
            }
        })
    }

    getContextMenuOptions (user) {
        const options = []
        if (user.isActive) {
            options.push({
                type: 'edit',
                caption: lang.d('manage_team_member'),
                uiEvent: 'edit-user'
            },
            {
                type: 'power',
                caption: lang.d('deactivate_user'),
                uiEvent: 'deactivate-user'
            })
        } else {
            options.push({
                type: 'power',
                caption: lang.d('activate_user'),
                uiEvent: 'activate-user'
            })
        }
        return options
    }

    onAction (uiEvent, user) {
        this.setState({ contextualMenuOpened: false })
        switch (uiEvent) {
        case 'edit-user':
            this.editUser(user)
            break
        case 'deactivate-user':
            this.requestDeactivateUser(user)
            break
        case 'activate-user':
            this.activate(user)
            break
        default:
            throw new Error('Unknown uiEvent: ' + uiEvent)
        }
    }

    requestDeactivateUser (user) {
        this.props.openModalDialog({
            title: lang.d('deactivate_user'),
            message: lang.d('deactivate_user_confirmation', { user: user.email }),
            successMessage: lang.d('user_deactivated'),
            settings: {
                onAccept: () => this.deactivate(user),
                acceptCaption: lang.d('deactivate'),
                dangerousAction: true,
                waitOnAccept: true
            }
        })
    }

    async activate (user) {
        try {
            await usersService.activateUser(user.id)
            this.updateUser({ ...user, status: 'ACTIVE', isActive: true })
        } catch (err) {
            showFailedToContactServerError()
            throw err
        }
    }

    async deactivate (user) {
        try {
            await usersService.deactivateUser(user.id)
            this.updateUser({ ...user, status: 'INACTIVE', isActive: false })
        } catch (err) {
            showFailedToContactServerError()
            throw err
        }
    }

    async editUser (user) {
        const workspaces = user.role === ROLE_NON_ADMIN && !user.nonAdminHasAccessToAllWorkspaces ? user.workspaces.filter(workspace => workspace.isActive) : undefined
        this.props.openModalDialog({
            title: lang.d('manage_team_member'),
            onSubmit: values => {
                const workspaces = values.role === ROLE_NON_ADMIN && !values.nonAdminHasAccessToAllWorkspaces ? values.selectedWorkspaces : undefined
                return userService.updateUser(user.id, values.role, workspaces)
            },
            onSubmitSuccess: (_, result) => { this.updateUser(result) },
            successMessage: lang.d('applied'),
            settings: {
                acceptCaption: lang.d('apply_changes'),
                formik: {
                    initialValues: { userWorkspaces: workspaces, nonAdminHasAccessToAllWorkspaces: user.nonAdminHasAccessToAllWorkspaces, role: user.role },
                    validate: values => {
                        const errors = {}
                        if (values.role === ROLE_NON_ADMIN && !values.nonAdminHasAccessToAllWorkspaces && (!values.selectedWorkspaces || values.selectedWorkspaces.length === 0)) {
                            errors.workspace = lang.d('at_least_one_workspace')
                        }
                        return errors
                    },
                    render: props => <UserForm {...props} />
                }
            }
        })
    }

    render () {
        return <div className="container">
            <div className="title">{lang.d('team_members')}</div>
            { this.state.users
                ? this.renderTable()
                : this.renderPlaceholder()
            }
        </div>
    }

    renderWorkspaceList (workspaces) {
        const list = workspaces.map(workspace => (formatWorkspaceName(workspace)))
        return <WorkspacesList workspaces={list} openModalDialog={this.props.openModalDialog} />
    }

    renderTable () {
        return <div className="DataTable">
            <table>
                <thead>
                    <tr>
                        { this.renderHeaders() }
                    </tr>
                </thead>
                <tbody>
                    { this.renderRows() }
                </tbody>
            </table>
        </div>
    }

    renderHeaders () {
        return <>
            <th style={{ width: '35%' }}>{`${lang.d('email')} or ${lang.d('name')}`}</th>
            <th style={{ width: '10%' }}>{lang.d('created_on')}</th>
            <th style={{ width: '10%' }}>{lang.d('status')}</th>
            <th style={{ width: '10%' }}>{lang.d('role')}</th>
            <th style={{ width: '30%' }}>{lang.d('workspaces')}</th>
            <th style={{ width: '5%' }}></th>
        </>
    }

    renderRows () {
        return this.state.users
            .map((user) =>
                <tr key={user.id} className={`${!user.isActive && 'deactivated'}`}>
                    <td>{user.email || user.name || 'None'}</td>
                    <td>{user.createdOn ? format(user.createdOn, 'd MMM yyyy') : '-'}</td>
                    <td>{lang.d(`USER_STATUS_${user.status}`)}</td>
                    <td>{lang.d(user.role)}</td>
                    <td>{user.workspaces ? this.renderWorkspaceList(user.workspaces.filter(workspace => workspace.isActive)) : lang.d('workspace_list_all_workspaces')}</td>
                    {user.id !== store.getState().user.id ? <td className="has-button"><Button preset="round" type="more" onClick={e => this.onContextMenu(e, user)} /></td> : <td></td>}
                </tr>
            )
    }

    renderPlaceholder () {
        return <div className="DataTable">
            <div className="loading-placeholder">
                <div className="fake-item double"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
            </div>
        </div>
    }

}

Members.propTypes = {
    openModalDialog: PropTypes.func.isRequired
}

const WorkspacesList = (props) => {
    const workspaces = props.workspaces

    if (workspaces.length === 0) {
        return lang.d('none')
    }

    const list = <>
        <div className="message">{ lang.d('all_workspaces_for_member_hint') }</div>
        <div className="input-list">
            { workspaces.map(workspaceName => <div className="list-item" key={workspaceName}>
                <Input
                    type="checkbox"
                    checked={true}
                    disabled={true}
                />
                <label>{ workspaceName }</label>
            </div>) }
        </div>
    </>
    const maxShownWithLink = 2
    const minToDisplayMoreLink = 3
    const showAllWorkspaces = () =>
        props.openModalDialog({
            title: lang.d('workspace_list_all_workspaces'),
            message: list,
            settings: {
                prompt: false,
                dialogClassName: 'workspaces-modal-dialog'
            }
        })
    let workspacesToDisplay
    let moreLink
    if (workspaces.length > minToDisplayMoreLink) {
        workspacesToDisplay = maxShownWithLink
        moreLink = <>
            , <a className="arrow right" onClick={showAllWorkspaces}>
                { lang.d('and_x_more', { count: workspaces.length - maxShownWithLink }) }
            </a>
        </>
    } else {
        workspacesToDisplay = minToDisplayMoreLink
    }

    return <>
        { workspaces.slice(0, workspacesToDisplay).reduce((acc, curr) => acc + ', ' + curr) }
        { moreLink }
    </>
}

WorkspacesList.propTypes = {
    workspaces: PropTypes.array,
    openModalDialog: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
    openModalDialog: payload => dispatch(openModalDialog(payload))
})

export default connect(null, mapDispatchToProps)(Members)
