import React, {Component} from "react";
import {connect} from "react-redux";
import {
    setCompanyAdminKey,
    setCompanyDefaultRendererSetting,
    setCompanySetting,
    setCompanyValidationSetting,
    updateCompanySettings
} from "../../actions/user";
import {resetHeader} from "../../actions/header";
import {closeDialog, openModalDialog} from "../../actions/modalDialog";
import lang from "../../utils/lang";
import {isCompanyOrSuperAdmin} from "../../reducers/user";
import * as PropTypes from "prop-types";
import {retrieveCompany} from "../../services/companies.service";
import GeneralCompanySettings from "./company/GeneralCompanySettings";
import ChartValidationCompanySettings from "./company/ChartValidationCompanySettings";
import RendererCompanySettings from "./company/RendererCompanySettings";
import HoldsCompanySettings from "./company/HoldsCompanySettings";
import SecurityCompanySettings from "./company/SecurityCompanySettings";
import AdvancedCompanySettings from "./company/AdvancedCompanySettings";

class CompanySettings extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            activeSection: 'general'
        }
    }

    async componentDidMount() {
        this.props.resetHeader()
        this.selectActiveSectionFromHash()
        retrieveCompany(this.props.company.id)
            .then(company => this.props.updateCompanySettings(company.settings))
            .then(() => this.setState({loading: false}))
    }

    selectActiveSectionFromHash() {
        const hash = window.location.hash.substring(1);
        if (hash) {
            this.setState({ activeSection: hash });
        }
    }

    render() {
        if (this.state.loading) {
            return <div className="Settings">
                <div className="simple-page">
                    { this.renderLoadingPlaceholder() }
                    { this.renderLoadingPlaceholder() }
                    { this.renderLoadingPlaceholder() }
                </div>
            </div>
        }
        return (
            <div className="Settings">
                <div className="sidebar">
                    { this.renderSidebar() }
                </div>
                <div className="simple-page">
                    <div className="page-title">{lang.d('company-settings')}</div>
                    { isCompanyOrSuperAdmin(this.props.user) && this.renderSettingsSection() }
                </div>
            </div>
        )
    }

    renderSidebar() {
        return <>
            { this.renderSidebarItem('general') }
            { this.renderSidebarItem('chart-validation') }
            { this.renderSidebarItem('renderer') }
            { this.renderSidebarItem('hold-tokens') }
            { this.renderSidebarItem('security') }
            { this.renderSidebarItem('advanced') }
        </>
    }

    renderSidebarItem(itemName) {
        return <a
            href={'#' + itemName}
            className={`tab ${this.state.activeSection === itemName ? 'active' : ''}`}
            onClick={() => this.setState({activeSection: itemName})}
        >
            { lang.d(itemName) }
        </a>
    }

    renderSettingsSection() {
        switch (this.state.activeSection) {
        case 'general':
            return <GeneralCompanySettings {...this.props} />
        case 'chart-validation':
            return <ChartValidationCompanySettings {...this.props} />
        case 'renderer':
            return <RendererCompanySettings {...this.props} />
        case 'hold-tokens':
            return <HoldsCompanySettings {...this.props} />
        case 'security':
            return <SecurityCompanySettings {...this.props} />
        case 'advanced':
            return <AdvancedCompanySettings {...this.props} />
        default:
            return <GeneralCompanySettings {...this.props} />
        }
    }

    renderLoadingPlaceholder() {
        return <div className="loading-placeholder">
            <div className="fake-item"></div>
            <div className="fake-item"></div>
            <div className="fake-item"></div>
        </div>
    }

}

CompanySettings.propTypes = {
    adminKey: PropTypes.string,
    user: PropTypes.object,
    company: PropTypes.object,
    setting: PropTypes.object.isRequired,
    updateCompanySettings: PropTypes.func.isRequired,
    setSetting: PropTypes.func.isRequired,
    setValidationSetting: PropTypes.func.isRequired,
    setDefaultRendererSetting: PropTypes.func.isRequired,
    resetHeader: PropTypes.func.isRequired,
    openModalDialog: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    setCompanyAdminKey: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    setting: state.context.company.settings,
    adminKey: state.context.company.adminKey,
    user: state.user,
    company: state.context.company
})

const mapDispatchToProps = dispatch => ({
    updateCompanySettings: (user) => dispatch(updateCompanySettings(user)),
    setSetting: (setting, value) => dispatch(setCompanySetting(setting, value)),
    setValidationSetting: (setting, value) => dispatch(setCompanyValidationSetting(setting, value)),
    setDefaultRendererSetting: (setting, value) => dispatch(setCompanyDefaultRendererSetting(setting, value)),
    setCompanyAdminKey: adminKey => dispatch(setCompanyAdminKey(adminKey)),
    resetHeader: () => dispatch(resetHeader()),
    openModalDialog: payload => dispatch(openModalDialog(payload)),
    closeModalDialog: payload => dispatch(closeDialog(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanySettings)
