import React from 'react'
import ReactDOM from 'react-dom'
import lang from '../../utils/lang'
import * as PropTypes from 'prop-types'
import { wrapAround } from '../../utils/helperFunctions'

/**
 * @prop {number} value
 * @prop {number} previewValue
 * @prop {string} caption
 * @prop {string} numeral "decimal", "alphabetic", "roman", "odd", "even"
 * @prop {array} skipNumerals
 * @prop {number} min
 * @prop {number} max
 * @prop {number} step
 * @prop {number} pixelsForStep
 * @prop {boolean} wrapsAround
 * @prop {boolean} sequence
 * @prop {string} unit
 * @prop {callback} onChange
 * @prop {callback} onChangePreview
 * @prop {callback} onChangeFinal
 * @prop {callback} onCancel
 * @prop {boolean} disabled
 * @prop {boolean} invisible
 * @prop {boolean} pulse
 * @prop {boolean} disableSteppers
 * @prop {boolean} loading
 */
export default class NumericInput extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            value: props.value,
            displayValue: null,
            editing: false,
            scrubbing: false
        }
        this.scrubbingThreshold = 3
        this.onMouseMoveBind = this.onMouseMove.bind(this)
        this.onMouseUpBind = this.onMouseUp.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.handleEscWhenScrubbing = this.handleEscWhenScrubbing.bind(this)
    }

    componentDidMount () {
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount () {
        if (this.state.scrubbing) this.leaveScrubbingMode()
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside (event) {
        if (this.state.editing && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.submitChanges()
        }
    }

    handleEscWhenScrubbing (e) {
        if (e.key === 'Escape') {
            this.leaveScrubbingMode()
            window.removeEventListener('mousemove', this.onMouseMoveBind)
            window.removeEventListener('mouseup', this.onMouseUpBind)
            e.stopPropagation()
            e.preventDefault()
        }
    }

    enterEditMode () {
        ReactDOM.findDOMNode(this.inputRef).select()
        this.setState({ editing: true, value: this.getSingleValue() })
    }

    leaveEditMode () {
        this.setState({ editing: false, displayValue: null })
        this.inputRef.blur()
    }

    enterScrubbingMode () {
        document.addEventListener('keydown', this.handleEscWhenScrubbing)
        this.setState({ scrubbing: true })
        this.refreshCursor()
    }

    leaveScrubbingMode () {
        document.removeEventListener('keydown', this.handleEscWhenScrubbing)
        this.submitChanges()
        this.setState({ scrubbing: false })
    }

    step () {
        if (this.props.step) {
            return this.props.step
        } else if (this.props.numeral === 'odd' || this.props.numeral === 'even') {
            return 2
        } else {
            return 1
        }
    }

    onKeyDown (e) {
        switch (e.key) {
        case 'Escape':
            this.leaveEditMode()
            if (this.props.onCancel) this.props.onCancel()
            e.stopPropagation()
            break
        case 'Enter':
            this.submitChanges()
            break
        case 'ArrowUp':
        case 'ArrowDown':
            // eslint-disable-next-line no-case-declarations
            const direction = e.key === 'ArrowUp' ? 1 : -1
            this.modifyValue(direction * this.getStep(e))
            break
        default:
            return
        }
        e.preventDefault()
    }

    stepBack (e) {
        this.submitNewValue(this.state.value - this.getStep(e), true)
    }

    stepForward (e) {
        this.submitNewValue(this.state.value + this.getStep(e), true)
    }

    getStep (e) {
        const step = e && e.altKey ? 1 : this.step()
        const multiplier = e && e.shiftKey ? 10 : 1
        return step * multiplier
    }

    modifyValue (amount) {
        if (this.state.editing) {
            this.setState({ value: this.submitNewValue(this.state.value + amount, false) })
        }
    }

    onTextboxChange (e) {
        this.setState({ value: this.toInteger(e.target.value), displayValue: e.target.value })
    }

    submitChanges () {
        this.leaveEditMode()
        if (this.state.value !== this.props.value && this.state.value !== undefined) {
            this.submitNewValue(this.state.value, true)
        }
    }

    submitNewValue (newValue, final = false) {
        if (isNaN(newValue)) {
            return newValue
        }
        if (!isNaN(this.props.max) && newValue >= this.props.max) {
            if (this.props.wrapsAround) {
                newValue = wrapAround(newValue, this.props.min || 0, this.props.max)
            } else {
                newValue = Math.min(this.props.max, newValue)
            }
        }
        if (!isNaN(this.props.min) && newValue < this.props.min) {
            if (this.props.wrapsAround) {
                newValue = wrapAround(newValue, this.props.min, this.props.max)
            } else {
                newValue = Math.max(this.props.min, newValue)
            }
        }
        if (this.props.onChange) this.props.onChange(newValue)
        if (final) {
            if (this.props.onChangeFinal) this.props.onChangeFinal(newValue)
        } else {
            if (this.props.onChangePreview) this.props.onChangePreview(newValue)
        }
        if (this.state.scrubbing) {
            this.refreshCursor(newValue)
        }
        this.setState({ value: newValue, displayValue: null })
        return newValue
    }

    positionAlpha (startPosition, e) {
        return {
            x: Math.abs(startPosition.x - e.clientX),
            y: Math.abs(startPosition.y - e.clientY)
        }
    }

    refreshCursor (value) {
        if (!value) {
            value = this.props.value
        }
        let cardinals = 'ew'
        if (!this.canStepBack(value) && this.canStepForward(value)) {
            cardinals = 'e'
        } else if (this.canStepBack(value) && !this.canStepForward(value)) {
            cardinals = 'w'
        }
        return cardinals
    }

    canStepBack (value = null) {
        if (this.props.wrapsAround) {
            return true
        }
        if (!value) {
            value = this.getSingleValue()
        }
        return isNaN(this.props.min) || value > this.props.min
    }

    canStepForward (value) {
        if (this.props.wrapsAround) {
            return true
        }
        if (!value) {
            value = this.getSingleValue()
        }
        return isNaN(this.props.max) || value < this.props.max
    }

    onMouseDown (e) {
        if (this.props.disabled || e.button !== 0) {
            return
        }
        this.dragStartPosition = { x: e.clientX, y: e.clientY }
        window.addEventListener('mousemove', this.onMouseMoveBind)
        window.addEventListener('mouseup', this.onMouseUpBind)
        e.preventDefault()
        e.stopPropagation()
    }

    onMouseMove (e) {
        if (e.button !== 0) {
            return
        }
        if (this.state.scrubbing) {
            const alphaX = e.clientX - this.scrubStartPosition.x
            const step = this.step() || 1
            const pixelsForStep = this.props.pixelsForStep || 1
            this.submitNewValue(this.scrubStartValue + Math.round(alphaX / pixelsForStep) * step, false)
        } else {
            const alpha = this.positionAlpha(this.dragStartPosition, e)
            if (alpha.x >= this.scrubbingThreshold) {
                this.scrubStartPosition = { x: e.clientX, y: e.clientY }
                this.scrubStartValue = this.getSingleValue()
                this.enterScrubbingMode()
            }
        }
        e.preventDefault()
        e.stopPropagation()
    }

    onMouseUp (e) {
        if (e.button !== 0) {
            return
        }
        window.removeEventListener('mousemove', this.onMouseMoveBind)
        window.removeEventListener('mouseup', this.onMouseUpBind)
        if (this.state.scrubbing) {
            this.leaveScrubbingMode()
        } else {
            const alpha = this.positionAlpha(this.dragStartPosition, e)
            if (alpha.x < this.scrubbingThreshold) {
                this.enterEditMode()
            }
        }
        e.preventDefault()
        e.stopPropagation()
    }

    toNumeral (number, props = this.props) {
        if (Array.isArray(number)) {
            return number.map(n => this.toNumeral(n))
        }
        return number === null ? (props.min || 0) : parseInt(number)
    }

    toInteger (value) {
        return parseInt(value)
    }

    getValue () {
        return Array.isArray(this.props.value) ? '' : this.toNumeral(this.props.value)
    }

    getSingleValue () {
        if (Array.isArray(this.props.value)) {
            return Math.round(this.props.value.reduce((a, v) => a + v) / this.props.value.length)
        } else {
            return this.props.value
        }
    }

    render () {
        if (this.props.invisible) {
            return null
        }

        let title, placeholder, value, previewingValue

        if (this.state.displayValue) {
            value = this.state.displayValue
        } else {
            if (this.state.editing || this.state.scrubbing) {
                value = this.state.value
            } else {
                previewingValue = !!this.props.previewValue
                value = previewingValue ? this.props.previewValue : this.props.value
            }

            if (Array.isArray(value)) {
                const values = value.filter(n => !isNaN(n) && n !== null)
                if (values.length > 1) {
                    placeholder = this.toNumeral(value).join(', ')
                    title = d('multiple-values') + ': ' + placeholder
                } else {
                    value = this.toNumeral(value[0])
                }
            } else {
                value = isNaN(value) ? '' : this.toNumeral(value)
            }
        }

        return <div
            className={`NumericInput ${this.props.disabled && 'disabled'} ${this.props.loading && 'loading'} ${this.props.pulse && 'pulse'} ${this.state.scrubbing ? 'scrubbing' : ''} ${previewingValue && 'previewing-value'}`}
            title={title} ref={node => this.wrapperRef = node}>
            {this.props.caption && <div key="caption" className="caption" onMouseDown={this.onMouseDown.bind(this)}>
                {this.props.caption}
            </div>}
            <div className="value">
                <div className="editable">
                    <input
                        type="text"
                        onKeyDown={this.onKeyDown.bind(this)}
                        onChange={this.onTextboxChange.bind(this)}
                        onFocus={this.enterEditMode.bind(this)}
                        onBlur={this.submitChanges.bind(this)}
                        placeholder={placeholder}
                        value={value}
                        ref={node => this.inputRef = node}
                    />
                </div>
                {!this.state.editing &&
                <div className="label"
                    title={this.props.disabled || title ? '' : lang.d('numeric_input_scrubber_hint')}>
                    {!this.props.disableSteppers &&
                    <div className={`arrow icon-arrow-light-left ${!this.canStepBack(this.props.value) && 'disabled'}`}
                        onMouseDown={e => this.stepBack(e)}/>
                    }
                    {placeholder
                        ? <div key="placeholder" className="placeholder" onMouseDown={this.onMouseDown.bind(this)}>
                            {placeholder}
                        </div>
                        : <div key="caption" className="caption" onMouseDown={this.onMouseDown.bind(this)}>
                            {value} {this.props.unit && <span className="unit">{this.props.unit}</span>}
                        </div>
                    }
                    {!this.props.disableSteppers &&
                    <div
                        className={`arrow icon-arrow-light-right ${!this.canStepForward(this.props.value) && 'disabled'}`}
                        onMouseDown={e => this.stepForward(e)}/>
                    }
                </div>
                }
            </div>
        </div>
    }
}

NumericInput.propTypes = {
    value: PropTypes.number,
    previewValue: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    pixelsForStep: PropTypes.number,
    skipNumerals: PropTypes.array,
    caption: PropTypes.string,
    numeral: PropTypes.string,
    unit: PropTypes.string,
    wrapsAround: PropTypes.bool,
    sequence: PropTypes.bool,
    onChange: PropTypes.func,
    onChangePreview: PropTypes.func,
    onChangeFinal: PropTypes.func,
    onCancel: PropTypes.func,
    disabled: PropTypes.bool,
    invisible: PropTypes.bool,
    pulse: PropTypes.bool,
    disableSteppers: PropTypes.bool,
    loading: PropTypes.bool
}

function d (dictionary) {
    return dictionary
}
